import React from 'react';
import { Information } from 'icons';
import styled, { css } from 'styled-components';
import { detailPageMobilePadding } from 'styled';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ detailMargin }) =>
    detailMargin
      ? css`
          margin-bottom: ${({ theme }) => theme.space[4]}px;
          ${detailPageMobilePadding}
        `
      : ''}
`;

const StyledInformationIcon = styled(Information)`
  height: 18px;
  width: 18px;
  margin-left: ${({ theme }) => theme.space[2]}px;
`;

const StyledParagraph = styled.p`
  font-style: italic;
`;

export const ExtraInformation = ({ children, detailMargin }) => (
  <StyledWrapper detailMargin={detailMargin}>
    <StyledParagraph>{children}</StyledParagraph>
    <StyledInformationIcon />
  </StyledWrapper>
);
