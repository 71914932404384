import { createActions, handleActions } from 'redux-actions';

export const initialStateOfStatus = {
  data: [],
  error: null,
  loading: false,
  initialState: true,
  pageNumber: 0,
  loadingMoreTasks: false,
  allDataFetched: false,
};

const initialState = {
  pendingCount: undefined,
  loadingPendingCount: true,
  PENDING: initialStateOfStatus,
  APPROVED: initialStateOfStatus,
  REJECTED: initialStateOfStatus,
  SEARCH: initialStateOfStatus,
  LINE_ITEMS: {
    ...initialStateOfStatus,
    selected: {},
    sharedId: null,
    pageNumber: null,
    allDataFetched: true,
  },
  overrides: {},
};

const PURCHASE_REQUEST_UPDATE = 'PURCHASE_REQUEST_UPDATE';
const PURCHASE_REQUESTS_UPDATE = 'PURCHASE_REQUESTS_UPDATE';
const PURCHASE_REQUESTS_OVERRIDES_UPDATE = 'PURCHASE_REQUESTS_OVERRIDES_UPDATE';

export const {
  purchaseRequestUpdate,
  purchaseRequestsUpdate,
  purchaseRequestsOverridesUpdate,
} = createActions(
  PURCHASE_REQUEST_UPDATE,
  PURCHASE_REQUESTS_UPDATE,
  PURCHASE_REQUESTS_OVERRIDES_UPDATE
);

export default handleActions(
  {
    [PURCHASE_REQUEST_UPDATE]: (state, action) => {
      return {
        ...state,
        data: state.data.map(task => {
          if (task.id === action.payload.id) {
            if (action.payload.status) {
              action.payload.statusUpdateDate = new Date();
              return { ...task, ...action.payload };
            }
          }
          return task;
        }),
      };
    },
    [PURCHASE_REQUESTS_UPDATE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    [PURCHASE_REQUESTS_OVERRIDES_UPDATE]: (state, action) => {
      const { id, values } = action.payload;
      const taskSpecificJson = {};
      taskSpecificJson[id] = { ...state.overrides[id], ...values };
      return {
        ...state,
        overrides: {
          ...state.overrides,
          ...taskSpecificJson,
        },
      };
    },
  },
  initialState
);
