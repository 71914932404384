import React from 'react';
import styled, { css } from 'styled-components';

import { Loader } from 'icons';

const StyledBadgeWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.blue[6]};
  position: absolute;
  color: ${({ theme }) => theme.colors.white};
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ smallerFontSize }) =>
    smallerFontSize
      ? css`
          font-size: ${({ theme }) => theme.fontSizes[0]}px;
        `
      : `font-size: ${({ theme }) => theme.fontSizes[1]}px;`};
`;

const StyledLoader = styled(Loader)`
  width: 18px;
  height: 10px;
  * {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export const Badge = ({ number, loading = false, ...props }) => {
  return loading ? (
    <StyledBadgeWrapper {...props}>
      <StyledLoader className="badgeLoader" />
    </StyledBadgeWrapper>
  ) : number ? (
    <StyledBadgeWrapper {...props} smallerFontSize={number > 9}>
      {loading ? <Loader /> : number > 99 ? '99+' : number}
    </StyledBadgeWrapper>
  ) : null;
};
