import React from 'react';
import styled from 'styled-components';
import { Tab } from 'components';

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const StyledFocusIndicator = styled.div`
  position: absolute;
  width: ${({ width }) => width}px;
  height: 5px;
  background-color: ${({ theme }) => theme.colors.tertiary};
  bottom: 0;
  left: calc(((100% / 3) * ${({ position }) => position}) - 16.5%);
  transform: translateX(-50%);
  transition: width 0.3s, left 0.3s ease-in-out;
  @media (prefers-color-scheme: dark) {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const Tabs = ({
  selectedTab,
  focusIndicatorWidth,
  setFocusIndicatorWidth,
  data,
  selectedIndex,
  dataCy,
  ...props
}) => (
  <StyledWrapper {...props}>
    {data.map(({ title, value }) => {
      return (
        <Tab
          focusIndicatorWidth={focusIndicatorWidth}
          setFocusIndicatorWidth={setFocusIndicatorWidth}
          selected={selectedTab === value}
          value={value}
          key={value}
        >
          {title}
        </Tab>
      );
    })}
    <StyledFocusIndicator
      position={selectedIndex + 1}
      width={focusIndicatorWidth}
    />
  </StyledWrapper>
);
