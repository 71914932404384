import React from 'react';
import useReactRouter from 'use-react-router';
import { useTranslation } from 'react-i18next';
import { SectionLink } from './SectionLink';

export const SectionLinkContainer = ({ to, ...props }) => {
  const {
    location: { pathname },
  } = useReactRouter();
  const { t } = useTranslation();
  return (
    <SectionLink
      t={t}
      active={pathname.includes(to) ? 1 : 0}
      to={to}
      {...props}
    />
  );
};
