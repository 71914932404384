export const OBJECT_TYPES = {
  PURCHASE_REQUEST: 'purchaseRequest',
  TRAVEL_AND_EXPENSE: 'travelAndExpense',
};

export const PURCHASE_REQUEST_TYPES = {
  REGULAR: 'REGULAR_PURCHASE_REQUEST',
  ARIBA: 'ARIBA_PURCHASE_REQUEST',
};

export const DEFAULT_PURCHASE_REQUEST_OBJECT = {
  budget: {},
  amount: {},
  costCenter: {},
  documents: {},
  previousStatus: [{}, {}, {}, {}],
  vendor: {},
};

export const DEFAULT_TRAVEL_AND_EXPENSE_REQUEST = {
  tripDetails: {
    employeeName: '',
    companyName: '',
    companyCountry: '',
    departureDate: '',
    departureTime: '',
    arrivalDate: '',
    arrivalTime: '',
    location: '',
    country: '',
    reason: '',
    tripTotal: '',
    amountReimbursed: '',
    amountPaidByCompany: '',
    amountReimbursedFormMeals: '',
    amountReimbursedForAccomodation: '',
  },
  tripLines: [],
  attachments: [],
  meals: [],
  costAssignments: [],
  receipts: [],
};
