import React from 'react';
import useReactRouter from 'use-react-router';
import { useTranslation } from 'react-i18next';
import { PlaceholderPage } from './PlaceholderPage';

export const PlaceholderPageContainer = () => {
  const {
    match: {
      params: { section },
    },
  } = useReactRouter();
  const { t } = useTranslation();

  return <PlaceholderPage t={t} iconType={section || 'default'} />;
};
