import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

// Middlewares
const middlewares = [thunk];

// Enhancers
const enhancers = [];

const composedEnhancers = composeWithDevTools(
  applyMiddleware(...middlewares),
  ...enhancers
);

const persistConfig = {
  key: 'approvals',
  storage,
  whitelist: ['persistedUserInterface'], // only these will be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composedEnhancers);

export default store;
