import React from 'react';
import styled, { css } from 'styled-components';
import { Button, Header, HomePage, PreferenceSection } from 'components';
import {
  detailPageMobilePadding,
  StyledTaskDetailContentWrapper,
} from 'styled';
import { subscribeToNotifications } from 'utils';

const StyledWrapper = styled.main`
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    display: flex;
  }
`;

const StyledPreferencesPageWrapper = styled.div`
  width: 100%;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    height: 100vh;
    overflow: auto;
  }
`;

const StyledPreferencesContentWrapper = styled(StyledTaskDetailContentWrapper)`
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    margin-top: 64px;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[5]}) {
    margin-left: calc(-92px + 16px + ${({ theme }) => theme.space[13]}px);
  }
`;

const StyledUl = styled.ul`
  ${({ subscribedToPushNotifications }) =>
    subscribedToPushNotifications
      ? ''
      : css`
          opacity: 0.3;
        `}
`;

const StyledSubscribedParagraph = styled.p`
  margin: ${({ theme }) => theme.space[2]}px 0px;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
`;

const StyledSubscribeWrapper = styled.div`
  ${detailPageMobilePadding}
  margin-top: ${({ theme }) => theme.space[4]}px;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    margin-top: 0px;
  }
`;

const SubscribeToNotificationsCallToAction = ({
  subscribedToPushNotifications,
}) =>
  subscribedToPushNotifications ? null : (
    <StyledSubscribeWrapper>
      <Button
        data-cy="subscribeToNotificationsButton"
        onClick={subscribeToNotifications}
        filled
      >
        Subscribe to notifications
      </Button>
      <StyledSubscribedParagraph>
        You are currently not subscribed to receive notifications
      </StyledSubscribedParagraph>
    </StyledSubscribeWrapper>
  );
export const PreferencesTemplate = ({
  headerProps,
  preferences,
  loading,
  t,
  subscribedToPushNotifications,
}) => (
  <>
    <StyledWrapper>
      <HomePage sidebar={1} />
      <StyledPreferencesPageWrapper>
        <Header margin {...headerProps} />
        <StyledPreferencesContentWrapper>
          <SubscribeToNotificationsCallToAction
            subscribedToPushNotifications={subscribedToPushNotifications}
          />
          <StyledUl
            subscribedToPushNotifications={subscribedToPushNotifications}
          >
            {Object.keys(preferences).map(key => {
              return (
                <PreferenceSection
                  loading={loading}
                  title={t(`PREFERENCES.${key.toUpperCase()}`)}
                  items={preferences[key]}
                />
              );
            })}
          </StyledUl>
        </StyledPreferencesContentWrapper>
      </StyledPreferencesPageWrapper>
    </StyledWrapper>
  </>
);
