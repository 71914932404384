import React from 'react';
import { Section } from './Section';

export const PreferenceSection = ({ loading, ...props }) => (
  <Section
    loading={loading}
    boldTitle
    caseSensitiveTitle
    preferences
    {...props}
  />
);
