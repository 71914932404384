import React from 'react';
import { BrokenRocket, Check } from 'icons';
import styled from 'styled-components';
import { LoadingTemplate } from 'components';

const StyledBrokenRocker = styled(BrokenRocket)`
  width: 200px;
  height: 220px;
`;

const StyledCheck = styled(Check)`
  width: 80px;
  height: 60px;
  * {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const DownloadFilePage = ({ error, loading, t }) =>
  error ? (
    <LoadingTemplate
      icon={<StyledBrokenRocker />}
      title={t('DOWNLOADING_FILE.ERROR_TITLE')}
    />
  ) : loading ? (
    <LoadingTemplate
      icon={undefined}
      title={t('DOWNLOADING_FILE.LOADING_TITLE')}
    />
  ) : (
    <LoadingTemplate
      dataCy="downloadFileSuccesful"
      icon={<StyledCheck />}
      title={t('DOWNLOADING_FILE.SUCCES_TITLE')}
    />
  );
