import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';
import { updatePreferences } from 'store/preferences/preferences.service';
import { canShowSurveyOnIOS } from 'utils';
import { useUrlParameters } from './urlParameters';
import { useUserPreferences } from './user';

export const useShowSurvey = () => {
  const {
    history,
    location: { pathname },
  } = useReactRouter();
  const dispatch = useDispatch();

  const userPreferences = useUserPreferences();

  const { showSurvey: surveyAlreadyActive } = useUrlParameters();
  useEffect(() => {
    if (
      !surveyAlreadyActive &&
      !userPreferences.loading &&
      userPreferences.approvals?.metrics?.amountOfLogins >= 3 &&
      !userPreferences.approvals.flags.showedSurvey &&
      canShowSurveyOnIOS()
    ) {
      setTimeout(() => {
        const updatedPreferences = {
          ...userPreferences.approvals,
          flags: {
            ...userPreferences.approvals.flags,
            showedSurvey: new Date(),
          },
        };
        updatePreferences(dispatch, updatedPreferences);
        history.push(`${pathname}?showSurvey=true`);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferences]);
};
