/* eslint-disable import/no-cycle */
import * as Sentry from '@sentry/browser';
import { endpoints } from 'config';

export class ApiError extends Error {
  constructor(message, customScope) {
    super(message);
    this.customScope = customScope;
    this.name = 'ApiError';
  }
}
export const logSentryError = (error, customScope = {}) => {
  Sentry.withScope(scope => {
    Object.keys(customScope).forEach(key => {
      const value = customScope[key];
      scope.setTag(key, value);
    });
    Sentry.captureException(error);
  });
};

export const logSentryMessage = (message, customScope = {}) => {
  Sentry.withScope(scope => {
    Object.keys(customScope).forEach(key => {
      const value = customScope[key];
      scope.setTag(key, value);
    });
    Sentry.captureMessage(message);
  });
};

export const redirectWebflow = () => {
  window.location = encodeURI(endpoints.oauthRedirect); // untested and probably wrong since it's not how redirect works but don't want to touch logout since it works
};

const isNotAuthenticatedFetch = response => {
  // For now this handles strange edge cases where status = 0.
  // Could also be used to handle more clear status codes like 302 of 403
  if (response.status === 0) {
    redirectWebflow();
    return true;
  }
  return false;
};

export const secureFetch = async (
  url,
  options = {},
  returnRawResponse = false
) => {
  const { headers, ...customOptions } = options;
  const customHeaders = new Headers(headers || {});
  const redirect = 'manual';
  const requestOptions = {
    redirect, // We do not follow redirects, we want secureFetch to handle them for us
    ...customOptions,
    headers: customHeaders,
  };
  const response = await fetch(url, requestOptions);

  if (isNotAuthenticatedFetch(response)) {
    return; // early return and no longer bother with the rsponse
  }

  if (response.status >= 400) {
    const customApiError = new ApiError(
      `fetching ${url} resulted into ${response.status}`
    );
    logSentryError(customApiError, {
      fetchedUrl: url,
      statusCode: response.status,
      ...options,
    });

    throw customApiError;
  }
  try {
    if (returnRawResponse) return response;

    const json = await response.json();
    return json;
  } catch (error) {
    const parsingError = new ApiError(`could not parse response for ${url}`);

    logSentryError(error, {
      customMessage: `could not parse response for ${url}`,
      fetchedUrl: url,
    });

    throw parsingError;
  }
};
