import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { overridesUpdate } from 'store/tasks/tasks.duck';
import { getTasksOverrides } from 'store/tasks/tasks.selectors';
import { Toast } from './Toast';

const useHideOverrides = overridesNotify => {
  const dispatch = useDispatch();
  const allUpdatingFalse = overridesNotify.every(({ updating }) => !updating);
  useEffect(() => {
    if (allUpdatingFalse && overridesNotify.length > 0) {
      setTimeout(() => {
        overridesNotify.forEach(({ id, ...values }) => {
          dispatch(
            overridesUpdate({
              id,
              values: {
                ...values,
                notify: false,
              },
            })
          );
        });
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUpdatingFalse, overridesNotify]);
};

export const ToastContainer = ({ ...props }) => {
  const { t } = useTranslation();
  const overrides = useSelector(getTasksOverrides);
  let status = '';
  let action = '';
  let numberOfPendingItems = 0;
  let numberOfErrorItems = 0;
  const overridesNotify = Object.keys(overrides)
    .map(key => ({
      id: key,
      ...overrides[key],
    }))
    .filter(({ notify }) => notify);

  overridesNotify.forEach(({ updating, message, newStatus }) => {
    if (action && action !== newStatus) {
      action = 'MULTIPLE';
    } else {
      action = newStatus;
    }
    if (updating) {
      status = 'UPDATING';
      numberOfPendingItems += 1;
    }
    if (message) {
      status = 'ERROR';
      numberOfErrorItems += 1;
    }
  });
  if (overridesNotify.length > 0 && !status) {
    status = 'SUCCESS';
  }
  useHideOverrides(overridesNotify);
  const numberOfItems =
    numberOfPendingItems || numberOfErrorItems || overridesNotify.length;
  const shouldShowToast = action && status;
  return shouldShowToast ? (
    <Toast
      labelText={t(`TOAST.${status}`, {
        action: t(`TOAST.STATUS_VERB_${action}`),
        count: numberOfItems,
      })}
      action={action}
      t={t}
      status={status}
      {...props}
    />
  ) : null;
};
