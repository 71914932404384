import React from 'react';
import styled from 'styled-components';
import { ChangelogItem, SinglePageTemplate } from 'components';
import {
  detailPageMobilePadding,
  StyledSection,
  StyledSectionTitle,
} from 'styled';

const StyledChangelogItem = styled(ChangelogItem)`
  margin: ${({ theme }) => theme.space[7]}px 0px;
  ${detailPageMobilePadding};
  &:first-of-type {
    margin-top: ${({ theme }) => theme.space[4]}px;
  }
  max-width: 500px;
`;

export const ChangelogTemplate = ({ data = [], title }) => (
  <SinglePageTemplate title={title}>
    <ul>
      {data.map(({ month, entries }) => (
        <StyledSection key={month}>
          <StyledSectionTitle sticky>{month}</StyledSectionTitle>
          <ul>
            {entries.map(({ date, items }) =>
              items.map(item => (
                <StyledChangelogItem date={date} key={item.text} {...item} />
              ))
            )}
          </ul>
        </StyledSection>
      ))}
    </ul>
  </SinglePageTemplate>
);
