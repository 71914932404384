import React from 'react';
import { MultipleTasksTemplate, SidePanel } from 'components';

export const MultipleTasksPage = ({ t, active, ...props }) => (
  <SidePanel
    headerProps={{ title: t('MULTIPLE_TASKS.TITLE') }}
    active={active}
    dataCy="multipleTasksSidePanel"
  >
    <MultipleTasksTemplate {...props} />
  </SidePanel>
);
