import React from 'react';
import styled from 'styled-components';
import { Button, ErrorInfo, TaskListItemComment } from 'components';
import { BottomButtonWrapper } from 'styled';

const StyledErrorInfo = styled(ErrorInfo)`
  margin-top: ${({ theme }) => theme.space[4]}px;
`;

export const MultipleTasksCommentGroup = ({
  t,
  onSubmit,
  tasks,
  overrideComment,
  setOverrideComment,
  setHasMandatoryError,
  hasMandatoryError,
  checkMandatoryError,
  setTextAreaValues,
  textAreaValues,
  previousRoute,
}) => {
  return (
    <>
      <StyledErrorInfo
        title={t('MULTIPLE_TASKS_CONFIRM.GENERAL_MANDATORY_ERROR_DESCRIPTION')}
        condition={hasMandatoryError}
      />
      {tasks.map((task, index) => (
        <TaskListItemComment
          index={index}
          setOverrideComment={setOverrideComment}
          overrideComment={overrideComment}
          task={task}
          key={task.id}
          setHasMandatoryError={setHasMandatoryError}
          checkMandatoryError={checkMandatoryError}
          setTextAreaValues={setTextAreaValues}
          textAreaValues={textAreaValues}
        />
      ))}
      <BottomButtonWrapper>
        <Button
          dataCy="taskDetailCancelRejectButton"
          to={previousRoute}
          type="submit"
        >
          {t('COMMON.CANCEL')}
        </Button>
        <Button
          filled
          type="submit"
          onSubmit={onSubmit}
          dataCy="multipleConfirmSubmit"
        >
          {t('COMMON.SUBMIT')}
        </Button>
      </BottomButtonWrapper>
    </>
  );
};
