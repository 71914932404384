import React from 'react';
import { customTheme } from 'theme/theme';
import { Section, SidePanel } from 'components';
import {
  combineCodeAndName,
  getMediumHumanReadableDate,
  toPriceNumber,
} from 'utils';

export const LineItemDetailPage = ({
  costAssignmentData,
  t,
  data,
  task,
  ...props
}) => (
  <SidePanel
    headerProps={{
      title: t('LINE_ITEM_DETAILS.TITLE'),
    }}
    mobilePadding={false}
    {...props}
  >
    {data
      ? [
          {
            title: '',
            items: [
              {
                type: 'KEY_VALUE',
                keyText: t('LINE_ITEM_DETAILS.ITEM_NAME'),
                valueText: data.name,
                valueTextBold: true,
                valueTextColor: customTheme.colors.primary,
              },
              {
                type: 'KEY_VALUE',
                keyText: t('LINE_ITEM_DETAILS.PRICE'),
                valueText: toPriceNumber(data.amount),
                valueTextBold: true,
              },
              {
                type: 'KEY_VALUE',
                keyText: t('TASK_DETAILS.QUANTITY'),
                valueText: data.quantity,
                valueTextBold: true,
              },
              {
                type: 'KEY_VALUE',
                keyText: t('LINE_ITEM_DETAILS.UNIT'),
                valueText: data.unit.code,
                valueTextBold: true,
              },
              {
                type: 'KEY_VALUE',
                keyText: t('LINE_ITEM_DETAILS.NEEDED_BY'),
                valueText: getMediumHumanReadableDate(data.needBy),
                valueTextBold: true,
              },
              {
                type: 'KEY_VALUE',
                keyText: t('LINE_ITEM_DETAILS.SUPPLIER_NAME'),
                valueText: combineCodeAndName(data.supplier),
              },
              {
                type: 'KEY_VALUE',
                keyText: t('TASK_DETAILS.DESCRIPTION'),
                valueText: data.description,
              },
              {
                type: 'FILE_LIST',
                keyText: t('TRAVEL_AND_EXPENSE_DETAILS.ATTACHTED_DOCUMENTS'),
                values: data.attachments || [],
                taskId: task.id,
              },
              {
                type: 'KEY_VALUE',
                keyText: t('LINE_ITEM_DETAILS.SHIP_TO'),
                valueText: combineCodeAndName(data.shipTo),
              },
            ],
          },
          {
            title: t('TASK_DETAILS.PROCUREMENT_INFORMATION'),
            items: [
              {
                type: 'KEY_VALUE',
                keyText: t(`TASK_DETAILS.${costAssignmentData?.type}.NAME`),
                valueText: costAssignmentData?.name,
              },
              {
                type: 'KEY_VALUE',
                keyText: t(`TASK_DETAILS.${costAssignmentData?.type}.CODE`),
                valueText: costAssignmentData?.code,
              },
              {
                type: 'KEY_VALUE',
                keyText: t('LINE_ITEM_DETAILS.BILL_TO'),
                valueText: combineCodeAndName(data.billTo),
              },
            ],
          },
          {
            title: t('LINE_ITEM_DETAILS.PO_RELEASE_STATUS'),
            items: [
              {
                type: 'METROLINE_ARIBA_PURCHASE_REQUESTS',
                task,
                displayedOnLineItem: true,
              },
            ],
          },
          {
            title: t('TASK_DETAILS.COMMENTS'),
            items: [
              {
                type: 'VIEW_ONLY_COMMENTS',
                values: data.comments,
                key: 'viewOnlyComments',
              },
            ],
          },
        ].map(({ type, ...sectionProps }) => {
          return <Section key={sectionProps.title} {...sectionProps} />;
        })
      : null}
  </SidePanel>
);
