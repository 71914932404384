import 'config/i18n.config';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { PreLoginPage } from 'components';
import store from 'store/store';
import { Main } from './Main';

// const EXCLUDED_PATHS_REGEX =  /^(?!\/oauth2|\/api|\/notifications).*$/
const persistor = persistStore(store);

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <PreLoginPage {...{ MainPage: Main }} />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
