import React from 'react';
import { FaqSection, SinglePageTemplate } from 'components';

export const FaqTemplate = ({ title, data }) => (
  <SinglePageTemplate title={title}>
    <ul>
      {data.map(questionCategory => {
        return (
          <FaqSection
            key={questionCategory.sectionName}
            questionCategory={questionCategory}
          />
        );
      })}
    </ul>
  </SinglePageTemplate>
);
