export const convertValueToNumber = value => {
  if (typeof value === 'string' || typeof value === 'number') {
    const number = Number(value);
    if (!Number.isNaN(number) && value !== '') {
      return number;
    }
  }
  return null;
};

export const getValueFromPrice = ({
  value: valueFromProps,
  totalValue,
  amount,
}) => {
  let returnValue = null;
  [valueFromProps, totalValue, amount].forEach(value => {
    const convertedValue = convertValueToNumber(value);
    if (convertedValue !== null) returnValue = convertedValue;
  });
  return returnValue;
};

export const toPriceNumber = props => {
  if (props) {
    const { currency } = props;
    const value = getValueFromPrice(props);

    if (value !== null && currency) {
      return Number(value).toLocaleString(undefined, {
        style: 'currency',
        currency,
        currencyDisplay: 'symbol',
      });
    }
  }
  return null;
};

export const getShareOfTotal = (share, total, currency) =>
  toPriceNumber({ amount: total * (share / 100), currency });

export const getPurchaseRequestId = (id, long) => {
  if (typeof id === 'string') {
    if (id.match(/PR\d+/)?.[0]) {
      return id;
    }
    const matchedIds = (() => {
      if (long) return id.match(/\d+/);
      return id.match(/[^00]\d+/);
    })();
    if (matchedIds) {
      return matchedIds[0];
    }
  }
  return '';
};

export const getPurchaseRequestIdAndItemId = id => {
  if (typeof id === 'string') {
    const matchedIds = id.match(/\d+/g);
    if (matchedIds && matchedIds.length === 2) {
      return {
        purchaseRequestId: matchedIds[0],
        itemId: matchedIds[1],
      };
    }
  }
  return null;
};

export const getShortPurchaseRequestItemId = id => {
  if (typeof id === 'string') {
    const matchedItemId = id.match(/\d+$/g);
    if (matchedItemId) {
      const leadingZeroRemoved = matchedItemId[0].replace(/^0+/gm, '');
      return leadingZeroRemoved;
    }
  }
  return '';
};

export const getTravelAndExpenseIds = id => {
  if (typeof id === 'string') {
    const matchedIds = id.match(/\d+/g);
    if (matchedIds && matchedIds.length === 2) {
      return {
        id: matchedIds[0],
        employeeNumber: matchedIds[1],
      };
    }
  }
  return {
    id: null,
    employeeNumber: null,
  };
};

export const getTaskIdWithEmployeeNumber = task => {
  if (task.id) {
    return task.employeeNumber ? `${task.id}::${task.employeeNumber}` : task.id;
  }
  return null;
};

export const getTaskTypeSpecificId = task => {
  if (task.tripNumber) {
    return getTaskIdWithEmployeeNumber(task);
  }
  return task.id;
};
export const getFirstTaskId = tasks => {
  if (tasks && tasks.data && tasks.data.length > 0) {
    return getTaskIdWithEmployeeNumber(tasks.data[0]);
  }
  return null;
};
