import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getFullName } from 'utils';

const StyledLink = styled(Link)`
  font-size: inherit;
  color: ${({ theme }) => theme.colors.tertiary};
  line-height: ${({ theme }) => theme.lineHeights[1]}px;
  text-decoration: underline;
`;

export const UserLink = ({
  currentUser,
  pathname,
  name,
  account,
  t,
  possesive = false,
  linkUrl,
  sidePanelUserLink,
  ...props
}) => {
  if (!name) {
    name = getFullName(account);
  }

  const LinkWithName = () => {
    const adaptedName = possesive
      ? name.endsWith('s')
        ? `${name}'`
        : `${name}'s`
      : name;

    if (!account.email) return adaptedName;
    return (
      <StyledLink to={linkUrl} {...props}>
        {adaptedName}
      </StyledLink>
    );
  };

  const you = possesive ? t('TASK_LIST.YOUR') : t('TASK_LIST.YOU');
  const isLoggedInUser = (() => {
    if (
      typeof account?.email === 'string' &&
      typeof currentUser?.email === 'string'
    ) {
      return account?.email.toLowerCase() === currentUser?.email.toLowerCase();
    }
    return false;
  })();
  return isLoggedInUser ? you : <LinkWithName />;
};
