import React from 'react';
import { SidePanel } from 'components';
import { environment } from 'config';
import { StyledIframe } from 'styled';

export const SatisfactionSurveyPage = ({ active, t, ...props }) => {
  return (
    <SidePanel
      headerProps={{
        title: t('COMMON.SURVEY_TITLE'),
      }}
      showAsPopUp
      active={active}
      {...props}
    >
      <StyledIframe
        data-cy="surveyPage"
        src={environment.satisfactionSurveyLink}
      />
    </SidePanel>
  );
};
