import React, { useEffect, useState } from 'react';
import { endpoints } from 'config';
import { frontendEnvironment, secureFetch } from 'utils';
import { SwitchEnvironmentPage } from './SwitchEnvironmentPage';

export const SwitchEnvironmentPageContainer = ({ ...props }) => {
  const fixedEnvironments = [
    {
      name: 'Production',
      url: 'https://approvals.borealis.studio',
    },
    {
      name: 'User Acceptance Testing (UAT)',
      url: 'https://approvals.uat.aks.borealisgroup.com',
    },
    {
      name: 'Development',
      url: 'https://approvals.dev.borealis.studio',
    },
    {
      name: 'Localhost',
      url: 'http://localhost:3000',
    },
  ];
  const [dynamicEnvironments, setDynamicEnvironments] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      const json = await secureFetch(endpoints.environments);
      setDynamicEnvironments(json);
      setLoading(false);
    })();
  }, []);

  if (frontendEnvironment === 'production') {
    return null;
  }

  return (
    <SwitchEnvironmentPage
      fixedEnvironments={fixedEnvironments}
      dynamicEnvironments={dynamicEnvironments}
      loading={loading}
      {...props}
    />
  );
};
