import React from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import { ExtraInformation } from 'components';
import { getFirstAndLastName } from 'utils';
import { OpenMultipleTasksLink } from './OpenMultipleTasksLink';

export const OpenMultipleTravelAndExpensesLinkContainer = ({
  task,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    location: { pathname },
  } = useReactRouter();
  return (
    <>
      <ExtraInformation detailMargin>
        {t('TRAVEL_AND_EXPENSE_DETAILS.OPEN_MULTIPLE_TASKS_EXTRA_INFORMATION', {
          firstName: getFirstAndLastName(task?.tripDetails?.employee?.name)
            ?.firstName,
        })}
      </ExtraInformation>
      <OpenMultipleTasksLink pathname={pathname} t={t} {...props} />
    </>
  );
};
