import React from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import { useUrlParameters } from 'hooks';
import { SatisfactionSurveyPage } from './SatisfactionSurveyPage';

export const SatisfactionSurveyPageContainer = ({ ...props }) => {
  const {
    location: { pathname },
  } = useReactRouter();
  const { t } = useTranslation();
  const { showSurvey } = useUrlParameters();
  return showSurvey ? (
    <SatisfactionSurveyPage
      overidePreviousPath={pathname}
      t={t}
      active={showSurvey}
      {...props}
    />
  ) : null;
};
