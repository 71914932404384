import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Arrow, Cross } from 'icons';
import styled, { css } from 'styled-components';
import {
  HamburgerButton,
  RefreshButton,
  SearchButton,
  SearchInput,
} from 'components';
import { underIOSStatusBar } from 'styled';

const StyledHeader = styled.header`
  user-select: none;
  ${underIOSStatusBar}
  position: sticky;
  background-color: white;
  z-index: 5;
  width: 100%;
  top: 0;
  transition: transform 0.5s ease-in-out;
  @media only screen and (max-width: ${({ theme }) => theme.breakPoints[3]}) {
    ${({ theme, borderBottom }) =>
      borderBottom
        ? css`
            border-bottom: 1px solid ${theme.colors.lightGray};
            @media (prefers-color-scheme: dark) {
              border-bottom: 1px solid ${theme.colors.lighterBlack};
            }
          `
        : css``}
  }
  @media (prefers-color-scheme: dark) {
    background-color: ${({ theme }) => theme.colors.black};
  }
  ${({ showHeader }) =>
    !showHeader
      ? css`
          transform: translateY(-100%);
        `
      : ''}
`;

const StyledSubWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.space[3]}px;
  /* justify-content: space-between; */
`;

const largeTitleStyle = css`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.primary};
  @media (prefers-color-scheme: dark) {
    color: ${({ theme }) => theme.colors.darkerWhite};
  }
`;

const StyledLargeH1 = styled.h1`
  ${largeTitleStyle}
  position: relative;
  display: block;

  margin: auto;
  ${({ singlePage }) =>
    singlePage
      ? css`
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[3]}) {
            margin-left: ${({ theme }) => theme.space[5]}px;
            font-weight: normal;
            font-size: 20px;
          }
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[5]}) {
            margin-left: ${({ theme }) => theme.space[13]}px;
          }
        `
      : ''}
`;
const StyledArrowWrapper = styled.div`
  position: absolute;
  padding-top: 20px;
  ${({ hideNavigationOnDesktop, theme }) =>
    hideNavigationOnDesktop
      ? css`
          @media only screen and (min-width: ${theme.breakPoints[3]}) {
            display: none;
          }
        `
      : ''}

  ${({ closeActionRightDesktop, theme }) =>
    closeActionRightDesktop
      ? css`
          @media only screen and (min-width: ${theme.breakPoints[3]}) {
            right: 0;
          }
        `
      : ''}
`;

const StyledCross = styled(Cross)`
  position: relative;
  right: -1px;
  path {
    fill: ${({ theme }) => theme.colors.primary};
    @media (prefers-color-scheme: dark) {
      fill: ${({ theme }) => theme.colors.darkerWhite};
    }
  }
`;

const StyledLink = styled(Link)`
  padding: ${({ theme }) => theme.space[3]}px ${({ theme }) => theme.space[4]}px;
  padding-top: 22px;
`;

const StyledHeaderWrapper = styled.div`
  position: relative;
  min-height: 59px;
  ${({ theme, borderBottom }) =>
    borderBottom
      ? css`
          border-bottom: 1px solid ${theme.colors.lightGray};
          @media (prefers-color-scheme: dark) {
            border-bottom: 1px solid ${theme.colors.lighterBlack};
          }
        `
      : null}
  ${({ onlyMobile, theme }) =>
    onlyMobile
      ? css`
          @media only screen and (min-width: ${theme.breakPoints[3]}) {
            display: none;
          }
        `
      : ''}

  ${({ emptyDesktop, theme }) =>
    emptyDesktop
      ? css`
          * {
            @media only screen and (min-width: ${theme.breakPoints[3]}) {
              display: none;
            }
          }
        `
      : ''}
  
  @media print {
    display: none;
  }
`;

const StyledArrow = styled(Arrow)`
  @media (prefers-color-scheme: dark) {
    path {
      stroke: ${({ theme }) => theme.colors.darkerWhite} !important;
    }
  }
`;

export const Header = forwardRef(
  (
    {
      showHeader,
      to,
      title = 'title',
      t,
      children,
      search = false,
      pathname,
      activateSearch = false,
      activeSearch = false,
      searchInputRef,
      onlyMobile = true,
      closable = false,
      borderBottom = true,
      hamburgerButton = false,
      refreshButton = false,
      emptyDesktop = false, // Displays an empty header on desktop
      singlePage = false,
      detailDesktopBackgroundColor,
      goBackEnabled,
      goBackHandler,
      hideNavigationOnDesktop,
      closeActionRightDesktop,
      ...props
    },
    ref
  ) => (
    <>
      <StyledHeader showHeader={showHeader}>
        <StyledHeaderWrapper
          borderBottom={borderBottom}
          onlyMobile={onlyMobile}
          emptyDesktop={emptyDesktop}
          detailDesktopBackgroundColor={detailDesktopBackgroundColor}
        >
          {hamburgerButton ? <HamburgerButton /> : null}
          {refreshButton ? (
            <RefreshButton /> /* the refresh button will only show up in standalone systems */
          ) : null}
          {search ? (
            <SearchInput ref={ref} activeSearch={activeSearch} />
          ) : null}
          {to ? (
            <StyledArrowWrapper
              hideNavigationOnDesktop={hideNavigationOnDesktop}
              closable={closable}
              closeActionRightDesktop={closeActionRightDesktop}
            >
              <StyledLink
                aria-label={t('COMMON.GO_TO_LINK', { link: to })}
                to={to}
                closable={closable ? 1 : 0}
              >
                {closable ? (
                  <StyledCross data-cy="headerCross" width={15} height={15} />
                ) : (
                  <StyledArrow width={8} height={15} />
                )}
              </StyledLink>
            </StyledArrowWrapper>
          ) : null}
          {!to && goBackEnabled ? (
            <StyledArrowWrapper closable={closable}>
              <StyledLink
                aria-label={t('COMMON.GO_TO_LINK', { link: to })}
                onClick={() => {
                  goBackHandler();
                }}
                closable={closable}
              >
                {closable ? (
                  <StyledCross data-cy="headerCross" width={15} height={15} />
                ) : (
                  <StyledArrow width={8} height={15} />
                )}
              </StyledLink>
            </StyledArrowWrapper>
          ) : null}
          <StyledSubWrapper {...props}>
            {/* same width as mirrored element to have correct title alignment */}
            <StyledLargeH1 singlePage={singlePage}>{title}</StyledLargeH1>
          </StyledSubWrapper>
          {search ? <SearchButton onClick={activateSearch} /> : null}
        </StyledHeaderWrapper>
        {children}
      </StyledHeader>
    </>
  )
);
