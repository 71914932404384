import React from 'react';
import { useTranslation } from 'react-i18next';
import { getActiveCostAssignment, getShareOfTotal } from 'utils';
import { SwipeableCards } from './SwipeableCards';

export const CostAssignmentsCardsContainer = ({ task }) => {
  const { t } = useTranslation();
  const data = task.costAssignments.map(costAssignment => {
    const { tripTotal: total, currency } = task.tripDetails;
    const { costSharePercentage } = costAssignment;
    const activeCostAssignment = getActiveCostAssignment(costAssignment);
    const { code, name, owner, type } = activeCostAssignment;
    return {
      header: {
        left: {
          keyText: t(`TASK_DETAILS.${type}.CODE`),
          valueText: code,
        },
        right: {
          keyText: t('TASK_DETAILS.SHARE'),
          valueText: getShareOfTotal(total, costSharePercentage, currency),
        },
      },
      body: [
        {
          keyText: t(`TASK_DETAILS.${type}.NAME`),
          valueText: name,
          valueMaxTwoLines: true,
        },
        {
          keyText: t(`TASK_DETAILS.${type}.OWNER`),
          valueText: owner,
        },
      ],
      showMore: true,
    };
  });
  return <SwipeableCards data={data} />;
};
