import React from 'react';
import { ContactTemplate, SidePanel } from 'components';

export const ContactPage = ({
  previousPath,
  active,
  onRefSidePanelSet,
  closed,
  t,
  ...props
}) => (
  <SidePanel
    headerProps={{
      title: t('CONTACT_DETAILS.TITLE'),
    }}
    active={active}
    dataCy="contactPanel"
  >
    <ContactTemplate t={t} {...props} />
  </SidePanel>
);
