import React, { forwardRef } from 'react';
import { TaskListTemplate } from 'components';

export const TaskListPage = forwardRef(
  (
    { tasks, countOfPendingTasks, t, loading, overrides, title, ...props },
    ref
  ) => (
    <TaskListTemplate
      headerProps={{
        title,
        search: true,
        onlyMobile: false,
        hamburgerButton: true,
        refreshButton: true,
      }}
      TaskListItemGroupProps={{
        loading,
        test: 'test',
        tasks,
        overrides,
      }}
      ref={ref}
      {...props}
    />
  )
);
