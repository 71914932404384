import { useSelector } from 'react-redux';
import {
  getTasksLineItems,
  getTravelAndExpenseEmployeeItems,
} from 'store/tasks/tasks.selectors';
import { useObjectTypeInViewSpecificCode } from './tasks';

export const useSelectedTasks = () => {
  const tasksLineItems = useSelector(getTasksLineItems);
  const travelAndExpenseEmployeeItems = useSelector(
    getTravelAndExpenseEmployeeItems
  );
  const filterTasks = tasks =>
    tasks.data.filter(task => {
      return tasks.selected[task.id];
    });

  return useObjectTypeInViewSpecificCode({
    travelAndExpenseCallback: () => filterTasks(travelAndExpenseEmployeeItems),
    purchaseRequestCallback: () => filterTasks(tasksLineItems),
  });
};

export const useNumberOfMultipleItem = () => {
  const tasksLineItems = useSelector(getTasksLineItems);
  const travelAndExpenseEmployeeItems = useSelector(
    getTravelAndExpenseEmployeeItems
  );
  const getCount = items => {
    if (items && items.data && items.data.length > 1) {
      if (items.data.length > 99) return 99;
      return items.data.length;
    }
    return null;
  };

  return useObjectTypeInViewSpecificCode({
    travelAndExpenseCallback: () => getCount(travelAndExpenseEmployeeItems),
    purchaseRequestCallback: () => getCount(tasksLineItems),
  });
};

export const useRequestorNameFromMultipleItems = () => {
  const TravelAndExpenseEmployeeItems = useSelector(
    getTravelAndExpenseEmployeeItems
  );
  return TravelAndExpenseEmployeeItems?.data?.[0]?.requestor?.name;
};
