import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { getTaskListSortingField } from 'store/persistedUserInterface/persistedUserInterface.selectors';
import { getTaskListSearch } from 'store/userInterface/userInterface.selectors';
import {
  getPurchaseRequestId,
  getSortingDirection,
  getSortingField,
} from 'utils';

export const useRefetchParameters = () => {
  const {
    match: {
      params: { taskId },
    },
  } = useReactRouter();
  const selectedSortingField = useSelector(getTaskListSortingField);
  const search = useSelector(getTaskListSearch);
  const sharedId = getPurchaseRequestId(taskId, true);
  return {
    sortingField: getSortingField(selectedSortingField),
    sortingDirection: getSortingDirection(selectedSortingField),
    search,
    sharedId,
  };
};
