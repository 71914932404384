import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Loader } from 'icons';
import { StatusIcon } from 'components';
import { BottomStatusWrapper } from 'styled';

const StyledStatusBar = styled.div`
  ${({ status }) => {
    switch (status) {
      case 'PENDING':
        return css`
          color: ${({ theme }) => theme.colors.primary};
          background-color: ${({ theme }) => theme.colors.lightblue[0]};
        `;
      case 'APPROVED':
        return css`
          color: ${({ theme }) => theme.colors.green};
          background-color: ${({ theme }) => theme.colors.lightGreen};
          @media (prefers-color-scheme: dark) {
            background-color: #0d1a00;
          }
        `;
      case 'REJECTED':
        return css`
          color: ${({ theme }) => theme.colors.red[5]};
          background-color: ${({ theme }) => theme.colors.red[0]};
          @media (prefers-color-scheme: dark) {
            background-color: #1d0000;
          }
        `;
      case 'DELETED':
        return css`
          background-color: ${({ theme }) => theme.colors.background};
        `;
      default:
        return null;
    }
  }}

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  padding: ${({ theme }) => theme.space[3]}px 0px;
`;

const StyledLoader = styled(Loader)`
  width: 50px;
  * {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledStatusIcon = styled(StatusIcon)`
  margin-right: 10px;
`;

export const StatusBar = ({ status, override }) => {
  const { t } = useTranslation();
  if (!status && !override) return null;
  return (
    <BottomStatusWrapper status={status}>
      {override && override.updating ? (
        <StyledStatusBar>
          <StyledLoader />
        </StyledStatusBar>
      ) : (
        <StyledStatusBar status={status}>
          <StyledStatusIcon status={status} />
          {t(`TASK_DETAILS.${status}_STATUS`)}
        </StyledStatusBar>
      )}
    </BottomStatusWrapper>
  );
};
