import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import { routes } from 'config';
import { useCurrentUser } from 'hooks/user';
import { AccountOptions } from './AccountOptions';

export const AccountOptionsContainer = ({ ...props }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const {
    location: { pathname },
  } = useReactRouter();

  const homepage = pathname === routes.home;

  const { t } = useTranslation();
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  }

  const currentUser = useCurrentUser();
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <AccountOptions
      t={t}
      ref={ref}
      open={open}
      setOpen={setOpen}
      currentUser={currentUser}
      homepage={homepage ? 1 : 0}
      {...props}
    />
  );
};
