import React from 'react';
import styled, { css } from 'styled-components';
import {
  ContactPage,
  LineItemDetailPage,
  TaskDetailTemplate,
  UserLink,
} from 'components';
import { routes } from 'config';
import { getPurchaseRequestId, toPriceNumber } from 'utils';

const StyledWrapper = styled.div`
  ${({ contactPanelActive }) =>
    contactPanelActive
      ? css`
          display: none;
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[3]}) {
            display: block;
          }
        `
      : ``}
`;

const AribaDetailPage = ({
  previousPath,
  task,
  costAssignmentData,
  t,
  pathname,
  section,
  approveProps,
  override,
  contactPanelActive,
  ...props
}) => {
  const { loading } = props;

  return (
    <>
      <StyledWrapper contactPanelActive={contactPanelActive}>
        <TaskDetailTemplate
          loading={loading}
          override={override}
          contactPanelActive={contactPanelActive}
          headerProps={{
            title: t('TASK_DETAILS.TITLE'),
            to: previousPath,
            search: false,
            onlyMobile: false,
            emptyDesktop: true,
            detailDesktopBackgroundColor: true,
          }}
          approveRejectProps={{
            previousPath,
          }}
          sections={[
            {
              title: t('TASK_DETAILS.PURCHASE_INFORMATION'),
              items: [
                {
                  type: 'KEY_VALUE',
                  keyText: t('TASK_DETAILS.TITLE_OF_REQUEST'),
                  valueText: task.title,
                  valueTextBold: true,
                },
                {
                  type: 'KEY_VALUE_GROUP',
                  key: 1,
                  items: [
                    {
                      type: 'KEY_VALUE',
                      keyText: t('TASK_DETAILS.PURCHASE_REQUEST_ID'),
                      valueText: getPurchaseRequestId(task.id),
                      dataCy: 'keyValueRequestId',
                      valueTextBold: true,
                    },
                    {
                      type: 'KEY_VALUE',
                      keyText: t('TASK_DETAILS.TOTAL_PRICE_REQUISITION'),
                      valueText: toPriceNumber(task.amount),
                      valueTextBold: true,
                    },
                  ],
                },
                {
                  type: 'KEY_VALUE_GROUP',
                  key: 2,
                  items: [
                    {
                      type: 'KEY_VALUE',
                      keyText: t('TASK_DETAILS.REQUESTOR_NAME'),
                      valueText: <UserLink account={task?.requestor} />,
                    },
                    {
                      type: 'FILE_LIST',
                      keyText: t(
                        'TRAVEL_AND_EXPENSE_DETAILS.ATTACHTED_DOCUMENTS'
                      ),
                      values: task.attachments || [],
                      taskId: task.id,
                    },
                  ],
                },
              ],
            },
            {
              title: t('TASK_DETAILS.LINE_ITEMS'),
              loading,
              key: 3,
              items: [
                {
                  type: 'LINE_ITEMS_CARDS',
                  key: 'LINE_ITEMS_CARDS',
                  task,
                },
              ],
            },
            {
              title: t('TASK_DETAILS.COMMENTS'),
              loading,
              items: [
                {
                  key: 'viewOnlyComments',
                  type: 'VIEW_ONLY_COMMENTS',
                  values: task.comments,
                },
              ],
            },
            {
              title: t('TASK_DETAILS.STATUS'),
              loading,
              items: [
                {
                  type: 'METROLINE_ARIBA_PURCHASE_REQUESTS',
                  task,
                  displayedOnLineItem: false,
                },
              ],
            },
          ]}
          approveProps={{
            // ...approveProps,
            to: `${pathname}/${routes.confirmApprove}`,
            text: t('COMMON.APPROVE'),
            filled: true,
          }}
          rejectProps={{
            to: `${pathname}/${routes.confirmReject}`,
            text: t('COMMON.REJECT'),
          }}
          task={task}
          {...props}
        />
      </StyledWrapper>
      <ContactPage task={task} />
      <LineItemDetailPage task={task} />
    </>
  );
};

export default AribaDetailPage;
