import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { routes } from 'config';
import { useActiveSearch, useSaveScrollPosition } from 'hooks';
import {
  getTaskListSortingField,
  getTaskListStatusFilter,
} from 'store/persistedUserInterface/persistedUserInterface.selectors';
import {
  getPurchaseRequestTasks,
  getTasksOverrides,
  getTravelAndExpenseTasks,
} from 'store/tasks/tasks.selectors';
import { fetchSearchTasks } from 'store/tasks/tasks.service';
import { getTaskListSearch } from 'store/userInterface/userInterface.selectors';
import {
  getFirstTaskId,
  getObjectTypeFromSection,
  getSortingDirection,
  getSortingField,
  getTaskListTitleKeyFromSection,
  injectRouteParameter,
  isMobile,
} from 'utils';
import { TaskListPage } from './TaskListPage';

const useSearchOnList = taskType => {
  const dispatch = useDispatch();
  const search = useSelector(getTaskListSearch);
  const selectedSorting = useSelector(getTaskListSortingField);

  useEffect(() => {
    if (search && dispatch) {
      fetchSearchTasks(
        dispatch,
        search,
        getSortingField(selectedSorting),
        getSortingDirection(selectedSorting),
        true,
        taskType
      );
    }
  }, [dispatch, search, selectedSorting, taskType]);
};

const useDefineWhichTasksToShow = (rawTasks, filter) => {
  const search = useSelector(getTaskListSearch);
  if (search === null) {
    return rawTasks[filter];
  }
  if (search === '') return [];
  return rawTasks.SEARCH;
};

let previousFilter;
export const TaskListPageContainer = ({ selectedTaskId, ...props }) => {
  useSaveScrollPosition(window);

  const { t } = useTranslation();
  const taskListPageRef = useRef();
  const {
    match: {
      params: { section },
    },
    history,
  } = useReactRouter();

  const objectTypeInView = getObjectTypeFromSection(section);
  useSearchOnList(objectTypeInView);

  const purchaseRequests = useSelector(getPurchaseRequestTasks);
  const travelAndExpenses = useSelector(getTravelAndExpenseTasks);

  let rawTasks;
  switch (objectTypeInView) {
    case 'travelAndExpense':
      rawTasks = travelAndExpenses;
      break;
    case 'purchaseRequest':
    default:
      rawTasks = purchaseRequests;
  }
  const filter = useSelector(getTaskListStatusFilter);

  const tasks = useDefineWhichTasksToShow(rawTasks, filter);

  const selectFirstTask = () => {
    const firstTaskId = getFirstTaskId(tasks);
    if (!isMobile() && firstTaskId) {
      history.replace(
        injectRouteParameter(routes.taskDetail, {
          section,
          taskId: firstTaskId,
        })
      );
    }
  };

  useEffect(() => {
    if (!selectedTaskId) {
      selectFirstTask();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);
  useEffect(() => {
    if (filter !== previousFilter && previousFilter && filter) {
      selectFirstTask();
    }
    previousFilter = filter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const titleKey = getTaskListTitleKeyFromSection(section);
  return (
    <TaskListPage
      tasks={tasks}
      t={t}
      selectedTaskId={selectedTaskId}
      loading={tasks.loading}
      searchIsActive={useActiveSearch()}
      ref={taskListPageRef}
      overrides={useSelector(getTasksOverrides)}
      title={t(titleKey)}
      filter={filter}
      {...props}
    />
  );
};
