import React from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';
// import { Spinner } from 'icons';
import { Loader } from 'icons';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledWrapper = styled.div`
  position: fixed;
  top: ${({ theme }) => theme.space[5]}px;
  left: 0;
  right: 0;
  color: red;
  z-index: 20;
  margin-left: auto;
  margin-right: auto;
  width: 65px;
  opacity: ${({ opacity }) => opacity};
  transition: 0.2s opacity;
`;

const StyledRefreshIcon = styled(Loader)`
  width: 65px;
  * {
    fill: ${({ theme }) => theme.colors.primary};
  }
  /* height: 50px; */
  /* animation-name: ${rotate};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
`;

export const PullToRefresh = ({ show }) => {
  const portalRoot = document.getElementById('portal');
  return ReactDOM.createPortal(
    <StyledWrapper opacity={show ? 1 : 0}>
      <StyledRefreshIcon />
    </StyledWrapper>,
    portalRoot
  );
};
