import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandableParagraph } from './ExpandableParagraph';

export const ExpandableParagraphContainer = ({
  children,
  height = 162,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [shouldShowButton, setShouldShowButton] = useState(false);
  const { t } = useTranslation();
  const onSubmit = event => {
    event.preventDefault();
    setOpen(!open);
  };
  const paragraphRef = useCallback(
    node => {
      if (node) {
        if (height <= node.clientHeight && !shouldShowButton) {
          setShouldShowButton(true);
        } else if (height > node.clientHeight && shouldShowButton) {
          setShouldShowButton(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldShowButton, children]
  );
  return (
    <ExpandableParagraph
      t={t}
      ref={paragraphRef}
      open={open}
      onSubmit={onSubmit}
      height={height}
      shouldShowButton={shouldShowButton}
      {...props}
    >
      {children}
    </ExpandableParagraph>
  );
};
