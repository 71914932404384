import * as React from 'react';
import { Information } from 'icons';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyle from 'theme/globalStyle';
import { customTheme } from 'theme/theme';

const backgroundColors = {
  success: customTheme.colors.lightGreen,
  warning: customTheme.colors.orange[0],
  error: customTheme.colors.red[0],
  info: customTheme.colors.background,
};

// we use specific colours for the title (as opposed to customTheme.colors.state[])
// in order to increase the contrast between the text and the background
// to pass the accessibility test in Lighthouse.
const titleColors = {
  success: customTheme.colors.green,
  warning: customTheme.colors.orange[5],
  error: customTheme.colors.red[6],
  info: customTheme.colors.primary,
};

// icon height should be same as title text
const StyledWarningIcon = styled(Information)`
  width: 25px;
  height: 25px;
  * {
    fill: ${props => titleColors[props.state]};
  }
`;

const StyledHideButton = styled.div`
  position: absolute;
  top: ${customTheme.space[4]}px;
  right: ${customTheme.space[10]}px;
  color: ${customTheme.colors.black};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: ${customTheme.colors.gray};
  }
  @media (max-width: 540px) {
    right: ${customTheme.space[4]}px;
  }
`;

const StyledTitle = styled.div`
  margin-left: ${customTheme.space[2]}px;
  margin-bottom: ${customTheme.space[2]}px;
  font-size: ${customTheme.fontSizes[2]}pt;
  font-weight: ${customTheme.fontWeights.bold};
`;

const StyledText = styled.div`
  max-width: calc(100% - 100px);
  color: ${customTheme.colors.lighterBlack};
  white-space: pre;
  a {
    color: inherit;
    font-weight: bold;
    text-decoration: underline;
  }
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledContentWrapper = styled.div`
  height: inherit;
  display: flex;
  flex-direction: column;
  color: inherit;
  font-size: ${customTheme.fontSizes[2]}px;
`;

const StyledBanner = styled.div`
  color: ${customTheme.colors.primary};
  width: 100vw;
  padding-top: ${customTheme.space[4]}px;
  padding-bottom: ${customTheme.space[4]}px;
  padding-left: ${customTheme.space[10]}px;
  padding-right: ${customTheme.space[10]}px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  @media (max-width: 540px) {
    padding-left: ${customTheme.space[4]}px;
    padding-right: ${customTheme.space[4]}px;
  }
`;

const StyledShadowBanner = styled.div`
  height: 80px;
  display: block;
`;

export const Banner = ({
  closable,
  closeHandler,
  text,
  state = 'error',
  title,
  ...props
}) => (
  <ThemeProvider theme={customTheme}>
    <GlobalStyle />
    <StyledShadowBanner />
    <StyledBanner
      {...props}
      style={{ backgroundColor: backgroundColors[state] }}
    >
      <StyledContentWrapper>
        <StyledTitleWrapper>
          <StyledWarningIcon state={state} />
          <StyledTitle
            dangerouslySetInnerHTML={{ __html: title }}
            data-cy="bannerTitle"
            style={{ color: titleColors[state] }}
          />
        </StyledTitleWrapper>
        <StyledText
          dangerouslySetInnerHTML={{ __html: text }}
          data-cy="bannerText"
        />
      </StyledContentWrapper>
      {closable ? (
        <StyledHideButton
          onClick={closeHandler}
          dangerouslySetInnerHTML={{ __html: 'Hide' }}
        />
      ) : null}
    </StyledBanner>
  </ThemeProvider>
);
