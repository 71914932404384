// Purchase Requests
export const getTasks = state => state.tasks.purchaseRequests;
export const getPurchaseRequestTasks = state => {
  return state.tasks.purchaseRequests;
};
export const getPendingPurchaseRequestCount = state =>
  state.tasks.purchaseRequests.pendingCount;

export const getLoadingPendingPurchaseRequestCount = state =>
  state.tasks.purchaseRequests.loadingPendingCount;

export const getTasksLineItems = state =>
  state.tasks.purchaseRequests.LINE_ITEMS;
export const getTasksOverrides = state => state.tasks.common.overrides;

// Travel and expenses
export const getTravelAndExpenseTasks = state => {
  return state.tasks.travelAndExpenses;
};

export const getTravelAndExpenseEmployeeItems = state =>
  state.tasks.travelAndExpenses.EMPLOYEE_ITEMS;

export const getPendingTravelAndExpensesCount = state =>
  state.tasks.travelAndExpenses.pendingCount;
export const getLoadingPendingTravelAndExpensesCount = state =>
  state.tasks.travelAndExpenses.loadingPendingCount;
