import React from 'react';
import styled, { css } from 'styled-components';
import {
  ExpandableParagraph,
  FileList,
  MissingDataParagraph,
} from 'components';
import { StyledPlaceholder } from 'styled';

const StyledKey = styled.div`
  color: ${({ theme }) => theme.colors.grayText};
  font-weight: bold;
  text-align: ${({ textAlign }) => textAlign};
  @media print {
    font-weight: bold;
  }
  @media (prefers-color-scheme: dark) {
    color: ${({ theme }) => theme.colors.secondary};
  }
  font-size: 18px;
`;
const StyledLi = styled.li``;
const StyledValue = styled.div`
  line-height: ${({ theme }) => theme.lineHeights[1]}px;
  text-align: ${({ textAlign }) => textAlign};
  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: ${({ fontWeight }) => fontWeight};
  ${({ valueTextBig }) =>
    valueTextBig
      ? css`
          font-size: ${({ theme }) => theme.fontSizes[3]}px;
        `
      : css`
          font-size: ${({ theme }) => theme.fontSizes[2]}px;
          margin-top: ${({ theme }) => theme.space[1]}px;
        `}
  ${({ valueMaxTwoLines }) =>
    valueMaxTwoLines
      ? css`
          height: 42px;
          overflow: hidden;
        `
      : ''}
  ${({ textAlign, valueMaxTwoLines }) =>
    textAlign !== 'right' && !valueMaxTwoLines
      ? css`
          display: flex;
          align-items: center;
        `
      : ''}

  ${({ valueTextColor }) =>
    valueTextColor
      ? css`
          color: ${() => valueTextColor};
        `
      : css``}
  @media (prefers-color-scheme: dark) {
    color: ${({ theme }) => theme.colors.darkerWhite};
  }
`;

export const KeyValue = ({
  keyText = 'keyText',
  valueText,
  className,
  type,
  t,
  loading,
  dataCy = '',
  valueTextBold,
  valueTextBig,
  valueMaxTwoLines,
  isExpandable = false,
  textAlign = 'left',
  valueTextColor,
  height,
  extraComponent = null,
  ...props
}) => {
  // trim valueText up front to filter our " " strings
  valueText = typeof valueText === 'string' ? valueText.trim() : valueText;
  return (
    <StyledLi className={className}>
      <StyledKey textAlign={textAlign}>{keyText}</StyledKey>
      <StyledValue
        data-cy={dataCy}
        textAlign={textAlign}
        fontWeight={valueTextBold ? 'bold' : 'normal'}
        valueTextBig={valueTextBig}
        valueMaxTwoLines={valueMaxTwoLines}
        valueTextColor={valueTextColor}
      >
        {(() => {
          if (loading) {
            return (
              <StyledPlaceholder
                height={isExpandable ? `${height || 155}px` : '21px'}
                width={isExpandable ? '100%' : '200px'}
                color="black"
              />
            );
          }
          if (type === 'FILE_LIST') {
            return <FileList t={t} {...props} />;
          }
          if (valueText)
            return isExpandable ? (
              <ExpandableParagraph height={height}>
                {valueText}
              </ExpandableParagraph>
            ) : (
              valueText
            );
          return <MissingDataParagraph />;
        })()}
      </StyledValue>
      {extraComponent}
    </StyledLi>
  );
};
