import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loop } from 'icons';
import styled from 'styled-components';
import { HeaderIconButton } from './HeaderIconButton';

const StyledHeaderIconButton = styled(HeaderIconButton)`
  right: 0;
`;

const StyledLoop = styled(Loop)`
  width: 24px;
  height: 24px;
  path {
    fill: ${({ theme }) => theme.colors.primary};
    @media (prefers-color-scheme: dark) {
      fill: ${({ theme }) => theme.colors.darkerWhite};
    }
  }
`;

export const SearchButton = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <StyledHeaderIconButton
      {...props}
      label={t('COMMON.SEARCH')}
      Icon={StyledLoop}
      dataCy="searchButton"
    />
  );
};
