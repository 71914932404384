import React from 'react';
import {
  Attachment,
  Chat,
  Code,
  Dollar,
  Faq,
  Idea,
  Logout,
  Preferences,
} from 'icons';
import { HomeTemplate } from 'components';
import { routes } from 'config';
import { canShowSurveyOnIOS, injectRouteParameter, logout } from 'utils';

const { placeholder: placeholderUrl, taskList: taskListUrl } = routes;

export const HomePage = ({
  t,
  sidebar,
  countOfPendingTasks,
  loadingPurchaseRequestCount,
  countOfPendingTravelAndExpenses,
  loadingTravelAndExpensesCount,
  currentUser,
  enableBetaFeatures,
  ...props
}) => {
  const sectionData = [
    {
      title: t('COMMON.PURCHASE_REQUESTS'),
      Icon: Attachment,
      number: countOfPendingTasks,
      to: injectRouteParameter(taskListUrl, {
        section: 'purchase-requests',
      }),
      loadingCount: loadingPurchaseRequestCount,
    },
    {
      title: t('COMMON.EXPENSES'),
      Icon: Dollar,
      number: countOfPendingTravelAndExpenses,
      to: injectRouteParameter(taskListUrl, {
        section: 'travel-and-expenses',
      }),
      disabled: false,
      loadingCount: loadingTravelAndExpensesCount,
    },
    {
      title: t('COMMON.IDEA'),
      Icon: Idea,
      number: 0,
      // Disable feature on older versions of IOS because not supported
      to: canShowSurveyOnIOS() ? routes.ideaSurvey : placeholderUrl,
      disabled: !canShowSurveyOnIOS(),
    },
  ];

  return (
    <HomeTemplate
      title={t('HOME.TITLE')}
      sectionData={sectionData}
      sidebar={sidebar}
      faqProps={{
        title: t('COMMON.FAQ'),
        Icon: Faq,
        number: 0,
        to: routes.faq,
        inverseIcon: false,
        sidebar,
      }}
      preferenceProps={{
        title: t('COMMON.PREFERENCES'),
        Icon: Preferences,
        number: 0,
        to: routes.preferences,
        sidebar,
      }}
      currentUser={currentUser}
      contactProps={{
        title: t('COMMON.SUPPORT'),
        Icon: Chat,
        number: 0,
        to: routes.support,
        sidebar,
      }}
      changeLogProps={{
        title: t('COMMON.CHANGELOG'),
        Icon: Code,
        sidebar,
        to: routes.changelog,
      }}
      logoutProps={{
        title: t('ACCOUNT_OPTIONS.LOGOUT'),
        Icon: Logout,
        number: 0,
        to: false,
        onClick: logout,
        sidebar,
      }}
      {...props}
    />
  );
};
