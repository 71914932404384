/* eslint-disable react/button-has-type */
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const StyledButton = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  font: inherit;
  font-weight: normal;
  padding: 12px ${({ theme }) => theme.space[3]}px;
  font-size: 16px;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-height: 45px;
  ${({ filled }) =>
    filled
      ? css`
          background-color: ${({ theme }) => theme.colors.primary};
          color: ${({ theme }) => theme.colors.white};
          @media (prefers-color-scheme: dark) {
            background-color: ${({ theme }) => theme.colors.darkerWhite};
            border-color: ${({ theme }) => theme.colors.darkerWhite};
            color: ${({ theme }) => theme.colors.black};
          }
        `
      : css`
          background-color: transparent;
          color: ${({ theme }) => theme.colors.primary};
          @media (prefers-color-scheme: dark) {
            border-color: ${({ theme }) => theme.colors.darkerWhite};
            color: ${({ theme }) => theme.colors.darkerWhite};
          }
        `}
  cursor: pointer;
  &:hover,
  &:focus {
    outline: none;
    ${({ filled }) =>
      filled
        ? css`
            border: 1px solid ${({ theme }) => theme.colors.tertiary};
            background-color: ${({ theme }) => theme.colors.tertiary};
          `
        : css`
            border: 1px solid ${({ theme }) => theme.colors.tertiary};
            color: ${({ theme }) => theme.colors.tertiary};
          `}
  }
`;

const WrappingElement = ({ to, children }) => {
  if (to) {
    return <Link to={to}>{children}</Link>;
  }
  return children;
};

export const Button = ({
  children = 'Download',
  type = 'button',
  dataCy = '',
  filled,
  to,
  onSubmit,
  ...props
}) => {
  return (
    <WrappingElement to={to}>
      <StyledButton
        data-cy={dataCy}
        filled={filled}
        onClick={onSubmit}
        type={type}
        {...props}
      >
        {children}
      </StyledButton>
    </WrappingElement>
  );
};
