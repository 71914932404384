import React from 'react';
import styled from 'styled-components';
import { Stars } from './Stars';
import { ReactComponent as RocketPart } from './rocketPart.svg';

const StyledRocket = styled(RocketPart)`
  position: relative;
  z-index: 2;
`;

export const Rocket = props => {
  return (
    <Stars {...props} color="#005d9a">
      <StyledRocket
        id="prefix__Layer_1"
        x={0}
        y={0}
        viewBox="0 0 118.7 133"
        xmlSpace="preserve"
      />
    </Stars>
  );
};
