import React from 'react';
import styled from 'styled-components';
import { MissingDataParagraph, UserLink } from 'components';
import { getMediumHumanReadableDate } from 'utils';

const StyledLi = styled.li`
  margin-bottom: ${({ theme }) => theme.space[4]}px;
`;

const StyledTextContent = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  font-weight: bold;
`;

const StyledUserLinkAndDataWrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
`;

export const ViewOnlyComments = ({ values, className }) => {
  if (!values || values.length === 0) {
    return <MissingDataParagraph className={className} />;
  }
  return (
    <ul className={className}>
      {values.map(({ user, date, text, id }) => (
        <StyledLi key={id}>
          <StyledTextContent>{text}</StyledTextContent>
          <StyledUserLinkAndDataWrapper>
            <UserLink account={user} />
            ,&nbsp;{getMediumHumanReadableDate(date)}
          </StyledUserLinkAndDataWrapper>
        </StyledLi>
      ))}
    </ul>
  );
};
