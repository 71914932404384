import {
  isDevelopment,
  isLocal,
  isPreview,
  isProduction,
  isUat,
} from './environment';

const gtmToken = (() => {
  return isProduction ? 'GTM-5N8RDRX' : 'GTM-NQLFB4J';
})();

export const approvalsConfig = {
  sentryEnabled: !isLocal,
  oneSignalEnabled:
    isPreview || isProduction || isUat || isDevelopment || isLocal,
  userTrackingEnabled: isProduction || isPreview || isLocal,
  gtmToken,
};
