import { PURCHASE_REQUEST_TYPES } from 'config';

export const executeRegularOrAribaPurchaseRequestLogic = ({
  task,
  aribaCallback,
  regularPurhcaseRequestCallback,
}) => {
  if (task?.type === PURCHASE_REQUEST_TYPES.ARIBA) {
    return aribaCallback();
  }
  if (task?.type === PURCHASE_REQUEST_TYPES.REGULAR) {
    return regularPurhcaseRequestCallback();
  }
  return null;
};

export const selectActiveCostAssignment = data => {
  let output;
  if (data?.costCenter)
    output = {
      type: 'costCenter',
      ...data.costCenter,
    };
  if (data?.costAssignment) {
    ['order', 'costCenter', 'network'].forEach(type => {
      if (data.costAssignment[type]?.code)
        output = {
          type,
          ...data.costAssignment[type],
        };
    });
  }
  return output;
};

export const filterPurchaseOrderInAribaApprovalSequence = ({
  approvalSequence,
  displayedOnLineItem,
}) => {
  return approvalSequence.filter(({ reason }) => {
    const isPurchaseOrderRelease = reason.startsWith('PO release – ');
    if (displayedOnLineItem) {
      return isPurchaseOrderRelease;
    }
    return !isPurchaseOrderRelease;
  });
};

export const purhaseRequestHasAttachment = task => {
  return executeRegularOrAribaPurchaseRequestLogic({
    task,
    regularPurhcaseRequestCallback: () => {
      return false;
    },
    aribaCallback: () => {
      if (task.attachments.length > 0) return true;
      return task.lineItems.some(({ attachments }) => attachments.length > 0);
    },
  });
};
