import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Header, SortBy, Tabs, TaskListItemSwipeGroup } from 'components';

const StyledSortByWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.space[2]}px 0px;
  padding-right: ${({ theme }) => theme.space[4]}px;
  @media (prefers-color-scheme: dark) {
    background-color: #171717;
  }
`;

export const TaskListTemplate = forwardRef(
  (
    {
      headerProps,
      TaskListItemGroupProps,
      selectedTaskId,
      searchIsActive,
      ...props
    },
    ref
  ) => (
    <section data-cy="taskList" ref={ref} {...props}>
      <Header {...headerProps}>
        {!searchIsActive ? <Tabs /> : null}
        {TaskListItemGroupProps.loading ||
        (TaskListItemGroupProps.tasks.data &&
          TaskListItemGroupProps.tasks.data.length > 0) ? (
          <StyledSortByWrapper>
            <SortBy />
          </StyledSortByWrapper>
        ) : null}
      </Header>
      <TaskListItemSwipeGroup
        selectedTaskId={selectedTaskId}
        {...TaskListItemGroupProps}
      />
    </section>
  )
);
