export const frontendEnvironment = process.env.REACT_APP_FRONTEND_ENV;
export const backendEnvironment = process.env.REACT_APP_BACKEND_ENV;

export const isProduction = frontendEnvironment === 'production';
export const isBeta = isProduction && window.location.hostname.includes('beta');
export const isUat = frontendEnvironment === 'uat';
export const isDevelopment = frontendEnvironment === 'development';
export const isTest = frontendEnvironment === 'test';
export const isPreview = frontendEnvironment === 'preview';
export const isLocal = frontendEnvironment === 'local';
export const isLocalDevelopmentWithProductionData =
  backendEnvironment === 'prod' && isLocal;

export const getPrNumberFromHostName = hostName => {
  const matchedNumber = hostName.match(/\d+/);
  return matchedNumber && matchedNumber[0] ? Number(matchedNumber[0]) : null;
};
