import React from 'react';
import { Cross } from 'icons';
import styled from 'styled-components';
import { MissingDataParagraph } from 'components';
import { StyledPlaceholder } from 'styled';

const IconBadge = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  margin-right: ${({ theme }) => theme.space[3]}px;
  max-width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContactLinkWrapper = styled.a`
  display: flex;
  margin-bottom: ${({ theme }) => theme.space[5]}px;
`;

const ContactInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 100%; /* ie fix for word wrap */
`;

const StyledLabel = styled.p`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  color: ${({ theme }) => theme.colors.darkerGray};
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.space[0]}px;
`;

const StyledLinkText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  color: ${({ theme }) => theme.colors.tertiary};
  word-break: break-all;
`;

export const ContactLink = ({
  icon = <Cross width={20} height={20} />,
  label = 'contact label',
  value,
  loading,
  href,
  hrefPreposition,
  target = '_self',
}) => {
  const hrefLink = (() => {
    if (href && !hrefPreposition) return href;
    if (href) {
      return `${hrefPreposition}:${href}`;
    }

    return value ? `${hrefPreposition}:${value}` : null;
  })();

  return (
    <ContactLinkWrapper href={hrefLink} target={target}>
      <IconBadge>{icon}</IconBadge>
      <ContactInfoWrapper>
        <StyledLabel>{label}</StyledLabel>
        {value || loading ? (
          <StyledLinkText>
            {loading ? (
              <StyledPlaceholder width="150px" height="17px" color="tertiary" />
            ) : (
              value
            )}
          </StyledLinkText>
        ) : (
          <MissingDataParagraph />
        )}
      </ContactInfoWrapper>
    </ContactLinkWrapper>
  );
};
