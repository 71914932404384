import React, { useCallback, useState } from 'react';
import { FaqQuestion } from './FaqQuestion';

export const FaqQuestionContainer = ({ ...props }) => {
  const [sectionToggled, setSectionToggled] = useState(false);

  const [openHeight, setOpenHeight] = useState(0);
  const collapseHandler = event => {
    event.preventDefault();
    setSectionToggled(!sectionToggled);
  };

  const paragraphRef = useCallback(
    node => {
      if (node) {
        // This timeout prevents a portion of the answer from being hidden
        // Seems like the app needs a little bit of time to actually calculate the reeal height
        setTimeout(() => {
          setOpenHeight(node.clientHeight);
        }, 100);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <FaqQuestion
      sectionToggled={sectionToggled}
      collapseHandler={collapseHandler}
      ref={paragraphRef}
      openHeight={openHeight}
      {...props}
    />
  );
};
