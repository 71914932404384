import { useEffect, useState } from 'react';
import { getIncidents } from 'utils/incidents';

export const useIncidentsBanner = () => {
  const [incidents, setIncidents] = useState({
    bannerText: null,
    bannerTitle: null,
    bannerEnabled: false,
  });
  useEffect(() => {
    getIncidents().then(allIncidents => {
      if (allIncidents) {
        const incidentBanner = allIncidents.find(
          incident => incident.bannerEnabled && incident.active
        );

        if (incidentBanner) {
          setIncidents({
            bannerEnabled: true,
            bannerTitle: incidentBanner.summary,
            bannerText: incidentBanner.bannerText,
          });
        }
      }
    });
  }, []);
  return incidents;
};
