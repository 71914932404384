import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Error } from 'components';

const StyledTitleSpan = styled.span`
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 2px;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: ${({ theme }) => theme.space[2]}px;
`;

const StyledTitleSubSpan = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: normal;
`;

const StyledHintSpan = styled.span`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  line-height: ${({ theme }) => theme.lineHeights[1]}px;
  margin-top: ${({ theme }) => theme.space[1]}px;
  color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledCountOfCharacters = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
`;

const StyledWrapper = styled.div``;

const shake = keyframes`
  0% {
    left: 0;
  }
  20% {
    left: 10px; 
  }
  60% {
    left: -10px;
  }
  80% {
    left: 10px;
  }
  100% {
    left: 0px;
  }
`;

const StyledTextarea = styled.textarea`
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 0;
  appearance: none;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  margin-top: 12px;
  resize: none;
  padding: ${({ theme }) => theme.space[1]}px;
  @media (prefers-color-scheme: dark) {
    background-color: ${({ theme }) => theme.colors.black};
    border: 1px solid ${({ theme }) => theme.colors.darkerGray};
  }
  background-color: transparent;
  ${({ error }) =>
    error
      ? css`
          position: relative;
          animation: ${shake} 0.3s linear;
          animation-delay: 0.2s;
        `
      : ''}
`;
//   ${commonSubtitleStyle}
export const Textarea = ({
  title = 'title',
  hint = "I'm a hint text",
  id = 'textarea',
  className,
  currentAmountOfCharacter = 500,
  maximumAmountOfCharacters = 500,
  required = false,
  t,
  characterError,
  textAreaRef,
  mandatoryError,
  showRequiredInTitle = true,
  dataCy,
  ...props
}) => (
  <StyledWrapper>
    <label htmlFor={id}>
      <StyledTitleSpan>
        {title}
        {showRequiredInTitle ? (
          <StyledTitleSubSpan>
            {required ? t('TEXTAREA.MANDATORY') : t('TEXTAREA.OPTIONAL')}
          </StyledTitleSubSpan>
        ) : null}
      </StyledTitleSpan>
      <Error
        title={t('TEXTAREA.MANDATORY_ERROR')}
        condition={mandatoryError}
        data-cy="textAreaMandatoryError"
      />
      <Error
        title={t('TEXTAREA.CHARACTER_ERROR')}
        condition={characterError}
        data-cy="textAreaCharacterError"
      />
      <StyledHintSpan>
        {hint || '\u00a0'}
        <StyledCountOfCharacters data-cy="textAreaCountOfCharacters">
          {currentAmountOfCharacter}/{maximumAmountOfCharacters}
        </StyledCountOfCharacters>
      </StyledHintSpan>
    </label>
    <StyledTextarea
      cols="40"
      rows={maximumAmountOfCharacters / 60}
      maxLength={maximumAmountOfCharacters}
      {...props}
      error={mandatoryError || characterError}
      id={id}
      ref={textAreaRef}
      data-cy={dataCy}
    />
  </StyledWrapper>
);
