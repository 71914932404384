import React, { useCallback } from 'react';
import { ErrorInfo } from './ErrorInfo';

export const ErrorInfoContainer = ({ condition = true, ...props }) => {
  const wrapperRef = useCallback(
    node => {
      if (node && condition) {
        node.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [condition]
  );
  return condition ? <ErrorInfo ref={wrapperRef} {...props} /> : null;
};
