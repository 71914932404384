import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useUserPreferences } from 'hooks';
import { updatePreferences } from 'store/preferences/preferences.service';
import { getOneSignalId } from 'store/user/user.selectors';
import { subscribeToNotifications } from 'utils';
import { PreferenceOption } from './PreferenceOption';

export const PreferenceOptionContainer = ({ value, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentPreferences = useUserPreferences().approvals;

  const subscribedToPushNotifications = Boolean(useSelector(getOneSignalId));

  const toggleHandler = (sectionKey, dataKey, newValue) => {
    if (!subscribedToPushNotifications) {
      subscribeToNotifications();
    } else {
      const updatedPreferences = currentPreferences;
      updatedPreferences[sectionKey][dataKey] = newValue;
      updatePreferences(dispatch, updatedPreferences);
    }
  };
  return (
    <PreferenceOption
      t={t}
      value={value}
      toggleHandler={toggleHandler}
      {...props}
    />
  );
};
