import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React from 'react';
import useReactRouter from 'use-react-router';

import { getOpenHamburgerMenu } from 'store/userInterface/userInterface.selectors';
import {
  getPendingPurchaseRequestCount,
  getLoadingPendingPurchaseRequestCount,
  getPendingTravelAndExpensesCount,
  getLoadingPendingTravelAndExpensesCount,
} from 'store/tasks/tasks.selectors';
import { useCurrentUser } from 'hooks/user';
import { useResetScrollPosition, useSetDocumentTitle } from 'hooks';
import { userInterfaceUpdate } from 'store/userInterface/userInterface.duck';
import { getEnableBetaFeatures } from 'store/user/user.selectors';
import { HomePage } from './HomePage';

const useRouteChangeToCloseHamburgerMenu = closeHamburgerMenu => {
  const { history } = useReactRouter();

  let lastRoute;
  history.listen(location => {
    if (lastRoute && lastRoute !== location.pathname) {
      closeHamburgerMenu();
    }
    lastRoute = location.pathname;
  });
};
export const HomePageContainer = ({ ...props }) => {
  const { t } = useTranslation();
  const pendingCount = useSelector(getPendingPurchaseRequestCount);
  const loadingPurchaseRequestCount = useSelector(
    getLoadingPendingPurchaseRequestCount
  );

  const pendingTravelAndExpensesCount = useSelector(
    getPendingTravelAndExpensesCount
  );
  const loadingTravelAndExpensesCount = useSelector(
    getLoadingPendingTravelAndExpensesCount
  );

  const isHamburgerMenuOpen = useSelector(getOpenHamburgerMenu);
  useSetDocumentTitle(t('COMMON.HOME'));
  useResetScrollPosition();
  const dispatch = useDispatch();
  const closeHamburgerMenu = () => {
    dispatch(userInterfaceUpdate({ openHamburgerMenu: false }));
  };
  useRouteChangeToCloseHamburgerMenu(closeHamburgerMenu);
  const currentUser = useCurrentUser();
  return (
    <HomePage
      countOfPendingTasks={pendingCount}
      loadingPurchaseRequestCount={loadingPurchaseRequestCount}
      countOfPendingTravelAndExpenses={pendingTravelAndExpensesCount}
      loadingTravelAndExpensesCount={loadingTravelAndExpensesCount}
      t={t}
      isHamburgerMenuOpen={isHamburgerMenuOpen}
      closeHamburgerMenu={closeHamburgerMenu}
      currentUser={currentUser}
      enableBetaFeatures={useSelector(getEnableBetaFeatures)}
      {...props}
    />
  );
};
