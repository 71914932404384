import React from 'react';
import styled from 'styled-components';
import { IconButton, StyledIconWrapper, StyledLabel } from './IconButton';

const StyledIconButton = styled(IconButton)`
  position: relative;
  width: 100%;
  max-width: 350px;
  border: 1px solid ${({ theme }) => theme.colors.tertiary};
  padding: ${({ theme }) => theme.space[3]}px 0px;
  display: flex;
  align-items: center;
  ${StyledIconWrapper} {
    margin-left: ${({ theme }) => theme.space[4]}px;
    position: relative;
    top: 2px;
    @media only screen and (min-width: ${({ theme }) => theme.breakPoints[0]}) {
      margin-left: ${({ theme }) => theme.space[5]}px;
    }
  }
  ${StyledLabel} {
    position: absolute;
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.tertiary};
    text-transform: uppercase;
    @media (prefers-color-scheme: dark) {
      color: ${({ theme }) => theme.colors.tertiary};
    }
  }
`;

export const RoundIconButton = ({ ...props }) => (
  <StyledIconButton {...props} />
);
