import React from 'react';
import { Link } from 'react-router-dom';
import { Cross, Logo } from 'icons';
import styled, { css } from 'styled-components';
import { SectionLink } from 'components';
import { routes } from 'config';
import {
  onlyMobileStyle,
  resetButtonLayout,
  StyledBackdrop,
  underIOSStatusBar,
} from 'styled';
import {
  frontendEnvironment,
  getPrNumberFromHostName,
  getUserName,
  isLocalDevelopmentWithProductionData,
  mataHari,
} from 'utils';

const StyledMain = styled.main`
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.colors.primary} 0%,
    ${({ theme }) => theme.colors.tertiary} 100%
  );
  ${() =>
    isLocalDevelopmentWithProductionData
      ? css`
          background: linear-gradient(
            180deg,
            ${({ theme }) => theme.colors.red[5]} 0%,
            ${({ theme }) => theme.colors.red[7]} 100%
          );
        `
      : ''}
  ${({ sidebar }) =>
    sidebar
      ? css`
          display: block;
          position: absolute;
          z-index: 7;
          width: 80%;
          transition: transform 0.2s;
          transform: translateX(-100%);
          @media only screen and (min-width: ${({ theme }) =>
            theme.breakPoints[3]}) {
            display: block;
            width: 120px;
            position: static;
            transform: translateX(0%);
          }
          @media only screen and (min-width: ${({ theme }) =>
            theme.breakPoints[5]}) {
            width: 190px;
          }
          height: 100vh;
          overflow: auto;
          background-color: ${({ theme }) => theme.colors.primary};
          /* border-right: 1px solid ${({ theme }) =>
            theme.colors.lightGray}; */
        `
      : css`
          height: 100vh;
          display: block;
          overflow-y: scroll;
          ${underIOSStatusBar}
        `}
  ${({ isHamburgerMenuOpen, sidebar }) =>
    isHamburgerMenuOpen && sidebar
      ? css`
          transform: translateX(0%);
        `
      : ''}
`;

const StyledLogoWrapper = styled.div`
  ${({ sidebar }) =>
    sidebar
      ? css`
          @media only screen and (max-width: ${({ theme }) =>
              theme.breakPoints[3]}) {
            justify-content: flex-start;
          }
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[3]}) {
            height: 138px;
          }
        `
      : css`
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[2]}) {
            padding-top: ${({ theme }) => theme.space[5]}px;
            margin-bottom: ${({ theme }) => theme.space[8]}px;
          }
          padding-top: ${({ theme }) => theme.space[4]}px;
          margin-bottom: ${({ theme }) => theme.space[4]}px;
        `}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSeperatorWrapper = styled.div`
  justify-content: center;
  display: none;
  ${({ sidebar }) =>
    sidebar
      ? css`
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[3]}) {
            display: flex;
          }
        `
      : ''}
`;

const sharedSeperatorStyle = css`
  border: none;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  width: 60px;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[5]}) {
    width: 80px;
  }
`;

const AccountOptionsSeperator = styled.hr`
  border: none;
  top: 0;
  position: sticky;
  width: 100% !important;
  height: 56px; /* same height as account options */
  background-color: ${({ theme }) => theme.colors.primary};
  ${() =>
    isLocalDevelopmentWithProductionData
      ? css`
          background-color: ${({ theme }) => theme.colors.red[5]};
        `
      : ''}
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  @media only screen and (max-width: ${({ theme }) => theme.breakPoints[3]}) {
    display: none;
  }
`;

const StyledFirstSeperator = styled.hr`
  ${sharedSeperatorStyle};
  margin-bottom: ${({ theme }) => theme.space[4]}px;
`;

const StyledSecondSeperator = styled.hr`
  ${sharedSeperatorStyle};
  ${onlyMobileStyle};
  ${({ sidebar }) =>
    sidebar
      ? css`
          margin-top: ${({ theme }) => theme.space[9]}px;
          margin-bottom: ${({ theme }) => theme.space[9]}px;

          @media only screen and (max-width: ${({ theme }) =>
              theme.breakPoints[3]}) {
            margin: ${({ theme }) => theme.space[4]}px 0px;
            margin-left: 97px;
            width: 100px;
          }
        `
      : css`
          width: calc(100% - ${({ theme }) => theme.space[4]}px) !important;
          margin: ${({ theme }) => theme.space[5]}px 0px;

          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[5]}) {
            margin: ${({ theme }) => theme.space[9]}px 0px;
          }
          @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
            margin-top: 0;
          }
        `}
`;

const StyledLogo = styled(Logo)`
  overflow: hidden;

  ${({ sidebar }) =>
    sidebar
      ? css`
          @media only screen and (max-width: ${({ theme }) =>
              theme.breakPoints[3]}) {
            #logo_text {
              display: none;
            }
            width: 57px;
            height: 53px;
            margin-top: 30px;
            margin-left: 24px;
            margin-bottom: ${({ theme }) => theme.space[2]}px;
          }
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[3]}) {
            width: 70px;
            height: 67px;
          }
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[5]}) {
            width: 85px;
            height: 80px;
          }
        `
      : css`
          width: 92px;
          height: 86px;
        `}
`;

const StyledContentSuperWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.space[5]}px;
  ${({ sidebar }) =>
    sidebar
      ? css`
          width: 100%;

          @media only screen and (max-width: ${({ theme }) =>
              theme.breakPoints[3]}) {
            display: block;
          }
        `
      : css`
          width: 320px;
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[2]}) {
            width: 600px;
          }
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[5]}) {
            width: 1000px;
          }
        `}
`;

const StyledSectionLinkWrapper = styled.ul`
  ${onlyMobileStyle}
  ${({ sidebar }) =>
    sidebar
      ? css`
          width: 100%;
        `
      : css`
          @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
            display: flex;
            flex-wrap: wrap;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            justify-content: center;
          }

          @supports not (display: grid) {
            display: flex;
            flex-wrap: wrap;
            max-width: 1000px;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            justify-content: center;
          }

          @supports (display: grid) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-row-gap: ${({ theme }) => theme.space[5]}px;
            justify-content: center;
            width: 100%;
            /* 
            Enable this one if we have more than 4 tiles
            @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[2]}) {
              grid-template-columns: repeat(4, 1fr);
            } */
            @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[5]}) {
              grid-row-gap: ${({ theme }) => theme.space[9]}px;
            }
          }
        `}
`;

const StyledCrossButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  ${resetButtonLayout}
  &:focus {
    background-color: ${({ theme }) => theme.colors.blue[4]};
  }
  padding: ${({ theme }) => theme.space[3]}px ${({ theme }) => theme.space[4]}px;
  ${({ sidebar }) =>
    sidebar
      ? css`
          @media only screen and (max-width: ${({ theme }) =>
              theme.breakPoints[3]}) {
            display: block;
          }
        `
      : ''}
`;

const StyledCross = styled(Cross)`
  width: 20px;
  height: 20px;
`;

const StyledEnvironmentLink = styled(Link)`
  display: block;
  color: white;
  text-align: center;
  margin-top: ${({ theme }) => theme.space[4]}px;
  margin-bottom: ${({ theme }) => theme.space[6]}px;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.5;
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  line-height: ${({ theme }) => theme.lineHeights[0]}px;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    margin-top: ${({ theme }) => theme.space[6]}px;
  }
`;

const ShowOnlyMobile = styled.span`
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    display: none;
  }
  display: block;
  color: inherit;
`;

const EnvironmentName = ({ children }) => {
  if (!frontendEnvironment) {
    console.info({ frontendEnvironment });
    return null;
  }
  if (frontendEnvironment === 'production') {
    return null;
  }
  let environmentName = '';
  if (frontendEnvironment === 'preview') {
    environmentName = `PR ${getPrNumberFromHostName(window.location.hostname)}`;
  } else {
    environmentName = frontendEnvironment;
  }
  return (
    <StyledEnvironmentLink to={routes.switchEnvironment}>
      On {environmentName} environment
      <br />
      <ShowOnlyMobile>{children}</ShowOnlyMobile>
    </StyledEnvironmentLink>
  );
};

export const HomeTemplate = ({
  sectionData = [],
  sidebar,
  isHamburgerMenuOpen,
  closeHamburgerMenu,
  faqProps,
  contactProps,
  preferenceProps,
  logoutProps,
  currentUser,
  changeLogProps,
}) => (
  <>
    <StyledMain
      data-cy="homePage"
      isHamburgerMenuOpen={isHamburgerMenuOpen}
      sidebar={sidebar}
    >
      {sidebar ? null : <AccountOptionsSeperator />}
      <StyledLogoWrapper sidebar={sidebar}>
        <Link to="/">
          <StyledLogo
            data-cy={sidebar ? 'logoSidebar' : 'logoHome'}
            title
            subtitle={false}
            mode="LIGHT"
            sidebar={sidebar}
          />
        </Link>
      </StyledLogoWrapper>
      <StyledCrossButton sidebar={sidebar} onClick={closeHamburgerMenu}>
        <StyledCross />
      </StyledCrossButton>
      <StyledContentSuperWrapper>
        <StyledContentWrapper sidebar={sidebar}>
          <StyledSeperatorWrapper sidebar={sidebar}>
            <StyledFirstSeperator />
          </StyledSeperatorWrapper>
          <StyledSectionLinkWrapper sidebar={sidebar}>
            {sectionData.map(props => (
              <SectionLink sidebar={sidebar} key={props.title} {...props} />
            ))}
          </StyledSectionLinkWrapper>
          <StyledSecondSeperator sidebar={sidebar} />
          <StyledSectionLinkWrapper sidebar={sidebar}>
            <SectionLink {...faqProps} />
            {sidebar ? <SectionLink onlyMobile {...preferenceProps} /> : null}
            <SectionLink {...changeLogProps} />
            <SectionLink
              onClick={() => mataHari.uxSnitch('clickedContactUs')}
              {...contactProps}
            />
          </StyledSectionLinkWrapper>

          {sidebar ? (
            <>
              <StyledSecondSeperator onlyMobile sidebar={sidebar} />
              <StyledSectionLinkWrapper onlyMobile sidebar={sidebar}>
                <SectionLink {...logoutProps} />
              </StyledSectionLinkWrapper>
            </>
          ) : null}
        </StyledContentWrapper>
      </StyledContentSuperWrapper>
      <EnvironmentName>{getUserName(currentUser)}</EnvironmentName>
    </StyledMain>
    <StyledBackdrop
      to="#"
      onClick={closeHamburgerMenu}
      timing="0.15s"
      mobile={1}
      active={isHamburgerMenuOpen ? 1 : 0}
    />
  </>
);
