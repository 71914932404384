export const expenseCategories = [
  {
    id: 'FLIGHT',
    expenseTypes: ['AIR', 'OTC', 'AIRP', 'FEEP'],
  },
  {
    id: 'HOTEL',
    expenseTypes: ['HTL', 'LAUN', 'HTLP', 'LAUP'],
  },
  {
    id: 'MEAL',
    expenseTypes: [
      'CATE',
      'KOME',
      'LRE',
      'LRI',
      'MEA1',
      'MEA2',
      'MEA3',
      'MEAB',
      'MEAD',
      'MEAI',
      'MEAO',
      'MEAH',
      'REPE',
      'REPI',
      'TAXB',
      'TAXD',
      'TAXL',
      'TEAM',
      'TIP',
      'MVOU',
      'CATP',
      'ME1P',
      'ME2P',
      'ME3P',
      'MEAP',
      'TEMP',
      'TIPP',
    ],
  },
  {
    id: 'TRANSPORT',
    expenseTypes: [
      'BUS',
      'CMT',
      'FERR',
      'TRAN',
      'TAX1',
      'TAXI',
      'TRN',
      'CMTP',
      'FERP',
      'TAXP',
      'TRAP',
      'TRNP',
    ],
  },
  {
    id: 'CAR',
    expenseTypes: [
      'CAR',
      'CARO',
      'COMC',
      'GARP',
      'GAS',
      'MILE',
      'OCC',
      'PARK',
      'ROAD',
      'TOLL',
      'TOLT',
      'CARP',
      'CAOP',
      'GASP',
      'PARP',
      'PATP',
      'TOLP',
    ],
  },
  {
    id: 'GROUP_OF_PEOPLE',
    expenseTypes: [
      'ADVE',
      'CONS',
      'FAIR',
      'EAUD',
      'REPR',
      'REP1',
      'OSME',
      'TEAB',
      'CON',
      'TRAI',
      'ADVP',
      'COSP',
      'FAIP',
      'RE1P',
      'OSMP',
      'TEAP',
      'CONP',
    ],
  },
  {
    id: 'OBJECTS',
    expenseTypes: [
      'BOOK',
      'DACO',
      'ENT',
      'FLOW',
      'GFT',
      'GFTE',
      'GIF1',
      'GIF2',
      'GIFE',
      'GIFT',
      'MAT',
      'MISC',
      'MOBA',
      'NEWS',
      'OFFI',
      'OMAT',
      'OTMA',
      'POST',
      'PPSE',
      'SMOF',
      'STAM',
      'TELE',
      'CLHS',
      'R8TL',
      'WISP',
      'DACP',
      'FLOP',
      'GI1P',
      'GI2P',
      'GIEP',
      'MISP',
      'NEWP',
      'OFFP',
      'OTMP',
      'OTSP',
      'POSP',
      'SOFP',
      'TELP',
    ],
  },
  {
    id: 'OTHER',
    expenseTypes: [
      'EXP1',
      'EXPA',
      'EXUT',
      'ECLN',
      'RECC',
      'MISR',
      'HEA',
      'MEMB',
      'MEMD',
      'OSER',
      'OTSE',
      'ODSB',
      'SPON',
      'SPOT',
      'Ü050',
      'Ü100',
      'Ü200',
      'IDEA',
      'DUMP',
      'EXPP',
      'EXUP',
      'MEDP',
      'MEMP',
      'RECP',
      'SPAP',
      'SPOP',
    ],
  },
];
