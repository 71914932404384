import { createActions, handleActions } from 'redux-actions';

const initialState = {
  currentUser: {
    email: 'jane.doe@borealisgroup.com',
    tracker: 'trackinID',
    initial: true,
  },
  preferences: {
    loading: true,
    approvals: {},
  },
};

const USER_UPDATE = 'USER_UPDATE';
const PREFERENCES_UPDATE = 'PREFERENCES_UPDATE';

export const { userUpdate } = createActions(USER_UPDATE);
export const { preferencesUpdate } = createActions(PREFERENCES_UPDATE);

export default handleActions(
  {
    [USER_UPDATE]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [PREFERENCES_UPDATE]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  initialState
);
