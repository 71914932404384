import React from 'react';
import styled, { css } from 'styled-components';
import { StyledSeperator } from 'styled';
import { Checkbox } from 'components';

const StyledWrapper = styled.li`
  display: flex;
`;

const StyledCheckbox = styled(Checkbox)`
  position: relative;
  margin-top: 10px;
  padding-right: 34px;
`;

const StyledChildWrapper = styled.div`
  width: calc(100% - 34px);
  ${({ noCheckbox }) =>
    noCheckbox
      ? css`
          margin-left: 34px;
        `
      : ''}
  ${({ hideSelectBox }) =>
    hideSelectBox
      ? css`
          margin-left: 0px;
          width: 100%;
        `
      : ''}
`;

export const SelectableTaskWrapper = ({
  status,
  selected,
  onChange,
  children,
  id,
  hideSelectBox,
}) => {
  return (
    <StyledWrapper data-cy={`selectableTaskWrapper${status}`}>
      {status === 'PENDING' ? (
        <StyledCheckbox
          checked={selected}
          onChange={() => onChange(selected, id)}
          label=""
          dataCy="taskCheckbox"
        />
      ) : null}
      <StyledChildWrapper
        noCheckbox={status !== 'PENDING'}
        hideSelectBox={hideSelectBox}
      >
        {children}
        <StyledSeperator />
      </StyledChildWrapper>
    </StyledWrapper>
  );
};
