import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchPreferences,
  updatePreferences,
} from 'store/preferences/preferences.service';
import { getCurrentUser, getUserPreferences } from 'store/user/user.selectors';

export const useCurrentUser = () => {
  return useSelector(getCurrentUser);
};

export const useUserPreferences = () => {
  return useSelector(getUserPreferences);
};

export const useHasActiveSession = () => {
  return useSelector(getUserPreferences);
};

let updatedTracking = false;
export const useTrackingLoginActivity = () => {
  const dispatch = useDispatch();
  const userPreferences = useUserPreferences();
  useEffect(() => {
    const updatedPreferences = { ...userPreferences.approvals };
    if (
      !userPreferences.loading &&
      updatedPreferences?.metrics &&
      !updatedTracking
    ) {
      updatedTracking = true;
      const now = new Date();
      updatedPreferences.metrics.lastLogin = now;
      updatedPreferences.metrics.amountOfLogins += 1;
      if (userPreferences && updatedPreferences.metrics.firstLogin === null) {
        updatedPreferences.metrics.firstLogin = now;
      }

      updatePreferences(dispatch, updatedPreferences);
    }
  }, [dispatch, userPreferences]);
};

export const useFetchUserPreferences = () => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  useEffect(() => {
    if (!currentUser.initial) {
      fetchPreferences(dispatch, {
        headers: {
          'X-cypress-id': 'INITIAL_PREFERENCES_CALL',
        },
      });
    }
  }, [dispatch, currentUser]);
};
