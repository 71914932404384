import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Function used in Google Tag Manager
// -> https://www.simoahava.com/analytics/capturing-the-correct-element-in-google-tag-manager/
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.matchesSelector ||
    Element.prototype.mozMatchesSelector ||
    Element.prototype.msMatchesSelector ||
    Element.prototype.oMatchesSelector ||
    Element.prototype.webkitMatchesSelector ||
    function matchesPolyfill(s) {
      const doc = this.document || this.ownerDocument;
      const matches = doc.querySelectorAll(s);
      let i = matches.length;
      // eslint-disable-next-line no-empty
      while (--i >= 0 && matches.item(i) !== this) {} // NOSONAR
      return i > -1;
    };
}
