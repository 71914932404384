import React from 'react';
import { Chat } from 'icons';
import styled, { css } from 'styled-components';
import { MissingDataParagraph, StatusIcon, UserLink } from 'components';
import { useCurrentUser } from 'hooks/user';
import {
  detailPageMobilePadding,
  STATUS_COLORS,
  StyledPlaceholder,
} from 'styled';
import {
  derrivedSequenceStatus,
  fixSAPFormatting,
  getFullName,
  getMediumHumanReadableDate,
} from 'utils';
import {
  Indentation,
  StyledVerticalConnector as StyledIndentVerticalConnector,
} from './Indentation';

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  display: block;
  ${({ transparent }) => {
    if (transparent) return;
    return css`
      border: 2px solid ${({ theme }) => theme.colors.gray};
    `;
  }};

  ${({ inverseIcon }) =>
    inverseIcon
      ? css`
          transform: scale(-1, 1);
        `
      : ''}
  border-radius: 50%;

  ${({ statusColor, status, transparent }) => {
    if (status === 'NEXT' || transparent) return;
    return css`
      border-color: ${statusColor};
      background-color: ${statusColor};
    `;
  }};
`;

const StyledStatusIcon = styled(StatusIcon)`
  ${({ start }) =>
    start
      ? css`
          width: 15px;
          height: 15px;
          margin-right: 6px;
          margin-left: 1px;
          position: relative;
          left: 3px;
          top: 3px;
          background-color: white;
          border: 6px solid ${({ theme }) => theme.colors.primary};
          @media (prefers-color-scheme: dark) {
            border-color: ${({ theme }) => theme.colors.darkerWhite};
            background-color: ${({ theme }) => theme.colors.black};
          }
        `
      : ''}
`;

const StyledConnector = styled.div`
  ${({ short }) =>
    short
      ? css`
          height: calc(100% - 65px);
          border-left: 1px dashed ${STATUS_COLORS.REJECTED};
        `
      : css`
          height: calc(100% - 42px);
          background-color: ${({ theme }) => theme.colors.gray};
        `};

  width: 1px;
  top: 33px;
  position: absolute;
  left: 11px;
`;

const StyledItemWrapper = styled.div`
  position: relative;
  display: flex;

  ${({ rejectionReason }) =>
    rejectionReason
      ? css`
          margin-top: -23px;
        `
      : css``};

  padding-bottom: ${({ theme }) => theme.space[6]}px;
  &:last-of-type {
    padding-bottom: 0px;
    ${StyledConnector} {
      display: none;
    }
    ${StyledIndentVerticalConnector} {
      height: 22px;
    }
  }
  &:first-of-type {
    ${StyledConnector} {
      top: 27px;
      height: calc(100% - 36px);
    }
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  margin-left: ${({ theme }) => theme.space[4]}px;
  margin-top: -2px;
  width: calc(100% - ${({ theme }) => theme.space[3]}px - 30px);
`;

const StyledStatusText = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.secondary};
  @media (prefers-color-scheme: dark) {
    color: ${({ theme }) => theme.colors.darkerWhite};
  }
  ${({ activeStep }) =>
    activeStep
      ? css`
          font-weight: bold;
        `
      : ''};
  ${({ date }) =>
    !date
      ? css`
          margin-top: 4px;
        `
      : ''};
`;

const StyledDate = styled.div`
  margin-top: ${({ theme }) => theme.space[1]}px;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 16px;
  @media (prefers-color-scheme: dark) {
    color: ${({ theme }) => theme.colors.darkerWhite};
  }
`;

const CommentTitle = styled.div`
  color: ${STATUS_COLORS.REJECTED};
  font-size: 14px;
`;

const CommentText = styled.p`
  font-size: 14px;
  white-space: pre-line;
  font-style: italic;
  color: ${({ theme }) => theme.colors.secondary};
`;

const MetrolineComment = ({ t, rejectionReason = undefined, taskStatus }) => (
  <StyledItemWrapper rejectionReason={1}>
    <StyledIconWrapper transparent={1} inverseIcon>
      <Chat color="#d10000" />
    </StyledIconWrapper>

    <StyledContentWrapper>
      <CommentTitle>{t('METROLINE.REJECTION_REASON_TITLE')}</CommentTitle>
      <CommentText>
        {rejectionReason
          ? fixSAPFormatting(rejectionReason)
          : t('METROLINE.REJECTION_REASON_NOT_PROVIDED')}
      </CommentText>
    </StyledContentWrapper>
    {taskStatus === 'DELETED' && <StyledConnector />}
  </StyledItemWrapper>
);
const MetrolineItem = ({
  start,
  requestor,
  taskStatus,
  t,
  status,
  date,
  approver,
  alias,
  byAlias,
  loading,
  rejectionReason = undefined,
  showRejectionReason,
  peerSortIds,
}) => {
  const account = (() => {
    if (approver) return approver;
    if (requestor) return requestor;
  })();
  const derrivedStatus = derrivedSequenceStatus(status, taskStatus);
  const userLink = (user, possesive = false) => (
    <UserLink
      data-cy="metroLineUserLink"
      name={getFullName(user)}
      account={user}
      possesive={possesive}
    />
  );
  const currentUser = useCurrentUser();
  if (status === 'NEXT' && taskStatus === 'REJECTED') {
    return null;
  }

  return (
    <>
      <StyledItemWrapper rejectionStep={derrivedStatus === 'REJECTED' ? 1 : 0}>
        <StyledConnector short={derrivedStatus === 'REJECTED' ? 1 : 0} />
        <Indentation indent={peerSortIds?.length > 1} />
        <StyledStatusIcon
          noIcon={start}
          start={start}
          status={status}
          size="BIG"
        />

        <StyledContentWrapper>
          <StyledStatusText date={date} activeStep={status === 'PENDING'}>
            {(() => {
              if (loading) {
                return (
                  <StyledPlaceholder
                    height="18px"
                    width="200px"
                    color="black"
                  />
                );
              }
              if (start) {
                return (
                  <>
                    {t('METROLINE.REQUESTED_BY')}
                    {userLink(account)}
                  </>
                );
              }
              if ((status === 'APPROVED' || status === 'REJECTED') && byAlias) {
                if (alias?.email === currentUser?.email) {
                  return (
                    <>
                      {t('METROLINE.STATUS_BY', {
                        status: t(`COMMON.${derrivedStatus}`),
                      })}
                      {userLink(alias)}
                      {t('METROLINE.ON')} {t('METROLINE.BEHALF')}{' '}
                      {t('METROLINE.OF')} {userLink(account)}{' '}
                    </>
                  );
                }
                return (
                  <>
                    {t('METROLINE.STATUS_BY', {
                      status: t(`COMMON.${derrivedStatus}`),
                    })}
                    {userLink(alias)}
                    {t('METROLINE.ON')} {userLink(account, true)}{' '}
                    {t('METROLINE.BEHALF')}
                  </>
                );
              }
              if (status === 'APPROVED' || status === 'REJECTED') {
                return (
                  <>
                    {t('METROLINE.STATUS_BY', {
                      status: t(`COMMON.${derrivedStatus}`),
                    })}
                    {userLink(account)}
                  </>
                );
              }
              if (status === 'PENDING') {
                return (
                  <>
                    {t('METROLINE.PENDING_APPROVAL_BY')}
                    {userLink(account)}
                  </>
                );
              }
              if (status === 'NEXT') {
                return (
                  <>
                    {t('METROLINE.PENDING_APPROVAL_BY')}
                    {userLink(account)}
                  </>
                );
              }
              if (status === 'DELETED') return t('COMMON.DELETED');
            })()}
          </StyledStatusText>
          {date && status !== 'NEXT' && status !== 'PENDING' ? (
            <StyledDate>{getMediumHumanReadableDate(date, !start)}</StyledDate>
          ) : null}
        </StyledContentWrapper>
      </StyledItemWrapper>
      {status === 'REJECTED' && showRejectionReason ? (
        <MetrolineComment
          t={t}
          rejectionReason={rejectionReason}
          taskStatus={taskStatus}
        />
      ) : null}
    </>
  );
};

const StyledWrapper = styled.li`
  ${detailPageMobilePadding};
  padding-bottom: ${({ theme }) => theme.space[4]}px;
`;

export const Metroline = ({
  t,
  requestor,
  request,
  approvalSequence,
  taskStatus,
  rejectionReason,
  loading,
  requestDate,
  title,
  showRejectionReason = true,
  showRequestor = true,
  ...props
}) => {
  const nothingToShow =
    !showRequestor &&
    approvalSequence?.length === 0 &&
    taskStatus !== 'DELETED';

  if (nothingToShow)
    return (
      <StyledWrapper {...props}>
        <MissingDataParagraph />
      </StyledWrapper>
    );

  return (
    <StyledWrapper {...props}>
      {showRequestor ? (
        <MetrolineItem
          loading={loading}
          requestor={requestor}
          start={1}
          t={t}
          date={requestDate}
        />
      ) : null}
      {approvalSequence.map((data, index) => (
        <MetrolineItem
          loading={loading}
          status={data.status}
          taskStatus={taskStatus}
          t={t}
          byAlias={data.byAlias}
          key={JSON.stringify(data)}
          rejectionReason={rejectionReason}
          showRejectionReason={showRejectionReason}
          indent={index < 3}
          {...data}
        />
      ))}
      {taskStatus === 'DELETED' ? (
        <MetrolineItem
          status={taskStatus}
          loading={loading}
          t={t}
          date={null}
        />
      ) : null}
    </StyledWrapper>
  );
};
