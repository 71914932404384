import React from 'react';
import styled from 'styled-components';
import { IconButton, StyledLabel } from './IconButton';

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  padding: 15px;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[5]}) {
    padding-right: ${({ theme }) => theme.space[4]}px;
    padding-left: ${({ theme }) => theme.space[4]}px;
  }
  ${StyledLabel} {
    display: none;
    @media only screen and (min-width: ${({ theme }) => theme.breakPoints[5]}) {
      display: block;
    }
  }
`;
export const HeaderIconButton = ({ ...props }) => (
  <StyledIconButton {...props} />
);
