import React from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import { routes } from 'config';
import { MultipleTasksConfirmPage } from './MultipleTasksConfirmPage';

export const MultipleTasksConfirmPageContainer = ({ ...props }) => {
  const { t } = useTranslation();
  const {
    match: { url },
  } = useReactRouter();
  const previousRoute = url.replace(`-${routes.confirmReject}`, '');
  return (
    <MultipleTasksConfirmPage previousRoute={previousRoute} t={t} {...props} />
  );
};
