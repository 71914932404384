import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangelogPage } from './ChangelogPage';

export const ChangelogPageContainer = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      const response = await fetch('/changelog.json');
      const body = await response.json();
      setData(body);
    })();
  }, []);
  return <ChangelogPage data={data} previousPath="/" t={t} />;
};
