import React from 'react';
import { Paperclip } from 'icons';
import { Error, Pill } from 'components';
import { StyledPlaceholder } from 'styled';
import {
  getPurchaseRequestId,
  getShortHumanReadableDate,
  toPriceNumber,
} from 'utils';
import {
  StyledBottomContentWrapper,
  StyledDate,
  StyledDateWrapper,
  StyledH3,
  StyledHighlightedMessageH4,
  StyledPillAndAttachmentWrapper,
  StyledPillWrapper,
  StyledRequestedBy,
  StyledRequestedByWrapper,
  StyledSpinner,
  StyledStatusIcon,
  StyledTitleAndDateWrapper,
} from './sharedElements';

/*
TODO: review naming props
*/
export const TravelAndExpenseListItem = ({
  selectable,
  t,
  focus,
  loading,
  task: { requestDate, amount, requestor, id, reason, hasAttachment, status },
  override,
  showId = true,
}) => {
  const derivedStatus = status || 'PENDING';
  return (
    <>
      <StyledTitleAndDateWrapper>
        <StyledH3 data-cy="taskListItemH3">
          {!loading ? (
            selectable ? (
              t('TASK_LIST.TRAVEL_AND_EXPENSES.TITLE_SELECTABLE', {
                id,
              })
            ) : (
              t('TASK_LIST.TRAVEL_AND_EXPENSES.TITLE', {
                requestor: requestor.name,
              })
            )
          ) : (
            <StyledPlaceholder height="26px" width="350px" color="primary" />
          )}
        </StyledH3>
        <StyledDateWrapper>
          <StyledDate data-cy="taskListItemRequestDate">
            {!loading ? (
              getShortHumanReadableDate(requestDate)
            ) : (
              <StyledPlaceholder height="14px" width="35px" color="secondary" />
            )}
          </StyledDate>
          {(() => {
            if (override) {
              // if (override.message) return <Warning />;
              if (override.updating) return <StyledSpinner />;
            }
            return (
              <StyledStatusIcon
                status={derivedStatus}
                data-cy={`circleStatus${derivedStatus}`}
                size="SMALL"
              />
            );
          })()}
        </StyledDateWrapper>
      </StyledTitleAndDateWrapper>
      <StyledPillAndAttachmentWrapper>
        {showId ? (
          <StyledPillWrapper>
            <Pill
              label={t('TASK_LIST.TRAVEL_AND_EXPENSES.PILL_LABEL')}
              value={getPurchaseRequestId(id)}
              data-cy={focus ? 'selectedTaskListItemId' : ''}
            />
          </StyledPillWrapper>
        ) : null}
        {hasAttachment && (
          <StyledDateWrapper>
            <Paperclip data-cy="attachmentImage" />
          </StyledDateWrapper>
        )}
      </StyledPillAndAttachmentWrapper>
      <StyledBottomContentWrapper>
        {override && override.message ? (
          <Error title={t('TASK_LIST.ERROR_MESSAGE')} condition />
        ) : (
          <>
            <StyledRequestedByWrapper>
              <StyledRequestedBy>
                {!loading ? (
                  reason
                ) : (
                  <StyledPlaceholder
                    height="14px"
                    width="150px"
                    color="secondary"
                  />
                )}
              </StyledRequestedBy>
            </StyledRequestedByWrapper>
            <StyledHighlightedMessageH4 data-cy="taskListItemAmount">
              {!loading ? (
                toPriceNumber(amount)
              ) : (
                <StyledPlaceholder
                  height="22px"
                  width="80px"
                  color="tertiary"
                />
              )}
            </StyledHighlightedMessageH4>
          </>
        )}
      </StyledBottomContentWrapper>
    </>
  );
};
/*
TODO: review HTML semantics
*/
