import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Textarea } from './Textarea';

export const TextareaContainer = ({
  maximumAmountOfCharacters = 512,
  submitted,
  mandatoryError,
  setMandatoryError,
  overrideValue,
  ...props
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [characterError, setCharacterError] = useState(false);
  const maximumAmountOfCharactersLeft =
    maximumAmountOfCharacters - value.length;
  const acceptableAmountOfCharacters = maximumAmountOfCharactersLeft > 0;

  useEffect(() => {
    if (mandatoryError && value) {
      setMandatoryError(false);
    }
  }, [value, mandatoryError, setMandatoryError]);
  useEffect(() => {
    if (!acceptableAmountOfCharacters) {
      setCharacterError(true);
    } else {
      setCharacterError(false);
    }
  }, [
    acceptableAmountOfCharacters,
    characterError,
    maximumAmountOfCharacters,
    maximumAmountOfCharactersLeft,
    value,
  ]);

  useEffect(() => {
    if (overrideValue && value !== overrideValue) {
      if (value) {
        setValue(`${value} ${overrideValue}`);
      } else {
        setValue(overrideValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overrideValue]);
  return (
    <Textarea
      onChange={event => setValue(event.target.value)}
      value={value}
      t={t}
      characterError={characterError}
      currentAmountOfCharacter={
        maximumAmountOfCharacters - maximumAmountOfCharactersLeft
      }
      mandatoryError={mandatoryError}
      maximumAmountOfCharacters={maximumAmountOfCharacters}
      {...props}
    />
  );
};
