import React from 'react';
import { Rocket } from 'icons';
import styled, { css } from 'styled-components';
import { Button, Header, ToHomePageButton } from 'components';

const StyledWrapper = styled.div`
  overflow: visible;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  .dynamicIcon {
    width: 180px;
    height: 180px;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[5]}) {
    margin-top: 100px;
  }
  ${({ headerProps }) =>
    headerProps
      ? css`
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[3]}) {
            margin-top: 137px;
          }
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[5]}) {
            margin-top: 206px;
          }
        `
      : ''}
`;

const StyledTitle = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes[4]}px;
  margin-top: ${({ theme }) => theme.space[3]}px;
`;

const StyledSubtitle = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: normal;
  margin-top: ${({ theme }) => theme.space[1]}px;
  max-width: 200px;
  text-align: center;
`;

const StyledButton = styled(Button)`
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  margin-top: ${({ theme }) => theme.space[3]}px;
  width: 180px;
`;

const StyledToHomePageButton = styled(ToHomePageButton)`
  margin-top: ${({ theme }) => theme.space[6]}px;
`;

export const EmptyState = ({
  Icon = Rocket,
  inverseIcon = false,
  title = 'I am a title',
  subtitle = 'I am a subtitle',
  action = undefined,
  actionText = undefined,
  children,
  headerProps,
  toHomePageButton,
  ...props
}) => {
  return (
    <>
      {headerProps ? (
        <Header title="" borderBottom={false} {...headerProps} />
      ) : null}
      <StyledWrapper data-cy="emptyState" headerProps={headerProps} {...props}>
        <Icon className="dynamicIcon" inverseIcon={inverseIcon} />
        <StyledTitle>{title}</StyledTitle>
        {subtitle ? <StyledSubtitle>{subtitle}</StyledSubtitle> : null}
        {children}
        {action && actionText && (
          <form onSubmit={action}>
            <StyledButton data-cy="startNewSearch" type="submit">
              {actionText}
            </StyledButton>
          </form>
        )}
        {toHomePageButton ? <StyledToHomePageButton /> : null}
      </StyledWrapper>
    </>
  );
};
