import React from 'react';
import { LostInSpaceDino, LostInSpaceDinoStatic } from 'icons';
import { EmptyState } from 'components';
import { supportsSVGAnimations } from 'utils';

export const NoConnectionPage = ({ retryConnectionHandler, t }) => (
  <EmptyState
    Icon={supportsSVGAnimations() ? LostInSpaceDino : LostInSpaceDinoStatic}
    title={t('NO_CONNECTION_PAGE.TITLE')}
    subtitle={t('NO_CONNECTION_PAGE.SUB_TITLE')}
    action={retryConnectionHandler}
    actionText={t('NO_CONNECTION_PAGE.RETRY_ACTION')}
  />
);
