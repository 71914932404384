import React from 'react';
import styled from 'styled-components';
import { Header, HomePage } from 'components';
import { environment } from 'config';
import { StyledIframe } from 'styled';

const StyledWrapper = styled.div`
  display: flex;
`;

const StyledIdeaSurveyWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 59px);
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    height: 100vh;
  }
`;
export const IdeaSurveyPage = ({ t }) => {
  return (
    <StyledWrapper>
      <HomePage sidebar={1} />
      <StyledIdeaSurveyWrapper>
        <Header title={t('COMMON.IDEA_TITLE')} to="/" />
        <StyledIframe src={environment.ideaSurveyLink} />
      </StyledIdeaSurveyWrapper>
    </StyledWrapper>
  );
};
