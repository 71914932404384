import React from 'react';
import styled from 'styled-components';
import { Refresh } from 'icons';
import { HeaderIconButton } from './HeaderIconButton';

const StyledRefresh = styled(Refresh)`
  width: 26px;
  height: 22px;
  position: relative;
  top: 2px;
  transition: transform 0.2s ease-in-out;
`;

const StyledHeaderIconButton = styled(HeaderIconButton)`
  display: none;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    display: flex;
  }
  &:hover,
  &:focus {
    ${StyledRefresh} {
      transform: rotate(180deg);
    }
  }
`;

export const RefreshButton = ({ t, ...props }) => (
  <StyledHeaderIconButton
    {...props}
    label={t('COMMON.REFRESH')}
    Icon={StyledRefresh}
  />
);
