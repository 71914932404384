import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDownloadFile } from 'hooks';
import { Toast } from './Toast';

export const ToastDownloadFileContainer = ({
  taskId,
  fileId,
  fileName,
  contentType,
  ...props
}) => {
  const { t } = useTranslation();
  const [hideToast, setHideToast] = useState(false);
  const { loading, error } = useDownloadFile({
    taskId,
    fileId,
    fileName,
    contentType,
  });
  const success = !loading && !error;
  useEffect(() => {
    if (success || error) {
      setTimeout(() => {
        setHideToast(true);
      }, 3000);
    }
  }, [success, error]);
  const status = error
    ? 'ERROR'
    : loading
    ? 'UPDATING'
    : success
    ? 'SUCCESS'
    : 'this should never happen';
  if (hideToast) return null;
  return (
    <Toast
      dataCy={`TOAST_DOWNLOAD_${status}`}
      status={status}
      labelText={t(`DOWNLOADING_FILE_TOAST.${status}_TITLE`)}
      {...props}
    />
  );
};
