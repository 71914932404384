import { Spinner } from 'icons';
import styled, { css } from 'styled-components';
import { Pill, StatusIcon } from 'components';
import { LINE_HEIGHT } from 'styled';

export const StyledTitleAndDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-top: ${({ theme }) => theme.space[2]}px;
`;

export const StyledH3 = styled.h3`
  width: calc(100% - 85px); /* IE11 otherwise resizes status icons */
  max-width: 400px;
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  max-height: ${LINE_HEIGHT * 2}em;
  line-height: ${LINE_HEIGHT}em;
  text-overflow: ellipsis; /* not supported yet for multiline */
  overflow: hidden;
  @media (prefers-color-scheme: dark) {
    color: ${({ theme }) => theme.colors.darkerWhite};
  }
`;

export const StyledDateWrapper = styled.div`
  margin-left: ${({ theme }) => theme.space[2]}px;
  margin-top: ${({ marginTop = '6px' }) => marginTop};
  display: flex;
  align-items: center;
`;

export const StyledDate = styled.div`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  font-size: 13px;
`;

export const iconPositionStyle = css`
  width: 15px;
  height: 15px;
  margin-left: 6px;
  margin-top: -2px;
`;

export const StyledStatusIcon = styled(StatusIcon)`
  margin-left: 6px;
  margin-top: -2px;
`;

export const StyledSpinner = styled(Spinner)`
  ${iconPositionStyle}
`;

export const StyledPillWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
`;

export const StyledRequestedByWrapper = styled.div`
  height: 2.4em;
  line-height: 1.2em;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
`;

export const StyledRequestedBy = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  max-height: 2.4em;
`;

export const StyledHighlightedMessageH4 = styled.h4`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 19px;
  white-space: nowrap;
  margin-left: ${({ theme }) => theme.space[2]}px;
  @media (prefers-color-scheme: dark) {
    color: ${({ theme }) => theme.colors.darkerWhite};
  }
`;

export const StyledBottomContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  bottom: ${({ theme }) => theme.space[3]}px;
  width: 100%;
`;

export const StyledWrapper = styled.div`
  position: relative;
  height: 100%;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  ${({ margin }) =>
    margin
      ? css`
          margin: 0 ${({ theme }) => theme.space[4]}px;
        `
      : ''}
`;

export const StyledItemNumberPill = styled(Pill)`
  margin-top: -1px;
`;

export const StyledPillAndAttachmentWrapper = styled(StyledTitleAndDateWrapper)`
  align-items: flex-end;
  padding-top: 0px;
`;
