import React from 'react';
import { Section } from './Section';

export const FaqSection = ({ questionCategory, ...props }) => (
  <Section
    faq
    title={questionCategory.sectionName}
    stickyTitles={false}
    items={questionCategory.questions}
    {...props}
  />
);
