import React, { useState } from 'react';
import { Banner } from './Banner';

export const BannerContainer = ({ closable, text, ...props }) => {
  const [bannerShown, setBannerShown] = useState(true);
  const closeHandler = () => {
    setBannerShown(false);
  };
  return (
    bannerShown && (
      <Banner
        closable={closable}
        closeHandler={closeHandler}
        text={text}
        {...props}
      />
    )
  );
};
