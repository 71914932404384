import React from 'react';
import styled, { css } from 'styled-components';

const Paragraph = styled.p`
  font-style: italic;
  color: inherit;
  ${({ paddingBottom }) =>
    paddingBottom
      ? css`
          padding-bottom: ${paddingBottom};
        `
      : ''}
`;

export const MissingDataParagraph = ({
  translationKey = 'DATA_NOT_AVAILABLE',
  t,
  ...props
}) => <Paragraph {...props}>{t(`KEY_VALUE.${translationKey}`)}</Paragraph>;
