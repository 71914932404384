import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  width: 30px;
`;
export const StyledVerticalConnector = styled.div`
  background-color: ${({ theme }) => theme.colors.gray};
  height: 42px;
  width: 1px;
  top: -9px;
  position: absolute;
  left: 11px;
`;

const StyledHorizontalConnector = styled.div`
  background-color: ${({ theme }) => theme.colors.gray};
  height: 1px;
  width: 17px;
  top: 12px;
  position: absolute;
  left: 11px;
`;

export const Indentation = ({ indent }) => {
  if (!indent) return null;

  return (
    <StyledWrapper>
      <StyledVerticalConnector />
      <StyledHorizontalConnector />
    </StyledWrapper>
  );
};
