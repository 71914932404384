/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    width: 87px;
  }
  font-size: 16px;
  // padding: 5px;
`;

const StyledLabelText = styled.p`
  @media only screen and (max-width: ${({ theme }) => theme.breakPoints[3]}) {
    display: none;
  }
`;

const StyledCheckBox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const StyledSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.gray};
  transition: 0.2s;
  border-radius: 34px;


  &:before {
    position: absolute;
    content: '';
    height: 23px;
    width: 23px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.2s;

    border-radius: 50%;
  }

  ${StyledCheckBox}:checked + & {
    background-color: ${({ theme }) => theme.colors.lightblue[5]};
  }

  ${StyledCheckBox}:checked + &:before {
     transform: translateX(19px);
   }
  }
`;

const StyledToggleWrapper = styled.div`
  width: 50px;
  height: 30px;
  position: relative;
  display: inline-block;

  &:focus-within {
    ${StyledSlider} {
      box-shadow: 0 0 6px ${({ theme }) => theme.colors.tertiary};
    }
  }
`;

export const OptionToggle = ({
  checked,
  checkedText = 'on',
  unCheckedText = 'off',
  sectionKey,
  dataKey,
  onToggle,
  autoFocus,
}) => {
  const [value, setValue] = useState(checked);

  const handleCheckboxChange = async e => {
    onToggle(sectionKey, dataKey, e.currentTarget.checked);
    setValue(e.currentTarget.checked);
  };

  return (
    <StyledWrapper>
      <StyledLabelText>{value ? checkedText : unCheckedText}</StyledLabelText>
      <StyledToggleWrapper>
        <StyledCheckBox
          type="checkbox"
          checked={value}
          autoFocus={autoFocus}
          onChange={handleCheckboxChange}
        />
        <StyledSlider />
      </StyledToggleWrapper>
    </StyledWrapper>
  );
};
