import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { isProduction } from '../utils/environment';
import { en } from './translations';

const throwErrorConfigForMissingKey = {
  saveMissing: true,
  missingKeyHandler: (ng, ns, key, fallbackValue) => {
    throw new Error(`Key not found ${key}, ${ng}, ${ns}, ${fallbackValue}`);
  },
};

i18n.use(initReactI18next).init({
  resources: {
    en,
  },
  lng: 'en',
  interpolation: {
    format: (value, format) => {
      if (format === 'lowercase') return value.toLowerCase();
      return value;
    },
    escapeValue: false,
  },
  ...(!isProduction && throwErrorConfigForMissingKey),
});

export default i18n;
