import React, { useEffect, useReducer } from 'react';
import { ShowClick } from './ShowClick';

function reducer(state, action) {
  const eventJson = {};
  switch (action.type) {
    case 'ADD':
      eventJson[new Date()] = action.event;
      return { ...state, ...eventJson };
    case 'REMOVE':
      // eslint-disable-next-line no-case-declarations
      const { [action.key]: omit, ...modifiedState } = state;
      return modifiedState;
    default:
      throw new Error();
  }
}

export const ShowClickContainer = ({ ...props }) => {
  const [eventsState, dispatchevents] = useReducer(reducer, {});
  const trackclick = event => {
    dispatchevents({ type: 'ADD', event });
  };
  const timeAliveInMilliseconds = 300;
  useEffect(() => {
    Object.keys(eventsState).forEach(key => {
      const timeDifference = new Date(key).getTime() - new Date().getTime();
      if (-timeDifference > timeAliveInMilliseconds + 1000) {
        dispatchevents({ type: 'REMOVE', key });
      }
    });
  }, [eventsState]);
  useEffect(() => {
    window.addEventListener('touchstart', trackclick);
    return () => {
      window.removeEventListener('touchstart', trackclick);
    };
  });
  return Object.keys(eventsState).map(key => (
    <ShowClick
      key={key}
      event={eventsState[key]}
      timeAliveInMilliseconds={timeAliveInMilliseconds}
      {...props}
    />
  ));
};
