import { IS_INTERNET_EXPLORER } from './responsive';

const localDateString = (date, options) => {
  // IE 11 does not support converting to specific timeZones
  if (IS_INTERNET_EXPLORER) {
    return date.toLocaleDateString('en-GB');
  }
  return date.toLocaleDateString('en-GB', {
    ...options,
    timeZone: 'Europe/Rome',
  });
};

const dateWithTimeZone = (
  timeZone,
  { year, month, day, hours = 0, minutes = 0, seconds = 0, ms = 0 }
) => {
  // IE 11 does not support converting to specific timeZones
  if (IS_INTERNET_EXPLORER) {
    return new Date(year, month - 1, day, hours, minutes, seconds, ms);
  }
  // https://stackoverflow.com/questions/15141762/how-to-initialize-a-javascript-date-to-a-particular-time-zone
  const date = new Date(
    Date.UTC(year, month - 1, day, hours, minutes, seconds, ms)
  );

  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone })); // en-GB doesn't work for some reason
  const offset = utcDate.getTime() - tzDate.getTime();

  date.setTime(date.getTime() + offset);

  return date;
};

export const getDateFromDateString = dateString => {
  // Mulesoft(on our request) adds a 'Z' but it is actually CET
  const dateStringWithoutZone = dateString.replace('Z', ' CET');

  const match = dateStringWithoutZone.match(
    /(?<date>(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2}))?(?<time>T(?<hours>\d{2}).?(?<minutes>\d{2}).?(?<seconds>\d{2})?\.?(?<ms>\d{3})?)?/
  );

  // if a date is found in the string, that's enough.
  if (match.groups.date) {
    return dateWithTimeZone('Europe/Rome', match.groups);
  }

  return '';
};

export const getHumanReadableDate = (dateString, showTime = true) => {
  if (dateString) {
    const date = getDateFromDateString(dateString);
    let options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    if (showTime) {
      options = {
        ...options,
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
      };
    }
    return localDateString(date, options);
  }
};

function isValidDate(d) {
  // eslint-disable-next-line no-restricted-globals
  return d instanceof Date && !isNaN(d);
}

export const getShortHumanReadableDate = dateString => {
  if (dateString) {
    const date = getDateFromDateString(dateString);
    if (!isValidDate(date)) return '';
    const options = {
      month: 'short',
      day: 'numeric',
    };
    const today = new Date();
    if (today.getYear() !== date.getYear()) options.year = '2-digit';
    return localDateString(date, options);
  }
  return null;
};

export const getShortHumanReadableDateForContacts = dateString => {
  if (dateString) {
    const date = getDateFromDateString(dateString);

    if (!isValidDate(date)) return '';
    const options = {
      month: 'short',
      day: 'numeric',
      year: '2-digit',
    };
    return localDateString(date, options);
  }
  return null;
};

export const getMediumHumanReadableDate = (dateString, showTime = true) => {
  if (dateString) {
    const date = getDateFromDateString(dateString);
    let options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    if (showTime) {
      options = {
        ...options,
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
      };
    }
    return localDateString(date, options);
  }
};

export const shortTime = timeString =>
  timeString ? timeString.match(/(\d{2}:\d{2})/)[0] : null;
