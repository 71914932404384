import { createActions, handleActions } from 'redux-actions';

export const initialStateOfStatus = {
  data: [],
  error: null,
  loading: false,
  initialState: true,
  pageNumber: 0,
  loadingMoreTasks: false,
  allDataFetched: false,
};

export const loadingState = {
  ...initialStateOfStatus,
  loading: true,
  initialState: false,
};

const initialState = {
  overrides: {},
};
const OVERRIDES_UPDATE = 'OVERRIDES_UPDATE';

export const { overridesUpdate } = createActions(OVERRIDES_UPDATE);

export default handleActions(
  {
    [OVERRIDES_UPDATE]: (state, action) => {
      const { id, values } = action.payload;
      return {
        ...state,
        overrides: {
          ...state.overrides,
          [id]: {
            ...state.overrides[id],
            ...values,
          },
        },
      };
    },
  },
  initialState
);
