import React from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import {
  useObjectTypeInViewSpecificCode,
  useRequestorNameFromMultipleItems,
} from 'hooks';
import { getPurchaseRequestId } from 'utils';
import { Pill } from './Pill';

export const PillMultipleItems = ({ ...props }) => {
  const {
    match: {
      params: { taskId },
    },
  } = useReactRouter();
  const { t } = useTranslation();
  let label = '';
  let value = '';
  const employeeName = useRequestorNameFromMultipleItems();
  useObjectTypeInViewSpecificCode({
    travelAndExpenseCallback: () => {
      label = t('MULTIPLE_TASKS.EMPLOYEE');
      value = employeeName;
    },
    purchaseRequestCallback: () => {
      label = t(
        `TASK_LIST.PURCHASE_REQUESTS.REGULAR_PURCHASE_REQUEST.PILL_LABEL`
      );
      value = getPurchaseRequestId(taskId);
    },
  });

  return <Pill label={label} value={value} {...props} />;
};
