import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useSetDocumentTitle = title => {
  const { t } = useTranslation();

  useEffect(() => {
    if (title) window.document.title = `${title} - ${t('HOME.TITLE')}`;
  }, [t, title]);
};
