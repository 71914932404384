import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOpenHamburgerMenu } from 'store/userInterface/userInterface.selectors';
import { userInterfaceUpdate } from 'store/userInterface/userInterface.duck';
import { HamburgerButton } from './HamburgerButton';

export const HamburgerButtonContainer = ({ ...props }) => {
  const dispatch = useDispatch();
  const open = useSelector(getOpenHamburgerMenu);
  const onClick = () => {
    dispatch(userInterfaceUpdate({ openHamburgerMenu: !open }));
  };
  return <HamburgerButton open={open} onClick={onClick} {...props} />;
};
