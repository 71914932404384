import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { DinoDetective } from 'icons';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'theme/globalStyle';
import { customTheme } from 'theme/theme';
import {
  AccountOptions,
  ChangelogPage,
  ConfirmationAlert,
  DownloadFilePage,
  EmptyState,
  FaqPage,
  HomePage,
  IdeaSurveyPage,
  NoConnectionPage,
  NotificationReminderPage,
  PlaceholderPage,
  PreferencesPage,
  PullToRefresh,
  SatisfactionSurveyPage,
  ShowClick,
  SupportPage,
  SwitchEnvironmentPage,
  TaskListAndDetailWrapper,
  Toast,
} from 'components';
import { environment, routes } from 'config';
import {
  useChangeTitleOnNewPendingTasks,
  useFetchUserPreferences,
  useOneSignalId,
  useRefreshAllTasks,
  useSetSentryScope,
  useShowNotificationReminder,
  useShowSurvey,
  useTrackingLoginActivity,
} from 'hooks';
import { getPurchaseRequestTasks } from 'store/tasks/tasks.selectors';
import { MaintenanceBannerContainer as MaintenanceBanner } from './atoms/Banner/MaintenanceBanner.container';

export const Main = () => {
  const { t } = useTranslation();

  useSetSentryScope();
  useOneSignalId();
  useRefreshAllTasks();
  useShowSurvey();
  useShowNotificationReminder();
  useTrackingLoginActivity();
  useFetchUserPreferences();
  useChangeTitleOnNewPendingTasks();

  const tasks = useSelector(getPurchaseRequestTasks);
  if (tasks.initialState) return <></>;
  return (
    <ThemeProvider theme={customTheme}>
      <>
        <GlobalStyle />
        <MaintenanceBanner />
        {window.navigator.onLine ? (
          <>
            <Switch>
              <Route path={routes.home} exact component={HomePage} />
              <Route path={routes.changelog} exact component={ChangelogPage} />
              <Route
                path={routes.preferences}
                exact
                component={PreferencesPage}
              />
              <Route path={routes.faq} exact component={FaqPage} />
              <Route
                path={routes.switchEnvironment}
                exact
                component={SwitchEnvironmentPage}
              />
              <Route
                path="/tasks/:section?/:taskId?/:subsection?/:subsectionId?"
                component={TaskListAndDetailWrapper}
              />
              <Route
                path={`${routes.placeholder}/:section?`}
                exact
                component={PlaceholderPage}
              />
              <Route
                path={routes.downloadFile}
                exact
                component={DownloadFilePage}
              />
              <Route
                path={routes.ideaSurvey}
                exact
                component={IdeaSurveyPage}
              />
              <Route path={routes.support} exact component={SupportPage} />
              <Route
                exact
                path={/^(?!\/api).*$/}
                render={
                  <EmptyState
                    title={t('COMMON.NOT_FOUND_TITLE')}
                    subtitle={t('COMMON.NOT_FOUND_SUBTITLE')}
                    headerProps={{ to: '/', onlyMobile: false }}
                    Icon={DinoDetective}
                    data-cy="notFoundPage"
                    toHomePageButton
                  />
                }
              />
            </Switch>
            <AccountOptions />
            <PullToRefresh />
            {environment.interviewMode ? <ShowClick /> : null}
            <SatisfactionSurveyPage />
            <NotificationReminderPage />
            <Toast />
            <ConfirmationAlert />
          </>
        ) : (
          <NoConnectionPage />
        )}
      </>
    </ThemeProvider>
  );
};
