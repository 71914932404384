import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import { SelectableTaskWrapper, SwipeableTaskWrapper } from 'components';
import { routes } from 'config';
import {
  getObjectTypeFromSection,
  getTaskIdWithEmployeeNumber,
  injectRouteParameter,
} from 'utils';
import { TaskListItem } from './TaskListItem';

export const TaskListItemContainer = ({
  selectedTaskId,
  task: { id, status },
  task,
  loading,
  swipeable,
  selectable,
  override,
  selected,
  onChangeSelect,
  hideSelectBox,
  type,
  ...props
}) => {
  const [localStatus, setLocalStatus] = useState(status);
  const {
    location: { pathname, search },
    match: {
      params: { section },
    },
  } = useReactRouter();
  const objectType = getObjectTypeFromSection(section);

  const nextPathname = injectRouteParameter(routes.taskDetail, {
    section,
    taskId: getTaskIdWithEmployeeNumber(task),
  });

  const { t } = useTranslation();
  const onSwipedUpdate = updatedStatus => {
    setLocalStatus(updatedStatus);
  };
  const focus = selectedTaskId === getTaskIdWithEmployeeNumber(task);
  const taskListItem = (
    <TaskListItem
      to={{ pathname: nextPathname, previousPath: `${pathname}${search}` }}
      loading={loading}
      t={t}
      localStatus={localStatus}
      selectable={selectable}
      // onSwipedUpdate={onSwipedUpdate}
      focus={focus}
      task={task}
      type={objectType}
      override={override}
      {...props}
    />
  );
  if (swipeable) {
    return (
      <SwipeableTaskWrapper
        id={id}
        status={status}
        localStatus={localStatus}
        onSwipedUpdate={onSwipedUpdate}
        task={task}
        override={override}
      >
        {taskListItem}
      </SwipeableTaskWrapper>
    );
  }
  if (selectable) {
    return (
      <SelectableTaskWrapper
        id={id}
        selected={selected}
        onChange={onChangeSelect}
        status={status}
        override={override}
        hideSelectBox={hideSelectBox}
      >
        {taskListItem}
      </SelectableTaskWrapper>
    );
  }
  return null;
};
