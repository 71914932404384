import React from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';

const fadeOut = keyframes`
  from {
    width: 10px;
    height: 10px;
    opacity: 1;
  }
  to {
    width: 15px;
    height: 15px;
    opacity: 0;
  }
`;
const StyledCircle = styled.div`
  pointer-events: none;
  background-color: #808080;
  width: 10px;
  height: 10px;
  position: fixed;
  left: ${({ left }) => left}px;
  top: ${({ top }) => top}px;
  animation: ${fadeOut} ${({ timeAliveInSeconds }) => timeAliveInSeconds}s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%);
  z-index: 5;
  border-radius: 50%;
`;

export const ShowClick = ({ event, timeAliveInMilliseconds }) => {
  const { clientX, clientY } = event.touches[0];
  const portalRoot = document.getElementById('portal');
  return ReactDOM.createPortal(
    <StyledCircle
      left={clientX}
      top={clientY}
      timeAliveInSeconds={timeAliveInMilliseconds / 1000}
    />,
    portalRoot
  );
};
