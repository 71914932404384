import React from 'react';
import styled from 'styled-components';
import {
  ErrorInfo,
  Header,
  LoadingOverlay,
  PurchaseRequestActionButtons,
  Section,
} from 'components';
import { StyledTaskDetailContentWrapper } from 'styled';

const StyledKeyValueGroupWrapper = styled.ul`
  margin-bottom: 300px;
  margin: auto;
`;

const StyledErrorInfo = styled(ErrorInfo)`
  margin-top: ${({ theme }) => theme.space[4]}px;
  @media only screen and (max-width: ${({ theme }) => theme.breakPoints[3]}) {
    margin-left: ${({ theme }) => theme.space[4]}px;
    margin-right: ${({ theme }) => theme.space[4]}px;
  }
`;

const TaskDetailTemplate = ({
  headerProps,
  sections,
  rejectProps,
  approveProps,
  task,
  loading,
  override,
}) => (
  <>
    <Header margin {...headerProps} />
    <StyledTaskDetailContentWrapper>
      {loading && <LoadingOverlay />}
      {override && override.message ? (
        <StyledErrorInfo
          title={`${override.internalError ? 'Internal error: ' : ''}${
            override.message
          }`}
        />
      ) : null}
      <StyledKeyValueGroupWrapper>
        {sections.map(({ type, ...sectionProps }) => {
          return <Section key={sectionProps.title} {...sectionProps} />;
        })}
      </StyledKeyValueGroupWrapper>
      <PurchaseRequestActionButtons
        override={override}
        task={task}
        rejectProps={rejectProps}
        approveProps={approveProps}
        loading={loading}
      />
    </StyledTaskDetailContentWrapper>
  </>
);

export default TaskDetailTemplate;
