import React from 'react';
import styled, { css } from 'styled-components';
import { ContactPage, MultipleTasksIcon, TaskDetailTemplate } from 'components';
import { routes } from 'config';
import {
  fixSAPFormatting,
  getFullName,
  getHumanReadableDate,
  getPurchaseRequestId,
  getShortPurchaseRequestItemId,
  toPriceNumber,
} from 'utils';

const StyledWrapper = styled.div`
  ${({ contactPanelActive }) =>
    contactPanelActive
      ? css`
          display: none;
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[3]}) {
            display: block;
          }
        `
      : ``}
`;

const StyledMultipleTasksIcon = styled(MultipleTasksIcon)`
  margin-top: ${({ theme }) => theme.space[1]}px;
  margin-left: ${({ theme }) => theme.space[2]}px;
`;

const PurchaseRequestDetailPage = ({
  previousPath,
  task,
  costAssignmentData,
  t,
  pathname,
  section,
  approveProps,
  override,
  contactPanelActive,
  ...props
}) => {
  const { loading } = props;

  return (
    <>
      <StyledWrapper contactPanelActive={contactPanelActive}>
        <TaskDetailTemplate
          loading={loading}
          override={override}
          contactPanelActive={contactPanelActive}
          headerProps={{
            title: t('TASK_DETAILS.TITLE'),
            to: previousPath,
            search: false,
            onlyMobile: false,
            emptyDesktop: true,
            detailDesktopBackgroundColor: true,
          }}
          approveRejectProps={{
            previousPath,
          }}
          sections={[
            {
              title: t('TASK_DETAILS.PURCHASE_INFORMATION'),
              items: [
                {
                  type: 'KEY_VALUE',
                  keyText: t('TASK_DETAILS.TITLE_OF_REQUEST'),
                  valueText: task.title,
                  valueTextBold: true,
                },
                {
                  type: 'KEY_VALUE_GROUP',
                  key: 1,
                  items: [
                    {
                      type: 'KEY_VALUE',
                      keyText: t('TASK_DETAILS.PURCHASE_REQUEST_NUMBER'),
                      valueText: (
                        <>
                          {getPurchaseRequestId(task.id)}
                          <StyledMultipleTasksIcon />
                        </>
                      ),
                      dataCy: 'keyValueRequestId',
                    },
                    {
                      type: 'KEY_VALUE',
                      keyText: t('TASK_DETAILS.ITEM_NUMBER'),
                      valueText: getShortPurchaseRequestItemId(task.id),
                    },
                  ],
                },
                {
                  type: 'OPEN_MULTIPLE_PURCHASE_REQUESTS',
                  key: 'OPEN_MULTIPLE_PURCHASE_REQUESTS',
                  taskType: t('TASK_DETAILS.REQUESTS'),
                  subject: t('TASK_DETAILS.PR_NUMBER'),
                },
                {
                  keyText: t('TASK_DETAILS.REQUESTOR_NAME'),
                  valueText: getFullName(task.requestor),
                  type: 'KEY_VALUE_GROUP',
                  key: 2,
                  items: [
                    {
                      type: 'KEY_VALUE',
                      keyText: t('TASK_DETAILS.REQUESTOR_NAME'),
                      valueText: getFullName(task.requestor),
                    },
                    {
                      type: 'KEY_VALUE',
                      keyText: t('TASK_DETAILS.PLANT_LOCATION'),
                      valueText: task.plantLocation,
                    },
                  ],
                },
                {
                  type: 'KEY_VALUE_GROUP',
                  key: 5,
                  items: [
                    {
                      type: 'KEY_VALUE',
                      keyText: t('TASK_DETAILS.TOTAL_PRICE_REQUISITION'),
                      valueText: toPriceNumber(task.amount),
                    },
                    {
                      type: 'KEY_VALUE',
                      keyText: t('TASK_DETAILS.QUANTITY'),
                      valueText: task.quantity
                        ? `${task.quantity} ${task.unit?.code}`
                        : null,
                    },
                  ],
                },
                {
                  type: 'FILE_LIST',
                  keyText: t('TASK_DETAILS.DOCUMENTS'),
                  values: task.documents || [],
                  showOnCondition: (task.documents || []).length > 0,
                },
              ],
            },
            {
              title: t('TASK_DETAILS.DESCRIPTION'),
              items: [
                {
                  type: 'KEY_VALUE',
                  keyText: t('TASK_DETAILS.REQUESTOR_DESCRIPTION'),
                  valueText: fixSAPFormatting(task.description),
                  isExpandable: true,
                },
                {
                  type: 'KEY_VALUE',
                  keyText: t('TASK_DETAILS.BSRF_TEXT'),
                  valueText: fixSAPFormatting(task.bsrfText),
                  isExpandable: true,
                },
              ],
            },
            {
              title: t('TASK_DETAILS.STATUS'),
              loading,
              items: [
                {
                  type: 'METROLINE_REGULAR_PURCHASE_REQUESTS',
                  task,
                },
              ],
            },
            {
              title: t('TASK_DETAILS.VENDOR'),
              items: [
                {
                  type: 'KEY_VALUE',
                  keyText: t('TASK_DETAILS.VENDOR_NAME'),
                  valueText: task?.vendor?.name,
                },
              ],
            },
            {
              title: t('TASK_DETAILS.PROCUREMENT_INFORMATION'),
              items: [
                {
                  type: 'KEY_VALUE_GROUP',
                  key: 3,
                  items: [
                    {
                      type: 'KEY_VALUE',
                      keyText: t(
                        `TASK_DETAILS.${costAssignmentData?.type}.NAME`
                      ),
                      valueText: costAssignmentData?.name,
                    },
                    {
                      type: 'KEY_VALUE',
                      keyText: t(
                        `TASK_DETAILS.${costAssignmentData?.type}.OWNER`
                      ),
                      valueText: getFullName(costAssignmentData?.owner),
                    },
                  ],
                },
                {
                  type: 'KEY_VALUE_GROUP',
                  key: 4,
                  items: [
                    {
                      type: 'KEY_VALUE',
                      keyText: t('TASK_DETAILS.DELIVERY_DATE'),
                      valueText: getHumanReadableDate(task.deliveryDate, false),
                    },
                    {
                      type: 'KEY_VALUE',
                      keyText: t(
                        `TASK_DETAILS.${costAssignmentData?.type}.CODE`
                      ),
                      valueText: costAssignmentData?.code,
                    },
                  ],
                },
              ],
            },
          ]}
          approveProps={{
            ...approveProps,
            text: t('COMMON.APPROVE'),
            filled: true,
          }}
          rejectProps={{
            to: `${pathname}/${routes.confirmReject}`,
            text: t('COMMON.REJECT'),
          }}
          task={task}
          {...props}
        />
      </StyledWrapper>
      <ContactPage task={task} />
    </>
  );
};

export default PurchaseRequestDetailPage;
