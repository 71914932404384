import React from 'react';
import { Paperclip } from 'icons';
import styled from 'styled-components';
import { FileLink, MissingDataParagraph } from 'components';
import { linkAttentionStyle } from 'styled';

// import { IOS_VERSION } from 'utils';

const DocumentListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const EntryWrapper = styled.div`
  margin-top: ${({ theme }) => theme.space[2]}px;
  display: flex;
  align-items: center;
`;

const StyledLinkText = styled.div`
  margin-left: ${({ theme }) => theme.space[1]}px;
  ${linkAttentionStyle}
`;

const StyledFileLink = styled(FileLink)`
  width: 100%;
`;

// https://gist.github.com/ngoclt/2857281
// const supportedOSversion = () => {
//   if (
//     IOS_VERSION &&
//     IOS_VERSION < 12.2 &&
//     window.matchMedia('(display-mode: standalone)').matches
//   ) {
//     return false;
//   }
//   return true;
// };

export const FileList = ({ taskId, values = [], valueTextBold }) => (
  <DocumentListWrapper fontWeight={valueTextBold ? '500' : 'normal'}>
    {values.length > 0 ? (
      values.map((value, index) => (
        <EntryWrapper key={index}>
          <>
            <Paperclip width={25} height={25} />
            <StyledFileLink
              contentType={value.contentType}
              fileName={value.fileName || value.description}
              taskId={taskId}
              fileId={value.fileId || value.imageID}
            >
              <StyledLinkText>
                {value.fileName || value.description}
              </StyledLinkText>
            </StyledFileLink>
          </>
        </EntryWrapper>
      ))
    ) : (
      <MissingDataParagraph />
    )}
  </DocumentListWrapper>
);
