import React from 'react';
import { Link } from 'react-router-dom';
import { ViewMultipleTasks } from 'icons';
import styled from 'styled-components';
import { routes } from 'config';
import { RoundIconButton } from './RoundIconButton';

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: -2px; /* counter balancing circle through Overshoting https://en.m.wikipedia.org/wiki/Overshoot_(typography) */
`;

const StyledViewMultipleTasks = styled(ViewMultipleTasks)`
  width: 20px;
  margin-left: -${({ theme }) => theme.space[1]}px; /* pure visual inbalance fix */
`;

export const OpenMultipleTasksLink = ({ pathname, t, taskType, ...props }) => (
  <StyledLink
    data-cy="openMultipleTasksLink"
    to={`${pathname}/${routes.multiple}`}
    {...props}
  >
    <RoundIconButton
      Icon={StyledViewMultipleTasks}
      label={t('TASK_DETAILS.OPEN_MULTIPLE_TASKS_LABEL', { taskType })}
    />
  </StyledLink>
);
