import React from 'react';
import styled from 'styled-components';
import {
  MultipleTasksIcon,
  PillMultipleItems,
  TaskListItemSelectGroup,
} from 'components';

const StyledWrapper = styled.div`
  padding-bottom: 73px; /* height of approve reject */
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    padding-bottom: 0px;
  }
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.space[5]}px 0px;
`;

const StyledPillMultipleItems = styled(PillMultipleItems)`
  margin-left: 10px;
`;

const StyledMultipleTasksIcon = styled(MultipleTasksIcon)`
  position: relative;
  left: -3px; /* to align the center to the center of the checkboxes */
`;

export const MultipleTasksTemplate = () => (
  <StyledWrapper>
    <StyledTitleWrapper>
      <StyledMultipleTasksIcon primary />
      <StyledPillMultipleItems />
    </StyledTitleWrapper>
    <TaskListItemSelectGroup />
  </StyledWrapper>
);
