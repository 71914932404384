import React from 'react';
import styled from 'styled-components';
import { OptionToggle } from 'components';
import { StyledPlaceholder } from 'styled';

const StyledKey = styled.div`
  color: ${({ theme }) => theme.colors.darkerGray};
  font-weight: normal;
  font-size: 16px;
  max-width: calc(100% - 24px - 50px);
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    max-width: calc(100% - (24px * 3) - 50px);
  }
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space[5]}px;
  }
  &:last-child {
    margin-bottom: ${({ theme }) => theme.space[7]}px;
  }
`;

export const PreferenceOption = ({
  keyText = 'keyText',
  value,
  className,
  sectionKey,
  dataKey,
  toggleHandler,
  loading,
  autoFocus,
}) => {
  return loading ? (
    <StyledLabel className={className}>
      <StyledPlaceholder height="30px" width="100%" color="black" />
    </StyledLabel>
  ) : (
    <li className={className}>
      <StyledLabel>
        <StyledKey>{keyText}</StyledKey>
        <OptionToggle
          checked={value}
          sectionKey={sectionKey}
          dataKey={dataKey}
          onToggle={toggleHandler}
          autoFocus={autoFocus}
        />
      </StyledLabel>
    </li>
  );
};
