import React, { forwardRef } from 'react';
import { Warning } from 'icons';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.red[0]};
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  * {
    color: ${({ theme }) => theme.colors.red[5]};
    font-size: ${({ theme }) => theme.fontSizes[2]}px;
  }
  width: 100%;
`;

const StyledWarning = styled(Warning)`
  .stroke {
    stroke: ${({ theme }) => theme.colors.red[5]};
  }
  .fill {
    fill: ${({ theme }) => theme.colors.red[5]};
  }
  width: 18px;
  height: 18px;
  margin-right: ${({ theme }) => theme.space[2]}px;
`;

const StyledContent = styled.div`
  display: flex;
`;

const StyledScrollToHack = styled.div`
  padding-top: 75px;
  margin-top: -75px;
`;

const StyledText = styled.div`
  width: calc(100% - 18px - ${({ theme }) => theme.space[2]}px);
`;

export const ErrorInfo = forwardRef(({ className, title, ...props }, ref) => (
  <div className={className}>
    <StyledScrollToHack ref={ref} />
    <StyledWrapper {...props}>
      <StyledContent>
        <StyledWarning />
        <StyledText data-cy="errorInfoTitle">{title}</StyledText>
      </StyledContent>
    </StyledWrapper>
  </div>
));
