const APPROVED_STATUS = 'APPROVED';
const REJECTED_STATUS = 'REJECTED';
const PENDING_STATUS = 'PENDING';
const NEXT_STATUS = 'NEXT';
const DELETED_STATUS = 'DELETED';

const isRejected = status => {
  return ['REJECTED', 'DECLINED'].includes(status);
};

export const derrivedSequenceStatus = (approvalSequenceStatus, taskStatus) => {
  if (approvalSequenceStatus === NEXT_STATUS) {
    return NEXT_STATUS;
  }

  if (approvalSequenceStatus === APPROVED_STATUS) {
    return APPROVED_STATUS;
  }

  if (approvalSequenceStatus === DELETED_STATUS) {
    return DELETED_STATUS;
  }

  if (isRejected(taskStatus) && approvalSequenceStatus !== APPROVED_STATUS) {
    return REJECTED_STATUS;
  }

  return PENDING_STATUS;
};

export const convertToSapStatus = inputStatus => {
  if (inputStatus === 'APPROVED') return 'A';
  if (inputStatus === 'REJECTED') return 'R';
  return null;
};
