import React from 'react';
import styled, { css } from 'styled-components';
import { customTheme } from 'theme/theme';
import { ContactPage, TravelAndExpenseDetailTemplate } from 'components';
import { routes } from 'config';
import { toPriceNumber } from 'utils';

const mapTextLines = (lineItem, index) => {
  if (lineItem.textLine.match(/^\d+\.\d+\.(\d{4})/)) {
    if (index === 0) return `${lineItem.textLine}`;
    return `\n\n${lineItem.textLine}`;
  }
  return lineItem.textLine;
};

const StyledWrapper = styled.div`
  ${({ contactPanelActive }) =>
    contactPanelActive
      ? css`
          display: none;
          @media only screen and (min-width: ${() =>
              customTheme.breakPoints[3]}) {
            display: block;
          }
        `
      : ``}
`;

const TravelAndExpenseDetailPage = ({
  previousPath,
  task,
  costAssignmentData,
  t,
  pathname,
  section,
  approveProps,
  override,
  contactPanelActive,
  ...props
}) => {
  return (
    <>
      <StyledWrapper contactPanelActive={contactPanelActive}>
        <TravelAndExpenseDetailTemplate
          override={override}
          contactPanelActive={contactPanelActive}
          t={t}
          headerProps={{
            title: t('TASK_DETAILS.TITLE'),
            to: previousPath,
            search: false,
            onlyMobile: false,
            emptyDesktop: true,
            detailDesktopBackgroundColor: true,
          }}
          approveRejectProps={{
            previousPath,
          }}
          sections={[
            {
              title: t('TRAVEL_AND_EXPENSE_DETAILS.TRIP_INFORMATION_TITLE'),
              loading: false,
              items: [
                {
                  type: 'KEY_VALUE_GROUP',
                  key: 1,
                  items: [
                    {
                      type: 'TRIP_START_END',
                      key: 'TRIP_START_END',
                      startDate: task.tripDetails.departureDate,
                      startTime: task.tripDetails.departureTime,
                      endDate: task.tripDetails.arrivalDate,
                      endTime: task.tripDetails.arrivalTime,
                    },
                    {
                      type: 'KEY_VALUE',
                      key: 'KEY_VALUE_1',
                      keyText: t('TRAVEL_AND_EXPENSE_DETAILS.TRIP_LOCATION'),
                      valueText: `${task.tripDetails.location}, ${task.tripDetails.country}`,
                      valueTextBold: false,
                    },
                  ],
                },
                {
                  type: 'KEY_VALUE_GROUP',
                  key: 2,
                  items: [
                    {
                      type: 'KEY_VALUE',
                      key: 'KEY_VALUE_2',
                      keyText: t('TRAVEL_AND_EXPENSE_DETAILS.TRIP_REASON'),
                      valueText: task.tripDetails.reason,
                      valueTextBold: false,
                    },
                    {
                      type: 'KEY_VALUE',
                      key: 'KEY_VALUE_3',
                      keyText: t(
                        'TRAVEL_AND_EXPENSE_DETAILS.AMOUNT_REIMBURSED'
                      ),
                      valueText: toPriceNumber({
                        amount: task.tripDetails.amountReimbursed,
                        currency: task.tripDetails.currency,
                      }),
                      valueTextBold: true,
                      valueTextColor: customTheme.colors.tertiary,
                    },
                  ],
                },
                {
                  type: 'OPEN_MULTIPLE_TRAVEL_AND_EXPENSES',
                  key: 'OPEN_MULTIPLE_TRAVEL_AND_EXPENSES',
                  taskType: t('TASK_DETAILS.REPORTS'),
                  subject: t('TASK_DETAILS.THIS_PERSON'),
                  task,
                },
                {
                  type: 'KEY_VALUE_GROUP',
                  key: 3,
                  items: [
                    {
                      type: 'KEY_VALUE',
                      key: 'KEY_VALUE_4',
                      keyText: t('TRAVEL_AND_EXPENSE_DETAILS.TOTAL_AMOUNT'),
                      valueText: toPriceNumber({
                        amount: task.tripDetails.tripTotal,
                        currency: task.tripDetails.currency,
                      }),
                      valueTextBold: true,
                    },
                    {
                      type: 'KEY_VALUE',
                      key: 'KEY_VALUE_5',
                      keyText: t(
                        'TRAVEL_AND_EXPENSE_DETAILS.AMOUNT_PAID_BY_COMPANY'
                      ),
                      valueText: toPriceNumber({
                        amount: task.tripDetails.amountPaidByCompany,
                        currency: task.tripDetails.currency,
                      }),
                      valueTextBold: true,
                      valueTextColor: customTheme.colors.darkblue[5],
                    },
                  ],
                },
                {
                  type: 'KEY_VALUE_GROUP',
                  key: 4,
                  items: [
                    {
                      type: 'KEY_VALUE',
                      key: 'KEY_VALUE_6',
                      keyText: t(
                        'TRAVEL_AND_EXPENSE_DETAILS.ADDITIONAL_TRIP_INFO'
                      ),
                      valueText: task.tripLines.map(mapTextLines).join(' '),
                      valueTextBold: false,
                      isExpandable: true,
                      height: 42,
                    },
                    {
                      type: 'FILE_LIST',
                      key: 'FILE_LIST_1',
                      keyText: t(
                        'TRAVEL_AND_EXPENSE_DETAILS.ATTACHTED_DOCUMENTS'
                      ),
                      values: task.attachments.map(attachment => {
                        return {
                          fileName:
                            attachment.description ||
                            t('TRAVEL_AND_EXPENSE_DETAILS.UNNAMED_DOCUMENT'),
                          fileId: attachment.receiptImage,
                          contentType: attachment.contentType,
                        };
                      }),
                      valueTextBold: false,
                    },
                  ],
                },
              ],
            },
            {
              title: t('TASK_DETAILS.COST_ASSIGNMENT'),
              key: 'COST_ASSIGNMENT',
              items: [
                {
                  type: 'COST_ASSIGNMENTS_CARDS',
                  key: 'COST_ASSIGNMENTS_CARDS',
                  task,
                },
              ],
            },
            {
              title: t('TASK_DETAILS.STATUS'),
              items: [
                {
                  type: 'METROLINE_TRAVEL_AND_EXPENSES',
                  task,
                },
              ],
            },
            {
              title: t('TRAVEL_AND_EXPENSE_DETAILS.EXPENSE_INFORMATION'),
              items: [
                {
                  type: 'MEALS',
                  key: 'MEALS',
                  task,
                },
                {
                  type: 'ACCOMODATION',
                  key: 'ACCOMODATION',
                  task,
                },
                {
                  type: 'CUSTOM_MILEAGE',
                  key: 'CUSTOM_MILEAGE',
                  task,
                },
                {
                  type: 'RECEIPTS',
                  key: 'RECEIPTS',
                  task,
                },
              ],
            },
          ]}
          approveProps={{
            ...approveProps,
            text: t('COMMON.APPROVE'),
            filled: true,
          }}
          rejectProps={{
            to: `${pathname}/${routes.confirmReject}`,
            text: t('COMMON.REJECT'),
          }}
          task={task}
          {...props}
        />
      </StyledWrapper>
      <ContactPage task={task} />
    </>
  );
};

export default TravelAndExpenseDetailPage;
