/* eslint-disable import/no-cycle */
/* eslint-disable no-alert */
import jwtDecode from 'jwt-decode';
import { endpoints } from 'config';
import { isLocal } from './environment';

export const insecurlyVerifyJwtToken = token => {
  try {
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.nbf && decodedToken.exp) {
        const {
          nbf: tokenNotValidBefore,
          exp: tokenNotValidAfter,
        } = decodedToken;
        const currentTimestamp = Math.round(new Date().valueOf() / 1000);
        return (
          tokenNotValidBefore < currentTimestamp &&
          currentTimestamp < tokenNotValidAfter
        );
      }
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const hasActiveBrowserSession = async () => {
  if (isLocal) return true;
  const { status } = await fetch('/oauth2/auth');
  return status === 202;
};

export const logout = () => {
  window.location.href = endpoints.azureLogout;
};

export const handleLogin = () => {
  window.location.reload();
};
