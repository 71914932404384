import React from 'react';
import { Loader } from 'icons';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`;

const StyledContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledTitle = styled.h2`
  padding-top: ${({ theme }) => theme.space[3]}px;
  color: ${({ theme }) => theme.colors.primary};
  max-width: 400px;
  width: calc(100% - ${({ theme }) => theme.space[5]}px);
  text-align: center;
`;

const StyledLoader = styled(Loader)`
  width: 90px;
  height: 60px;
  * {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledBlockedMessage = styled.p`
  position: absolute;
  top: ${({ theme }) => theme.space[3]}px;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  width: calc(100% - ${({ theme }) => theme.space[4]}px);
  text-align: center;
`;

export const LoadingTemplate = ({
  t,
  icon = <StyledLoader />,
  isBlocked,
  title,
  dataCy,
}) => {
  return (
    <StyledWrapper data-cy={dataCy}>
      <StyledContentWrapper>
        {icon}
        <StyledTitle>{title}</StyledTitle>
      </StyledContentWrapper>
      {isBlocked ? (
        <StyledBlockedMessage>
          {t('PRE_LOGIN_PAGE.BLOCKER')}
        </StyledBlockedMessage>
      ) : null}
    </StyledWrapper>
  );
};
