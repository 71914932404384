import React from 'react';
import { Arrow } from 'icons';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
`;

const StyledTextWrapper = styled.div`
  color: ${({ theme }) => theme.colors.tertiary};
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  text-decoration: underline;
`;

const StyledArrow = styled(Arrow)`
  width: 6px;
  height: 19px;
  margin-left: ${({ theme }) => theme.space[2]}px;
  transform: rotate(180deg);
  position: relative;
  top: 2px;
  * {
    stroke: ${({ theme }) => theme.colors.tertiary};
  }
`;

export const FakeLink = ({ children, ...props }) => (
  <StyledWrapper {...props}>
    <StyledTextWrapper>{children}</StyledTextWrapper>
    <StyledArrow />
  </StyledWrapper>
);
