import { userInterfaceUpdate } from 'store/userInterface/userInterface.duck';

export const confirm = ({
  message,
  succesCallback,
  confirmText = 'Ok',
  cancelText = 'Cancel',
  dispatch,
}) => {
  dispatch(
    userInterfaceUpdate({
      confirmAlert: {
        active: true,
        text: message,
        succesCallback,
        confirmText,
        cancelText,
      },
    })
  );
};
