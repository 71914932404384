import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationDino } from 'icons';
import styled from 'styled-components';
import { SidePanel } from 'components';
import { routes } from 'config';
import { linkAttentionStyle } from 'styled';

const StyledNotificationDino = styled(NotificationDino)`
  width: 160px;
  height: 160px;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    margin-top: ${({ theme }) => theme.space[4]}px;
    width: 300px;
    height: 300px;
  }
`;

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    justify-content: end;
  }
`;

const StyledTitle = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: ${({ theme }) => theme.space[1]}px;
  font-size: 22px;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    font-size: 32px;
    margin-bottom: ${({ theme }) => theme.space[2]}px;
    margin-top: ${({ theme }) => theme.space[4]}px;
  }
`;

const StyledParagraph = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  max-width: 320px;
  line-height: 19px;
`;

const StyledLink = styled(Link)`
  ${linkAttentionStyle}
`;

export const NotificationReminderPage = ({ active, t, ...props }) => {
  return (
    <SidePanel
      headerProps={{
        title: t('NOTIFICATION_REMINDER.HEADER_TITLE'),
      }}
      showAsPopUp
      active
      {...props}
    >
      <StyledWrapper data-cy="notificationReminderPage">
        <StyledNotificationDino />
        <StyledTitle>{t('NOTIFICATION_REMINDER.BODY_TITLE')}</StyledTitle>
        <StyledParagraph>
          {t('NOTIFICATION_REMINDER.BODY_TEXT')}
          {
            <StyledLink to={routes.preferences}>
              {t('NOTIFICATION_REMINDER.PREFERENCES_LINK')}
            </StyledLink>
          }
        </StyledParagraph>
      </StyledWrapper>
    </SidePanel>
  );
};
/*
HEADER_TITLE
BODY_TITLE
BODY_TEXT
PREFERENCES_LINK
*/
