import { createActions, handleActions } from 'redux-actions';

const initialState = {
  taskListSortingField: 'requestDate DESC',
  taskListStatusFilter: 'PENDING',
};

const PERSISTED_USER_INTERFACE_UPDATE = 'PERSISTED_USER_INTERFACE_UPDATE';

export const { persistedUserInterfaceUpdate } = createActions(
  PERSISTED_USER_INTERFACE_UPDATE
);
export default handleActions(
  {
    [PERSISTED_USER_INTERFACE_UPDATE]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  initialState
);
