import React from 'react';
import styled, { css } from 'styled-components';
import { Stars } from './Stars';
import { ReactComponent as Magnifier } from './magnifier.svg';

const StyledMagnifier = styled(Magnifier)`
  position: absolute;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
`;

const StyledStars = styled(Stars)`
  transform: rotate(-135deg);
  ${({ inverseIcon }) =>
    inverseIcon
      ? css`
          transform: rotate(135deg) scale(-1, 1);
        `
      : ''}
`;

export const Magnifiorite = props => {
  return (
    <StyledStars {...props} color="#005d9a">
      <StyledMagnifier
        id="prefix__Layer_1"
        x={0}
        y={0}
        viewBox="0 0 118.7 133"
        xmlSpace="preserve"
      />
    </StyledStars>
  );
};
