import React from 'react';
import { BrokenRocket, Call, Mail, MicrosoftTeams } from 'icons';
import styled from 'styled-components';
import {
  ContactLink,
  EmptyState,
  OrganisationChart,
  ProfilePicture,
} from 'components';
import { useEmailBody, useEmailSubject } from 'hooks';
import { StyledPlaceholder } from 'styled';
import { composeMicrosoftTeamsId } from 'utils';

const StyledSelectedContactWrapper = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.space[3]}px;
  margin-bottom: ${({ theme }) => theme.space[5]}px;
  align-items: center;
`;

const StyledTitleWrapper = styled.div`
  margin-left: ${({ theme }) => theme.space[3]}px;
`;

const StyledJobTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
`;

const StyledFullName = styled.h1`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.primary};
  @media (prefers-color-scheme: dark) {
    color: ${({ theme }) => theme.colors.darkerWhite};
  }
`;

const StyledMissingDataTitle = styled.span`
  font-style: italic;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.primary};
  @media (prefers-color-scheme: dark) {
    color: ${({ theme }) => theme.colors.darkerWhite};
  }
`;
export const ContactTemplate = ({
  fullName = 'Jane Doe',
  contact = {
    firstName: 'Jane',
    lastName: 'Doe',
    telephone: '+32 472 01 01 01',
    email: 'jane.doe@borealisgroup.com',
    skype: 'jane.doe',
  },
  loading,
  error,
  task = {},
  t,
  objectTypeInView,
}) => {
  const emailSubject = useEmailSubject(task, objectTypeInView);
  const emailBody = useEmailBody(task, fullName, objectTypeInView);
  return (
    <>
      {error ? (
        <EmptyState
          Icon={BrokenRocket}
          title={t('COMMON.500_TITLE')}
          subtitle={t('COMMON.500_SUBTITLE')}
        />
      ) : (
        <>
          <StyledSelectedContactWrapper>
            <ProfilePicture loading={loading} profile={contact?.profile} />
            <StyledTitleWrapper>
              {loading ? (
                <StyledPlaceholder
                  width="150px"
                  height="20px"
                  color="primary"
                />
              ) : fullName ? (
                <StyledFullName data-cy="contactUserName">
                  {fullName}
                </StyledFullName>
              ) : (
                <StyledMissingDataTitle>
                  {t('KEY_VALUE.DATA_NOT_AVAILABLE')}
                </StyledMissingDataTitle>
              )}

              <StyledJobTitle>
                {loading ? (
                  <StyledPlaceholder
                    width="150px"
                    height="16px"
                    color="secondary"
                    marginTop="4px"
                  />
                ) : contact?.profile?.jobTitle ? (
                  contact?.profile?.jobTitle
                ) : (
                  t('KEY_VALUE.DATA_NOT_AVAILABLE')
                )}
              </StyledJobTitle>
            </StyledTitleWrapper>
          </StyledSelectedContactWrapper>
          <div>
            {[
              {
                label: 'Email',
                icon: <Mail width={20} height={20} />,
                value: contact?.profile?.userPrincipalName,
                href: `${contact?.profile?.userPrincipalName}?${emailSubject}&${emailBody}`,
                hrefPreposition: 'mailto',
              },
              {
                label: 'Phone',
                icon: <Call width={20} height={20} />,
                value: contact?.profile?.mobilePhone,
                href: contact?.profile?.mobilePhone,
                hrefPreposition: 'tel',
              },
              {
                label: 'Microsoft Teams',
                icon: <MicrosoftTeams width={20} height={30} />,
                value: composeMicrosoftTeamsId(contact),
                href: `https://teams.microsoft.com/l/chat/0/0?users=${contact?.profile?.userPrincipalName}`,
                target: '_blank',
              },
            ].map(contactLinkProps => (
              <ContactLink
                key={contactLinkProps.label}
                loading={loading}
                href={contactLinkProps.href}
                {...contactLinkProps}
              />
            ))}
          </div>
          <OrganisationChart loading={loading} contact={contact} />
        </>
      )}
    </>
  );
};
