import React from 'react';
import {
  Accomodation,
  Airfare,
  Car,
  GroupOfPeople,
  Meals,
  Objects,
  Other,
  Transportation,
} from 'icons';
import styled, { css } from 'styled-components';
import {
  AccomodationDetailsTable,
  CostAssignmentsCards,
  FaqQuestion,
  KeyValue,
  LineItemsCards,
  MealDetailsTable,
  MetrolineAribaPurchaseRequests,
  MetrolineRegularPurchaseRequests,
  MetrolineTravelAndExpenses,
  OpenMultiplePurchaseRequestsLink,
  OpenMultipleTravelAndExpensesLink,
  PreferenceOption,
  TripDetailsCard,
  TripDetailsList,
  TripStartEnd,
  ViewOnlyComments,
} from 'components';
import {
  sectionItemWrapperStyle,
  StyledSection,
  StyledSectionTitle,
} from 'styled';
import { groupReceiptsByLocalDefinedGroup, toPriceNumber } from 'utils';

const StyledViewOnlyComments = styled(ViewOnlyComments)`
  ${sectionItemWrapperStyle}
  margin-bottom: ${({ theme }) => theme.space[4]}px;
`;

const StyledKeyValue = styled(KeyValue)`
  ${sectionItemWrapperStyle}
  margin-bottom: ${({ theme }) => theme.space[4]}px;
`;

const sharedMultipleLinkStyle = css`
  ${sectionItemWrapperStyle}
  margin-bottom: ${({ theme }) => theme.space[4]}px;
`;

const StyledOpenMultiplePurchaseRequestsLink = styled(
  OpenMultiplePurchaseRequestsLink
)`
  ${sharedMultipleLinkStyle}
`;

const StyledOpenMultipleTravelAndExpensesLink = styled(
  OpenMultipleTravelAndExpensesLink
)`
  ${sharedMultipleLinkStyle}
`;

const StyledPreferenceOption = styled(PreferenceOption)`
  ${sectionItemWrapperStyle}
`;

const StyledSectionWrapper = styled.ul`
  ${({ preferences }) =>
    preferences
      ? css`
          padding: 5px 5px 0 0;
        `
      : ''}

  ${({ faq }) =>
    faq
      ? css`
          padding-right: ${({ theme }) => theme.space[4]}px};
        `
      : ''}
`;

const StyledTripDetailsCard = styled(TripDetailsCard)`
  padding-bottom: ${({ theme }) => theme.space[5]}px;
`;

const StyledKeyValueGroupUl = styled.ul`
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[1]}) {
    display: flex;
    justify-content: space-between;
    li {
      &:first-of-type {
        min-width: 230px;
      }
      max-width: 350px;
      width: 50%;
      padding-right: 10px;
    }
  }
`;

const mapOverItems = (items, loading, t) => {
  return (
    items.length > 0 &&
    items.map(({ type, ...nestedProps }) => {
      if (type === 'METROLINE_REGULAR_PURCHASE_REQUESTS') {
        return (
          <MetrolineRegularPurchaseRequests
            loading={loading}
            key={nestedProps}
            {...nestedProps}
          />
        );
      }
      if (type === 'METROLINE_ARIBA_PURCHASE_REQUESTS') {
        return (
          <MetrolineAribaPurchaseRequests
            loading={loading}
            key={nestedProps}
            {...nestedProps}
          />
        );
      }
      if (type === 'METROLINE_TRAVEL_AND_EXPENSES') {
        return (
          <MetrolineTravelAndExpenses
            loading={loading}
            key={nestedProps}
            {...nestedProps}
          />
        );
      }
      if (type === 'OPEN_MULTIPLE_PURCHASE_REQUESTS') {
        return (
          <StyledOpenMultiplePurchaseRequestsLink
            key={nestedProps.key}
            {...nestedProps}
          />
        );
      }
      if (type === 'OPEN_MULTIPLE_TRAVEL_AND_EXPENSES') {
        return (
          <StyledOpenMultipleTravelAndExpensesLink
            key={nestedProps.key}
            {...nestedProps}
          />
        );
      }
      if (type === 'VIEW_ONLY_COMMENTS') {
        return (
          <StyledViewOnlyComments key={nestedProps.key} {...nestedProps} />
        );
      }
      if (type === 'KEY_VALUE_GROUP') {
        return (
          <li key={nestedProps.key}>
            <StyledKeyValueGroupUl>
              {mapOverItems(nestedProps.items, loading, t)}
            </StyledKeyValueGroupUl>
          </li>
        );
      }
      if (type === 'QUESTION') {
        return (
          <FaqQuestion key={JSON.stringify(nestedProps)} {...nestedProps} />
        );
      }
      if (type === 'COST_ASSIGNMENTS_CARDS') {
        return (
          <CostAssignmentsCards
            key={nestedProps.key}
            task={{
              ...nestedProps.task,
            }}
          />
        );
      }
      if (type === 'LINE_ITEMS_CARDS') {
        return (
          <LineItemsCards
            key={nestedProps.key}
            task={{
              ...nestedProps.task,
            }}
          />
        );
      }
      if (type === 'PREFERENCE') {
        return (
          <StyledPreferenceOption
            key={nestedProps.sectionKey + nestedProps.dataKey}
            {...nestedProps}
            loading={loading}
          />
        );
      }
      if (type === 'TRIP_START_END') {
        return (
          <TripStartEnd
            key="Trip start End"
            {...nestedProps}
            loading={loading}
          />
        );
      }
      if (type === 'MEALS') {
        if (nestedProps.task.meals.length) {
          return (
            <StyledTripDetailsCard
              Icon={Meals}
              key={nestedProps.key}
              keyValueProps={{
                keyText: t(`TRAVEL_AND_EXPENSE_DETAILS.MEALS_INTERNATIONAL`),
                valueText: toPriceNumber({
                  amount:
                    nestedProps.task.tripDetails.amountReimbursedFormMeals,
                  currency: nestedProps.task.tripDetails.currency,
                }),
              }}
            >
              <MealDetailsTable rows={nestedProps.task.meals} />
            </StyledTripDetailsCard>
          );
        }
        return null;
      }
      if (type === 'ACCOMODATION') {
        if (nestedProps.task.accomodations?.length) {
          return (
            <StyledTripDetailsCard
              Icon={Accomodation}
              key={nestedProps.key}
              keyValueProps={{
                keyText: t(`TRAVEL_AND_EXPENSE_DETAILS.ACCOMODATION_PER_DIEM`),
                valueText: toPriceNumber({
                  amount:
                    nestedProps.task.tripDetails
                      .amountReimbursedForAccomodation,
                  currency: nestedProps.task.tripDetails.currency,
                }),
              }}
            >
              <AccomodationDetailsTable rows={nestedProps.task.accomodations} />
            </StyledTripDetailsCard>
          );
        }
        return null;
      }
      if (type === 'CUSTOM_MILEAGE') {
        if (
          (nestedProps.task.tripDetails?.amountReimbursedForMileage &&
            nestedProps.task.tripDetails?.amountReimbursedForMileage !== '0') ||
          (nestedProps.task.tripDetails?.distanceMileage &&
            nestedProps.task.tripDetails?.distanceMileage !== '0')
        ) {
          return (
            <TripDetailsCard
              Icon={Car}
              key={nestedProps.key}
              keyValueProps={{
                keyText: t(`TRIP_DETAILS.CAR`),
                valueText: toPriceNumber({
                  amount:
                    nestedProps.task.tripDetails?.amountReimbursedForMileage,
                  currency: nestedProps.task.tripDetails.currency,
                }),
              }}
            >
              <TripDetailsList
                attachments={nestedProps.task.attachments}
                items={[
                  {
                    description: t('TRIP_DETAILS.TOTAL_DISTANCE', {
                      distance: nestedProps.task.tripDetails?.distanceMileage,
                    }),
                    expenseTypeName: t('TRIP_DETAILS.MILEAGE'),
                  },
                ]}
              />
            </TripDetailsCard>
          );
        }
        return null;
      }

      if (type === 'RECEIPTS') {
        const icons = {
          FLIGHT: Airfare,
          HOTEL: Accomodation,
          MEAL: Meals,
          TRANSPORT: Transportation,
          CAR: Car,
          GROUP_OF_PEOPLE: GroupOfPeople,
          OBJECTS: Objects,
          OTHER: Other,
        };
        return groupReceiptsByLocalDefinedGroup(nestedProps.task.receipts).map(
          (category, index) => {
            return (
              <TripDetailsCard
                Icon={icons[category.groupId]}
                dark={category.paidByCompany}
                key={index}
                keyValueProps={{
                  keyText: t(`TRIP_DETAILS.${category.groupId}`),
                  valueText: toPriceNumber({
                    amount: category.total,
                    currency: nestedProps.task.tripDetails.currency,
                  }),
                }}
              >
                <TripDetailsList
                  attachments={nestedProps.task.attachments}
                  dark={category.paidByCompany}
                  items={category.receipts}
                />
              </TripDetailsCard>
            );
          }
        );
      }

      // TripDetailsList
      const { showOnCondition } = nestedProps;
      if (showOnCondition !== undefined && !showOnCondition) return null;
      return (
        <StyledKeyValue
          key={nestedProps.key || nestedProps.keyText}
          type={type}
          loading={loading}
          {...nestedProps}
        />
      );
    })
  );
};
export const Section = ({
  items,
  title,
  sectionToggled,
  sectionFocused,
  collapseAble,
  loading,
  boldTitle = false,
  caseSensitiveTitle = false,
  stickyTitles = true,
  preferences = false,
  faq = false,
  t,
}) => (
  <StyledSection focused={sectionFocused}>
    <StyledSectionTitle
      bold={boldTitle}
      caseSensitiveTitle={caseSensitiveTitle}
      sticky={stickyTitles}
    >
      {title}
    </StyledSectionTitle>
    <StyledSectionWrapper
      key={`section ${title}`}
      sectionToggled={sectionToggled}
      collapseAble={collapseAble}
      preferences={preferences}
      faq={faq}
    >
      {mapOverItems(items, loading, t)}
    </StyledSectionWrapper>
  </StyledSection>
);
