import React, { forwardRef } from 'react';
import { Arrow, Logout, Preferences } from 'icons';
import styled, { css } from 'styled-components';
import { LinkOrAnchor } from 'components';
import { endpoints, routes } from 'config';
import { resetButtonLayout } from 'styled';
import { getUserName } from 'utils';

const StyledWrapper = styled.div`
  display: none;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: ${({ theme }) => theme.space[7]}px;
    top: 0;
    z-index: 5;
  }
`;

const colorSwitchTiming = '0.3s';

const StyledButton = styled.button`
  justify-content: flex-end;
  height: 56px !important; /* same height as header */
  ${resetButtonLayout}
  height: 100%;
  padding: 0px ${({ theme }) => theme.space[4]}px;
  display: flex;
  align-items: center;
  transition: background-color ${colorSwitchTiming};

  ${({ open }) =>
    open
      ? css`
          background-color: ${({ theme }) => theme.colors.primary};
        `
      : css`
          background-color: transparent;
        `}

  &:focus {
    outline: none;
    ${({ open }) =>
      open
        ? css`
            background-color: ${({ theme }) => theme.colors.primary};
          `
        : css`
            background-color: ${({ theme }) => theme.colors.focus};
            @media (prefers-color-scheme: dark) {
              background-color: ${({ theme }) => theme.colors.focusDark};
            }
          `}
  }
`;

const StyledFullName = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  transition: color ${colorSwitchTiming};
  margin: 0px ${({ theme }) => theme.space[2]}px;
  ${({ open, homepage }) =>
    open || homepage
      ? css`
          color: white;
        `
      : css`
          color: ${({ theme }) => theme.colors.primary};
          @media (prefers-color-scheme: dark) {
            color: ${({ theme }) => theme.colors.darkerWhite} !important;
          }
        `}
`;

const initialsRadius = '38px';

const StyledArrow = styled(Arrow)`
  width: 10px;
  height: 10px;
  margin-top: 2px;
  transition: transform 0.1s;
  path {
    transition: stroke ${colorSwitchTiming};
    @media (prefers-color-scheme: dark) {
      stroke: ${({ theme }) => theme.colors.darkerWhite};
    }
  }
  ${({ open }) =>
    open
      ? css`
          transform: rotate(-90deg);
          path {
            stroke: white;
          }
        `
      : css`
          transform: rotate(0deg);
        `}

  ${({ homepage }) =>
    homepage
      ? css`
          path {
            stroke: white;
          }
        `
      : ''}
`;

const StyledUl = styled.ul`
  overflow: hidden;
  transition: height 0.2s ease;
  background-color: rgba(0, 93, 154, 0.95);
  ${({ open }) =>
    open
      ? css`
          height: 132px;
        `
      : css`
          height: 0;
        `}
`;

const StyledLi = styled.li``;

const StyledPreferences = styled(Preferences)`
  width: 18px;
  height: 18px;
`;

const StyledLogout = styled(Logout)`
  height: 16px;
  position: relative;
  left: 1px;
`;

const StyledOptionIconWrapper = styled.div`
  width: ${initialsRadius};
  margin-right: ${({ theme }) => theme.space[2]}px;
  display: flex;
  justify-content: center;
`;

const StyledLink = styled(LinkOrAnchor)`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  padding: ${({ theme }) => theme.space[4]}px ${({ theme }) => theme.space[4]}px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.tertiary};
  }
  color: white;
  display: flex;
  align-items: center;
`;

const Option = ({ to, icon, children, onClick }) => (
  <StyledLi>
    <StyledLink to={to} onClick={onClick}>
      <StyledOptionIconWrapper>{icon}</StyledOptionIconWrapper>
      {children}
    </StyledLink>
  </StyledLi>
);
export const AccountOptions = forwardRef(
  ({ currentUser, setOpen, open, t, homepage }, ref) => {
    return (
      <StyledWrapper ref={ref}>
        <StyledButton open={open} onClick={() => setOpen(!open)} type="submit">
          {/* <StyledInitials open={open}>{initials}</StyledInitials> */}
          <StyledFullName homepage={homepage} open={open}>
            {getUserName(currentUser)}
          </StyledFullName>
          <StyledArrow homepage={homepage} open={open} />
        </StyledButton>
        <StyledUl open={open}>
          <Option
            onClick={() => setOpen(!open)}
            to={routes.preferences}
            icon={<StyledPreferences />}
          >
            {t('ACCOUNT_OPTIONS.PREFERENCES')}
          </Option>
          <Option to={endpoints.azureLogout} icon={<StyledLogout />}>
            {t('ACCOUNT_OPTIONS.LOGOUT')}
          </Option>
        </StyledUl>
      </StyledWrapper>
    );
  }
);
