import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { approvalsConfig, getUserInfo } from 'utils';

const setSentryUserScope = async dispatch => {
  const userInfo = await getUserInfo(dispatch);

  if (approvalsConfig.sentryEnabled && userInfo) {
    Sentry.configureScope(scope => {
      scope.setUser({ email: userInfo.email });
    });
  }
};

export const useSetSentryScope = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    setSentryUserScope(dispatch); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
};
