import React from 'react';
import styled, { css } from 'styled-components';
import { MultipleTasks } from 'icons';

const StyledSuperWrapper = styled.div`
  display: flex; /* one of the mysteries of css why you need a parent of display flex to make this layout work */
`;
const StyledWrapper = styled.span`
  position: relative;
`;

const StyledMultipleTasks = styled(MultipleTasks)`
  width: 23px;
  @media (prefers-color-scheme: dark) {
    path {
      stroke: ${({ theme }) => theme.colors.darkerWhite};
    }
  }
  ${({ primary }) =>
    primary
      ? css`
          path {
            stroke: ${({ theme }) => theme.colors.primary};
            @media (prefers-color-scheme: dark) {
              stroke: ${({ theme }) => theme.colors.darkerWhite};
            }
          }
        `
      : ''}
  height: 23px;
`;

const StyledNumber = styled.div`
  height: 12px;
  position: absolute;
  top: -4px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 16px;
  text-align: center;
  background-color: white;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.secondary};
  @media (prefers-color-scheme: dark) {
    color: ${({ theme }) => theme.colors.darkerWhite};
    background-color: ${({ theme }) => theme.colors.black};
  }
  ${({ primary }) =>
    primary
      ? css`
          color: ${({ theme }) => theme.colors.primary};
          @media (prefers-color-scheme: dark) {
            color: ${({ theme }) => theme.colors.darkerWhite};
          }
        `
      : ''}
`;

export const MultipleTasksIcon = ({ primary, number = 8, ...props }) => (
  <StyledSuperWrapper>
    <StyledWrapper data-cy="multipleTasksIcon" {...props}>
      <StyledMultipleTasks primary={primary ? 1 : 0} />
      <StyledNumber primary={primary ? 1 : 0}>{number}</StyledNumber>
    </StyledWrapper>
  </StyledSuperWrapper>
);
