import React from 'react';
import styled from 'styled-components';
import { Warning } from 'icons';

const StyledErrorSpan = styled.span`
  display: flex;
  color: ${({ theme }) => theme.colors.red[5]};
  align-items: baseline;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
`;

const StyledErrorIcon = styled(Warning)`
  position: relative;
  top: 2px;
  margin-right: 6px;
  width: 16px;
  height: 14px;
  .stroke {
    stroke: ${({ theme }) => theme.colors.red[5]};
  }
  .fill {
    fill: ${({ theme }) => theme.colors.red[5]};
  }
`;

export const Error = ({ condition, title, ...props }) => {
  if (condition) {
    return (
      <StyledErrorSpan {...props}>
        <StyledErrorIcon />
        {title}
      </StyledErrorSpan>
    );
  }
  return null;
};
