import { expenseCategories } from 'config';
import { logSentryError } from './secureFetch';

export const groupReceiptsByLocalDefinedGroup = receipts => {
  // TODO: make it DRY
  if (!Array.isArray(receipts)) return null;
  const unpaidReceiptsGroupedByCategory = [];
  const paidReceiptsGroupedByCategory = [];

  const matchedReceiptsIndexes = [];
  expenseCategories.forEach(category => {
    const matchedUnpaidReceipts = [];
    const matchedPaidReceipts = [];
    let totalUnpaidReceipts = 0;
    let totalPaidReceipts = 0;
    receipts.forEach((receipt, receiptIndex) => {
      const unknownType =
        category.id === 'OTHER' &&
        !matchedReceiptsIndexes.includes(receiptIndex);
      const matchesThisCategory = category.expenseTypes.includes(
        receipt.expenseTypeCode
      );
      if (matchesThisCategory || unknownType) {
        matchedReceiptsIndexes.push(receiptIndex);
        if (!receipt.paidByCompany) {
          matchedUnpaidReceipts.push(receipt);
          totalUnpaidReceipts += Number(receipt.localCurrencyAmount);
        } else {
          matchedPaidReceipts.push(receipt);
          totalPaidReceipts += Number(receipt.localCurrencyAmount);
        }
      }
      if (!matchesThisCategory && unknownType) {
        logSentryError(
          `Custom error: unknown expense type ${receipt.expenseTypeCode}`
        );
      }
    });
    if (matchedUnpaidReceipts.length > 0) {
      unpaidReceiptsGroupedByCategory.push({
        groupId: category.id,
        paidByCompany: false,
        total: totalUnpaidReceipts,
        receipts: matchedUnpaidReceipts,
      });
    }
    if (matchedPaidReceipts.length > 0) {
      paidReceiptsGroupedByCategory.push({
        groupId: category.id,
        paidByCompany: true,
        total: totalPaidReceipts,
        receipts: matchedPaidReceipts,
      });
    }
  });
  return [...unpaidReceiptsGroupedByCategory, ...paidReceiptsGroupedByCategory];
};

export const validateTravelAndExpense = travelAndExpense => {
  if (
    travelAndExpense &&
    travelAndExpense?.tripDetails &&
    Array.isArray(travelAndExpense?.receipts) &&
    travelAndExpense?.approval?.approver?.email
  ) {
    const {
      tripTotal,
      amountReimbursedFormMeals,
      amountReimbursedForAccomodation,
      amountReimbursedForMileage,
      tripNumber,
      employeeNumber,
      cypress,
    } = travelAndExpense.tripDetails;
    if (
      tripTotal &&
      amountReimbursedFormMeals &&
      amountReimbursedForAccomodation &&
      amountReimbursedForMileage &&
      tripNumber &&
      employeeNumber &&
      !cypress
    ) {
      return true;
    }
  }
  return false;
};

export const sumOfReceiptsIsEqualToTotalAmount = travelAndExpense => {
  const {
    tripTotal,
    amountReimbursedFormMeals,
    amountReimbursedForAccomodation,
    amountReimbursedForMileage,
  } = travelAndExpense.tripDetails;
  const sum = (accumulator, currentValue) => accumulator + currentValue;
  const receiptsTotal =
    travelAndExpense.receipts.length > 0
      ? travelAndExpense.receipts
          .map(({ localCurrencyAmount }) => Number(localCurrencyAmount))
          .reduce(sum)
      : 0;

  const calculatedTripTotal = [
    receiptsTotal,
    amountReimbursedFormMeals,
    amountReimbursedForAccomodation,
    amountReimbursedForMileage,
  ]
    .map(value => Number(value))
    .reduce(sum);
  return Math.round(calculatedTripTotal) === Math.round(Number(tripTotal));
};

export const reportToSentryIfSumOfReceiptsIsNotEqualToTotalAmount = travelAndExpense => {
  if (
    validateTravelAndExpense(travelAndExpense) &&
    !sumOfReceiptsIsEqualToTotalAmount(travelAndExpense)
  ) {
    const {
      tripDetails: { tripNumber, employeeNumber },
      approval: {
        approver: { email },
      },
    } = travelAndExpense;
    const customErrorString = `Custom error: Trip total and calculated trip are not equal for ${tripNumber}::${employeeNumber}, approver ${email}`;
    console.error(customErrorString);
    logSentryError(customErrorString);
  }
};
