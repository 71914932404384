import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';
import { endpoints, OBJECT_TYPES, routes } from 'config';
import { useRefetchParameters } from 'hooks';
import { updateStatusTask } from 'store/tasks/tasks.service';
import {
  defineTasksToUpdate,
  getObjectTypeFromSection,
  getTravelAndExpenseIds,
  injectRouteParameter,
  isMobile,
  secureFetch,
} from 'utils';
import { TaskDetailConfirmPage } from './TaskDetailConfirmPage';

export const TaskDetailConfirmPageContainer = ({
  commentNoteRequired,
  status,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    match: {
      params: { taskId, section },
    },
    history,
  } = useReactRouter();
  const previousRoute = injectRouteParameter(routes.taskDetail, {
    section,
    taskId,
  });

  const [task, setTask] = useState({});
  const [mandatoryCommentNoteError, setMandatoryCommentNoteError] = useState(
    false
  );

  const objectTypeInView = getObjectTypeFromSection(section);

  let url = '';
  if (objectTypeInView === OBJECT_TYPES.PURCHASE_REQUEST) {
    url = `${endpoints.purchaseRequests}/${taskId}`;
  } else if (objectTypeInView === OBJECT_TYPES.TRAVEL_AND_EXPENSE) {
    const { id, employeeNumber } = getTravelAndExpenseIds(taskId);
    url = `${endpoints.travelAndExpenses}/${id}?employeeNumber=${employeeNumber}`;
  }

  useEffect(() => {
    (async () => {
      const json = await secureFetch(url);
      setTask(Array.isArray(json) ? json[0] : json);
    })();
  }, [objectTypeInView, taskId, url]);

  const dispatch = useDispatch();
  const textAreaRef = useRef(null); // TODO: change with none ref logic
  const maximumAmountOfCharacters = 512;
  const refetchParameters = useRefetchParameters();
  const onSubmit = event => {
    event.preventDefault();
    const commentNote = textAreaRef.current.value;
    if (commentNote || commentNoteRequired === false) {
      updateStatusTask(
        dispatch,
        status,
        defineTasksToUpdate(task, taskId, objectTypeInView),
        [commentNote],
        { ...refetchParameters, taskId },
        objectTypeInView
      );
      if (isMobile()) {
        history.push(injectRouteParameter(routes.taskList, { section }));
      } else {
        history.push(
          injectRouteParameter(routes.taskDetail, { section, taskId })
        );
      }
    } else {
      setMandatoryCommentNoteError(true);
    }
  };
  return (
    <TaskDetailConfirmPage
      t={t}
      submitProps={{
        onSubmit,
      }}
      textAreaProps={{
        required: commentNoteRequired,
        textAreaRef,
        maximumAmountOfCharacters,
        mandatoryError: mandatoryCommentNoteError,
        hint: t(`TASK_DETAIL_CONFIRM.MOTIVATION_LONG.${status}`),
        setMandatoryError: setMandatoryCommentNoteError,
      }}
      previousRoute={previousRoute}
      {...props}
    />
  );
};
