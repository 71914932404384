import React from 'react';
import { AccountPlaceholder } from 'icons';
import styled, { css } from 'styled-components';
import { getFullName } from 'utils';

const SharedSubjectStyle = css`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const StyledImg = styled.img`
  ${SharedSubjectStyle}
`;

const StyledWrapper = styled.div`
  width: 60px;
  height: 60px;
`;

const StyledPlaceHolder = styled.div`
  ${SharedSubjectStyle}
  background-color: #e5e5e5; /* god forbids consistent grays */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledAccountPlaceholder = styled(AccountPlaceholder)`
  width: 25px;
`;

function isString(obj) {
  return Object.prototype.toString.call(obj) === '[object String]';
}

export const ProfilePicture = ({ loading, profile, ...props }) => {
  const fullName = getFullName(profile);

  return (
    <StyledWrapper>
      {!loading &&
      profile?.profilePicture &&
      isString(profile?.profilePicture) ? (
        <StyledImg
          src={`data:image/png;base64, ${profile?.profilePicture}`}
          alt={`Profile ${fullName}`}
          {...props}
        />
      ) : (
        <StyledPlaceHolder>
          <StyledAccountPlaceholder />
        </StyledPlaceHolder>
      )}
    </StyledWrapper>
  );
};
