import React from 'react';
import { Spinner } from 'icons';
import styled, { css } from 'styled-components';
import { Button, ProfilePicture } from 'components';
import { centerAbsolutePositionStyle, StyledPlaceholder } from 'styled';
import { getFullName } from 'utils';

const borderRadius = '5px';

const StyledCardWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  padding: ${({ theme }) => theme.space[3]}px 12px;
  margin-bottom: -1px;
  &:first-of-type {
    border-top-left-radius: ${borderRadius};
    border-top-right-radius: ${borderRadius};
  }
  &:last-of-type {
    border-bottom-left-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};
  }
  ${({ focus }) =>
    focus
      ? css`
          border-color: ${({ theme }) => theme.colors.tertiary};
        `
      : css`
          border-color: ${({ theme }) => theme.colors.gray};
        `}
`;

const StyledTitleWrapper = styled.div`
  margin-left: ${({ theme }) => theme.space[3]}px;
  width: calc(100% - 93px);
`;

const StyledJobTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
`;
const StyledFullName = styled.h1`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.tertiary};
  @media (prefers-color-scheme: dark) {
    color: ${({ theme }) => theme.colors.darkerWhite};
  }
`;

const StyledConnector = styled.div`
  height: 10px;
  width: 1px;
  ${({ focus }) =>
    focus
      ? css`
          background-color: ${({ theme }) => theme.colors.tertiary};
        `
      : css`
          background-color: ${({ theme }) => theme.colors.gray};
        `}
`;

const StyledConnectorWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Connector = ({ ...props }) => (
  <StyledConnectorWrapper>
    <StyledConnector {...props} />
  </StyledConnectorWrapper>
);

const ContactCard = ({ focus, loading, isManager, profile }) => {
  if ((!profile || !profile.givenName) && !loading) return null;
  return (
    <>
      <StyledCardWrapper
        data-cy={isManager ? 'managerContactCard' : ''}
        focus={focus}
      >
        <ProfilePicture loading={loading} profile={profile} />
        <StyledTitleWrapper>
          <StyledFullName>
            {!loading ? (
              getFullName(profile)
            ) : (
              <StyledPlaceholder width="100px" height="22px" color="tertiary" />
            )}
          </StyledFullName>
          <StyledJobTitle>
            {!loading ? (
              profile?.jobTitle
            ) : (
              <StyledPlaceholder
                width="100px"
                height="14px"
                marginTop="4px"
                color="secondary"
              />
            )}
          </StyledJobTitle>
        </StyledTitleWrapper>
      </StyledCardWrapper>
      {isManager ? <Connector /> : null}
    </>
  );
};

const StyledH3 = styled.h3`
  width: calc(100% + ${({ theme }) => theme.space[4]}px);
  border-top: 1px solid ${({ theme }) => theme.colors.gray};
  padding-top: ${({ theme }) => theme.space[3]}px;
  margin-left: -${({ theme }) => theme.space[4]}px;
  padding-left: ${({ theme }) => theme.space[4]}px;
  margin-bottom: ${({ theme }) => theme.space[3]}px;
`;

const StyledButton = styled(Button)`
  position: relative;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.space[4]}px;
`;

const StyledSuperWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.space[4]}px;
`;

const StyledSpinner = styled(Spinner)`
  ${centerAbsolutePositionStyle}
  height: 20px;
  * {
    stroke: ${({ theme }) => theme.colors.white};
  }
`;

export const OrganisationChart = ({
  managers,
  showManagers,
  onClickShowManagersLevel,
  contact,
  t,
  loadingManagers,
  errorManagers,
  loading,
}) => (
  <StyledSuperWrapper>
    <StyledH3>{t('ORGANISATION_CHART.ORGANISATION')}</StyledH3>
    {managers?.length === 0 ? null : (
      <StyledButton
        data-cy="orgChartButton"
        onClick={onClickShowManagersLevel}
        filled
      >
        {loadingManagers ? (
          <StyledSpinner />
        ) : (
          t('ORGANISATION_CHART.MANAGEMENT_LEVEL', {
            state: showManagers
              ? t('ORGANISATION_CHART.HIDE')
              : t('ORGANISATION_CHART.SHOW'),
          })
        )}
      </StyledButton>
    )}
    {errorManagers ||
      (showManagers && managers
        ? managers.map(manager => (
            <div key={manager?.userPrincipalName}>
              <ContactCard isManager profile={manager} />
            </div>
          ))
        : null)}
    {loadingManagers ? (
      <div>
        <ContactCard isManager loading />
      </div>
    ) : null}
    {loading ? (
      <div>
        <ContactCard focus={1} loading />
      </div>
    ) : (
      <ContactCard focus={1} profile={contact?.profile} />
    )}
    <div>
      {!loading &&
      contact?.directReports &&
      contact?.directReports?.length > 0 ? (
        <>
          <Connector focus={1} />
          {contact.directReports.map(directReport => (
            <ContactCard
              key={directReport?.userPrincipalName}
              profile={directReport}
            />
          ))}
        </>
      ) : null}
    </div>
  </StyledSuperWrapper>
);
