import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Cross, Loop } from 'icons';
import { resetButtonLayout } from 'styled';

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-left: ${({ theme }) => theme.space[4]}px;
`;

const StyledInput = styled.input`
  background-color: transparent;
  border-radius: 0px;
  padding-left: calc(
    ${({ theme }) => theme.space[4]}px + ${({ theme }) => theme.space[1]}px
  );
  padding-right: 20px;
  height: ${({ theme }) => theme.fontSizes[2] + theme.space[3]}px;
  appearance: none;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  color: white;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  width: 100%;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: rgba(255, 255, 255, 0.5); /* TODO: review this colour */
    @supports (-webkit-overflow-scrolling: touch) {
      position: relative;
      top: 2px;
    }
  }
`;

const StyledLoop = styled(Loop)`
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  left: 0;
  width: 14px;
  height: 14px;
  * {
    fill: rgba(255, 255, 255, 0.5);
  }
`;
const StyledCancelButton = styled.button`
  color: white;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  padding: 19px ${({ theme }) => theme.space[4]}px;
  ${resetButtonLayout}
  &:focus {
    outline: none;
    background-color: ${({ theme }) => theme.colors.blue[4]};
  }
`;

const StyledSearchWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  transform: translateY(-100%);
  transition: 0.3s all;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  opacity: 0;
  ${({ activeSearch }) =>
    activeSearch
      ? css`
          transform: translateY(0%);
          opacity: 1;
        `
      : ''}
`;

const StyledCrossButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 0;
  opacity: 0;
  transform: rotate(90deg);
  transition: all 0.2s;
  ${resetButtonLayout}
  ${({ value }) =>
    value
      ? css`
          opacity: 1;
          transform: rotate(0deg);
        `
      : ''}
`;

const StyledCross = styled(Cross)`
  width: 12px;
  height: 12px;
  margin-top: 1px;
`;

export const SearchInput = forwardRef(
  (
    {
      t,
      className,
      onKeyUp,
      submitCancel,
      submitSearch,
      submitClear,
      activeSearch,
      value,
      ...props
    },
    ref
  ) => (
    <StyledSearchWrapper activeSearch={activeSearch}>
      <StyledWrapper className={className}>
        <form onSubmit={submitSearch}>
          <StyledInput
            aria-label={t('COMMON.SEARCH')}
            data-cy="searchInput"
            {...props}
            value={value}
            placeholder={t('COMMON.SEARCH')}
            type="search"
            ref={ref}
            name="searchInput"
          />
        </form>
        <StyledLoop />
        <form onSubmit={submitClear}>
          <StyledCrossButton
            aria-label={t('COMMON.CLEAR_SEARCH')}
            value={value}
            type="submit"
          >
            <StyledCross />
          </StyledCrossButton>
        </form>
      </StyledWrapper>
      <form onSubmit={submitCancel}>
        <StyledCancelButton data-cy="searchInputCancel" type="submit">
          {t('COMMON.CANCEL')}
        </StyledCancelButton>
      </form>
    </StyledSearchWrapper>
  )
);
