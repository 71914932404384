import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TaskListItemComment } from './TaskListItemComment';

export const TaskListItemCommentContainer = ({
  setOverrideComment,
  checkMandatoryError,
  setHasMandatoryError,
  setTextAreaValues,
  textAreaValues,
  task,
  ...props
}) => {
  const { t } = useTranslation();
  const textAreaRef = useRef(null);
  const [mandatoryError, setMandatoryError] = useState(false);
  const submitCopyComment = event => {
    event.preventDefault();
    setOverrideComment(textAreaRef.current.value);
  };
  useEffect(() => {
    if (checkMandatoryError && !textAreaRef.current.value) {
      setHasMandatoryError(true);
      if (mandatoryError === false) {
        setMandatoryError(true);
      }
    }
  }, [checkMandatoryError, mandatoryError, setHasMandatoryError]);
  useEffect(() => {
    if (
      checkMandatoryError &&
      textAreaValues[task.id] !== textAreaRef.current.value
    ) {
      textAreaValues[task.id] = textAreaRef.current.value;
      setTextAreaValues({
        ...textAreaValues,
      });
    }
  }, [
    checkMandatoryError,
    mandatoryError,
    setHasMandatoryError,
    setTextAreaValues,
    task.id,
    textAreaValues,
  ]);
  return (
    <TaskListItemComment
      submitCopyComment={submitCopyComment}
      textAreaRef={textAreaRef}
      mandatoryError={mandatoryError}
      setMandatoryError={setMandatoryError}
      t={t}
      task={task}
      {...props}
    />
  );
};
