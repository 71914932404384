import { useTranslation } from 'react-i18next';
import React from 'react';

import { FaqPage } from './FaqPage';

export const FaqPageContainer = () => {
  const { t } = useTranslation();

  return <FaqPage previousPath="/" t={t} />;
};
