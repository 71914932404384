/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { routes } from 'config';
import {
  useObjectTypeInViewSpecificCode,
  useRefetchParameters,
  useSelectedTasks,
} from 'hooks';
import { purchaseRequestsUpdate } from 'store/tasks/purchaseRequests/purchaseRequests.duck';
import {
  getTasksLineItems,
  getTasksOverrides,
  getTravelAndExpenseEmployeeItems,
} from 'store/tasks/tasks.selectors';
import { updateStatusTask } from 'store/tasks/tasks.service';
import { travelAndExpensesUpdate } from 'store/tasks/travelAndExpenses/travelAndExpenses.duck';
import {
  confirm,
  getObjectTypeFromSection,
  getTaskTypeSpecificId,
  mataHari,
} from 'utils';
import { TaskListItemSelectGroup } from './TaskListItemSelectGroup';

export const TaskListItemSelectGroupContainer = ({ ...props }) => {
  const {
    match: {
      params: { section },
    },
    location: { pathname },
    history,
  } = useReactRouter();

  const objectTypeInView = getObjectTypeFromSection(section);
  // mataHari.uxSnitch('bulkApprovePanelOpened', { recordType: objectTypeInView });

  const dispatch = useDispatch();
  let multipleItems = { data: [] };
  const tasksLineItems = useSelector(getTasksLineItems);
  const travelAndExpenseEmployeeItems = useSelector(
    getTravelAndExpenseEmployeeItems
  );

  let dispatchSelectChange;

  useObjectTypeInViewSpecificCode({
    purchaseRequestCallback: () => {
      dispatchSelectChange = selected =>
        dispatch(
          purchaseRequestsUpdate({
            LINE_ITEMS: {
              ...multipleItems,
              selected,
            },
          })
        );
      multipleItems = tasksLineItems;
    },
    travelAndExpenseCallback: () => {
      dispatchSelectChange = selected =>
        dispatch(
          travelAndExpensesUpdate({
            EMPLOYEE_ITEMS: {
              ...multipleItems,
              selected,
            },
          })
        );
      multipleItems = travelAndExpenseEmployeeItems;
    },
  });
  const overrides = useSelector(getTasksOverrides);
  const { t } = useTranslation();
  const [nothingSelectedError, setNothingSelectedError] = useState(false);

  const selectedTasks = useSelectedTasks();
  let isAllSelected = selectedTasks.length > 0;
  let atLeastOneSelected = false;
  let oneOfItemsHasAnError = false;
  let oneOfItemsIsLoading = false;
  const showLoadingState = multipleItems.loading || multipleItems.initialState;
  let atLeastOneTaskIsPending = false;

  multipleItems.data.forEach(task => {
    const overrideId = getTaskTypeSpecificId(task);
    if (task.status === 'PENDING') {
      atLeastOneTaskIsPending = true;
      if (!multipleItems.selected[task.id]) {
        isAllSelected = false;
      }
    }
    if (multipleItems.selected[task.id]) atLeastOneSelected = true;

    if (overrides && overrides[overrideId]) {
      const { message, updating } = overrides[overrideId];
      if (message) oneOfItemsHasAnError = true;
      if (updating) oneOfItemsIsLoading = true;
    }
  });

  const submitCommon = (event, callbackIfSuccesfull) => {
    event.preventDefault();
    if (atLeastOneSelected) {
      setNothingSelectedError(false);
      callbackIfSuccesfull();
    } else {
      setNothingSelectedError(true);
    }
  };

  const onSubmitReject = event => {
    mataHari.uxSnitch('bulkReject', { recordType: objectTypeInView });
    submitCommon(event, () => {
      history.push(`${pathname}-${routes.confirmReject}`);
    });
  };

  const refetchParameters = useRefetchParameters();

  const onSubmitApprove = event => {
    mataHari.uxSnitch('bulkApprove', { recordType: objectTypeInView });
    submitCommon(event, () => {
      // eslint-disable-next-line no-alert
      confirm({
        message: t('MULTIPLE_TASKS.CONFIRM_ALERT'),
        succesCallback: () =>
          updateStatusTask(
            dispatch,
            'APPROVED',
            selectedTasks,
            selectedTasks.map(() => ''),
            refetchParameters,
            objectTypeInView,
            true
          ),
        confirmText: t('DIALOG.CONFIRM_APPROVE'),
        cancelText: t('DIALOG.NO'),
      });
    });
  };
  return (
    <TaskListItemSelectGroup
      tasks={multipleItems}
      overrides={overrides}
      t={t}
      isAllSelected={isAllSelected}
      onChangeSelectAll={selected => {
        if (selected) dispatchSelectChange({});
        if (!selected) {
          const tasksToSelect = {};
          multipleItems.data.forEach(task => {
            if (task.status === 'PENDING') tasksToSelect[task.id] = true;
          });
          dispatchSelectChange(tasksToSelect);
        }
      }}
      onChangeSelect={(selected, id) =>
        dispatchSelectChange({ ...multipleItems.selected, [id]: !selected })
      }
      onSubmitReject={onSubmitReject}
      onSubmitApprove={onSubmitApprove}
      nothingSelectedError={nothingSelectedError}
      oneOfItemsHasAnError={oneOfItemsHasAnError}
      oneOfItemsIsLoading={oneOfItemsIsLoading}
      atLeastOneTaskIsPending={atLeastOneTaskIsPending}
      showLoadingState={showLoadingState}
      {...props}
    />
  );
};
