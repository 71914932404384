import React from 'react';
import { Paperclip } from 'icons';
import styled from 'styled-components';
import { KeyValue, LinkOrAnchor, MissingDataParagraph } from 'components';
import {
  detailPageMobilePadding,
  sharedCardStyle,
  StyledCardHeader,
} from 'styled';

const cardSnapWidth = 'calc(100vw - 64px)';
const StyledContentWrapper = styled.article`
  /* width: 100%; */
  ${sharedCardStyle}
  padding-bottom: ${({ theme }) => theme.space[6]}px;
  min-width: ${cardSnapWidth};
  margin-left: 12px;
  scroll-snap-align: center;
  &:first-of-type {
    margin-left: 16px;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[2]}) {
    width: 100%;
    min-width: auto;
    margin-left: 0 !important;
  }
`;

const StyledContentKeyValue = styled(KeyValue)`
  padding: ${({ theme }) => theme.space[4]}px;
  padding-bottom: 0;
`;

const StyledPaperclip = styled(Paperclip)`
  float: right;
  position: relative;
  top: ${({ theme }) => theme.space[1]}px;
`;

const StyledLeftHeaderKeyValue = styled(KeyValue)`
  padding-right: ${({ theme }) => theme.space[2]}px;
`;

const Card = ({ dataCy, to, header, body = [] }) => {
  return (
    <LinkOrAnchor to={to} data-cy={dataCy}>
      <StyledContentWrapper>
        <StyledCardHeader>
          {header.left ? <StyledLeftHeaderKeyValue {...header.left} /> : null}
          {header.right ? (
            <div>
              <KeyValue
                textAlign="right"
                valueTextBold
                valueTextBig
                {...header.right}
              />
              {header.hasAttachment ? <StyledPaperclip /> : null}
            </div>
          ) : null}
        </StyledCardHeader>
        {body.map(props => (
          <StyledContentKeyValue {...props} />
        ))}
      </StyledContentWrapper>
    </LinkOrAnchor>
  );
};

const StyledCardsWrapper = styled.li`
  display: flex;
  padding-bottom: ${({ theme }) => theme.space[9]}px;
  overflow: auto;
  padding-top: 10px;
  scroll-snap-type: mandatory;
  scroll-snap-type: x mandatory;

  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[2]}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    overflow: visible;
    margin-left: ${({ theme }) => theme.space[4]}px;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    margin-left: 0px;
    grid-template-columns: 1fr;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[4]}) {
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledMissingDataParagraphWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.space[3]}px;
  padding-left: ${({ theme }) => theme.space[3]}px;
  ${detailPageMobilePadding}
`;

/* TODO: figure out how to add margin to the last element in a scroll snap, this is a hack */
const StyledGiveLastElementMargin = styled.div`
  min-width: 16px;
  background-color: transparent;
`;

export const SwipeableCards = ({ data }) => {
  return data?.length ? (
    <StyledCardsWrapper>
      {data.map((singleData, index) => {
        return <Card {...singleData} key={index} />;
      })}
      <StyledGiveLastElementMargin />
    </StyledCardsWrapper>
  ) : (
    <StyledMissingDataParagraphWrapper>
      <MissingDataParagraph />
    </StyledMissingDataParagraphWrapper>
  );
};
