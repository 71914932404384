import TagManager from 'react-gtm-module';
import { approvalsConfig } from './config';
import { getUserInfo } from './userInfo';

const data = getUserInfo();
let userId = 'Anonymous';
if (data) {
  const { tracker } = data;

  // We use the MD5 of a user's email address (lowercase) as a universal tracking id
  userId = tracker || 'Anonymous';
}
const defaultTagManagerArgs = {
  gtmId: approvalsConfig.gtmToken,
};

const prApprovedEvent = {
  dataLayer: {
    userId,
    event: 'purchaseRequestApproved',
  },
};

const prRejectedEvent = {
  dataLayer: {
    userId,
    event: 'purchaseRequestRejected',
  },
};

const prApprovalFailedEvent = {
  dataLayer: {
    userId,
    event: 'purchaseRequestApprovalFailed',
  },
};

const tripApprovedEvent = {
  dataLayer: {
    userId,
    event: 'tripApproved',
  },
};

const tripRejectedEvent = {
  dataLayer: {
    userId,
    event: 'tripRejected',
  },
};

const tripApprovalFailedEvent = {
  dataLayer: {
    userId,
    event: 'tripApprovalFailed',
  },
};

// Only enable analytics if in prod
if (approvalsConfig.userTrackingEnabled) {
  // Bootstrap the analytics with the correct user tracking info
  TagManager.initialize({
    gtmId: approvalsConfig.gtmToken,
    dataLayer: {
      userId,
    },
  });
}

export const mataHari = {
  purchaseRequestApproved: () => {
    TagManager.initialize({ ...defaultTagManagerArgs, ...prApprovedEvent });
  },
  purchaseRequestRejected: () => {
    TagManager.initialize({ ...defaultTagManagerArgs, ...prRejectedEvent });
  },
  purchaseRequestApprovalFailed: () => {
    TagManager.initialize({
      ...defaultTagManagerArgs,
      ...prApprovalFailedEvent,
    });
  },
  tripApproved: () => {
    TagManager.initialize({ ...defaultTagManagerArgs, ...tripApprovedEvent });
  },
  tripRejected: () => {
    TagManager.initialize({ ...defaultTagManagerArgs, ...tripRejectedEvent });
  },
  tripApprovalFailed: () => {
    TagManager.initialize({
      ...defaultTagManagerArgs,
      ...tripApprovalFailedEvent,
    });
  },
  uxSnitch: (uxEventName, extraArgs = {}) => {
    TagManager.initialize({
      ...defaultTagManagerArgs,
      dataLayer: {
        event: uxEventName,
        ...extraArgs,
      },
    });
  },
};
