export const getFullName = data => {
  if (data) {
    if (data.firstName && data.lastName)
      return `${data.firstName} ${data.lastName}`;
    if (data.givenName && data.surname)
      return `${data.givenName} ${data.surname}`;
    if (data.name) return data.name;
    if (data.userName) return data.userName;
  }
  return null;
};

export const getFirstAndLastName = input => {
  if (typeof input === 'string' && input.includes(',')) {
    const [lastName, firstName] = input.split(', ');
    return {
      firstName,
      lastName,
    };
  }
  return {
    firstName: null,
    lastName: null,
  };
};

export const fixSAPFormatting = (inputText, newLineSymbol = '\n') =>
  typeof inputText === 'string'
    ? inputText.replace(/<\(>&<\)>/gi, '&').replace(/\|/gi, newLineSymbol)
    : inputText;

export const combineCodeAndName = input => {
  if (input) {
    const { name, code } = input;
    if (name && code) return `${name}, ${code}`;
    if (name) return name;
    if (code) return code;
  }
  return null;
};
