import React from 'react';
import { Loader, Rocket } from 'icons';
import styled from 'styled-components';
import {
  Button,
  Checkbox,
  EmptyState,
  ErrorInfo,
  LoadingOverlay,
  TaskListItem,
} from 'components';
import { BottomButtonWrapper } from 'styled';
import { getTaskTypeSpecificId } from 'utils';

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: ${({ theme }) => theme.space[5]}px;
  text-transform: uppercase;
`;

const StyledTaskListItem = styled(TaskListItem)`
  height: 105px;
`;

const StyledBottomButtonWrapper = styled(BottomButtonWrapper)`
  margin-left: -${({ theme }) => theme.space[3]}px;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    margin-left: 0;
    margin-top: ${({ theme }) => theme.space[5]}px;
    position: sticky;
    width: 100%;
    form:first-of-type,
    form:nth-of-type(2) {
      width: calc(50% - ${({ theme }) => theme.space[2]}px);
    }
  }
  @media all and (-ms-high-contrast: none) {
    position: relative !important;
  }
`;

const StyledErrorInfo = styled(ErrorInfo)`
  margin-bottom: ${({ theme }) => theme.space[5]}px;
`;

const StyledLoaderWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLoader = styled(Loader)`
  width: 50px;
  * {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledContentWrapper = styled.div`
  position: relative;
`;

export const TaskListItemSelectGroup = ({
  tasks,
  overrides,
  onChangeSelect,
  onChangeSelectAll,
  isAllSelected,
  t,
  onSubmitReject,
  onSubmitApprove,
  nothingSelectedError,
  oneOfItemsHasAnError,
  oneOfItemsIsLoading,
  atLeastOneTaskIsPending,
  showLoadingState,
}) => {
  return (
    <StyledContentWrapper>
      <StyledErrorInfo
        title={t('MULTIPLE_TASKS.NOTHING_SELECTED_ERROR_DESCRIPTION')}
        data-cy="multipleItemsErrorNothingSelected"
        condition={nothingSelectedError}
      />
      <StyledErrorInfo
        title={t('MULTIPLE_TASKS.ITEM_HAS_ERROR_DESCRIPTION')}
        condition={oneOfItemsHasAnError}
      />
      {atLeastOneTaskIsPending ? (
        <StyledCheckbox
          checked={isAllSelected}
          label={t('MULTIPLE_TASKS.SELECT_ALL')}
          initialBorderColor="secondary"
          onChange={() => onChangeSelectAll(isAllSelected)}
          dataCy="multipleItemsSelectAll"
        />
      ) : null}
      {showLoadingState && <LoadingOverlay transparentBackground={false} />}
      {tasks.data && tasks.data.length > 0 ? (
        tasks.data.map(task => (
          <StyledTaskListItem
            selectable
            key={task.id}
            task={task}
            override={overrides[getTaskTypeSpecificId(task)]}
            selected={tasks.selected[task.id]}
            onChangeSelect={onChangeSelect}
            margin={false}
            showId={false}
            showItemId
            hideSelectBox={!atLeastOneTaskIsPending}
          />
        ))
      ) : (
        <EmptyState
          Icon={Rocket}
          inverseIcon={false}
          title={t(`TASK_LIST.EMPTY_PENDING_REPORTS_TITLE`)}
          subtitle={t(`TASK_LIST.EMPTY_PENDING_REPORTS_SUBTITLE`)}
        />
      )}
      {atLeastOneTaskIsPending ? (
        <StyledBottomButtonWrapper>
          {oneOfItemsIsLoading ? (
            <StyledLoaderWrapper>
              <StyledLoader />
            </StyledLoaderWrapper>
          ) : (
            <>
              <form onSubmit={onSubmitReject}>
                <Button data-cy="multipleItemsActionReject" type="submit">
                  {t('COMMON.REJECT')}
                </Button>
              </form>
              <form onSubmit={onSubmitApprove}>
                <Button
                  data-cy="multipleItemsActionApprove"
                  filled
                  type="submit"
                >
                  {t('COMMON.APPROVE')}
                </Button>
              </form>{' '}
            </>
          )}
        </StyledBottomButtonWrapper>
      ) : null}
    </StyledContentWrapper>
  );
};
