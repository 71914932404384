import React from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import { ExtraInformation } from 'components';
import { useNumberOfMultipleItem } from 'hooks';
import { OpenMultipleTasksLink } from './OpenMultipleTasksLink';

export const OpenMultiplePurchaseRequestsLinkContainer = ({ ...props }) => {
  const { t } = useTranslation();
  const {
    location: { pathname },
  } = useReactRouter();
  if (useNumberOfMultipleItem()) {
    return (
      <>
        <ExtraInformation detailMargin>
          {t('TASK_DETAILS.OPEN_MULTIPLE_TASKS_EXTRA_INFORMATION')}
        </ExtraInformation>
        <OpenMultipleTasksLink pathname={pathname} t={t} {...props} />
      </>
    );
  }
  return null;
};
