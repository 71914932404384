import React from 'react';
import { Button } from 'components';
import { BottomButtonWrapper } from 'styled';

export const TaskActionButtons = ({ rejectProps, approveProps }) => {
  return (
    <BottomButtonWrapper>
      <Button data-cy="taskActionButtonReject" {...rejectProps}>
        {rejectProps.text}
      </Button>
      <Button data-cy="taskActionButtonApprove" {...approveProps}>
        {approveProps.text}
      </Button>
    </BottomButtonWrapper>
  );
};
