import React from 'react';
import { Triposaurus, UnderConstruction } from 'icons';
import styled, { keyframes } from 'styled-components';
import { EmptyState, Header, ToHomePageButton } from 'components';

const rotate = keyframes`
  0% {
    transform: rotate(-5deg)
  }
  100% {
    transform: rotate(5deg)
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledUnderConstruction = styled(UnderConstruction)`
  margin-top: -25px;
  width: calc(100% - 100px);
  max-width: 500px;
  animation: ${rotate} 2s infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  transform-origin: top;
  path {
    fill: ${({ theme }) => theme.colors.primary};
    @media (prefers-color-scheme: dark) {
      fill: ${({ theme }) => theme.colors.tertiary};
    }
  }
`;

const StyledToHomePageButton = styled(ToHomePageButton)`
  margin-top: 80px;
`;

const DefaultPage = () => (
  <div data-cy="placeholderPage">
    <Header search={false} margin borderBottom={false} to="/" title="" />
    <StyledContentWrapper>
      <StyledUnderConstruction />
      <StyledToHomePageButton />
    </StyledContentWrapper>
  </div>
);
export const PlaceholderPage = ({ t, iconType }) =>
  ({
    default: <DefaultPage />,
    'travel-reports-and-expenses': (
      <EmptyState
        headerProps={{ to: '/', onlyMobile: false }}
        title={t('PLACEHOLDER.COMING_SOON')}
        subtitle=""
        Icon={Triposaurus}
        toHomePageButton
      />
    ),
  }[iconType] || <DefaultPage />);
