import React from 'react';
import { Check } from 'icons';
import styled, { css } from 'styled-components';
import { borderBottom } from 'styled';
import { getShortHumanReadableDate } from 'utils';

const StyledTable = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
`;
const StyledCheck = styled(Check)`
  * {
    fill: ${({ theme }) => theme.colors.tertiary};
  }
  width: 12px;
  height: 9px;
`;

const StyledTd = styled.td`
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  padding: ${({ theme }) => theme.space[2]}px 0px;
  text-align: center;

  width: ${({ cellCount }) => `calc((100% - 70px) / ${cellCount})`};
  display: inline-block;
  white-space: nowrap;
  &:first-of-type {
    text-align: left;
    padding-left: ${({ theme }) => theme.space[3]}px;
    width: 70px;
  }
`;

const StyledTh = styled.th`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  padding: ${({ theme }) => theme.space[2]}px 0px;
  width: ${({ cellCount }) => `calc((100% - 70px) / ${cellCount})`};
  display: inline-block;
  white-space: nowrap;
  &:first-of-type {
    width: 70px;
  }
`;

const StyledThLabel = styled.div`
  font-weight: normal;
  color: ${({ theme }) => theme.colors.blue[6]};
  @media (prefers-color-scheme: dark) {
    color: ${({ theme }) => theme.colors.tertiary} !important;
  }
  margin-top: 2px;
`;

const StyledThContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledBodyTr = styled.tr`
  ${borderBottom}
  &:last-of-type {
    border-bottom: none;
  }
`;

const StyledIconWrapper = styled.div`
  height: 25px;
  display: flex;
  align-items: flex-end;
  .dynamicIcon {
    * {
      fill: ${({ theme }) => theme.colors.blue[6]} !important;
      // stroke: ${({ theme }) => theme.colors.blue[6]} !important;
      @media (prefers-color-scheme: dark) {
        fill: ${({ theme }) => theme.colors.tertiary} !important;
      }
    }
    ${({ top }) =>
      top
        ? css`
            position: relative;
            top: ${top};
          `
        : ''}
    width: 30px;
  }
`;
const showCheck = condition => (condition ? <StyledCheck /> : null);

export const TripDetailsTable = ({ dataCy, headers, rows }) => {
  return (
    <StyledTable data-cy={dataCy}>
      <thead>
        <tr>
          <StyledTh label="date" cellCount={headers.length} />
          {headers.map(({ Icon, label, viewBox, top }, index) => {
            return (
              <StyledTh key={index} cellCount={headers.length}>
                <StyledThContent>
                  <StyledIconWrapper top={top}>
                    <Icon
                      className="dynamicIcon"
                      viewBox={viewBox || undefined}
                    />
                  </StyledIconWrapper>
                  <StyledThLabel>{label}</StyledThLabel>
                </StyledThContent>
              </StyledTh>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map(({ date, ...fields }, index) => (
          <StyledBodyTr key={index}>
            <StyledTd cellCount={headers.length}>
              {getShortHumanReadableDate(date)}
            </StyledTd>
            {Object.keys(fields).map(key => (
              <>
                <StyledTd cellCount={headers.length}>
                  {showCheck(fields[key])}
                </StyledTd>
              </>
            ))}
          </StyledBodyTr>
        ))}
      </tbody>
    </StyledTable>
  );
};
