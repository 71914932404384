import React from 'react';
import { createPortal } from 'react-dom';
import { Check, Min, Spinner } from 'icons';
import styled, { css, keyframes } from 'styled-components';

// replace with theming values
const backgroundColors = {
  UPDATING: '#4CB7DF',
  ERROR: '#DE4B4B',
  SUCCESS: '#87BC4D',
};

const StyledWrapper = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  background-color: ${({ status }) => backgroundColors[status]};
  padding: 10px;
  padding-right: ${({ theme }) => theme.space[3]}px;
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  transition: opacity 0.2s ease 0s, top 0.2s ease 0s;
  top: 0px;
  opacity: 0;
  min-width: ${({ minWidth }) => minWidth}px;
  pointer-events: none;
  border-radius: 30px;
  ${({ status }) =>
    status === 'UPDATING'
      ? css`
          top: 9px;
          opacity: 1;
        `
      : ''}
  ${({ status }) =>
    status && status !== 'UPDATING'
      ? css`
          transition: opacity 0.2s ease 5s, top 0.2s ease 5s,
            background-color 0.1s ease 0s;

          top: 9px;
          opacity: 1;
        `
      : ''}
`;

const StyledIconSuperWrapper = styled.div`
  position: relative;
  width: ${({ theme }) => theme.fontSizes[2]}px;
  height: ${({ theme }) => theme.fontSizes[2]}px;
  margin-right: 10px;
`;

const StyledSpinner = styled(Spinner)`
  width: 100%;
  height: 100%;
  * {
    stroke: white;
  }
`;

const appearFromMiddle = keyframes`
  to {
    width: 100%;
    height: 100%;
  }
`;

const StyledIconWrapper = styled.div`
  width: 0%;
  height: 0%;
  ${({ status }) =>
    status === 'SUCCESS' || status === 'ERROR'
      ? css`
          background-color: white;
        `
      : ''}
  border-radius: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  ${({ status }) =>
    status && status !== 'UPDATING'
      ? css`
          animation-name: ${appearFromMiddle};
          animation-duration: 0.2s;
          animation-fill-mode: forwards;
        `
      : ''}
`;

const StyledCheck = styled(Check)`
  width: 9px;
  height: 7px;
  margin-right: -1px;
  path {
    fill: ${({ theme }) => theme.colors.green};
  }
`;

const StyledCross = styled(Min)`
  width: 10px;
  path {
    stroke: ${({ theme }) => theme.colors.red[5]};
  }
`;

const StyledTextWrapper = styled.div`
  color: white;
  width: calc(100% - 20px);
`;

export const Toast = ({ dataCy, labelText, status, action }) => {
  const portalRoot = document.getElementById('portal');
  return createPortal(
    <StyledWrapper
      minWidth={action === 'MULTIPLE' ? 273 : 180}
      data-cy={dataCy}
      status={status}
    >
      <StyledIconSuperWrapper>
        <StyledSpinner />
        <StyledIconWrapper status={status}>
          {status === 'SUCCESS' ? (
            <StyledCheck data-cy="toastCheckIcon" />
          ) : (
            <StyledCross />
          )}
        </StyledIconWrapper>
      </StyledIconSuperWrapper>
      <StyledTextWrapper>{labelText}</StyledTextWrapper>
    </StyledWrapper>,
    portalRoot
  );
};
