import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { userInterfaceUpdate } from 'store/userInterface/userInterface.duck';
import { getTaskListSearch } from 'store/userInterface/userInterface.selectors';
import { isIOSDevice, mataHari } from 'utils';
import { Header } from './Header';

export const HeaderContainer = ({
  search,
  closeable,
  closePageHandler,
  goBackEnabled = false,
  to,
  ...props
}) => {
  const { t } = useTranslation();
  const showHeader = true;
  const {
    location: { pathname },
  } = useReactRouter();
  const dispatch = useDispatch();
  const { history } = useReactRouter();

  const searchInputRef = useRef();
  const activateSearch = event => {
    event.preventDefault();
    mataHari.uxSnitch('searchOpened');
    dispatch(userInterfaceUpdate({ taskListSearch: '' }));
    searchInputRef.current.focus();
  };
  const activeSearch = useSelector(getTaskListSearch) !== null && search;
  const back = () => {
    if (history.length === 2 || isIOSDevice()) {
      history.push(to || '/'); /* go to home is as fallback */
    } else {
      history.goBack();
    }
  };
  return (
    <Header
      activateSearch={activateSearch}
      pathname={pathname}
      showHeader={showHeader}
      t={t}
      activeSearch={activeSearch}
      search={search}
      ref={searchInputRef}
      closeable={false}
      closePageHandler={closePageHandler}
      goBackEnabled={goBackEnabled}
      goBackHandler={back}
      to={to}
      {...props}
    />
  );
};
