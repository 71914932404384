import { createActions, handleActions } from 'redux-actions';
import { routes } from 'config';

const showHeaderPerPage = {};
Object.keys(routes).forEach(key => (showHeaderPerPage[key] = true));
const initialState = {
  taskListSearch: null,
  selectedAccount: '5a577144-7994-4ac0-940b-d966761c8230',
  openHamburgerMenu: false,
  showHeaderPerPage,
  confirmAlert: {
    active: false,
    text: null,
    succesCallback: null,
    confirmText: 'Ok',
    cancelText: 'Cancel',
  },
};

const USER_INTERFACE_UPDATE = 'USER_INTERFACE_UPDATE';

export const { userInterfaceUpdate } = createActions(USER_INTERFACE_UPDATE);

export default handleActions(
  {
    [USER_INTERFACE_UPDATE]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  initialState
);
