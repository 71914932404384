/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import { useDownloadFile, useUrlParameters } from 'hooks';
import { DownloadFilePage } from './DownloadFilePage';

export const DownloadFilePageContainer = ({ ...props }) => {
  const {
    match: {
      params: { fileId, taskId },
    },
  } = useReactRouter();

  const { contentType, fileName } = useUrlParameters();

  const { loading, error } = useDownloadFile({
    taskId,
    fileId,
    fileName,
    contentType,
  });
  const { t } = useTranslation();

  return <DownloadFilePage error={error} loading={loading} t={t} {...props} />;
};
