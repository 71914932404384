import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from 'config';
import {
  oneSignalSetIdDelay,
  useObjectTypeInViewSpecificCode,
  useSubscribedToPushNotifications,
  useUserPreferences,
} from 'hooks';
import { linkAttentionStyle } from 'styled';
import { EmptyState } from './EmptyState';

const StyledLink = styled(Link)`
  ${linkAttentionStyle}
`;
export const EmptyPendingListContainer = () => {
  const { t } = useTranslation();
  const [subtitle, setSubtitle] = useState('');

  const subscribedToPushNotifications = useSubscribedToPushNotifications();
  const userPreferences = useUserPreferences();
  const isSubscribedToNewUpdates = useObjectTypeInViewSpecificCode({
    travelAndExpenseCallback: () => {
      return userPreferences?.approvals?.travelAndExpenses?.notifyOnNew;
    },
    purchaseRequestCallback: () => {
      return userPreferences?.approvals?.purchaseRequests?.notifyOnNew;
    },
  });

  useEffect(() => {
    if (!userPreferences.loading) {
      const timer = setTimeout(() => {
        if (subscribedToPushNotifications && isSubscribedToNewUpdates) {
          setSubtitle(t('TASK_LIST.EMPTY_PENDING_REQUESTS_SUBTITLE'));
        } else {
          setSubtitle(
            <>
              {t(`TASK_LIST.EMPTY_INFORMED_NEW_REQUESTS`)}
              <StyledLink to={routes.preferences}>
                {t('TASK_LIST.EMPTY_ACTIVATE_NOTIFICATIONS')}
              </StyledLink>
            </>
          );
        }
      }, [oneSignalSetIdDelay]);
      return () => {
        clearTimeout(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userPreferences,
    isSubscribedToNewUpdates,
    subscribedToPushNotifications,
  ]);

  return (
    <EmptyState
      title={t(`TASK_LIST.EMPTY_PENDING_REQUESTS_TITLE`)}
      subtitle={subtitle}
    />
  );
};
