import React from 'react';
import styled from 'styled-components';
import { resetButtonLayout } from 'styled';

export const StyledButton = styled.button`
  /* width: 100%; */
  display: flex;
  align-items: center;
  /* margin-left: -2px; */ /* counter balancing circle through Overshoting https://en.m.wikipedia.org/wiki/Overshoot_(typography) */
  ${resetButtonLayout}
`;

export const StyledIconWrapper = styled.div`
  margin-right: ${({ theme }) => theme.space[2]}px;
`;

export const StyledLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  width: calc(100% - 40px - ${({ theme }) => theme.space[2]}px);
  line-height: ${({ theme }) => theme.fontSizes[2] * 1.2}px;
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  color: ${({ theme }) => theme.colors.primary};
  @media (prefers-color-scheme: dark) {
    color: ${({ theme }) => theme.colors.darkerWhite};
  }
`;

export const IconButton = ({ label, Icon, dataCy, ...props }) => {
  return (
    <StyledButton data-cy={dataCy} {...props}>
      <StyledIconWrapper>
        <Icon />
      </StyledIconWrapper>
      <StyledLabel>{label}</StyledLabel>
    </StyledButton>
  );
};
