import React from 'react';
import styled, { css } from 'styled-components';
import { Triangle } from 'icons';

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  select::-ms-expand {
    display: none;
  }
`;

const StyledSortIcon = styled(Triangle)`
  pointer-events: none;
  fill: ${({ theme }) => theme.colors.secondary};
`;

const StyledSelect = styled.select`
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.secondary};
  padding-right: ${({ theme }) => theme.space[5]}px;
  margin-right: -${({ theme }) => theme.space[4]}px;
  appearance: unset;
  outline: none;
  direction: rtl;
  margin-top: 2px;

  ${({ active }) => {
    if (active)
      return css`
        border-radius: 0px;
        color: ${({ theme }) => theme.colors.tertiary};
        @media (prefers-color-scheme: dark) {
          color: ${({ theme }) => theme.colors.darkerWhite};
        }
        & + ${StyledSortIcon} {
          path {
            fill: ${({ theme }) => theme.colors.tertiary};
            @media (prefers-color-scheme: dark) {
              fill: ${({ theme }) => theme.colors.darkerWhite};
            }
          }
        }
      `;
  }}

  &:focus {
    border-radius: 0px;
    color: ${({ theme }) => theme.colors.tertiary};
    & + ${StyledSortIcon} {
      path {
        fill: ${({ theme }) => theme.colors.tertiary};
      }
    }
  }
  border: none;
  option {
    direction: rtl;
  }
  background-color: unset;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* unset does not seem to work on ie11 */
    background: ${({ theme }) => theme.colors.background};
  }
  @media (prefers-color-scheme: dark) {
    background-color: #171717;
  }
`;

export const SortBy = ({
  t,
  sorted,
  selectedSortingField,
  onChangeHandler,
  sortingOptions,
}) => {
  return (
    <StyledLabel htmlFor="sortSelect">
      <StyledSelect
        id="sortSelect"
        name="sortSelect"
        onChange={onChangeHandler}
        active={sorted}
        aria-label={t('SORTING_FIELDS.LABEL')}
        value={selectedSortingField}
        data-cy="sortBy"
      >
        {Object.keys(sortingOptions).map((key, index) => (
          <option value={key} key={index}>
            {sortingOptions[key]}
          </option>
        ))}
      </StyledSelect>
      <StyledSortIcon width="8px" height="4px" />
    </StyledLabel>
  );
};
