import React from 'react';
import styled from 'styled-components';
import {
  PillMultipleItems,
  Header,
  MultipleTasksCommentGroup,
} from 'components';
import { StyledTaskDetailContentWrapper, subtitleStyle } from 'styled';

const StyledTitle = styled.h3`
  ${subtitleStyle};
  margin-top: ${({ theme }) => theme.space[4]}px;
`;

const StyledDescription = styled.p`
  margin-top: ${({ theme }) => theme.space[3]}px;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const MultipleTasksConfirmTemplate = ({
  subtitle,
  description,
  headerProps,
  previousRoute,
}) => (
  <>
    <Header margin {...headerProps} />
    <StyledTaskDetailContentWrapper mobileSideMargin topMargin>
      <PillMultipleItems />
      <StyledTitle>{subtitle}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
      <MultipleTasksCommentGroup previousRoute={previousRoute} />
    </StyledTaskDetailContentWrapper>
  </>
);
