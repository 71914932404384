import React from 'react';
import { useSelector } from 'react-redux';
import { useCurrentUser } from 'hooks/user';
import { getTasksOverrides } from 'store/tasks/tasks.selectors';
import { StatusBar } from '../StatusBar';
import { TaskActionButtons } from './TaskActionButtons';

const activeApprover = approvalSequence => {
  if (!approvalSequence) {
    return [];
  }
  const pendingSteps = approvalSequence.filter(sequence => {
    return sequence.status === 'PENDING';
  });

  if (pendingSteps && pendingSteps.length > 0) {
    return pendingSteps[pendingSteps.length - 1].approver;
  }
  return null;
};

const activeAliasApprover = approvalSequence => {
  if (!approvalSequence) {
    return [];
  }
  const pendingSteps = approvalSequence.filter(sequence => {
    return sequence.status === 'PENDING';
  });

  if (pendingSteps && pendingSteps.length > 0) {
    return pendingSteps[pendingSteps.length - 1].alias;
  }
  return null;
};

export const PurchaseRequestActionButtonsContainer = ({
  task: { id, approvalSequence },
  task,
  ...props
}) => {
  const overrides = useSelector(getTasksOverrides);

  const currentUser = useCurrentUser();
  const override = overrides[id];

  const aliasApprover = activeAliasApprover(approvalSequence);
  const currentApprover = activeApprover(approvalSequence);

  const { status } = task;
  if (!status) return null;
  if (override && override.updating) {
    return <StatusBar override={override} />;
  }

  if (
    status === 'PENDING' &&
    (currentApprover?.email === currentUser.email ||
      aliasApprover?.email === currentUser.email)
  ) {
    return <TaskActionButtons {...props} />;
  }
  return <StatusBar status={status} />;
};
