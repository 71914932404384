import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Header, Textarea } from 'components';
import { BottomButtonWrapper, StyledTaskDetailContentWrapper } from 'styled';

const StyledTextAreaWrapper = styled.div`
  width: 100%;
  padding: 0px ${({ theme }) => theme.space[5]}px;
  padding-top: ${({ theme }) => theme.space[3]}px;
  margin: auto;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    padding: 0;
    padding-top: ${({ theme }) => theme.space[3]}px;
    margin: 0;
  }
`;

export const TaskDetailConfirmTemplate = ({
  headerProps,
  submitProps,
  cancelProps,
  textAreaProps,
}) => (
  <>
    <Header margin {...headerProps} />
    <StyledTaskDetailContentWrapper>
      <form onSubmit={submitProps.onSubmit}>
        <StyledTextAreaWrapper>
          <Textarea {...textAreaProps} />
        </StyledTextAreaWrapper>
        <BottomButtonWrapper>
          <Link data-cy="taskDetailCancelRejectButton" to={cancelProps.to}>
            <Button {...cancelProps}>{cancelProps.text}</Button>
          </Link>
          <Button
            data-cy="taskDetailConfirmRejectButton"
            type="submit"
            {...submitProps}
          >
            {submitProps.text}
          </Button>
        </BottomButtonWrapper>
      </form>
    </StyledTaskDetailContentWrapper>
  </>
);
