import { OBJECT_TYPES } from 'config';
import { purchaseRequestsUpdate } from './purchaseRequests/purchaseRequests.duck';
import {
  fetchMorePurchaseRequests,
  fetchPendingPurchaseRequestsCount,
  fetchPurchaseRequestLineItems,
  fetchPurchaseRequests,
  searchPurchaseRequests,
  updatePurchaseRequestStatus,
} from './purchaseRequests/purchaseRequests.service';
import { travelAndExpensesUpdate } from './travelAndExpenses/travelAndExpenses.duck';
import {
  fetchMoreTravelAndExpenses,
  fetchPendingTravelAndExpensesCount,
  fetchTravelAndExpenses,
  searchTravelAndExpenses,
  updateTravelAndExpensesStatus,
} from './travelAndExpenses/travelAndExpenses.service';
import { loadingState } from './tasks.duck';

export const fetchAllTasks = async (
  dispatch,
  sortingField = 'requestDate',
  sortingDirection = 'DESC'
) => {
  [purchaseRequestsUpdate, travelAndExpensesUpdate].forEach(updateFunction =>
    updateFunction({
      PENDING: loadingState,
      APPROVED: loadingState,
      REJECTED: loadingState,
    })
  );

  ['PENDING', 'APPROVED', 'REJECTED'].forEach(status => {
    [fetchPurchaseRequests, fetchTravelAndExpenses].forEach(fetchFunction => {
      fetchFunction(dispatch, status, sortingField, sortingDirection);
    });
  });
};

export const fetchPendingTasks = async (
  dispatch,
  sortingField = 'requestDate',
  sortingDirection = 'DESC'
) => {
  [purchaseRequestsUpdate, travelAndExpensesUpdate].forEach(updateFunction =>
    updateFunction({
      PENDING: loadingState,
    })
  );

  [fetchPurchaseRequests, fetchTravelAndExpenses].forEach(fetchFunction => {
    fetchFunction(dispatch, 'PENDING', sortingField, sortingDirection);
  });
};

export const fetchSearchTasks = async (
  dispatch,
  searchQuery,
  sortingField,
  sortingDirection,
  enableLoadingState,
  taskTypeToFetch = OBJECT_TYPES.PURCHASE_REQUEST
) => {
  const defaultParams = {
    dispatch,
    searchQuery,
    sortingField,
    sortingDirection,
    enableLoadingState,
  };

  if (taskTypeToFetch === OBJECT_TYPES.PURCHASE_REQUEST) {
    searchPurchaseRequests({ ...defaultParams });
  }
  if (taskTypeToFetch === OBJECT_TYPES.TRAVEL_AND_EXPENSE) {
    searchTravelAndExpenses({ ...defaultParams });
  }
};

export const fetchMoreTasks = async (
  dispatch,
  statusToFetch,
  search,
  pageNumber,
  sortingField,
  sortingDirection,
  currentTasks,
  taskTypeToFetch = OBJECT_TYPES.PURCHASE_REQUEST
) => {
  const category = search ? 'SEARCH' : statusToFetch;
  const dynamicParams = (() => {
    if (category === 'SEARCH') return `searchTerm=${search}`;
    return `statusCode=${statusToFetch}`;
  })();

  const sharedParams = {
    dispatch,
    category,
    pageNumber,
    sortingField,
    sortingDirection,
    currentTasks,
  };

  if (taskTypeToFetch === OBJECT_TYPES.PURCHASE_REQUEST) {
    fetchMorePurchaseRequests({ ...sharedParams, dynamicParams });
  }
  if (taskTypeToFetch === OBJECT_TYPES.TRAVEL_AND_EXPENSE) {
    fetchMoreTravelAndExpenses({ ...sharedParams, search });
  }
};
export const fetchCountOfPendingTasks = async dispatch => {
  fetchPendingPurchaseRequestsCount(dispatch);
  fetchPendingTravelAndExpensesCount(dispatch);
};

export const fetchTasksLineItems = async (dispatch, sharedId) => {
  fetchPurchaseRequestLineItems(dispatch, sharedId);
};

/*
TODO: break down this function in multiple function since it has to much logic on it's own
*/
export const updateStatusTask = async (
  dispatch,
  newStatus,
  tasks,
  rejectNotes,
  { sortingField, sortingDirection, search, sharedId, taskId },
  taskType = OBJECT_TYPES.PURCHASE_REQUEST,
  updateMultiple = false
) => {
  if (taskType === OBJECT_TYPES.PURCHASE_REQUEST) {
    updatePurchaseRequestStatus(
      dispatch,
      newStatus,
      tasks,
      rejectNotes,
      sortingField,
      sortingDirection,
      search,
      sharedId
    );
  }
  if (taskType === OBJECT_TYPES.TRAVEL_AND_EXPENSE) {
    updateTravelAndExpensesStatus(
      dispatch,
      newStatus,
      tasks,
      rejectNotes,
      sortingField,
      sortingDirection,
      search,
      taskId,
      updateMultiple
    );
  }
};
