import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { resetButtonLayout } from 'styled';

const StyledButton = styled.button`
  text-align: left;
  ${resetButtonLayout}
`;

export const LinkOrAnchor = ({ onClick, to, children, ...props }) => {
  if (!to) {
    if (onClick) {
      return (
        <StyledButton onClick={onClick} type="button" {...props}>
          {children}
        </StyledButton>
      );
    }
    return <>{children}</>;
  }
  return to.includes('http') || to.includes('mailto') ? (
    <a href={to} {...props}>
      {children}
    </a>
  ) : (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};
