import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { OBJECT_TYPES } from 'config';
import { getTaskListSortingField } from 'store/persistedUserInterface/persistedUserInterface.selectors';
import {
  getPendingPurchaseRequestCount,
  getPendingTravelAndExpensesCount,
  getTasksLineItems,
} from 'store/tasks/tasks.selectors';
import {
  fetchAllTasks,
  fetchCountOfPendingTasks,
  fetchPendingTasks,
  fetchTasksLineItems,
} from 'store/tasks/tasks.service';
import {
  getObjectTypeFromSection,
  getPurchaseRequestId,
  getSortingDirection,
  getSortingField,
} from 'utils';

export const useFetchTasksLineItems = objectTypeInView => {
  const tasksLineItems = useSelector(getTasksLineItems);
  const dispatch = useDispatch();
  const {
    match: {
      params: { taskId },
    },
  } = useReactRouter();
  const purchaseRequestIdRouter = getPurchaseRequestId(taskId, true);
  useEffect(() => {
    if (
      tasksLineItems.sharedId !== purchaseRequestIdRouter &&
      objectTypeInView === OBJECT_TYPES.PURCHASE_REQUEST
    ) {
      fetchTasksLineItems(dispatch, purchaseRequestIdRouter);
    }
  }, [
    tasksLineItems.sharedId,
    purchaseRequestIdRouter,
    objectTypeInView,
    dispatch,
  ]);
};

export const useObjectTypeInViewSpecificCode = ({
  travelAndExpenseCallback = () => {},
  purchaseRequestCallback = () => {},
}) => {
  const {
    match: {
      params: { section },
    },
  } = useReactRouter();

  const objectTypeInView = getObjectTypeFromSection(section);

  if (
    objectTypeInView === OBJECT_TYPES.TRAVEL_AND_EXPENSE &&
    travelAndExpenseCallback
  ) {
    return travelAndExpenseCallback();
  }
  if (
    objectTypeInView === OBJECT_TYPES.PURCHASE_REQUEST &&
    purchaseRequestCallback
  ) {
    return purchaseRequestCallback();
  }
  return null;
};

const fetchTasks = (dispatch, selectedSorting) => {
  fetchAllTasks(
    dispatch,
    getSortingField(selectedSorting),
    getSortingDirection(selectedSorting)
  );
  fetchCountOfPendingTasks(dispatch);
};

const fetchOnlyPendingTasks = (dispatch, selectedSorting) => {
  fetchPendingTasks(
    dispatch,
    getSortingField(selectedSorting),
    getSortingDirection(selectedSorting)
  );
  fetchCountOfPendingTasks(dispatch);
};

export const useRefreshAllTasks = () => {
  const dispatch = useDispatch();
  const selectedSorting = useSelector(getTaskListSortingField);
  useEffect(() => {
    // Initial fetch
    fetchTasks(dispatch, selectedSorting);

    const interval = setInterval(() => {
      fetchOnlyPendingTasks(dispatch, selectedSorting);
    }, 300000);

    // Clears interval on prop change
    return () => clearInterval(interval);
  }, [dispatch, selectedSorting]);
};

export const useTotalPendingCount = () => {
  const pendingPurchaseRequestCount = useSelector(
    getPendingPurchaseRequestCount
  );
  const pendingTravelAndExpensesCount =
    useSelector(getPendingTravelAndExpensesCount) || 0;
  return pendingPurchaseRequestCount + pendingTravelAndExpensesCount;
};

let countOfNewPendingItemsSinceOutOfFocus = 0;
let previousTotalPendingCount = 0;
let titleChangeIntervalId = null;
let previousTitle = '';
let globalTranslation = null;

window.onfocus = () => {
  if (previousTitle) document.title = previousTitle;
  previousTitle = '';
  countOfNewPendingItemsSinceOutOfFocus = 0;
  if (titleChangeIntervalId) clearInterval(titleChangeIntervalId);
};

window.onblur = () => {
  titleChangeIntervalId = setInterval(() => {
    const notifyUserOfNewPendingItems =
      countOfNewPendingItemsSinceOutOfFocus > 0;
    if (notifyUserOfNewPendingItems) {
      if (!previousTitle) {
        previousTitle = document.title;
      }
      if (document.title === previousTitle) {
        document.title = globalTranslation('DOCUMENT_TITLE.NEW_PENDING_ITEMS', {
          count: countOfNewPendingItemsSinceOutOfFocus,
        });
      } else {
        document.title = previousTitle;
      }
    }
  }, 2000);
};

export const useChangeTitleOnNewPendingTasks = () => {
  const totalPendingCount = useTotalPendingCount();
  const windowInFocus = document.hasFocus();
  const differenceInCount = totalPendingCount - previousTotalPendingCount;
  const { t } = useTranslation();
  globalTranslation = t;

  if (differenceInCount > 0 && !windowInFocus) {
    countOfNewPendingItemsSinceOutOfFocus += differenceInCount;
  }

  if (previousTotalPendingCount !== totalPendingCount)
    previousTotalPendingCount = totalPendingCount;
};
