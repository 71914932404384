const defaultConfig = {
  oauthLocation: 'https://login.borealis.studio',
  azureBaseUrl:
    'https://login.microsoftonline.com/ce5330fc-da76-4db0-8b83-9dfdd963f09a/oauth2/v2.0',
  serverDomain: '/api',
  interviewMode: false,
  ideaSurveyLink: 'https://digitalstudio.typeform.com/to/iNU6So1u',
  satisfactionSurveyLink: 'https://form.typeform.com/to/B4srngFe',
};

const environmentConfig = {
  local: {
    ...defaultConfig,
  },
  development: {
    ...defaultConfig,
    interviewMode: true,
  },
  preview: {
    ...defaultConfig,
  },
  test: {
    ...defaultConfig,
  },
  uat: {
    ...defaultConfig,
    interviewMode: true,
  },
  production: {
    ...defaultConfig,
    satisfactionSurveyLink: 'https://digitalstudio.typeform.com/to/tk3kyurQ',
  },
};

export const environment = (env => {
  return environmentConfig[env];
})(process.env.REACT_APP_FRONTEND_ENV || 'local');
