/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { PullToRefresh } from './PullToRefresh';

export const PullToRefreshContainer = ({ ...props }) => {
  const treshold = -100;
  const [show, setShow] = useState(false);

  const handleScroll = () => {
    if (window.scrollY < treshold && !show) {
      setShow(true);
    }
    if (window.scrollY > treshold && show) {
      setShow(false);
    }
  };

  const mouseReleased = () => {
    if (window.scrollY < treshold) {
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('touchend', mouseReleased);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('touchend', mouseReleased);
    };
  }, [handleScroll]);

  return <PullToRefresh show={show} {...props} />;
};
