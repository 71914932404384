import React from 'react';
import useReactRouter from 'use-react-router';
import { TaskListAndDetailWrapper } from './TaskListAndDetailWrapper';

export const TaskListAndDetailWrapperContainer = ({ ...props }) => {
  const {
    match: {
      params: { taskId, subsection, section },
    },
  } = useReactRouter();

  return (
    <TaskListAndDetailWrapper
      taskId={taskId}
      section={section}
      subsection={subsection}
      {...props}
    />
  );
};
