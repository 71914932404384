/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlParameters } from 'hooks';
import { getTaskListSearch } from 'store/userInterface/userInterface.selectors';
import { userInterfaceUpdate } from 'store/userInterface/userInterface.duck';
import { SearchInput } from './SearchInput';

let timeout = 0;

export const SearchInputContainer = forwardRef(({ ...props }, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { history } = useReactRouter();
  const [value, setValue] = useState('');
  const initiateSearch = event => {
    const {
      target: { value: inputValue },
    } = event;
    setValue(inputValue);
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      dispatch(userInterfaceUpdate({ taskListSearch: inputValue }));
    }, 500);
  };

  const taskListSearch = useSelector(getTaskListSearch);

  useEffect(() => {
    if (taskListSearch !== null) {
      if (taskListSearch !== value) {
        setValue(taskListSearch);
      }
    }
  }, [taskListSearch]);

  const { search: searchValueFromUrl } = useUrlParameters();
  useEffect(() => {
    if (taskListSearch !== searchValueFromUrl) {
      history.replace({
        search: taskListSearch ? `?search=${taskListSearch}` : '',
      });
    }
  }, [dispatch, history, searchValueFromUrl, taskListSearch]);

  useEffect(() => {
    if (!taskListSearch && searchValueFromUrl) {
      dispatch(userInterfaceUpdate({ taskListSearch: searchValueFromUrl }));
    }
  }, []);

  const submitCancel = event => {
    event.preventDefault();
    dispatch(userInterfaceUpdate({ taskListSearch: null }));
  };

  const submitClear = event => {
    event.preventDefault();
    dispatch(userInterfaceUpdate({ taskListSearch: '' }));
    ref.current.focus();
  };

  const submitSearch = event => {
    event.preventDefault();
    ref.current.blur();
  };

  useEffect(() => {
    if (!taskListSearch && searchValueFromUrl) {
      dispatch(userInterfaceUpdate({ taskListSearch: searchValueFromUrl }));
    }
  }, []);

  useEffect(() => {
    if (taskListSearch === '') {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 10); // Hack to make sure the user sees the input field when scrolled down. Timeout is there to wait for keyboard to open on mobile
    }
  }, [taskListSearch]);

  return (
    <SearchInput
      value={value}
      onChange={initiateSearch}
      submitCancel={submitCancel}
      submitClear={submitClear}
      submitSearch={submitSearch}
      t={t}
      ref={ref}
      {...props}
    />
  );
});
