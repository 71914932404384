import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paperclip } from 'icons';
import styled, { css } from 'styled-components';
import { FileLink, MissingDataParagraph } from 'components';
import { borderBottom, linkAttentionStyle } from 'styled';
import {
  getShortHumanReadableDate,
  logSentryError,
  toPriceNumber,
} from 'utils';

const StyledUl = styled.ul`
  width: calc(100% ${({ theme }) => theme.space[3] + theme.space[5]}px);
  margin-left: ${({ theme }) => theme.space[3]}px;
  margin-right: ${({ theme }) => theme.space[4]}px;
`;

const StyledLi = styled.li`
  min-height: 100px;
  margin-top: ${({ theme }) => theme.space[2]}px;
  ${borderBottom}
  &:last-of-type {
    border-bottom: none;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    display: flex;
    align-items: baseline;
    min-height: 80px;
  }
`;
const StyledDate = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    width: calc(20% - 5px);
  }
`;

const StyledAnotherContentWrapper = styled.div`
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    width: calc(80% + 5px);
  }
`;

const StyledTitle = styled.h4`
  width: calc(100% - 50px);
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  ${({ dark }) =>
    dark
      ? css`
          color: ${({ theme }) => theme.colors.blue[6]};
        `
      : css`
          color: ${({ theme }) => theme.colors.tertiary};
        `}
  height: 38px;
  overflow: hidden;
  padding-right: ${({ theme }) => theme.space[2]}px;
`;
const StyledPrice = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  text-align: right;
`;
const StyledTitlePriceIconGroup = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
const StyledPaperclip = styled(Paperclip)``;

const StyledDescription = styled.div`
  margin-top: ${({ theme }) => theme.space[1]}px;
`;

const showOptionalExtraDescription = extraDescription =>
  extraDescription ? ` | ${extraDescription}` : '';

const StyledFileLink = styled(FileLink)`
  display: flex;
  white-space: nowrap;
`;

const StyledFileName = styled.div`
  ${linkAttentionStyle}
  margin-right: 6px;
`;

const Attachment = ({ attachments, imageID, t }) => {
  if (!imageID) return null;
  const fullAttachmentData = (() =>
    attachments.filter(({ receiptImage }) => receiptImage === imageID)?.[0])();
  if (!fullAttachmentData) {
    logSentryError(`Could not find matching attachment for ${imageID}`);
    return null;
  }
  return (
    <StyledFileLink
      contentType={fullAttachmentData.contentType}
      fileName={fullAttachmentData.description}
      fileId={fullAttachmentData.receiptImage}
    >
      <StyledFileName>
        {t('TRAVEL_AND_EXPENSE_DETAILS.ATTACHMENT_NAME_FALLBACK')}
      </StyledFileName>
      <StyledPaperclip />
    </StyledFileLink>
  );
};

export const TripDetailsList = ({ attachments = [], items = [], dark }) => {
  const { t } = useTranslation();
  return (
    <StyledUl>
      {items.map(
        (
          {
            expenseTypeName,
            imageID,
            date,
            amount,
            currency,
            description,
            businessReason,
            businessPurpose,
          },
          index
        ) => (
          <StyledLi key={index}>
            <StyledDate>
              {date ? (
                getShortHumanReadableDate(date)
              ) : (
                <MissingDataParagraph translationKey="DATE_NOT_AVAILABLE" />
              )}
            </StyledDate>
            <StyledAnotherContentWrapper>
              <StyledTitlePriceIconGroup>
                <StyledTitle dark={dark}>{expenseTypeName}</StyledTitle>
                <div>
                  <StyledPrice>
                    {toPriceNumber({ currency, amount })}
                  </StyledPrice>

                  <Attachment
                    imageID={imageID}
                    t={t}
                    attachments={attachments}
                  />
                </div>
              </StyledTitlePriceIconGroup>
              <StyledDescription>{`
              ${description}
              ${showOptionalExtraDescription(businessReason)}
              ${showOptionalExtraDescription(businessPurpose)}
            `}</StyledDescription>
            </StyledAnotherContentWrapper>
          </StyledLi>
        )
      )}
    </StyledUl>
  );
};
