import React, { forwardRef } from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  border: none;
  width: auto;
  overflow: visible;
  background: transparent;
  appearance: none;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  color: ${({ theme }) => theme.colors.primary};
  @media (prefers-color-scheme: dark) {
    color: ${({ theme }) => theme.colors.tertiary};
  }
  font-weight: bold;
`;

const StyledParagraph = styled.p`
  max-height: ${({ maxHeight }) => maxHeight};
  overflow: hidden;
  line-height: 1.3em;
  color: inherit;
`;

export const ExpandableParagraph = forwardRef(
  ({ open, t, onSubmit, children, height, shouldShowButton }, ref) => (
    <div>
      <StyledParagraph ref={ref} maxHeight={open ? 'auto' : `${height}px`}>
        {children}
      </StyledParagraph>
      {shouldShowButton ? (
        <form onSubmit={onSubmit}>
          <StyledButton type="submit">
            {open
              ? t('EXPANDABLE_PARAGRAPH.SHOW_LESS')
              : t('EXPANDABLE_PARAGRAPH.SHOW_MORE')}
          </StyledButton>
        </form>
      ) : null}
    </div>
  )
);
