import React from 'react';
import styled, { css } from 'styled-components';
import { Header } from 'components';
import { StyledBackdrop } from 'styled';

const StyledWrapper = styled.div`
  z-index: 9;
  @media only screen and (max-width: ${({ theme }) => theme.breakPoints[3]}) {
    ${({ active }) =>
      active
        ? css`
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
          `
        : css`
            display: none;
          `}
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    width: 100vw;
    background-color: white;
    height: 100%;
    overflow: auto;
    position: absolute;
    top: 0;
    transition: right 0.5s, opacity 0.1s;
    ${({ showAsPopUp }) =>
      showAsPopUp
        ? css`
            opacity: 0;
            width: 600px;
            height: 600px;
            max-height: calc(100vh - ${({ theme }) => theme.space[9]}px);
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
          `
        : css`
            right: -401px;
            width: 400px;
          `}
    ${({ active }) =>
      active
        ? css`
            right: 0px;
            opacity: 1;
          `
        : css``}
    ${({ closed }) =>
      closed
        ? css`
            width: 0;
          `
        : css``}
  }
  @media (prefers-color-scheme: dark) {
    background-color: ${({ theme }) => theme.colors.black};
  }
`;

const StyledChildrenWrapper = styled.div`
  ${({ mobilePadding }) =>
    mobilePadding
      ? css`
          padding: 0px ${({ theme }) => theme.space[3]}px;
        `
      : ''}

  @media only screen and (max-width: ${({ theme }) => theme.breakPoints[3]}) {
    padding-bottom: ${({ theme }) => theme.space[3]}px;
  }

  ${({ showAsPopUp }) =>
    showAsPopUp
      ? css`
          height: calc(100% - 59px);
          background-color: white;
        `
      : ''}
        
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    padding: 0px ${({ theme }) => theme.space[4]}px;
  }
`;

export const SidePanel = ({
  previousPath,
  active,
  onRefSidePanelSet,
  closed,
  headerProps,
  children,
  dataCy,
  showAsPopUp,
  mobilePadding = true,
}) => (
  <>
    <StyledBackdrop to={previousPath} active={active ? 1 : 0} />
    <StyledWrapper
      showAsPopUp={showAsPopUp}
      closed={closed}
      active={active}
      ref={onRefSidePanelSet}
    >
      {closed ? null : (
        <>
          <Header {...headerProps} />
          <StyledChildrenWrapper
            mobilePadding={mobilePadding}
            showAsPopUp={showAsPopUp}
            data-cy={dataCy}
          >
            {children}
          </StyledChildrenWrapper>
        </>
      )}
    </StyledWrapper>
  </>
);
