import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LinkOrAnchor, SinglePageTemplate } from 'components';
import { routes } from 'config';
import { StyledSeperator } from 'styled';

const StyledParagraph = styled.p`
  font-size: inherit;
  max-width: 500px;
`;

const StyledTitle = styled.h3`
  font-size: inherit;
`;

const StyledLink = styled(LinkOrAnchor)`
  font-size: inherit;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  display: block;
  margin-top: ${({ theme }) => theme.space[3]}px;
`;

const StyledSection = styled.section`
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  padding: ${({ theme }) => theme.space[4]}px ${({ theme }) => theme.space[6]}px;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    padding-left: 0px;
  }
`;

const ExtraStyledSeperator = styled(StyledSeperator)`
  margin: 0px ${({ theme }) => theme.space[2]}px;
  width: calc(100% - (${({ theme }) => theme.space[2]}px * 2));
  max-width: calc(500px + (${({ theme }) => theme.space[4]}px * 2));
  background-color: ${({ theme }) => theme.colors.lightGray};
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    margin: 0;
    max-width: calc(500px + ${({ theme }) => theme.space[4]}px);
  }
`;

export const SupportPage = () => {
  const { t } = useTranslation();

  return (
    <SinglePageTemplate title={t('COMMON.SUPPORT')}>
      <StyledSection>
        <StyledParagraph>
          {t('SUPPORT.INTRO_TITLE')}
          <br />
          <br />
          {t('SUPPORT.INTRO_DESCRIPTION')}
        </StyledParagraph>
      </StyledSection>
      <ExtraStyledSeperator />
      <StyledSection>
        <StyledTitle>{t('SUPPORT.ISSUE_TITLE')}</StyledTitle>
        <StyledParagraph>{t('SUPPORT.ISSUE_DESCRIPTION')}</StyledParagraph>
        <StyledLink
          target="_blank"
          to="https://omv.service-now.com/sp?id=sc_cat_item&sys_id=baffbe498757f0507726ca6e8bbb35e7&sysparm_category=e15706fc0a0a0aa7007fc21e1ab70c2f"
        >
          {t('SUPPORT.ISSUE_ACTION')}
        </StyledLink>
      </StyledSection>
      <ExtraStyledSeperator />
      <StyledSection>
        <StyledTitle>{t('SUPPORT.SUGGESTION_TITLE')}</StyledTitle>
        <StyledParagraph>{t('SUPPORT.SUGGESTION_DESCRIPTION')}</StyledParagraph>
        <StyledLink to={routes.ideaSurvey}>
          {t('SUPPORT.SUGGESTION_ACTION')}
        </StyledLink>
      </StyledSection>
      <ExtraStyledSeperator />
    </SinglePageTemplate>
  );
};
