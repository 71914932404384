/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { endpoints } from 'config';
import {
  convertToSapStatus,
  getTaskIdWithEmployeeNumber,
  logSentryError,
  mataHari,
  secureFetch,
  sleep,
} from 'utils';
import { loadingState, overridesUpdate } from '../tasks.duck';
import {
  initialStateOfStatus,
  travelAndExpensesUpdate,
} from './travelAndExpenses.duck';

const PAGE_SIZE = 20;
const DEFAULT_SORTING_FIELD = 'requestDate';
const DEFAULT_SORTING_DIRECTION = 'DESC';
const allDataFetched = data => data.length < PAGE_SIZE;

const apiStatusCode = statusToFetch => {
  switch (statusToFetch) {
    case 'APPROVED':
      return 'A';
    case 'REJECTED':
      return 'R';
    default:
      return 'W';
  }
};

export const fetchTravelAndExpenses = async (
  dispatch,
  statusToFetch = 'PENDING',
  sortingField = DEFAULT_SORTING_FIELD,
  sortingDirection = DEFAULT_SORTING_DIRECTION
) => {
  try {
    const url = `${
      endpoints.travelAndExpenses
    }?pageSize=${PAGE_SIZE}&statusCode=${apiStatusCode(
      statusToFetch
    )}&sortDirection=${sortingDirection}`;
    const data = await secureFetch(url);

    dispatch(
      travelAndExpensesUpdate({
        [statusToFetch]: {
          loading: false,
          pageNumber: 1,
          data: data || [],
          allDataFetched: allDataFetched(data),
        },
      })
    );
  } catch (error) {
    dispatch(travelAndExpensesUpdate({ [statusToFetch]: { error } }));
  }
};

export const fetchMoreTravelAndExpenses = async ({
  dispatch,
  category,
  pageNumber,
  sortingField,
  sortingDirection,
  currentTasks,
  search,
}) => {
  const searchParams = search ? `&searchTerm=${search.toUpperCase()}` : '';
  try {
    const url = `${
      endpoints.travelAndExpenses
    }?pageSize=${PAGE_SIZE}&statusCode=${apiStatusCode(
      category
    )}&sortDirection=${sortingDirection}&pageNumber=${pageNumber}${searchParams}`;

    const result = await secureFetch(url);
    const newTasks = result;

    dispatch(
      travelAndExpensesUpdate({
        [category]: {
          loading: false,
          pageNumber,
          data: [...currentTasks.data, ...newTasks],
          allDataFetched: allDataFetched(newTasks),
          loadingMoreTasks: false,
        },
      })
    );
  } catch (error) {
    dispatch(
      travelAndExpensesUpdate({
        [category]: { ...currentTasks, errorOnFetchingMore: error },
      })
    );
  }
};

export const fetchPendingTravelAndExpensesCount = async dispatch => {
  try {
    const json = await secureFetch(endpoints.pendingTravelAndExpensesCount);
    const { pendingTravelAndExpenses: pendingCountString } = json;
    const pendingCount = Number(pendingCountString);

    dispatch(
      travelAndExpensesUpdate({
        pendingCount,
        loadingPendingCount: false,
      })
    );
  } catch (error) {
    // error is already caught in secureFetch but we do not want to send it twice
  }
};

export const searchTravelAndExpenses = async ({
  dispatch,
  searchQuery,
  sortingField,
  sortingDirection,
  enableLoadingState,
}) => {
  if (enableLoadingState) {
    dispatch(
      travelAndExpensesUpdate({
        SEARCH: loadingState,
      })
    );
  }
  try {
    const result = await secureFetch(
      `${
        endpoints.travelAndExpenses
      }?searchTerm=${searchQuery.toUpperCase()}&sortDirection=${sortingDirection}&pageNumber=1&pageSize=${PAGE_SIZE}`
    );
    dispatch(
      travelAndExpensesUpdate({
        SEARCH: {
          loading: false,
          pageNumber: 1,
          data: result,
          allDataFetched: allDataFetched(result),
        },
      })
    );
  } catch (error) {
    dispatch(travelAndExpensesUpdate({ SEARCH: { error } }));
  }
};

export const fetchTravelAndExpensesOfEmployee = async (
  dispatch,
  employeeNumber,
  enableLoadingState = true
) => {
  const defaultState = { employeeNumber, selected: {} };
  if (enableLoadingState) {
    dispatch(
      travelAndExpensesUpdate({
        EMPLOYEE_ITEMS: { ...loadingState, ...defaultState },
      })
    );
  }
  try {
    const data = await secureFetch(
      `${endpoints.travelAndExpenses}?employeeNumber=${employeeNumber}&pageSize=${PAGE_SIZE}&statusCode=W`
    );
    dispatch(
      travelAndExpensesUpdate({
        EMPLOYEE_ITEMS: {
          data,
          loading: false,
          initialState: false,
          ...defaultState,
        },
      })
    );
  } catch (error) {
    dispatch(
      travelAndExpensesUpdate({
        EMPLOYEE_ITEMS: { ...initialStateOfStatus, error, ...defaultState },
      })
    );
  }
};

export const updateTravelAndExpensesStatus = async (
  dispatch,
  newStatus,
  tasks,
  rejectNotes,
  sortingField,
  sortingDirection,
  search,
  taskId,
  updateMultiple
) => {
  try {
    tasks.forEach(task => {
      dispatch(
        overridesUpdate({
          id: `${task.id}::${task.employeeNumber}`,
          values: { updating: true, message: null, newStatus, notify: true },
        })
      );
    });
    const action = convertToSapStatus(newStatus);

    const items = tasks.map(({ employeeNumber, id }, index) => {
      if (!employeeNumber || !id)
        throw Error(
          `Invalid employeeNumber: ${employeeNumber} or tripnumber: ${id}`
        );
      return {
        tripNumber: id,
        employeeNumber,
        rejectNote: rejectNotes[index],
      };
    });

    const body = {
      action,
      items,
    };
    const response = await secureFetch(
      endpoints.respond.travelAndExpenses,
      {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        mode: 'cors',
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
      },
      true
    );
    const responseJson = await response.json();
    if (responseJson.result.length === 0) {
      tasks.forEach(task => {
        dispatch(
          overridesUpdate({
            id: `${task.id}::${task.employeeNumber}`,
            values: {
              updating: false,
              message: 'Got an empty response',
              newStatus,
              notify: true,
            },
          })
        );
      });
    }
    let atlLeastOneSuccesfull = false;
    await sleep(5000);
    responseJson.result.forEach(
      ({ tripNumber, employeeNumber, success, message }) => {
        const id = getTaskIdWithEmployeeNumber({
          id: tripNumber,
          employeeNumber,
        });
        if (success) atlLeastOneSuccesfull = true;
        if (success) {
          if (action === 'A') mataHari.tripApproved();
          if (action === 'R') mataHari.tripRejected();
        } else {
          mataHari.tripApprovalFailed();
          logSentryError(`${id} ${message}`, {
            causedBy: 'SAP',
          });
        }
        dispatch(
          overridesUpdate({
            id,
            values: { updating: false, message, success, notify: true },
          })
        );
      }
    );
    if (atlLeastOneSuccesfull) {
      fetchPendingTravelAndExpensesCount(dispatch);
      if (updateMultiple)
        fetchTravelAndExpensesOfEmployee(
          dispatch,
          tasks[0].employeeNumber,
          false
        );

      fetchTravelAndExpenses(
        dispatch,
        'PENDING',
        sortingField,
        sortingDirection
      );
      if (newStatus === 'APPROVED')
        fetchTravelAndExpenses(
          dispatch,
          'APPROVED',
          sortingField,
          sortingDirection
        );
      if (newStatus === 'REJECTED')
        fetchTravelAndExpenses(
          dispatch,
          'REJECTED',
          sortingField,
          sortingDirection
        );
      if (search)
        searchTravelAndExpenses({
          dispatch,
          searchQuery: search,
          sortingField,
          sortingDirection,
          enableLoadingState: false,
        });
    }
  } catch (error) {
    mataHari.tripApprovalFailed();
    logSentryError(error);
    console.error(error);
    tasks.forEach(task => {
      dispatch(
        overridesUpdate({
          id: getTaskIdWithEmployeeNumber(task),
          values: {
            updating: false,
            message: error,
            newStatus,
            notify: true,
          },
        })
      );
    });
  }
};
