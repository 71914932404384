import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { OBJECT_TYPES } from 'config';
import { purhaseRequestHasAttachment } from 'utils';
import { PurchaseRequestListItem } from './PurchaseRequestListItem';
import { TravelAndExpenseListItem } from './TravelAndExpenseListItem';

const StyledWrapper = styled.div`
  position: relative;
  height: 100%;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  ${({ margin }) =>
    margin
      ? css`
          margin: 0 ${({ theme }) => theme.space[4]}px;
        `
      : ''}
`;

const StyledLink = styled(Link)`
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  &:focus {
    outline: none;
    @media (prefers-color-scheme: dark) {
      background-color: ${({ theme }) => theme.colors.focusDark};
    }
    background-color: ${({ theme }) => theme.colors.darkFocus};
  }
  ${({ focus }) =>
    focus &&
    css`
      @media (prefers-color-scheme: dark) {
        background-color: ${({ theme }) => theme.colors.focusDark};
      }
      background-color: ${({ theme }) => theme.colors.darkFocus};
    `}
`;

/*
TODO: review naming props
*/
export const TaskListItem = ({
  to,
  task,
  focus,
  className,
  type,
  margin = true,
  t,
  ...props
}) => {
  return (
    <StyledLink
      to={to}
      aria-label={t('COMMON.GET_MORE_DETAILS_ABOUT', { details: task.title })}
      focus={focus ? 1 : 0}
      className={className}
    >
      <StyledWrapper margin={margin} data-cy={`taskListItem${task.id}`}>
        {type === OBJECT_TYPES.PURCHASE_REQUEST && (
          <PurchaseRequestListItem
            t={t}
            hasAttachment={purhaseRequestHasAttachment(task)}
            focus={focus}
            task={task}
            {...props}
          />
        )}
        {type === OBJECT_TYPES.TRAVEL_AND_EXPENSE && (
          <TravelAndExpenseListItem
            t={t}
            focus={focus}
            task={task}
            {...props}
          />
        )}
      </StyledWrapper>
    </StyledLink>
  );
};
