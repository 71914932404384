import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { customTheme } from 'theme/theme';
import useReactRouter from 'use-react-router';
import { FakeLink } from 'components';
import {
  combineCodeAndName,
  getMediumHumanReadableDate,
  toPriceNumber,
} from 'utils';
import { SwipeableCards } from './SwipeableCards';

const StyledFakeLink = styled(FakeLink)`
  margin-top: ${({ theme }) => theme.space[2]}px;
`;

export const LineItemsCardsContainer = ({ task }) => {
  const {
    location: { pathname },
  } = useReactRouter();

  const { t } = useTranslation();
  const data = task.lineItems.map(lineItem => ({
    header: {
      left: {
        keyText: t('LINE_ITEM_DETAILS.ITEM_NAME'),
        valueText: lineItem.name,
        valueTextBold: true,
        valueMaxTwoLines: true,
        valueTextColor: customTheme.colors.primary,
      },
      right: {
        keyText: t('LINE_ITEM_DETAILS.PRICE'),
        valueText: toPriceNumber(lineItem.amount),
        valueMaxTwoLines: !lineItem.attachments.length,
      },
      hasAttachment: lineItem.attachments.length,
    },
    body: [
      {
        keyText: t('LINE_ITEM_DETAILS.NEEDED_BY'),
        valueText: getMediumHumanReadableDate(lineItem.needBy),
      },
      {
        keyText: t('LINE_ITEM_DETAILS.SUPPLIER_NAME'),
        valueText: combineCodeAndName(lineItem.supplier),
        valueTextBold: true,
      },
      {
        keyText: t('TASK_DETAILS.DESCRIPTION'),
        valueText: lineItem.description,
        valueMaxTwoLines: true,
        extraComponent: (
          <StyledFakeLink>{t('COMMON.READ_MORE')}</StyledFakeLink>
        ),
      },
    ],
    to: `${pathname}/line-items/${lineItem.id}`,
    dataCy: 'aribaLineItemLink',
  }));
  return <SwipeableCards data={data} />;
};
