import React from 'react';
import { Loader } from 'icons';
import styled, { css } from 'styled-components';
import { borderTop } from 'styled';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledName = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  line-height: ${({ theme }) => theme.lineHeights[1]}px;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 2px;
  font-weight: bold;
`;

const StyledUl = styled.ul`
  width: 100%;
  max-width: 600px;
  &:nth-child(2) {
    ${borderTop}
  }
`;

const StyledActive = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${({ theme }) => theme.colors.tertiary};
  position: absolute;
  border-radius: 50%;
  left: -18px;
  top: 7px;
  ${({ active }) =>
    active
      ? css`
          opacity: 1 !important;
        `
      : css`
          opacity: 0;
        `}
`;

const StyledListItem = styled.li`
  margin: ${({ theme }) => theme.space[6]}px;
  position: relative;
  &:hover {
    ${StyledActive} {
      opacity: 0.3;
    }
  }
`;

const StyledLoader = styled(Loader)`
  width: 50px;
  * {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledParagraphDisclaimer = styled.p`
  margin-left: ${({ theme }) => theme.space[6]}px;
  margin-top: 20px;
`;

const Environment = ({ name, url }) => (
  <StyledListItem>
    <StyledActive active={window.location.origin === url} />
    <a href={url}>
      <StyledName>{name}</StyledName>
      <div>{url}</div>
    </a>
  </StyledListItem>
);
export const SwitchEnvironmentPage = ({
  fixedEnvironments,
  dynamicEnvironments,
  loading,
  ...props
}) => (
  <StyledWrapper {...props}>
    <StyledUl>
      <StyledParagraphDisclaimer>
        Disclaimer, data may be incorrect
      </StyledParagraphDisclaimer>
      {fixedEnvironments.map(enironmentProps => (
        <Environment key={enironmentProps.url} {...enironmentProps} />
      ))}
    </StyledUl>
    {loading ? (
      <StyledLoader />
    ) : dynamicEnvironments.length ? (
      <StyledUl>
        {dynamicEnvironments.map(enironmentProps => (
          <Environment key={enironmentProps.url} {...enironmentProps} />
        ))}
      </StyledUl>
    ) : (
      <p>No open pull requests</p>
    )}
  </StyledWrapper>
);
