import React from 'react';
import styled from 'styled-components';

import { getShortHumanReadableDate, shortTime } from 'utils';
import { sectionItemWrapperStyle } from 'styled';

import { StyledPlaceholder } from '../../../styled/styled';

const StyledKey = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  font-weight: bold;
  @media print {
    font-weight: bold;
  }
  @media (prefers-color-scheme: dark) {
    color: ${({ theme }) => theme.colors.secondary};
  }
  font-size: 18px;
`;

const StyledLi = styled.li`
  ${sectionItemWrapperStyle}
  margin-bottom: ${({ theme }) => theme.space[4]}px;
`;

const StyledDateEntry = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const StyledDateEntryWrapper = styled.div`
  display: flex;
  align-items: baseline;
  line-height: ${({ theme }) => theme.lineHeights[1]}px;
  white-space: pre-wrap;
  margin-top: ${({ theme }) => theme.space[1]}px;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  font-weight: ${({ fontWeight }) => fontWeight};
  @media (prefers-color-scheme: dark) {
    color: ${({ theme }) => theme.colors.darkerWhite};
  }
  max-width: 220px;
`;

const StyledLabel = styled.label`
  width: 85px;
`;

export const TripStartEnd = ({
  startDate,
  startTime,
  endDate,
  endTime,
  t,
  loading,
}) => (
  <StyledLi>
    <StyledKey>{t('TRAVEL_AND_EXPENSE_DETAILS.DATE_AND_TRIP_INFO')}</StyledKey>
    <StyledDateEntryWrapper>
      {loading ? (
        <StyledPlaceholder height="21px" width="200px" color="black" />
      ) : (
        <>
          <StyledLabel>
            {t('TRAVEL_AND_EXPENSE_DETAILS.START_DATE')}{' '}
          </StyledLabel>
          <StyledDateEntry>
            {t('TRAVEL_AND_EXPENSE_DETAILS.DATE_ENTRY', {
              date: getShortHumanReadableDate(startDate),
              time: shortTime(startTime),
            })}
          </StyledDateEntry>
        </>
      )}
    </StyledDateEntryWrapper>
    <StyledDateEntryWrapper>
      {loading ? (
        <StyledPlaceholder height="21px" width="200px" color="black" />
      ) : (
        <>
          <StyledLabel>{t('TRAVEL_AND_EXPENSE_DETAILS.END_DATE')} </StyledLabel>
          <StyledDateEntry>
            {t('TRAVEL_AND_EXPENSE_DETAILS.DATE_ENTRY', {
              date: getShortHumanReadableDate(endDate),
              time: shortTime(endTime),
            })}
          </StyledDateEntry>
        </>
      )}
    </StyledDateEntryWrapper>
  </StyledLi>
);
