import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyle from 'theme/globalStyle';
import { customTheme } from 'theme/theme';
import { LoadingTemplate } from 'components';

const StyledParagraphContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledParagraph = styled.p`
  font-size: ${customTheme.fontSizes[2]}px;
  max-width: calc(100% - ${customTheme.space[9]}px);
  text-align: center;
`;

export const PreLoginPage = ({ t, isBlockedWhileLoggingIn }) => (
  <ThemeProvider theme={customTheme}>
    <>
      <GlobalStyle />
      <StyledParagraphContainer>
        <StyledParagraph>{t('PRE_LOGIN_PAGE.LOGGED_OUT')}</StyledParagraph>
      </StyledParagraphContainer>
      ) : window.navigator.onLine ? (
      <LoadingTemplate
        {...{
          t,
          isBlocked: isBlockedWhileLoggingIn,
          title: t('PRE_LOGIN_PAGE.HOLD_ON'),
        }}
      />
    </>
  </ThemeProvider>
);
