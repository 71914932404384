import React from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import { useCurrentUser } from 'hooks/user';
import { injectRouteParameter } from 'utils';
import { UserLink } from './UserLink';

export const UserLinkContainer = ({ account, ...props }) => {
  const { t } = useTranslation();
  const {
    location: { pathname },
    match: { path, params },
  } = useReactRouter();
  const linkUrl = injectRouteParameter(path, {
    ...params,
    subsection: 'contact',
    subsectionId: account.email,
  });
  return (
    <UserLink
      currentUser={useCurrentUser()}
      t={t}
      pathname={pathname}
      account={account}
      linkUrl={linkUrl}
      {...props}
    />
  );
};
