import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { persistedUserInterfaceUpdate } from 'store/persistedUserInterface/persistedUserInterface.duck';
import { getTaskListSortingField } from 'store/persistedUserInterface/persistedUserInterface.selectors';
import { getSortingOptionsFromSection, mataHari } from 'utils';
import { SortBy } from './SortBy';

export const SortByContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedSortingField = useSelector(getTaskListSortingField);
  const [sorted, setSorted] = useState(selectedSortingField !== '');

  const {
    match: {
      params: { section },
    },
  } = useReactRouter();

  const sortingOptions = getSortingOptionsFromSection(section, t);

  const onChangeHandler = event => {
    mataHari.uxSnitch('changedSorting');
    const selectedSorting = event.target.value;
    setSorted(selectedSorting !== 'requestDate DESC');
    dispatch(
      persistedUserInterfaceUpdate({ taskListSortingField: event.target.value })
    );

    event.target.blur();
  };

  return (
    <SortBy
      sorted={sorted}
      selectedSortingField={selectedSortingField}
      onChangeHandler={onChangeHandler}
      t={t}
      sortingOptions={sortingOptions}
    />
  );
};
