/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { OBJECT_TYPES } from 'config';
import { getTaskListStatusFilter } from 'store/persistedUserInterface/persistedUserInterface.selectors';
import {
  getPendingPurchaseRequestCount,
  getPendingTravelAndExpensesCount,
} from 'store/tasks/tasks.selectors';
import { getObjectTypeFromSection } from '../../../utils';
import { Tabs } from './Tabs';

export const TabsContainer = ({ ...props }) => {
  const { t } = useTranslation();
  const {
    match: {
      params: { section },
    },
  } = useReactRouter();

  const [focusIndicatorWidth, setFocusIndicatorWidth] = useState(0);
  const selectedTab = useSelector(getTaskListStatusFilter);

  const objectTypeInView = getObjectTypeFromSection(section);

  const pendingPurhaseRequestsCount = useSelector(
    getPendingPurchaseRequestCount
  );
  const pendingTravelAndExpensesCount = useSelector(
    getPendingTravelAndExpensesCount
  );

  let pendingCount;
  switch (objectTypeInView) {
    case OBJECT_TYPES.PURCHASE_REQUEST:
      pendingCount = isNaN(pendingPurhaseRequestsCount)
        ? '?'
        : pendingPurhaseRequestsCount;
      break;
    case OBJECT_TYPES.TRAVEL_AND_EXPENSE:
      pendingCount = isNaN(pendingTravelAndExpensesCount)
        ? '?'
        : pendingTravelAndExpensesCount;
      break;
    default:
      pendingCount = 0;
  }
  const data = [
    {
      title: `${t('COMMON.PENDING')} (${pendingCount})`,
      value: 'PENDING',
    },
    { title: t('COMMON.APPROVED'), value: 'APPROVED' },
    { title: t('COMMON.REJECTED'), value: 'REJECTED' },
  ];
  const selectedIndex = data.findIndex(({ value }) => value === selectedTab);
  return (
    <Tabs
      selectedTab={selectedTab}
      focusIndicatorWidth={focusIndicatorWidth}
      setFocusIndicatorWidth={setFocusIndicatorWidth}
      data={data}
      selectedIndex={selectedIndex}
      {...props}
    />
  );
};
