import React from 'react';
import styled, { css } from 'styled-components';
import { Check, Min, ExclamationMark, Garbage } from 'icons';
import { STATUS_COLORS } from 'styled';

const StyledCheck = styled(Check)`
  width: 12px;
  height: 9px;
  ${({ size }) => {
    if (size === 'SMALL')
      return css`
        width: 8px;
        height: 6px;
      `;
  }}
`;

const StyledMin = styled(Min)`
  width: 12px;
  ${({ size }) => {
    if (size === 'SMALL')
      return css`
        width: 9px;
        height: 3px;
      `;
  }}
`;

const StyledGarbage = styled(Garbage)`
  width: 10px;
  height: 10px;
  ${({ size }) => {
    if (size === 'BIG')
      return css`
        width: 11px;
        height: 11px;
      `;
    if (size === 'SMALL')
      return css`
        width: 9px;
        height: 9px;
      `;
  }}
`;

const StyledExclamationMark = styled(ExclamationMark)`
  padding-left: 1px;
  width: 23px;
  height: 11px;
  ${({ size }) => {
    if (size === 'SMALL')
      return css`
        width: 3px;
        height: 11px;
      `;
  }}
  @media (prefers-color-scheme: dark) {
    * {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;

  ${({ statusColor }) => {
    return css`
      border-color: ${statusColor};
      background-color: ${statusColor};
    `;
  }};
  ${({ status }) =>
    status === 'PENDING'
      ? css`
          @media (prefers-color-scheme: dark) {
            border-color: ${({ theme }) => theme.colors.darkerWhite};
            background-color: ${({ theme }) => theme.colors.darkerWhite};
          }
        `
      : ''}
  ${({ theme, status }) =>
    status === 'NEXT'
      ? css`
          border: 2px solid ${theme.colors.gray};
        `
      : ''}
  ${({ size }) => {
    if (size === 'BIG') {
      return css`
        width: 23px;
        height: 23px;
      `;
    }
    if (size === 'SMALL') {
      return css`
        width: 15px;
        height: 15px;
      `;
    }
  }}
`;

const StyledClockHand = styled.div`
  background-color: ${({ theme }) => theme.colors.gray};
  position: relative;
  ${({ first }) =>
    first
      ? css`
          height: 7px;
          width: 2px;
          top: -2px;
          left: 2px;
        `
      : css`
          width: 7px;
          height: 2px;
          top: -2px;
          left: 2px;
        `}
`;

const Clock = () => (
  <div>
    <StyledClockHand first />
    <StyledClockHand />
  </div>
);

export const StatusIcon = ({ size, status, noIcon, ...props }) => (
  <StyledIconWrapper
    status={status}
    statusColor={STATUS_COLORS[status]}
    size={size}
    {...props}
  >
    {(() => {
      // if (thisAccount && status === 'PENDING')
      if (noIcon) return null;
      switch (status) {
        case 'APPROVED':
          return <StyledCheck size={size} />;
        case 'REJECTED':
          return <StyledMin size={size} />;
        case 'PENDING':
          return <StyledExclamationMark size={size} />;
        case 'DELETED':
          return <StyledGarbage size={size} />;
        case 'NEXT':
          return <Clock />;
        default:
          return null;
      }
    })()}
  </StyledIconWrapper>
);
