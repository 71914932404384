import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { useActiveSearch, useMobile } from 'hooks';
import {
  getTaskListSortingField,
  getTaskListStatusFilter,
} from 'store/persistedUserInterface/persistedUserInterface.selectors';
import { fetchMoreTasks } from 'store/tasks/tasks.service';
import { userInterfaceUpdate } from 'store/userInterface/userInterface.duck';
import { getTaskListSearch } from 'store/userInterface/userInterface.selectors';
import {
  getObjectTypeFromSection,
  getSortingDirection,
  getSortingField,
} from 'utils';
import { TaskListItemSwipeGroup } from './TaskListItemSwipeGroup';

export const TaskListItemSwipeGroupContainer = ({ tasks, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mobile = useMobile();
  const filter = useSelector(getTaskListStatusFilter);
  const search = useSelector(getTaskListSearch);
  const selectedSorting = useSelector(getTaskListSortingField);
  const {
    match: {
      params: { section },
    },
  } = useReactRouter();

  const resetSearchHandler = event => {
    event.preventDefault();
    dispatch(userInterfaceUpdate({ taskListSearch: '' }));
    document.querySelector('[type="search"]').focus();
  };

  const fetchMore = () => {
    const sortingField = getSortingField(selectedSorting);
    const sortingDirection = getSortingDirection(selectedSorting);

    fetchMoreTasks(
      dispatch,
      filter,
      search,
      tasks.pageNumber + 1,
      sortingField,
      sortingDirection,
      tasks,
      getObjectTypeFromSection(section)
    );
  };
  return (
    <TaskListItemSwipeGroup
      t={t}
      fetchMore={fetchMore}
      searchUrlValue={search}
      searchIsActive={useActiveSearch()}
      tasks={tasks}
      filter={filter}
      mobile={mobile}
      resetSearchHandler={resetSearchHandler}
      {...props}
    />
  );
};
