import { PreferencesPage } from 'components';
import { OBJECT_TYPES, routes } from 'config';
import { getSortingOptionsForTaskType } from './sorting';

const sectionToObjectLookup = {
  'purchase-requests': OBJECT_TYPES.PURCHASE_REQUEST,
  'travel-and-expenses': OBJECT_TYPES.TRAVEL_AND_EXPENSE,
};

const sectionToObjectConstantLookup = {
  'purchase-requests': 'PURCHASE_REQUEST',
  'travel-and-expenses': 'TRAVEL_AND_EXPENSE',
};

const pathRegEx = path => new RegExp(`(/${path})$`);

export const injectRouteParameter = (route, parameters) => {
  Object.keys(parameters).forEach(key => {
    const matchQueryParameterRegex = new RegExp(`:${key}\\??`);
    route = route.replace(matchQueryParameterRegex, parameters[key]);
  });
  route = route.replace(/\/undefined/g, '');
  return route;
};

export const getDetailComponentFromRoute = pathname => {
  if (pathname.match(pathRegEx(routes.preferences))) {
    return PreferencesPage;
  }
  return undefined;
};

export const getObjectTypeFromSection = section => {
  return sectionToObjectLookup[section] || OBJECT_TYPES.PURCHASE_REQUEST;
};
export const getConstantCaseTypeFromSection = section =>
  sectionToObjectConstantLookup[section] ||
  sectionToObjectConstantLookup['purchase-requests'];

export const getSortingOptionsFromSection = (section, t) => {
  return getSortingOptionsForTaskType(getObjectTypeFromSection(section), t);
};

export const getTaskListTitleKeyFromSection = section =>
  `TASK_LIST.TITLES.${sectionToObjectConstantLookup[section]}`;
