export const getActiveCostAssignment = costAssignment => {
  if (
    costAssignment.order &&
    costAssignment.order?.code &&
    costAssignment.order?.name &&
    costAssignment.order?.owner
  ) {
    return { type: 'order', ...costAssignment.order };
  }
  if (
    costAssignment.network &&
    costAssignment.network?.code &&
    costAssignment.network?.name
  ) {
    return { type: 'network', ...costAssignment.network };
  }
  return { type: 'costCenter', ...costAssignment.costCenter };
};
