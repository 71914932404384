import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  BrokenRocket,
  DinoDetective,
  Loader,
  Magnifiorite,
  Rocket,
} from 'icons';
import styled from 'styled-components';
import {
  EmptyPendingList,
  EmptyState,
  TaskListItem,
  TaskListLastItem,
} from 'components';
import { getTaskTypeSpecificId, parseError } from 'utils';

const StyledWarningWrapper = styled.div`
  display: block;
  margin: auto;
  margin-top: ${({ theme }) => theme.space[3]}px;
  width: calc(100% - ${({ theme }) => theme.space[6]}px);
`;

const StyledLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ theme }) => theme.space[3]}px;
`;

const StyledLoader = styled(Loader)`
  width: 70px;
  * {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledNoSearchResultsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledNoSearchResultsNote = styled.p`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  max-width: 400px;
  margin-top: ${({ theme }) => theme.space[6]}px;
`;

const emptyIconForStatus = status => {
  if (status === 'APPROVED') {
    return { Icon: Magnifiorite, inverseIcon: false };
  }
  if (status === 'REJECTED') {
    return { Icon: Magnifiorite, inverseIcon: true };
  }
  return { Icon: Rocket, inverseIcon: false };
};

const StyledInfiniteScroll = styled(InfiniteScroll)`
  padding-bottom: 35px; /* Height warning message at the bottom of the last item */
`;

export const TaskListItemSwipeGroup = ({
  tasks,
  t,
  searchUrlValue,
  selectedTaskId,
  fetchMore,
  searchIsActive,
  resetSearchHandler,
  /* mobile, */
  filter,
  overrides,
}) => {
  if (tasks.loading) {
    return [...Array(20).keys()].map(key => (
      <TaskListItem
        swipeable
        key={key}
        loading={tasks.loading}
        task={{ id: -1 }}
      />
    ));
  }

  if (tasks && tasks.data && tasks.data.length > 0) {
    // const scrollableTarget = mobile ? null : 'infiniteScrollTargetDesktop'; // This seems to have broken down at some point?
    return (
      <StyledInfiniteScroll
        dataLength={tasks.data.length}
        next={fetchMore}
        hasMore={!tasks.allDataFetched}
        scrollableTarget="infiniteScrollTargetDesktop"
        key="infiniteScrollTargetDesktop"
        loader={
          <StyledLoaderWrapper>
            <StyledLoader />
          </StyledLoaderWrapper>
        }
      >
        {tasks.data.map(task => {
          return (
            <TaskListItem
              swipeable
              selectedTaskId={selectedTaskId}
              key={task.key}
              task={task}
              override={overrides[getTaskTypeSpecificId(task)]}
              loading={tasks.loading}
              filter={filter}
            />
          );
        })}
        {tasks.errorOnFetchingMore
          ? parseError(tasks.errorOnFetchingMore, t)
          : null}
        <TaskListLastItem
          searchIsActive={searchIsActive}
          t={t}
          tasks={tasks}
          filter={filter}
        />
      </StyledInfiniteScroll>
    );
  }

  return (
    <StyledWarningWrapper>
      {(() => {
        if (tasks) {
          if (tasks.error)
            return (
              <EmptyState
                Icon={BrokenRocket}
                title={t('COMMON.500_TITLE')}
                subtitle={t('COMMON.500_SUBTITLE')}
              />
            );
          if (searchUrlValue)
            return (
              <StyledNoSearchResultsWrapper>
                <EmptyState
                  Icon={DinoDetective}
                  title={t('TASK_LIST.NO_SEARCH_RESULTS')}
                  subtitle=""
                  action={resetSearchHandler}
                  actionText={t('TASK_LIST.START_NEW_SEARCH')}
                />
                <StyledNoSearchResultsNote>
                  <b>{t('TASK_LIST.NOTE')}</b>: {t('TASK_LIST.NOT_FOUND_NOTE')}
                </StyledNoSearchResultsNote>
              </StyledNoSearchResultsWrapper>
            );
          if (searchIsActive) return null;
          if (tasks.data && tasks.data.length === 0) {
            if (filter === 'APPROVED' || filter === 'REJECTED') {
              return (
                <EmptyState
                  {...emptyIconForStatus(filter)}
                  title={t(`TASK_LIST.EMPTY_${filter}_REQUESTS_TITLE`)}
                  subtitle={t(`TASK_LIST.EMPTY_${filter}_REQUESTS_SUBTITLE`)}
                />
              );
            }
            return <EmptyPendingList />;
          }
        }
        return t('TASK_LIST.UNKOWN_ERROR');
      })()}
    </StyledWarningWrapper>
  );
};
