import React from 'react';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import { getTasksOverrides } from 'store/tasks/tasks.selectors';
import { StatusBar } from '../StatusBar';
import { TaskActionButtons } from './TaskActionButtons';

export const TravelAndExpenseActionButtonsContainer = ({ task, ...props }) => {
  const {
    match: {
      params: { taskId },
    },
  } = useReactRouter();
  const overrides = useSelector(getTasksOverrides);
  const override = overrides[taskId];
  if (override && override.updating) {
    return <StatusBar override={override} />;
  }
  if (task.tripDetails.status !== 'PENDING') {
    return <StatusBar status={task.tripDetails.status} />;
  }
  return <TaskActionButtons {...props} />;
};
