import { endpoints } from 'config';
import { secureFetch } from 'utils';
import { preferencesUpdate } from '../user/user.duck';

export const fetchPreferences = async (dispatch, options = {}) => {
  try {
    const response = await secureFetch(endpoints.preferences, options);
    dispatch(
      preferencesUpdate({
        preferences: {
          loading: false,
          ...response[0].data,
        },
      })
    );
  } catch (error) {}
};

export const updatePreferences = async (dispatch, updatedPreferences) => {
  try {
    await secureFetch(
      endpoints.preferences,
      {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        body: JSON.stringify({ approvals: updatedPreferences }),
      },
      true
    );
    fetchPreferences(dispatch);
  } catch (error) {}
};
