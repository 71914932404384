import React from 'react';
import styled, { css } from 'styled-components';
import { sharedCardStyle, StyledCardHeader } from 'styled';
import { KeyValue } from 'components';
import { Other } from 'icons';
import { customTheme } from 'theme/theme';

const StyledArticle = styled.article`
  ${sharedCardStyle}
  margin-top: ${({ theme }) => theme.space[5]}px;
  width: calc(100% - ${({ theme }) => theme.space[3] * 2}px);
  margin-left: ${({ theme }) => theme.space[3]}px;
  margin-right: ${({ theme }) => theme.space[3]}px;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;

const StyledIconWrapper = styled.div`
  width: 70px;
  height: 70px;
  ${({ dark }) =>
    dark
      ? css`
          background-color: ${({ theme }) => theme.colors.blue[6]};
        `
      : css`
          background-color: ${({ theme }) => theme.colors.tertiary};
        `}
  border-radius: 50%;
  margin-top: -${({ theme }) => theme.space[4]}px;
  .dynamicIcon {
    * {
      fill: white;
      @media (prefers-color-scheme: dark) {
        fill: ${({ theme }) => theme.colors.black} !important;
      }
    }
    width: 100%;
    height: 100%;
  }
`;

export const TripDetailsCard = ({
  dark,
  Icon = Other,
  keyValueProps,
  children,
  className,
}) => {
  return (
    <StyledArticle className={className}>
      <StyledCardHeader>
        <StyledIconWrapper dark={dark}>
          <Icon className="dynamicIcon" />
        </StyledIconWrapper>
        <KeyValue
          textAlign="right"
          valueTextBold
          valueTextBig
          valueTextColor={
            dark ? customTheme.colors.blue[6] : customTheme.colors.tertiary
          }
          {...keyValueProps}
        />
      </StyledCardHeader>
      {children}
    </StyledArticle>
  );
};
