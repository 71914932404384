import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from './Button';

export const ToHomePageButton = ({ className, ...props }) => {
  const { t } = useTranslation();
  return (
    <Link className={className} to="/">
      <Button {...props}>{t('BUTTON.BACK_TO_HOMEPAGE')}</Button>
    </Link>
  );
};
