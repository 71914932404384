import React from 'react';
import { CopyPaste } from 'icons';
import styled from 'styled-components';
import { Pill, RoundIconButton, Textarea } from 'components';
import { useObjectTypeInViewSpecificCode } from 'hooks';
import { getShortPurchaseRequestItemId } from 'utils';

const StyledWrapper = styled.div`
  margin-top: ${({ theme }) => theme.space[4]}px;
  padding-bottom: ${({ theme }) => theme.space[4]}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

const StyledIconButton = styled(RoundIconButton)`
  margin-top: ${({ theme }) => theme.space[3]}px;
`;

const StyledPill = styled(Pill)`
  display: inline;
  position: relative;
  top: -1px;
  left: ${({ theme }) => theme.space[2]}px;
`;

const StyledCopyPaste = styled(CopyPaste)`
  width: 18px;
`;

export const TaskListItemComment = ({
  overrideComment,
  submitCopyComment,
  t,
  task,
  textAreaRef,
  mandatoryError,
  setMandatoryError,
  index,
}) => (
  <StyledWrapper>
    <Textarea
      required
      showRequiredInTitle={false}
      overrideValue={overrideComment}
      textAreaRef={textAreaRef}
      mandatoryError={mandatoryError}
      setMandatoryError={setMandatoryError}
      hint={t(`TASK_DETAIL_CONFIRM.MOTIVATION_LONG.REJECTED`)}
      dataCy="taskDetailConfirmTextArea"
      title={useObjectTypeInViewSpecificCode({
        travelAndExpenseCallback: () =>
          t('TASK_LIST.TRAVEL_AND_EXPENSES.TITLE_SELECTABLE', {
            id: task.id,
          }),
        purchaseRequestCallback: () => (
          <>
            {task.title}
            <StyledPill
              outlined
              label={getShortPurchaseRequestItemId(task.id)}
            />
          </>
        ),
      })}
    />
    {index === 0 ? (
      <form data-cy="duplicateCommentForm" onSubmit={submitCopyComment}>
        <StyledIconButton
          Icon={StyledCopyPaste}
          label={t('MULTIPLE_TASKS_CONFIRM.BUTTON_COPY_LABEL')}
        />
      </form>
    ) : null}
  </StyledWrapper>
);
