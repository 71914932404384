import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';
import { updatePreferences } from 'store/preferences/preferences.service';
import {
  oneSignalSetIdDelay,
  useSubscribedToPushNotifications,
} from './oneSignal';
import { useUserPreferences } from './user';

let alreadyChecked = false;

export const useShowNotificationReminder = () => {
  const {
    history,
    location: { pathname },
  } = useReactRouter();
  const dispatch = useDispatch();
  const userPreferences = useUserPreferences();
  const subscribedToPushNotifications = useSubscribedToPushNotifications();
  useEffect(() => {
    if (
      !userPreferences.loading &&
      !alreadyChecked &&
      userPreferences?.approvals?.metrics
    ) {
      const timer = setTimeout(() => {
        const {
          approvals: {
            metrics: { amountOfLogins, showedNotificationReminders },
          },
        } = userPreferences;
        const shouldShowPopup =
          !subscribedToPushNotifications &&
          showedNotificationReminders?.length < 3 &&
          amountOfLogins > 0 &&
          amountOfLogins % 4 === 0;
        alreadyChecked = true;
        if (shouldShowPopup) {
          const updatedPreferences = {
            ...userPreferences.approvals,
            metrics: {
              ...userPreferences.approvals.metrics,
              showedNotificationReminders: [
                ...showedNotificationReminders,
                new Date(),
              ],
            },
          };
          updatePreferences(dispatch, updatedPreferences);
          history.push(`${pathname}?showNotificationReminder=true`);
        }
      }, [oneSignalSetIdDelay]);
      return () => {
        clearTimeout(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferences, subscribedToPushNotifications]);
};
