import React from 'react';
import { TaskDetailConfirmTemplate } from 'components';

export const TaskDetailConfirmPage = ({
  t,
  submitProps,
  textAreaProps,
  previousRoute,
  ...props
}) => (
  <TaskDetailConfirmTemplate
    cancelProps={{ to: previousRoute, text: t('COMMON.CANCEL') }}
    submitProps={{
      filled: true,
      text: t('COMMON.SUBMIT'),
      ...submitProps,
    }}
    headerProps={{
      title: t('TASK_DETAIL_CONFIRM.TITLE'),
      to: previousRoute,
      search: false,
      onlyMobile: false,
      emptyDesktop: true,
      detailDesktopBackgroundColor: true,
    }}
    textAreaProps={{
      title: t('TASK_DETAIL_CONFIRM.LEAVE_A_COMMENT'),
      hint: t('TASK_DETAIL_CONFIRM.MOTIVATION_LONG'),
      dataCy: 'taskDetailConfirmTextArea',
      ...textAreaProps,
    }}
    {...props}
  />
);
