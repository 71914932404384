const DEFAULT_SORTING_FIELD = 'requestDate';
const DEFAULT_SORTING_DIRECTION = 'DESC';

export const getSortingField = selectedSorting => {
  return selectedSorting.split(' ')[0] || DEFAULT_SORTING_FIELD;
};

export const getSortingDirection = selectedSorting => {
  return selectedSorting.split(' ')[1] || DEFAULT_SORTING_DIRECTION;
};

const defaultSortOptions = t => ({
  'requestDate DESC': `${t('SORTING_FIELDS.SORT_BY')} ${t(
    'SORTING_FIELDS.CREATED_AT_DESC'
  )}`,
  'requestDate ASC': `${t('SORTING_FIELDS.SORT_BY')} ${t(
    'SORTING_FIELDS.CREATED_AT_ASC'
  )}`,
});

const sortOptions = t => ({
  purchaseRequest: {
    ...defaultSortOptions(t),
    'amount DESC': `${t('SORTING_FIELDS.SORT_BY')} ${t(
      'SORTING_FIELDS.AMOUNT_DESC'
    )}`,
    'amount ASC': `${t('SORTING_FIELDS.SORT_BY')} ${t(
      'SORTING_FIELDS.AMOUNT_ASC'
    )}`,
  },
  travelAndExpense: {
    ...defaultSortOptions(t),
  },
});

export const getSortingOptionsForTaskType = (taskType, t) => {
  return sortOptions(t)[taskType];
};
