import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BrokenRocket, DinoDetective } from 'icons';
import useReactRouter from 'use-react-router';
import { EmptyState } from 'components';
import {
  DEFAULT_PURCHASE_REQUEST_OBJECT,
  DEFAULT_TRAVEL_AND_EXPENSE_REQUEST,
  endpoints,
  OBJECT_TYPES,
  routes,
} from 'config';
import {
  useFetchTasksLineItems,
  useRefetchParameters,
  useResetScrollPosition,
  useSetDocumentTitle,
} from 'hooks';
import { getTasksOverrides } from 'store/tasks/tasks.selectors';
import { updateStatusTask } from 'store/tasks/tasks.service';
import {
  confirm,
  defineTasksToUpdate,
  executeRegularOrAribaPurchaseRequestLogic,
  getConstantCaseTypeFromSection,
  getObjectTypeFromSection,
  getTravelAndExpenseIds,
  injectRouteParameter,
  reportToSentryIfSumOfReceiptsIsNotEqualToTotalAmount,
  secureFetch,
  selectActiveCostAssignment,
} from 'utils';
import AribaDetailPage from './AribaDetailPage';
import PurchaseRequestDetailPage from './PurchaseRequestDetailPage';
import TravelAndExpenseDetailPage from './TravelAndExpenseDetailPage';

const fetchTask = async (taskId, taskType, setTask, setLoading, setError) => {
  setLoading(true);
  const url = (() => {
    if (taskType === OBJECT_TYPES.TRAVEL_AND_EXPENSE) {
      const { id, employeeNumber } = getTravelAndExpenseIds(taskId);
      return `${endpoints.travelAndExpenses}/${id}?employeeNumber=${employeeNumber}`;
    }
    return `${endpoints.purchaseRequests}/${taskId}`;
  })();

  try {
    const json = await secureFetch(url);
    if (taskType === OBJECT_TYPES.PURCHASE_REQUEST) {
      setTask(json[0]);
    }
    if (taskType === OBJECT_TYPES.TRAVEL_AND_EXPENSE) {
      setTask(json);
    }
    setLoading(false);
    setError(false);
  } catch (error) {
    setError(error);
  }
};

export const TaskDetailPageContainer = ({ ...props }) => {
  useResetScrollPosition();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    match: {
      params: { taskId, section, subsection },
    },
    location: { pathname },
  } = useReactRouter();

  const objectTypeInView = getObjectTypeFromSection(section);

  let defaultTask = {};

  switch (objectTypeInView) {
    case OBJECT_TYPES.TRAVEL_AND_EXPENSE:
      defaultTask = DEFAULT_TRAVEL_AND_EXPENSE_REQUEST;
      break;
    case OBJECT_TYPES.PURCHASE_REQUEST:
      defaultTask = DEFAULT_PURCHASE_REQUEST_OBJECT;
      break;
    default:
      defaultTask = DEFAULT_PURCHASE_REQUEST_OBJECT;
  }
  const overrides = useSelector(getTasksOverrides);

  const [task, setTask] = useState(defaultTask);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const override = overrides[taskId];
  const shouldRefetch = (() => {
    if (override && override.notify && override.success && !override.updating) {
      return true;
    }
    return false;
  })();
  const overrideMessage = override ? override.message : null;

  useFetchTasksLineItems(objectTypeInView);
  useEffect(() => {
    if (overrideMessage) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [overrideMessage]);
  useEffect(() => {
    fetchTask(taskId, objectTypeInView, setTask, setLoading, setError);
  }, [taskId, shouldRefetch, objectTypeInView]);
  const refetchParameters = useRefetchParameters();
  const onSubmitApprove = event => {
    event.preventDefault();
    confirm({
      message: t('COMMON.ACTION_THIS_REQUEST', {
        action: t(`COMMON.APPROVE`),
      }),
      succesCallback: () => {
        updateStatusTask(
          dispatch,
          'APPROVED',
          defineTasksToUpdate(task, taskId, objectTypeInView),
          [''],
          { ...refetchParameters, taskId },
          objectTypeInView
        );
      },
      confirmText: t('DIALOG.CONFIRM_APPROVE'),
      cancelText: t('DIALOG.NO'),
      dispatch,
    });
  };

  useEffect(() => {
    reportToSentryIfSumOfReceiptsIsNotEqualToTotalAmount(task);
  }, [task]);

  const costAssignmentData = selectActiveCostAssignment(task);

  const previousPath = injectRouteParameter(routes.taskList, {
    section,
  });
  useSetDocumentTitle(task && task.title ? task.title : null);
  if (error) {
    return (
      <EmptyState
        Icon={BrokenRocket}
        title={t('COMMON.500_TITLE')}
        subtitle={t('COMMON.500_SUBTITLE')}
        headerProps={{ to: previousPath }}
      />
    );
  }
  const commonChildProps = {
    ...props,
    approveProps: { onSubmit: onSubmitApprove },
    pathname,
    previousPath,
    section,
    task,
    t,
    costAssignmentData,
    loading,
    override,
    contactPanelActive: subsection,
  };
  if (task && Object.keys(task).length !== 0) {
    if (objectTypeInView === OBJECT_TYPES.PURCHASE_REQUEST) {
      return executeRegularOrAribaPurchaseRequestLogic({
        task,
        regularPurhcaseRequestCallback: () => (
          <PurchaseRequestDetailPage {...commonChildProps} />
        ),
        aribaCallback: () => <AribaDetailPage {...commonChildProps} />,
      });
    }
    if (objectTypeInView === OBJECT_TYPES.TRAVEL_AND_EXPENSE) {
      return <TravelAndExpenseDetailPage {...commonChildProps} />;
    }
  }

  return (
    <EmptyState
      Icon={DinoDetective}
      title={t('COMMON.NOT_FOUND_TITLE')}
      subtitle={t(
        `TASK_DETAILS.NOT_FOUND_${getConstantCaseTypeFromSection(
          section
        )}_DETAILS`,
        { taskId }
      )}
      headerProps={{ to: previousPath }}
      data-cy="notFoundDetail"
    />
  );
};

export default TaskDetailPageContainer;
