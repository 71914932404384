import { createActions, handleActions } from 'redux-actions';

export const initialStateOfStatus = {
  data: [],
  error: null,
  loading: false,
  initialState: true,
  pageNumber: 0,
  loadingMoreTasks: false,
  allDataFetched: false,
};

const initialState = {
  pendingCount: undefined,
  loadingPendingCount: true,
  PENDING: initialStateOfStatus,
  APPROVED: initialStateOfStatus,
  REJECTED: initialStateOfStatus,
  SEARCH: initialStateOfStatus,
  EMPLOYEE_ITEMS: {
    ...initialStateOfStatus,
    selected: {},
    employeeNumber: null,
    pageNumber: null,
    allDataFetched: true,
  },
  overrides: {},
};

const TRAVEL_AND_EXPENSES_UPDATE = 'TRAVEL_AND_EXPENSES_UPDATE';

export const { travelAndExpensesUpdate } = createActions(
  TRAVEL_AND_EXPENSES_UPDATE
);

export default handleActions(
  {
    [TRAVEL_AND_EXPENSES_UPDATE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  initialState
);
