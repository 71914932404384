import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import { endpoints } from 'config';
import {
  getFullName,
  getObjectTypeFromSection,
  mataHari,
  secureFetch,
} from 'utils';
import { ContactPage } from './ContactPage';

export const ContactPageContainer = ({ task }) => {
  const {
    match: {
      params: { section, subsectionId: contactId, subsection },
    },
  } = useReactRouter();
  const [contact, setContact] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const objectTypeInView = getObjectTypeFromSection(section);
  useEffect(() => {
    if (contactId && subsection === 'contact') {
      setLoading(true);
      mataHari.uxSnitch('contactPanelOpened');
      (async () => {
        setError(false);
        const url = `${endpoints.contact}/${contactId}`;
        try {
          const json = await secureFetch(url);
          setContact(json);
          setLoading(false);
        } catch (e) {
          setError(e);
        }
      })();
    }
  }, [contactId, subsection]);
  const fullName = getFullName(contact.profile);
  const { t } = useTranslation();

  return (
    <ContactPage
      fullName={fullName}
      contact={contact}
      contactId={contactId}
      loading={loading}
      error={error}
      task={task}
      active={contactId && subsection === 'contact'}
      t={t}
      objectTypeInView={objectTypeInView}
    />
  );
};
