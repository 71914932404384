import React from 'react';
import { useTranslation } from 'react-i18next';
import { IS_MICROSOFT_APP, IOS_VERSION } from 'utils';
import { RefreshButton } from './RefreshButton';

export const RefreshButtonContainer = ({ ...props }) => {
  const { t } = useTranslation();
  if (IS_MICROSOFT_APP || IOS_VERSION) {
    return (
      <RefreshButton
        onClick={() => window.location.reload()}
        t={t}
        {...props}
      />
    );
  }
  return null;
};
