/* eslint-disable radix */
import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import { isLocalDevelopmentWithProductionData } from 'utils';

export const StyledWrapper = styled.main`
  padding: ${({ theme }) => theme.space[4]}px ${({ theme }) => theme.space[3]}px;
`;

export const sectionItemWrapperStyle = css`
  padding: 0px ${({ theme }) => theme.space[4]}px;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    padding: 0;
  }
`;

const sharedBottomStyle = css`
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  background-color: white;
  box-shadow: 0px -4px 3px white;
  @media (prefers-color-scheme: dark) {
    background-color: ${({ theme }) => theme.colors.black};
    box-shadow: 0px -4px 3px ${({ theme }) => theme.colors.black};
  }
  margin: auto;
  z-index: 2;
`;

export const BottomStatusWrapper = styled.div`
  height: 50px;
  ${sharedBottomStyle}

  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    height: 80px;
    width: calc(100% - 520px - ${({ theme }) => theme.space[7]}px * 2);
    max-width: calc(1000px - ${({ theme }) => theme.space[7]}px * 2);
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[5]}) {
    width: calc(100% - 700px - 92px * 3);
    max-width: calc(1000px - 92px * 2);
  }

  @media print {
    display: none;
  }
`;

export const underIOSStatusBar = css`
  @media only screen and (max-width: ${({ theme }) => theme.breakPoints[3]}) {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  }
`;

export const BottomButtonWrapper = styled.div`
  ${sharedBottomStyle}
  box-shadow: 0px -4px 3px white;
  button {
    ${() =>
      isLocalDevelopmentWithProductionData
        ? css`
            background-color: ${({ theme }) => theme.colors.red[5]};
            border: none;
            color: white;
          `
        : ''}

    width: 50vw;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    padding: ${({ theme }) => theme.space[3]}px 0px;
    width: calc(100% - 520px - 56px * 2);
    max-width: calc(1000px - ${({ theme }) => theme.space[7]}px * 2);
    & > a:first-child,
    & > a:nth-child(2),
    & > form:nth-child(2),
    & > button:nth-child(2) {
      width: calc(50% - ${({ theme }) => theme.space[3]}px);
    }
    button {
      width: 100%;
    }
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[4]}) {
    width: calc(100% - 520px - 48px * 2);
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[5]}) {
    padding: ${({ theme }) => theme.space[4]}px 0px;
    width: calc(
      100% - 698px - 92px * 3
    ); /* not a round number because of 1px borders */
    max-width: calc(1000px - 92px * 2);
  }
  @media print {
    display: none;
  }
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
`;

export const ListFilterWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const progress = keyframes`
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
      transform: translate3d(100%, 0, 0);
  }
`;

// https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
function shadeColor(color, percent) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR =
    R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

  return `#${RR}${GG}${BB}`;
}

const defaultLighter = 0;
export const StyledPlaceholder = styled.span`
  display: block;
  position: relative;
  overflow: hidden;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin-top: ${({ marginTop }) => marginTop};
  background-color: ${({ theme, color }) =>
    shadeColor(theme.colors[color], defaultLighter)};
  opacity: 0.8;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      ${({ theme, color }) => shadeColor(theme.colors[color], defaultLighter)},
      ${({ theme, color }) =>
        shadeColor(theme.colors[color], defaultLighter + 30)},
      ${({ theme, color }) => shadeColor(theme.colors[color], defaultLighter)}
    );
    animation: ${progress} 1.5s ease infinite;
  }
`;

export const resetButtonLayout = css`
  background-color: transparent;
  border: none;
  &:focus {
    outline: none;
    background-color: ${({ theme }) => theme.colors.focus};
    @media (prefers-color-scheme: dark) {
      background-color: ${({ theme }) => theme.colors.focusDark};
    }
  }
`;

export const StyledBackdrop = styled(Link)`
  display: none;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 6;
  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    z-index: 7;
  }
  background-color: black;
  @media (prefers-color-scheme: dark) {
    background-color: white;
  }
  transition-property: opacity;
  transition-duration: ${({ timing = '0.5s' }) => timing};
  ${({ active }) =>
    active
      ? css`
          opacity: 0.3;
        `
      : css`
          pointer-events: none;
        `}
  ${({ mobile }) =>
    mobile
      ? css`
          display: block;
        `
      : css`
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[3]}) {
            display: block;
          }
        `}
  &:focus, visited {
    outline: none;
  }
`;

export const StyledSidePanel = styled.div`
  z-index: 9;
  background-color: white;
  @media (prefers-color-scheme: dark) {
    background-color: ${({ theme }) => theme.colors.black};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakPoints[3]}) {
    ${({ active }) =>
      active
        ? css`
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
          `
        : css`
            // visibility: hidden;
            display: none;
          `}
  }
`;
export const StyledTaskDetailContentWrapper = styled.div`
  position: relative;
  padding-bottom: 73px; /* height of approve reject */
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[5]}) {
    padding-bottom: 97px; /* height of approve reject */
  }
  ${({ mobileSideMargin }) =>
    mobileSideMargin
      ? css`
          padding-left: ${({ theme }) => theme.space[3]}px;
          padding-right: ${({ theme }) => theme.space[3]}px;
          ${BottomButtonWrapper} {
            margin-left: -${({ theme }) => theme.space[3]}px;
            @media only screen and (min-width: ${({ theme }) =>
                theme.breakPoints[3]}) {
              margin-left: 0;
            }
          }
        `
      : ''}
  ${({ topMargin }) =>
    topMargin
      ? css`
          padding-top: ${({ theme }) => theme.space[3]}px;
        `
      : ''}
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    padding-left: ${({ theme }) => theme.space[7]}px;
    padding-right: ${({ theme }) => theme.space[7]}px;
    max-width: 1000px;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[5]}) {
    padding-left: 92px;
    padding-right: 92px;
  }
`;

export const StyledSinglePageContentWrapper = styled(
  StyledTaskDetailContentWrapper
)`
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    margin-top: 64px;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[5]}) {
    margin-left: calc(-92px + 16px + ${({ theme }) => theme.space[13]}px);
  }
`;

export const StyledSeperator = styled.hr`
  border: none;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray};
  margin-top: 3px;
  margin-bottom: 3px;
  transition: all 0.5s;
  transition-delay: 0.45s;
  ${({ marginRight }) =>
    marginRight
      ? css`
          width: calc(100% - ${({ theme }) => theme.space[4]}px);
        `
      : css`
          width: 100%;
        `}
  ${({ confirmed }) =>
    confirmed
      ? css`
          height: 0;
          margin-top: 0;
          margin-bottom: 0;
        `
      : ''}
`;

export const subtitleStyle = css`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  font-weight: normal;
`;

export const onlyMobileStyle = css`
  ${({ onlyMobile }) =>
    onlyMobile
      ? css`
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[3]}) {
            display: none;
          }
        `
      : ''}
`;

export const boxShadow = css`
  box-shadow: 0 0 8px 0 rgba(178, 175, 175, 0.5);
`;

export const sharedCardStyle = css`
  background-color: transparent;
  ${boxShadow};
  border-radius: 6px;
`;

export const borderTop = css`
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
  @media (prefers-color-scheme: dark) {
    border-top: 1px solid ${({ theme }) => theme.colors.lighterBlack};
  }
`;

export const borderBottom = css`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  @media (prefers-color-scheme: dark) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lighterBlack};
  }
`;

export const StyledCardHeader = styled.header`
  display: flex;
  padding: 10px ${({ theme }) => theme.space[4]}px;
  ${borderBottom}
  align-items: center;
  justify-content: space-between;
`;

export const detailPageMobilePadding = css`
  padding-left: ${({ theme }) => theme.space[4]}px;
  padding-right: ${({ theme }) => theme.space[4]}px;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const centerAbsolutePositionStyle = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export const StyledSectionTitle = styled.h3`
  ${subtitleStyle}
  ${({ bold }) =>
    bold === true
      ? css`
          font-weight: bold;
          font-size: 20px;
        `
      : css``}

  ${({ sticky }) =>
    sticky === true
      ? css`
          position: sticky;
        `
      : ''}
  top: 57px; /* height header */

  ${({ caseSensitiveTitle }) =>
    caseSensitiveTitle === true
      ? ``
      : css`
          text-transform: uppercase;
        `}
  padding-top: ${({ theme }) => theme.space[4]}px;
  ${detailPageMobilePadding}
  margin-bottom: 20px;
  background-color: white;
  box-shadow: 0px 4px 3px white;
  @media (prefers-color-scheme: dark) {
    background-color: ${({ theme }) => theme.colors.black};
    box-shadow: 0px 4px 3px ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.darkerWhite};
  }
  z-index: 1;
`;

export const StyledSection = styled.li`
  top: 0;
  @media only screen and (max-width: ${({ theme }) => theme.breakPoints[3]}) {
    max-width: 750px;
  }
  ${borderTop}

  ${({ focused }) =>
    focused === true
      ? css`
          border-top-color: ${({ theme }) => theme.colors.tertiary};
        `
      : css``}
&:first-of-type {
    margin-top: 0;
    border-top: none;
    ${StyledSectionTitle} {
      margin-top: 0px;
      padding-top: calc(
        ${({ theme }) => theme.space[4]}px - 5px
      ); /* align with tabs */
    }
  }
  @media print {
    border-top: none;
    page-break-inside: avoid;
  }
`;

export const StyledIframe = styled.iframe`
  height: 100%;
  width: 100%;
  border: none;
`;

export const linkAttentionStyle = css`
  color: ${({ theme }) => theme.colors.tertiary};
  font-weight: ${({ fontWeight }) => fontWeight};
  text-decoration: underline;
  line-height: ${({ theme }) => theme.lineHeights[1]}px;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
`;
