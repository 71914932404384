import { useTranslation } from 'react-i18next';
import { OBJECT_TYPES } from 'config';
import {
  fixSAPFormatting,
  getPurchaseRequestId,
  getShortHumanReadableDateForContacts,
  getShortPurchaseRequestItemId,
  shortTime,
  toPriceNumber,
} from 'utils';

const fixDescription = description => {
  if (!description) return description;
  let convertedDescription = `%0A${fixSAPFormatting(description, '%0A')}`;
  const characterLimit = 500;
  convertedDescription =
    convertedDescription.length > characterLimit
      ? `${convertedDescription.slice(0, characterLimit)}...`
      : convertedDescription;
  return convertedDescription;
};

const nullToDataNotAvailable = (inputObject, t) => {
  Object.keys(inputObject).forEach(key => {
    if (!inputObject[key]) {
      inputObject[key] = t('KEY_VALUE.DATA_NOT_AVAILABLE');
    }
  });
  return inputObject;
};

export const useEmailBody = (task, contactName, objectTypeInView) => {
  const { t } = useTranslation();

  if (objectTypeInView === OBJECT_TYPES.PURCHASE_REQUEST) {
    if (task && task.requestor && contactName) {
      const {
        title,
        id,
        description,
        requestor: { firstName, lastName },
        amount,
      } = task;
      const convertedAmount = toPriceNumber(amount);

      return `body=${t(
        'CONTACT_DETAILS.PURCHASE_REQUEST_MAIL_BODY',
        nullToDataNotAvailable(
          {
            contactName,
            id: getPurchaseRequestId(id),
            itemId: getShortPurchaseRequestItemId(id),
            title,
            description: fixDescription(description),
            firstName,
            lastName,
            amount: convertedAmount,
          },
          t
        )
      )}`;
    }
  }
  if (objectTypeInView === OBJECT_TYPES.TRAVEL_AND_EXPENSE) {
    const {
      arrivalDate,
      arrivalTime,
      departureDate,
      departureTime,
      location,
      reason,
      amountReimbursed,
      currency,
    } = task.tripDetails;

    return `body=${t(
      'CONTACT_DETAILS.TRAVEL_AND_EXPENSE_MAIL_BODY',
      nullToDataNotAvailable(
        {
          id: task?.tripDetails?.tripNumber,
          startDate: getShortHumanReadableDateForContacts(departureDate),
          startTime: shortTime(departureTime),
          endDate: getShortHumanReadableDateForContacts(arrivalDate),
          endTime: shortTime(arrivalTime),
          location,
          reason,
          amountReimbursed: toPriceNumber({
            amount: amountReimbursed,
            currency,
          }),
        },
        t
      )
    )}`;
  }
};

export const useEmailSubject = (task, objectTypeInView) => {
  const { t } = useTranslation();
  const { id, title } = task;

  if (objectTypeInView === OBJECT_TYPES.PURCHASE_REQUEST) {
    return `subject=${t('CONTACT_DETAILS.PURCHASE_REQUEST_MAIL_SUBJECT', {
      id: getPurchaseRequestId(id),
      title,
    })}`;
  }
  if (objectTypeInView === OBJECT_TYPES.TRAVEL_AND_EXPENSE) {
    return `subject=${t('CONTACT_DETAILS.TRAVEL_AND_EXPENSE_MAIL_SUBJECT', {
      id: task?.tripDetails?.tripNumber,
    })}`;
  }
};
