import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { endpoints } from 'config';
import {
  hasActiveBrowserSession,
  logSentryError,
  redirectWebflow,
} from 'utils';
import { PreLoginPage } from './PreLoginPage';

export const PreLoginPageContainer = ({ MainPage, ...props }) => {
  const { t } = useTranslation();
  const [isBlockedWhileLoggingIn, setIsBlockedWhileLoggingIn] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authCheckResolved, setAuthCheckResolved] = useState(false);

  useEffect(() => {
    if (
      !isLoggedIn &&
      !window.location.href.includes(endpoints.oauth) &&
      authCheckResolved
    ) {
      redirectWebflow();
    }
    hasActiveBrowserSession().then(result => {
      setIsLoggedIn(result);
      setAuthCheckResolved(true);
    });
  }, [authCheckResolved, isLoggedIn]);

  useEffect(() => {
    const time = 8000;
    const reportNotLoggedIn = setTimeout(() => {
      logSentryError(
        `A user was waiting to be logged in for more than ${time} milliseconds`
      );
      setIsBlockedWhileLoggingIn(true);
    }, time);
    if (isLoggedIn) {
      clearTimeout(reportNotLoggedIn);
    }
    return () => clearTimeout(reportNotLoggedIn);
  }, [isLoggedIn]);

  // Passing a component rather than children will avoid an unnecessary render.
  // If this render contains logic that should not be executed upfront, then this is a better way
  // Ideally this should be part of the architecture and you should not be able to reach the application without being logged in (e.g. NGINX Proxy)
  return isLoggedIn ? (
    <MainPage />
  ) : (
    <PreLoginPage {...{ isBlockedWhileLoggingIn, t, ...props }} />
  );
};
