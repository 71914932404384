import React from 'react';
import { useTranslation } from 'react-i18next';
import { filterPurchaseOrderInAribaApprovalSequence } from 'utils';
import { Metroline } from './Metroline';

export const MetrolineAribaPurchaseRequestsContainer = ({
  task,
  displayedOnLineItem,
}) => {
  const { approvalSequence, status, ...props } = task;
  const { t } = useTranslation();
  return (
    <Metroline
      {...props}
      taskStatus={status}
      approvalSequence={filterPurchaseOrderInAribaApprovalSequence({
        approvalSequence,
        displayedOnLineItem,
      })}
      showRejectionReason={false}
      showRequestor={!displayedOnLineItem}
      t={t}
    />
  );
};
