import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const StyledInitials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.radius};
  height: ${props => props.radius};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  background-color: white;
`;

const shake = keyframes`
  0% {
    right: 0;
  }
  50% {
    right: 30px;
  }
  100% {
    right: 0px;
  }
`;

const StyledUserButton = styled.button`
  position: relative;
  display: flex;
  z-index: 3;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  border: none;
  background-color: transparent;
  &:focus {
    outline: none;
    ${StyledInitials} {
      background-color: ${({ theme }) => theme.colors.tertiary};
      color: white;
      border: 1px solid ${({ theme }) => theme.colors.tertiary};
    }
  }
  ${({ collapsed, index }) =>
    collapsed && index !== 0
      ? css`
          animation: ${shake} 0.4s ease;
          animation-delay: ${0.05 * index}s;
        `
      : ''}
`;

const appear = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StyledFullName = styled.div`
  color: white;
  margin-right: ${({ theme }) => theme.space[2]}px;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  text-align: right;
  opacity: 0;
  display: none;
  ${({ collapsed, index }) =>
    collapsed
      ? css`
          animation: ${appear} 0.5s ease;
          animation-delay: ${0.1 * index}s;
          animation-fill-mode: forwards;
          display: block;
        `
      : ''};
`;

const StyledSelectionWrapper = styled.div`
  position: fixed;
  background-color: rgba(0, 93, 154, 0.9);
  width: 100%;
  left: 0;
  top: 0;
  height: 0;
  overflow: hidden;
  transition: height 0.5s, opacity 0.5s;
  z-index: 2;
  opacity: 0.5;
  ${({ collapsed, accountsLength }) =>
    collapsed
      ? css`
          height: ${accountsLength * 50 + 15}px;
          opacity: 1;
        `
      : ''};
`;

const StyledBlackBackground = styled.div`
  position: fixed;
  z-index: 1;
  background-color: black;
  opacity: 0;
  top: 0;
  left: 0;
  transition: opacity 0.3s;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
  ${({ collapsed }) =>
    collapsed
      ? css`
          opacity: 0.4;
          pointer-events: auto;
        `
      : ''};
`;

const StyledUserUl = styled.ul`
  position: absolute;
  right: ${({ theme }) => theme.space[3]}px;
  top: ${({ theme }) => theme.space[3]}px;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    display: none;
  }
  @media print {
    display: none;
  }
`;

const StyledUserLi = styled.li`
  position: relative;
  margin-top: -40px;
  transition: margin-top 0.3s;
  transition-delay: 0.1s;
  ${({ isSelectedAccount }) => {
    if (isSelectedAccount) {
      return css`
        z-index: 4;
      `;
    }
  }};
  ${({ collapsed }) =>
    collapsed
      ? css`
          margin-top: 10px;
        `
      : ''};
  &:first-of-type {
    margin-top: 0px;
  }
`;

const Account = ({
  data: { initials, name, id },
  chooseUser,
  collapsed,
  index,
  selectedAccount,
}) => (
  <StyledUserLi
    isSelectedAccount={selectedAccount === id}
    collapsed={collapsed}
  >
    <form onSubmit={event => chooseUser(event, id)}>
      <StyledUserButton index={index} collapsed={collapsed}>
        <StyledFullName index={index} collapsed={collapsed}>
          {name}
        </StyledFullName>
        <StyledInitials radius="40px">{initials}</StyledInitials>
      </StyledUserButton>
    </form>
  </StyledUserLi>
);

export const SwitchAccount = ({
  data,
  collapsed,
  chooseUser,
  close,
  selectedAccount,
}) => (
  <>
    <StyledUserUl>
      {data.map((account, index) => (
        <Account
          collapsed={collapsed}
          chooseUser={chooseUser}
          data={account}
          key={index}
          index={index}
          selectedAccount={selectedAccount}
        />
      ))}
    </StyledUserUl>
    <StyledSelectionWrapper
      accountsLength={data.length}
      collapsed={collapsed}
    />
    <StyledBlackBackground onClick={close} collapsed={collapsed} />
  </>
);
