import * as React from 'react';
import { Loader } from 'icons';
import styled, { css } from 'styled-components';

const StyledContentWrapper = styled.div`
  position: absolute;
  top: 40vh;
  left: calc(50% - 45px);
`;

const StyledLoader = styled(Loader)`
  width: 90px;
  height: 60px;
  padding-bottom: ${({ theme }) => theme.space[5]}px;
  * {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledLoadingOverlay = styled.div`
  z-index: 3;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  ${({ transparentBackground }) =>
    transparentBackground
      ? css`
          background-color: rgba(255, 255, 255, 0.7);
        `
      : css`
          background-color: white;
        `}
`;

export const LoadingOverlay = ({ transparentBackground = true }) => (
  <StyledLoadingOverlay transparentBackground={transparentBackground}>
    <StyledContentWrapper>
      <StyledLoader />
    </StyledContentWrapper>
  </StyledLoadingOverlay>
);
