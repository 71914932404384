import { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'utils';

export const useMobile = () => {
  const [mobile, setMobile] = useState(isMobile());
  const handleResize = useCallback(() => {
    if (!isMobile() && mobile) {
      setMobile(false);
    } else if (isMobile() && !mobile) {
      setMobile(true);
    }
  }, [mobile]);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);
  return mobile;
};
