import React from 'react';
import styled, { keyframes } from 'styled-components';

const StyledStarsWrapper = styled.div`
  width: 97%;
  height: 95%;
  left: 3px;
  overflow: hidden;
  position: absolute;
  border-radius: 50%;
  top: 4px;
`;

const moveStars = keyframes`
	from {
		top: 0px;
	}
  to {
    top: 200px;
  }
`;

const StyledStars = styled.svg`
  position: absolute;
  top: calc(${({ top }) => top}px / 2);
`;

const StyledStarsAnimator = styled.div`
  z-index: 1;
  position: absolute;
  width: 100%;
  left: 0;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: ${moveStars};
  animation-duration: ${({ speed }) => speed * 1.5}s;
`;

const StyledBackground = styled.svg`
  position: absolute;
`;

const StyledWrapper = styled.div`
  position: relative;
`;

export const Stars = ({ children, color, ...props }) => {
  return (
    <StyledWrapper {...props}>
      <StyledBackground
        id="prefix__Layer_1"
        x={0}
        y={0}
        viewBox="0 0 118.7 133"
        xmlSpace="preserve"
      >
        <circle
          cx={58.7}
          cy={58.2}
          r={57}
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#e8f2f9"
        />
        <path
          fill="none"
          stroke="#005D9A"
          strokeWidth="3"
          d="M2.1,46.8C0,63.5,4.9,79.4,14.4,91.7c5.3,6.9,13.8,14.1,21.3,17.5
	 M109.4,87.1c15.7-27.3,6.4-62.1-20.9-77.9C61.3-6.5,26.4,2.9,10.7,30.1"
        />
      </StyledBackground>
      {children}
      <StyledStarsWrapper>
        <StyledStarsAnimator speed="10">
          {[...Array(3).keys()].map(key => (
            <StyledStars
              id="prefix__Layer_2"
              x={0}
              y={0}
              viewBox="0 0 118.7 160"
              xmlSpace="preserve"
              key={key}
              top={-key * 200}
            >
              <circle fill={color} cx={42.3} cy={-25.3} r={3.5} />
              <circle fill={color} cx={24.8} cy={55.8} r={3} />
              <circle fill={color} cx={89.3} cy={22.3} r={2.5} />
              <circle fill={color} cx={69.3} cy={5.3} r={3.5} />
            </StyledStars>
          ))}
        </StyledStarsAnimator>
        <StyledStarsAnimator speed="30">
          {[...Array(3).keys()].map(key => (
            <StyledStars
              id="prefix__Layer_2"
              x={0}
              y={0}
              viewBox="0 0 118.7 160"
              xmlSpace="preserve"
              key={key}
              top={-key * 200}
            >
              <circle fill={color} cx={34.3} cy={34.3} r={1.5} />
              <circle fill={color} cx={53.5} cy={83} r={1.5} />
              <circle fill={color} cx={87.3} cy={56.3} r={1.5} />
              <circle fill={color} cx={15.2} cy={89.3} r={1.5} />
            </StyledStars>
          ))}
        </StyledStarsAnimator>
      </StyledStarsWrapper>
    </StyledWrapper>
  );
};
