import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import useReactRouter from 'use-react-router';
import { useRefetchParameters } from 'hooks';
import { updateStatusTask } from 'store/tasks/tasks.service';
import { getObjectTypeFromSection, IOS_VERSION } from 'utils';
import { SwipeableTaskWrapper } from './SwipeableTaskWrapper';

const IOS_BUFFER_PX = 40;
const SWIPE_BUFFER_SIZE = (() => {
  if (IOS_VERSION < 13) {
    return IOS_BUFFER_PX;
  }
  if (IOS_VERSION >= 13) {
    return 10;
  }
  return 0;
})();

const useDelayedTaskUpdate = (confirmed, task, status) => {
  const dispatch = useDispatch();
  const refetchParameters = useRefetchParameters();

  const {
    match: {
      params: { section },
    },
  } = useReactRouter();

  const objectTypeInView = getObjectTypeFromSection(section);
  useEffect(() => {
    if (confirmed) {
      setTimeout(() => {
        updateStatusTask(
          dispatch,
          status,
          [task],
          [''],
          refetchParameters,
          objectTypeInView
        );
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmed]);
};

export const SwipeableTaskContainer = ({
  status,
  children,
  task,
  override,
}) => {
  const initialSwipingDetails = {
    deltaX: 0,
    open: false,
    initialDirection: null,
  };
  const [confirmedReject, setConfirmedReject] = useState(false);
  const [confirmedApprove, setConfirmedApprove] = useState(false);
  const [swipingDetails, setSwipingDetails] = useState(initialSwipingDetails);

  const { t } = useTranslation();

  const shouldStartSwiping = ({ initial, dir }) => {
    const [swipeStartPosition] = initial;
    if (dir === 'Up' || dir === 'Down') return false;
    if (
      swipeStartPosition < SWIPE_BUFFER_SIZE ||
      swipeStartPosition > window.innerWidth - SWIPE_BUFFER_SIZE
    ) {
      return false;
    }
    return true;
  };

  const delayClose = useCallback(() => {
    setTimeout(() => {
      setSwipingDetails({
        ...swipingDetails,
        initialDirection: null,
        open: false,
      });
    }, 150);
  }, [swipingDetails]);

  const openWidth = 130;
  const handlers = useSwipeable({
    onSwiping: args => {
      if (!shouldStartSwiping(args)) return;
      const details = {
        ...swipingDetails,
      };
      const roundFactor = 5;
      let roundedDeltaX =
        Math.ceil(Math.round(args.absX) / roundFactor) * roundFactor;
      if (details.open && args.dir === 'Left') roundedDeltaX = -roundedDeltaX;
      if (swipingDetails.open) roundedDeltaX += openWidth;

      if (swipingDetails.deltaX !== roundedDeltaX) {
        details.deltaX = roundedDeltaX;
      }
      if (!details.initialDirection) details.initialDirection = args.dir;
      if (JSON.stringify(swipingDetails) !== JSON.stringify(details)) {
        setSwipingDetails(details);
      }
    },
    onSwiped: args => {
      const { absX, dir } = args;
      if (!shouldStartSwiping(args)) return;
      let delta;
      const shouldClose =
        swipingDetails.open &&
        ((swipingDetails.initialDirection === 'Right' && dir === 'Left') ||
          (swipingDetails.initialDirection === 'Left' && dir === 'Right'));
      if (shouldClose) {
        delta = 0;
        delayClose();
      } else {
        delta = absX > 50 ? openWidth : 0;
      }
      setSwipingDetails({
        ...swipingDetails,
        deltaX: delta,
        open: !!delta,
      });
    },
    preventDefaultTouchmoveEvent: false,
    trackMouse: false,
    trackTouch: true,
    delta: 5,
  });
  useDelayedTaskUpdate(confirmedApprove, task, 'APPROVED');
  useDelayedTaskUpdate(confirmedReject, task, 'REJECTED');

  const node = useRef();

  const closeOpenListOnOutsideTouch = useCallback(
    e => {
      if (node && node.current) {
        if (node.current.contains(e.target)) {
          // inside click
          return;
        }
        setSwipingDetails({ ...swipingDetails, deltaX: 0 });
        delayClose();
      }
    },
    [delayClose, swipingDetails]
  );

  useEffect(() => {
    if (swipingDetails.open) {
      document.addEventListener('touchstart', closeOpenListOnOutsideTouch);
      return () => {
        document.removeEventListener('touchstart', closeOpenListOnOutsideTouch);
      };
    }
  }, [closeOpenListOnOutsideTouch, swipingDetails.open]);

  useEffect(() => {
    if (override && override.message) {
      if (confirmedReject || confirmedApprove) {
        setConfirmedReject(false);
        setConfirmedApprove(false);
        setSwipingDetails(initialSwipingDetails);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [override]);

  const swipingDetailsOpenRef = useRef(swipingDetails.open);
  swipingDetailsOpenRef.current = swipingDetails.open;
  const [openExtraInfo, setOpenExtraInfo] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (swipingDetailsOpenRef.current && status === 'PENDING') {
      setTimeout(() => {
        if (swipingDetailsOpenRef.current && !openExtraInfo) {
          setOpenExtraInfo(true);
        }
      }, 400);
    }
    if (!swipingDetailsOpenRef.current && openExtraInfo) {
      setOpenExtraInfo(false);
    }
  });
  return (
    <SwipeableTaskWrapper
      t={t}
      handlers={handlers}
      status={status}
      {...swipingDetails}
      confirmedApprove={confirmedApprove}
      setConfirmedApprove={setConfirmedApprove}
      confirmedReject={confirmedReject}
      setConfirmedReject={setConfirmedReject}
      ref={node}
      openExtraInfo={openExtraInfo}
    >
      {children}
    </SwipeableTaskWrapper>
  );
};
