import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import { endpoints } from 'config';
import { secureFetch } from 'utils';
import { OrganisationChart } from './OrganisationChart';

export const OrganisationChartContainer = ({ ...props }) => {
  const {
    match: {
      params: { subsectionId: contactId },
    },
  } = useReactRouter();
  const defaultState = false;
  const [managers, setManagers] = useState(defaultState);
  const [showManagers, setShowManagers] = useState(defaultState);
  const [loadingManagers, setLoadingManagers] = useState(defaultState);
  const [errorManagers, setErrorManagers] = useState(defaultState);

  const { t } = useTranslation();

  useEffect(() => {
    if (contactId) {
      setManagers(defaultState);
      setShowManagers(defaultState);
      setLoadingManagers(defaultState);
      setErrorManagers(defaultState);
    }
  }, [defaultState, contactId]);

  const onClickShowManagersLevel = async () => {
    if (showManagers) {
      setShowManagers(false);
    } else if (!managers) {
      setLoadingManagers(true);
      setErrorManagers(false);
      const url = `${endpoints.contact}/${contactId}/managers`;
      try {
        const json = await secureFetch(url);
        setManagers(json);
        setLoadingManagers(false);
        setShowManagers(true);
      } catch (e) {
        setErrorManagers(e);
      }
    } else {
      setShowManagers(true);
    }
  };
  return (
    <OrganisationChart
      onClickShowManagersLevel={onClickShowManagersLevel}
      errorManagers={errorManagers}
      loadingManagers={loadingManagers}
      managers={managers}
      showManagers={showManagers}
      t={t}
      {...props}
    />
  );
};
