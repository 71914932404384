import React from 'react';
import styled from 'styled-components';
import { customTheme } from 'theme/theme';
import { Pill } from 'components';
import { getHumanReadableDate } from 'utils';

const StyledTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  margin-top: -${({ theme }) => theme.space[1]}px; /*correction for alignment with Pill*/
  margin-right: ${({ theme }) => theme.space[2]}px;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledExtraInfo = styled.div`
  margin-top: 6px;
`;

const StyledPill = styled(Pill)`
  display: inline;
  position: relative;
  top: -2px;
`;

export const ChangelogItem = ({
  className,
  text,
  type,
  date,
  jiraStoryNumber,
}) => (
  <li data-cy="changelogItem" className={className}>
    <StyledTitleWrapper>
      <StyledTitle>{text}</StyledTitle>
    </StyledTitleWrapper>
    <StyledExtraInfo>
      {getHumanReadableDate(date, false)}
      {jiraStoryNumber ? ' • ' : null}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://borealisgroup.atlassian.net/browse/${jiraStoryNumber}`}
      >
        {jiraStoryNumber}
      </a>
      {type ? (
        <>
          {' • '}
          <StyledPill
            color={
              type === 'BUGFIX' || type === 'HOTFIX'
                ? customTheme.colors.red[5]
                : undefined
            }
            darkModeColor={
              type === 'BUGFIX' || type === 'HOTFIX'
                ? customTheme.colors.red[5]
                : undefined
            }
            label={type}
            outlined
          />
        </>
      ) : null}
    </StyledExtraInfo>
  </li>
);
