import React from 'react';
import { useTranslation } from 'react-i18next';
import { Metroline } from './Metroline';

export const MetrolineRegularPurchaseRequestsContainer = ({ task }) => {
  const { approvalSequence, status, ...props } = task;
  const { t } = useTranslation();
  return (
    <Metroline
      taskStatus={status}
      approvalSequence={approvalSequence}
      t={t}
      {...props}
    />
  );
};
