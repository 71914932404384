import React from 'react';
import { createPortal } from 'react-dom';
import { Cross } from 'icons';
import styled, { css } from 'styled-components';
import { resetButtonLayout, StyledBackdrop } from 'styled';

const StyledSuperWrapper = styled.section`
  position: fixed;
  left: 0;
  right: 0;
  z-index: 8;
  margin: auto;
  width: 400px;
  height: 200px;
  transition: transform 0.5s, opacity 0.5s;
  opacity: ${({ active }) => (active ? 1.0 : 0.3)};
  max-width: calc(100% - ${({ theme }) => theme.space[5]}px);

  @media only screen and (max-width: ${({ theme }) => theme.breakPoints[3]}) {
    bottom: 0;
    padding-bottom: ${({ theme }) => theme.space[5]}px;
    transform: ${({ active }) =>
      active ? 'translateY(0%)' : 'translateY(100%)'};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    top: 0;
    padding-top: ${({ theme }) => theme.space[5]}px;
    transform: ${({ active }) =>
      active ? 'translateY(0%)' : 'translateY(-100%)'};
  }
`;

const StyledWrapper = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.space[5] - theme.space[3]}px;
  padding-left: ${({ theme }) => theme.space[5]}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

const StyledParagraph = styled.p`
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const sharedButtonStyle = css`
  ${resetButtonLayout};
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  padding: ${({ theme }) => theme.space[3]}px;
  margin-left: ${({ theme }) => theme.space[3]}px;
`;

const StyledPositiveButton = styled.button`
  ${sharedButtonStyle}
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledNegativeButton = styled.button`
  ${sharedButtonStyle};
`;

const StyledCrossButton = styled.button`
  ${resetButtonLayout};
  width: 12px;
  height: 12px;
  margin-top: 2px;
  padding: ${({ theme }) => theme.space[3]}px;
  box-sizing: content-box;
  path {
    fill: ${({ theme }) => theme.colors.secondary};
  }
  &:hover {
    path {
      fill: ${({ theme }) => theme.colors.red[5]} !important;
    }
  }
`;

const StyledTitleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ConfirmationAlert = ({
  closeAlert,
  text,
  active,
  confirmText,
  cancelText,
  confirmAlert,
}) => {
  const portalRoot = document.getElementById('portal');
  return createPortal(
    <>
      <StyledBackdrop
        fixed
        to="#"
        mobile={1}
        onClick={closeAlert}
        active={active ? 1 : 0}
      />
      <StyledSuperWrapper active={active}>
        <StyledWrapper>
          <StyledTitleWrapper>
            <StyledParagraph>{text}</StyledParagraph>
            <StyledCrossButton onClick={closeAlert}>
              <Cross />
            </StyledCrossButton>
          </StyledTitleWrapper>
          <StyledButtonWrapper>
            <StyledPositiveButton onClick={confirmAlert} type="button">
              {confirmText}
            </StyledPositiveButton>
            <StyledNegativeButton onClick={closeAlert} type="button">
              {cancelText}
            </StyledNegativeButton>
          </StyledButtonWrapper>
        </StyledWrapper>
      </StyledSuperWrapper>
    </>,
    portalRoot
  );
};
