import React from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import { useUrlParameters } from 'hooks';
import { NotificationReminderPage } from './NotificationReminderPage';

export const NotificationReminderPageContainer = ({ ...props }) => {
  const {
    location: { pathname },
  } = useReactRouter();
  const { t } = useTranslation();
  const { showNotificationReminder } = useUrlParameters();
  return showNotificationReminder ? (
    <NotificationReminderPage
      overidePreviousPath={pathname}
      t={t}
      active={showNotificationReminder}
      {...props}
    />
  ) : null;
};
