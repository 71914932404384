import * as Sentry from '@sentry/browser';
import { approvalsConfig, beforeSend, frontendEnvironment } from 'utils';

export const initSentry = async () => {
  if (approvalsConfig.sentryEnabled) {
    Sentry.init({
      dsn: 'https://35285ad06fa04f27bb50a1151ece607f@sentry.io/1847559',
      environment: frontendEnvironment,
      beforeSend,
    });
    Sentry.configureScope(scope => {
      scope.setTag('app-name', 'approvals-frontend');
    });
  }
};
