import React, { useState } from 'react';
import { FileLink } from './FileLink';

export const FileLinkContainer = ({ ...props }) => {
  const [showDownloadToast, setShowDownloadToast] = useState(0);

  return (
    <FileLink
      showDownloadToast={showDownloadToast}
      onClick={() => setShowDownloadToast(showDownloadToast + 1)}
      {...props}
    />
  );
};
