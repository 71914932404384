import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConfirmAlert } from 'store/userInterface/userInterface.selectors';
import { userInterfaceUpdate } from 'store/userInterface/userInterface.duck';
import { ConfirmationAlert } from './ConfirmationAlert';

export const ConfirmationAlertContainer = ({ ...props }) => {
  const { active, text, confirmText, cancelText, succesCallback } = useSelector(
    getConfirmAlert
  );
  const dispatch = useDispatch();
  const closeAlert = useCallback(() => {
    dispatch(
      userInterfaceUpdate({
        confirmAlert: {
          active: false,
          text,
          confirmText,
          cancelText,
        },
      })
    );
  }, [cancelText, confirmText, dispatch, text]);
  const confirmAlert = useCallback(() => {
    succesCallback();
    closeAlert();
  }, [closeAlert, succesCallback]);
  const handleKeyDown = useCallback(
    event => {
      const escapeKey = 27;
      const enterKey = 13;
      if (event.keyCode === escapeKey) {
        closeAlert();
      }
      if (event.keyCode === enterKey) {
        confirmAlert();
      }
    },
    [closeAlert, confirmAlert]
  );

  useEffect(() => {
    if (active) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [active, handleKeyDown]);
  return (
    <ConfirmationAlert
      text={text}
      closeAlert={closeAlert}
      confirmAlert={confirmAlert}
      active={active}
      confirmText={confirmText}
      cancelText={cancelText}
      {...props}
    />
  );
};
