import React from 'react';
import styled from 'styled-components';
import { Hamburger } from 'icons';
import { resetButtonLayout } from 'styled';

const StyledButton = styled.button`
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    display: none;
  }
  margin-top: 3px;
  position: absolute;
  height: 100%;
  padding: 0px ${({ theme }) => theme.space[4]}px;
  ${resetButtonLayout}
`;

const StyledHamburger = styled(Hamburger)`
  width: 28px;
  height: 14px;
  @media (prefers-color-scheme: dark) {
    path {
      fill: ${({ theme }) => theme.colors.darkerWhite} !important;
    }
  }
`;

export const HamburgerButton = ({ ...props }) => (
  <StyledButton {...props} type="submit">
    <StyledHamburger />
  </StyledButton>
);
