import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { persistedUserInterfaceUpdate } from 'store/persistedUserInterface/persistedUserInterface.duck';
import { mataHari } from 'utils';
import { Tab } from './Tab';

export const TabContainer = ({
  selected,
  children,
  focusIndicatorWidth,
  setFocusIndicatorWidth,
  ...props
}) => {
  const dispatch = useDispatch();
  const ref = useCallback(
    node => {
      if (selected && node) {
        const { width: contentWidth } = node.getBoundingClientRect();
        if (focusIndicatorWidth !== contentWidth)
          setFocusIndicatorWidth(contentWidth);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected, children]
  );

  const onSubmit = (event, taskListStatusFilter) => {
    mataHari.uxSnitch(`switchedTab${taskListStatusFilter}`);
    event.preventDefault();
    dispatch(
      persistedUserInterfaceUpdate({
        taskListStatusFilter,
      })
    );
  };
  return (
    <Tab selected={selected} ref={ref} onSubmit={onSubmit} {...props}>
      {children}
    </Tab>
  );
};
