import React, { useCallback, useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import { routes } from 'config';
import { useMobile } from 'hooks';
import { injectRouteParameter } from 'utils';
import { SidePanel } from './SidePanel';

export const SidePanelContainer = ({
  overidePreviousPath,
  active,
  showAsPopUp,
  headerProps,
  ...props
}) => {
  const {
    match: {
      params: { section, taskId },
    },
  } = useReactRouter();
  const [closed, setClosed] = useState(!active);
  const previousPath =
    overidePreviousPath ||
    injectRouteParameter(routes.taskDetail, {
      section,
      taskId,
    });
  const onRefSidePanelSet = useCallback(ref => {
    if (ref) {
      ref.addEventListener(
        'transitionend',
        event => {
          setClosed(
            window.innerWidth <
              Math.floor(event.target.getBoundingClientRect().right)
          );
        },
        false
      );
      ref.addEventListener(
        'transitionstart',
        () => {
          setClosed(false);
        },
        false
      );
    }
  }, []);

  const mobile = useMobile();

  useEffect(() => {
    if (mobile && closed) {
      setClosed(false);
    }
    if (!mobile && !active && !closed) {
      setClosed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobile]);
  return (
    <SidePanel
      {...props}
      showAsPopUp={showAsPopUp}
      closed={closed}
      active={active}
      previousPath={previousPath}
      onRefSidePanelSet={onRefSidePanelSet}
      headerProps={{
        closeActionRightDesktop: showAsPopUp,
        closable: true,
        onlyMobile: false,
        search: false,
        to: previousPath,
        ...headerProps,
      }}
    />
  );
};
