import React from 'react';
import styled from 'styled-components';
import { Header, HomePage } from 'components';
import { StyledTaskDetailContentWrapper } from 'styled';

const StyledWrapper = styled.main`
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    display: flex;
  }
`;

const StyledPageWrapper = styled.div`
  width: 100%;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    height: 100vh;
    overflow: auto;
  }
`;

const StyledSinglePageContentWrapper = styled(StyledTaskDetailContentWrapper)`
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    margin-top: 64px;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[5]}) {
    margin-left: calc(-92px + 16px + ${({ theme }) => theme.space[13]}px);
  }
`;

export const SinglePageTemplate = ({ title, children }) => (
  <StyledWrapper>
    <HomePage sidebar={1} />
    <StyledPageWrapper>
      <Header
        title={title}
        to="/"
        search={false}
        onlyMobile={false}
        hideNavigationOnDesktop
        singlePage
        detailDesktopBackgroundColor
        goBackEnabled
        margin
      />
      <StyledSinglePageContentWrapper>
        {children}
      </StyledSinglePageContentWrapper>
    </StyledPageWrapper>
  </StyledWrapper>
);
