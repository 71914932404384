import React from 'react';
import styled from 'styled-components';
import {
  ErrorInfo,
  Header,
  LoadingOverlay,
  Section,
  TravelAndExpenseActionButtons,
  UserLink,
} from 'components';
import {
  sectionItemWrapperStyle,
  StyledPlaceholder,
  StyledTaskDetailContentWrapper,
} from 'styled';

const StyledPaddedPlaceholder = styled(StyledPlaceholder)`
  margin-bottom: 5px;
`;

const StyledKeyValueGroupWrapper = styled.ul`
  margin-bottom: 300px;
  margin: auto;
`;

const StyledErrorInfo = styled(ErrorInfo)`
  margin-top: ${({ theme }) => theme.space[4]}px;
  @media only screen and (max-width: ${({ theme }) => theme.breakPoints[3]}) {
    margin-left: ${({ theme }) => theme.space[4]}px;
    margin-right: ${({ theme }) => theme.space[4]}px;
  }
`;

const StyledEmployeeInfoWrapper = styled.div`
  ${sectionItemWrapperStyle}
  margin-top: ${({ theme }) => theme.space[4]}px;
`;

const StyledUserLink = styled(UserLink)`
  font-size: 18px;
  font-weight: bold;
`;

const StyledCompanyName = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const TravelAndExpenseDetailTemplate = ({
  headerProps,
  sections,
  rejectProps,
  approveProps,
  task,
  loading,
  override,
  t,
}) => {
  const [lastName, firstName] = task.tripDetails.employee?.name?.split(',') || [
    '',
    '',
  ];
  return (
    <>
      <Header margin {...headerProps} />
      <StyledTaskDetailContentWrapper>
        {loading && <LoadingOverlay />}
        {override && override.message ? (
          <StyledErrorInfo
            title={`${override.internalError ? 'Internal error: ' : ''}${
              override.message
            }`}
          />
        ) : null}
        <StyledKeyValueGroupWrapper>
          <StyledEmployeeInfoWrapper>
            {loading ? (
              <>
                <StyledPaddedPlaceholder
                  height="20px"
                  width="200px"
                  color="primary"
                />
                <StyledPlaceholder height="20px" width="200px" color="black" />
              </>
            ) : (
              <>
                <StyledUserLink
                  name={task.tripDetails.employee?.name}
                  account={{
                    firstName,
                    lastName,
                    email: task.tripDetails.employee?.email,
                  }}
                  t={t}
                />
                <StyledCompanyName>{`${task.tripDetails.companyName}, ${task.tripDetails.companyCountry}`}</StyledCompanyName>
              </>
            )}
          </StyledEmployeeInfoWrapper>
          {sections.map(({ type, ...sectionProps }) => {
            return (
              <Section
                loading={loading}
                key={sectionProps.title}
                {...sectionProps}
              />
            );
          })}
        </StyledKeyValueGroupWrapper>
        <TravelAndExpenseActionButtons
          task={task}
          rejectProps={rejectProps}
          approveProps={approveProps}
        />
      </StyledTaskDetailContentWrapper>
    </>
  );
};
