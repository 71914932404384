import React from 'react';
import { Check, Warning } from 'icons';
import styled, { css } from 'styled-components';
import { mataHari } from 'utils';
// import { StyledSeperator } from 'styled';

const StyledSwipeOverlay = styled.div`
  width: ${({ width }) => width}px;
  ${({ confirmed }) =>
    confirmed
      ? css`
          width: 100%;
        `
      : ''}
  max-width: 100%;
  min-height: 140px;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  ${({ open }) =>
    open
      ? css`
          transition: width 0.5s;
        `
      : css`
          transition: width 0.2s;
        `}
`;

const StyledButton = styled.button`
  background-color: ${({ status }) =>
    status === 'APPROVED'
      ? 'rgba(83, 159, 0, 0.8)'
      : 'rgba(209, 0, 0, 0.8)'}; /* 145, 177, 25 */
  border: none;
  width: 100%;
  height: 100%;
  outline: none;
  overflow: hidden;
  text-transform: uppercase;
`;

const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  transition: width 0.5s;
  ${({ confirmed, confirmedOther }) => {
    if (confirmed)
      return css`
        width: 100%;
      `;
    if (confirmedOther)
      return css`
        width: 0%;
      `;
  }}
`;

const StyledButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: ${({ theme }) => theme.space[4]}px; */
`;

const StyledContentWrapper = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  height: 140px;
  transition: height 0.5s;
  transition-delay: 0.45s;
  ${({ confirmed }) =>
    confirmed
      ? css`
          height: 0;
        `
      : ''}
`;

const iconStyle = css`
  height: 23px;
`;

const StyledCheck = styled(Check)`
  ${iconStyle}
`;

const StyledSpan = styled.span`
  color: white;
  margin-top: ${({ theme }) => theme.space[4]}px;
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
`;

const StyledParagraph = styled.p`
  width: 110px;
  min-width: 110px;
`;

const StyledChildrenWrapper = styled.div`
  width: 100%;
  flex-shrink: 0;
  margin-left: ${({ marginLeft }) => marginLeft}px;
  ${({ confirmed }) =>
    confirmed
      ? css`
          margin-left: -100%;
        `
      : ''}
  ${({ open }) =>
    open
      ? css`
          transition: margin-left 0.5s;
        `
      : css`
          transition: margin-left 0.2s;
        `}
`;

const StyledSeperator = styled.hr`
  width: calc(100% - ${({ theme }) => theme.space[4]}px);
  border: none;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray};

  @media (prefers-color-scheme: dark) {
    background-color: ${({ theme }) => theme.colors.lighterBlack};
  }
  margin-top: 3px;
  margin-bottom: 3px;
  transition: all 0.5s;
  transition-delay: 0.45s;
  ${({ confirmed }) =>
    confirmed
      ? css`
          height: 0;
          margin-top: 0;
          margin-bottom: 0;
        `
      : ''}
`;

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledExtraInfoWrapper = styled.div`
  position: absolute;
  z-index: 2;
  padding: 12px ${({ theme }) => theme.space[3]}px;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  width: 100%;
  display: flex;
  align-items: center;
  transform: perspective(800px) rotateX(-90deg);
  transform-origin: top;
  transition: transform 0.2s, opacity 0.2s 0.5s;
  transition-timing-function: linear;
  justify-content: center;
  /* margin-top: ${({ theme }) => theme.space[1]}px; */
  ${({ error, theme }) =>
    error
      ? css`
          background-color: ${theme.colors.red[0]};
        `
      : css`
          background-color: ${theme.colors.focus};
          @media (prefers-color-scheme: dark) {
            background-color: ${theme.colors.focusDark};
          }
        `}
  ${({ open }) =>
    open
      ? css`
          transform: perspective(800px) rotateX(0deg);
        `
      : ''}
  ${({ oneConfirmed }) =>
    oneConfirmed
      ? css`
          opacity: 0;
        `
      : ''}
`;
const StyledExtraInfoContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const StyledExtraInfo = styled.span`
  ${({ error, theme }) =>
    error
      ? css`
          color: ${theme.colors.red[5]};
        `
      : css`
          color: ${theme.colors.tertiary};
        `}

  margin-left: 12px;
  /* margin-top: 2px; */
  width: calc(100% - ${({ theme }) => theme.space[4]}px);
`;

const StyledWarning = styled(Warning)`
  ${({ error, theme }) =>
    error
      ? css`
          .stroke {
            stroke: ${theme.colors.red[5]};
          }
          .fill {
            fill: ${theme.colors.red[5]};
          }
        `
      : ''}
  width: 21px;
  height: 18px;
`;

const Action = ({
  title,
  status,
  setConfirmed,
  confirmed,
  confirmedOther,
  icon,
}) => (
  <StyledForm
    onSubmit={event => {
      event.preventDefault();
      mataHari.uxSnitch('swipeEvent');
      setConfirmed(true);
    }}
    confirmed={confirmed}
    confirmedOther={confirmedOther}
  >
    <StyledButton type="submit" status={status}>
      <StyledButtonContent>
        {icon}
        <StyledSpan>{title}</StyledSpan>
      </StyledButtonContent>
    </StyledButton>
  </StyledForm>
);
const SwipeActions = ({
  direction,
  deltaX,
  open,
  confirmedApprove,
  confirmedReject,
  setConfirmedApprove,
  status,
  oneConfirmed,
  t,
}) => (
  <StyledSwipeOverlay
    direction={direction}
    width={deltaX}
    open={open}
    confirmed={oneConfirmed}
  >
    {status === 'PENDING' ? (
      <>
        <Action
          title={t('COMMON.APPROVE')}
          status="APPROVED"
          confirmed={confirmedApprove}
          confirmedOther={confirmedReject}
          setConfirmed={setConfirmedApprove}
          icon={<StyledCheck />}
        />
      </>
    ) : (
      <StyledParagraph>
        {t('TASK_LIST.ALREADY_EVALUATED_MESSAGE')}
      </StyledParagraph>
    )}
  </StyledSwipeOverlay>
);

const OverlayInfo = ({ error, title, ...props }) => (
  <StyledExtraInfoWrapper error={error} {...props}>
    <StyledExtraInfoContentWrapper>
      <StyledWarning error={error ? 1 : 0} />
      <StyledExtraInfo error={error}>{title}</StyledExtraInfo>
    </StyledExtraInfoContentWrapper>
  </StyledExtraInfoWrapper>
);

export const SwipeableTaskWrapper = React.forwardRef(
  (
    {
      handlers,
      direction,
      deltaX,
      open,
      children,
      confirmedApprove,
      setConfirmedApprove,
      confirmedReject,
      setConfirmedReject,
      status,
      t,
      initialDirection,
      openExtraInfo,
    },
    ref
  ) => {
    const oneConfirmed = confirmedApprove || confirmedReject;
    const genericSwipeActionsProps = {
      direction,
      deltaX,
      open,
      confirmedApprove,
      confirmedReject,
      setConfirmedReject,
      setConfirmedApprove,
      status,
      oneConfirmed,
      t,
    };
    return (
      <StyledWrapper ref={ref}>
        <StyledContentWrapper confirmed={oneConfirmed} {...handlers}>
          {initialDirection === 'Right' ? (
            <SwipeActions {...genericSwipeActionsProps} />
          ) : null}
          <StyledChildrenWrapper
            marginLeft={initialDirection === 'Left' ? -deltaX : 0}
            open={open}
            confirmed={initialDirection === 'Left' && oneConfirmed}
          >
            {children}
          </StyledChildrenWrapper>
          {initialDirection === 'Left' ? (
            <SwipeActions {...genericSwipeActionsProps} />
          ) : null}
        </StyledContentWrapper>
        <OverlayInfo
          title={t('TASK_LIST.SWIPE_EXTRA_INFO')}
          open={openExtraInfo}
          oneConfirmed={oneConfirmed}
        />
        <StyledSeperator marginRight confirmed={oneConfirmed} />
      </StyledWrapper>
    );
  }
);
