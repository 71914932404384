import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breakfast, Lunch, Meals } from 'icons';
import { TripDetailsTable } from './TripDetailsTable';

export const MealDetailsTable = ({ rows }) => {
  const { t } = useTranslation();
  return (
    <TripDetailsTable
      dataCy="mealDetailsTable"
      headers={[
        {
          Icon: Breakfast,
          label: t('TASK_DETAILS.BREAKFAST'),
          viewBox: '0 0 29.9 21.2',
        },
        {
          Icon: Lunch,
          label: t('TASK_DETAILS.LUNCH'),
          viewBox: '0 0 33 18.5',
        },
        {
          Icon: Meals,
          label: t('TASK_DETAILS.DINNER'),
          viewBox: '16 14 40 36',
          top: '2px',
        },
      ]}
      rows={rows}
    />
  );
};
