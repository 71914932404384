import React from 'react';
import { useIncidentsBanner } from 'hooks';
import { BannerContainer } from './Banner.container';

export const MaintenanceBannerContainer = ({ ...props }) => {
  const { bannerText, bannerEnabled, bannerTitle } = useIncidentsBanner();
  if (bannerEnabled) {
    return (
      <BannerContainer
        data-cy="incidentBanner"
        text={bannerText}
        title={bannerTitle}
        closable
        {...props}
      />
    );
  }
  return null;
};
