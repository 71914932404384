import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';
import { OBJECT_TYPES, routes } from 'config';
import { fetchTravelAndExpensesOfEmployee } from 'store/tasks/travelAndExpenses/travelAndExpenses.service';
import { getObjectTypeFromSection, getTravelAndExpenseIds } from 'utils';
import { MultipleTasksPage } from './MultipleTasksPage';

export const MultipleTasksPageContainer = ({ ...props }) => {
  const {
    match: {
      params: { subsection, section, taskId },
    },
    location: { pathname },
  } = useReactRouter();
  const dispatch = useDispatch();
  const objectTypeInView = getObjectTypeFromSection(section);
  const { employeeNumber } = getTravelAndExpenseIds(taskId);

  useEffect(() => {
    if (
      objectTypeInView === OBJECT_TYPES.TRAVEL_AND_EXPENSE &&
      pathname.includes(routes.multiple) &&
      !pathname.includes(routes.confirmReject)
    ) {
      fetchTravelAndExpensesOfEmployee(dispatch, employeeNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeNumber, pathname]);

  const { t } = useTranslation();

  return (
    <MultipleTasksPage
      t={t}
      active={subsection === routes.multiple}
      {...props}
    />
  );
};
