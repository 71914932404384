import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';
import { useSelectedTasks, useRefetchParameters } from 'hooks';
import { getObjectTypeFromSection } from 'utils';
import { updateStatusTask } from 'store/tasks/tasks.service';
import { MultipleTasksCommentGroup } from './MultipleTasksCommentGroup';

export const MultipleTasksCommentGroupContainer = ({
  previousRoute,
  ...props
}) => {
  const {
    match: {
      params: { section },
    },
    history,
  } = useReactRouter();

  const objectTypeInView = getObjectTypeFromSection(section);

  const selectedTasks = useSelectedTasks();
  const defaultTextAreaValues = {};
  selectedTasks.forEach(task => {
    defaultTextAreaValues[task.id] = '';
  });
  const { t } = useTranslation();
  const [overrideComment, setOverrideComment] = useState(null);
  const [checkMandatoryError, setCheckMandatoryError] = useState(false);
  const [hasMandatoryError, setHasMandatoryError] = useState(false);
  const [textAreaValues, setTextAreaValues] = useState(defaultTextAreaValues);

  const onSubmit = event => {
    event.preventDefault();
    setHasMandatoryError(false);
    setCheckMandatoryError(true);
  };
  useEffect(() => {
    if (checkMandatoryError && hasMandatoryError) {
      setCheckMandatoryError(false);
    }
  }, [checkMandatoryError, hasMandatoryError]);

  const refetchParameters = useRefetchParameters();
  const dispatch = useDispatch();
  useEffect(() => {
    let allTextAreaValuesFilledIn = Object.keys(textAreaValues).length > 0;
    Object.keys(textAreaValues).forEach(key => {
      if (!textAreaValues[key]) allTextAreaValuesFilledIn = false;
    });
    if (allTextAreaValuesFilledIn) {
      updateStatusTask(
        dispatch,
        'REJECTED',
        selectedTasks,
        selectedTasks.map(({ id }) => textAreaValues[id]),
        refetchParameters,
        objectTypeInView,
        true
      );
      history.replace(previousRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textAreaValues]);

  useEffect(() => {
    if (selectedTasks.length === 0) {
      history.replace(previousRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTasks]);

  return (
    <MultipleTasksCommentGroup
      overrideComment={overrideComment}
      setOverrideComment={setOverrideComment}
      hasMandatoryError={hasMandatoryError}
      setHasMandatoryError={setHasMandatoryError}
      checkMandatoryError={checkMandatoryError}
      t={t}
      onSubmit={onSubmit}
      tasks={selectedTasks}
      textAreaValues={textAreaValues}
      setTextAreaValues={setTextAreaValues}
      previousRoute={previousRoute}
      {...props}
    />
  );
};
