import React, { forwardRef } from 'react';
import { Arrow } from 'icons';
import styled, { css } from 'styled-components';
import { subtitleStyle } from 'styled';

const StyledAnswerLi = styled.li`
  font-size: 16px;
  a {
    color: ${({ theme }) => theme.colors.tertiary};
    text-decoration: underline;
  }
`;

const StyledQuestionWrapper = styled.ul`
  transition: height 0.2s linear;
  max-width: 90%;
  overflow: hidden;
  ${({ sectionToggled, openHeight }) => {
    if (!openHeight) {
      return css`
        height: auto;
      `;
    }
    if (!sectionToggled) {
      return css`
        height: 0px;
      `;
    }
    if (openHeight) {
      return css`
        height: ${openHeight}px;
      `;
    }
  }}
  @media print {
    max-height: none;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakPoints[3]}) {
    padding-left: ${({ theme }) => theme.space[4]}px;
  }
`;

const StyledFaqBulletList = styled.ol`
  padding-left: 15px;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    padding-left: 15px;
  }
  & > li {
    list-style-type: unset;
  }
`;

const StyledQuestionForm = styled.form`
  background-color: white;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const StyledArrow = styled.div`
  width: 6px;
  height: 6px;
  margin-right: 8px;
  transition: transform 0.2s ease;
  * > path {
    stroke: ${({ theme }) => theme.colors.gray};
  }

  ${({ sectionToggled }) =>
    !sectionToggled
      ? css`
          transform: rotate(0deg);
        `
      : css`
          transform: rotate(-90deg);
        `}
`;

const StyledQuestionTitle = styled.h3`
  ${subtitleStyle}
  font-weight: bold;
  font-size: 20px;
  position: sticky;
  top: 57px; /* height header */

  margin-bottom: ${({ theme }) => theme.space[4]}px;
  background-color: white;
  padding-left: ${({ theme }) => theme.space[4]}px;
  padding-right: ${({ theme }) => theme.space[4]}px;
  @media (prefers-color-scheme: dark) {
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.tertiary};
  }
  z-index: 1;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    padding-left: 0;
    padding-right: 0;
  }
  max-width: 95%;
`;

const StyledSubmitButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  text-align: left;
  background-color: white;
  &:focus {
    outline: none;
    ${StyledArrow} {
      * > path {
        stroke: ${({ theme }) => theme.colors.tertiary};
      }
    }
  }

  @media (prefers-color-scheme: dark) {
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.tertiary};
  }
  border: none;
  width: 100%;
`;

const StyledFaqQuestion = styled.div`
  &:first-child {
    padding-top: 0px;
  }
  transition: padding-bottom 0.3s linear;
  ${({ sectionToggled }) =>
    sectionToggled
      ? css`
          padding-bottom: ${({ theme }) => theme.space[5]}px;
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[3]}) {
            padding-bottom: ${({ theme }) => theme.space[7]}px;
          }
        `
      : css`
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[3]}) {
            padding-bottom: ${({ theme }) => theme.space[3]}px;
          }
        `}
`;

export const FaqQuestion = forwardRef(
  (
    {
      sectionToggled,
      collapseHandler,
      question,
      answer,
      bulletList,
      answers,
      openHeight,
    },
    ref
  ) => (
    <StyledFaqQuestion sectionToggled={sectionToggled}>
      <StyledQuestionForm onSubmit={collapseHandler}>
        <StyledSubmitButton data-cy="faq-section-button" type="submit">
          <StyledQuestionTitle bold>{question}</StyledQuestionTitle>
          <StyledArrow sectionToggled={sectionToggled}>
            <Arrow />
          </StyledArrow>
        </StyledSubmitButton>
      </StyledQuestionForm>
      <StyledQuestionWrapper
        ref={ref}
        openHeight={openHeight}
        sectionToggled={sectionToggled}
      >
        {bulletList ? (
          <StyledFaqBulletList>
            {answers.map(a => (
              <StyledAnswerLi
                key={a}
                data-cy="faq-section-hidden-text"
                dangerouslySetInnerHTML={{ __html: a }}
              />
            ))}
          </StyledFaqBulletList>
        ) : (
          <StyledAnswerLi
            data-cy="faq-section-hidden-text"
            dangerouslySetInnerHTML={{ __html: answer }}
          />
        )}
      </StyledQuestionWrapper>
    </StyledFaqQuestion>
  )
);
