import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSubscribedToPushNotifications, useUserPreferences } from 'hooks';
import { PreferencesPage } from './PreferencesPage';

export const PreferencesPageContainer = () => {
  const { t } = useTranslation();
  const userPreferences = useUserPreferences();
  const preferencesLoading = userPreferences.loading;
  const preferences = userPreferences.approvals;

  // Also update defaults (utils/config.js) if you change these
  const composedUserPreferences = {
    purchaseRequests: [
      {
        type: 'PREFERENCE',
        keyText: t('PREFERENCES.PURCHASE_REQUESTS.NOTIFY_ON_NEW'),
        sectionKey: 'purchaseRequests',
        dataKey: 'notifyOnNew',
        value: preferences?.purchaseRequests?.notifyOnNew,
        autoFocus: true,
      },
      {
        type: 'PREFERENCE',
        keyText: t('PREFERENCES.PURCHASE_REQUESTS.NOTIFY_ON_UPDATE'),
        sectionKey: 'purchaseRequests',
        dataKey: 'notifyOnUpdate',
        value: preferences?.purchaseRequests?.notifyOnUpdate,
      },
      {
        type: 'PREFERENCE',
        keyText: t('PREFERENCES.PURCHASE_REQUESTS.NOTIFY_ON_DELETE'),
        sectionKey: 'purchaseRequests',
        dataKey: 'notifyOnDelete',
        value: preferences?.purchaseRequests?.notifyOnDelete,
      },
      {
        type: 'PREFERENCE',
        keyText: t('PREFERENCES.PURCHASE_REQUESTS.NOTIFY_ON_FURTHER_STEPS'),
        sectionKey: 'purchaseRequests',
        dataKey: 'notifyOnFurtherSteps',
        value: preferences?.purchaseRequests?.notifyOnFurtherSteps,
      },
    ],
    travelAndExpenses: [
      {
        type: 'PREFERENCE',
        keyText: t('PREFERENCES.TRAVEL_AND_EXPENSES.NOTIFY_ON_NEW'),
        sectionKey: 'travelAndExpenses',
        dataKey: 'notifyOnNew',
        value: preferences?.travelAndExpenses?.notifyOnNew,
      },
    ],
  };
  const subscribedToPushNotifications = useSubscribedToPushNotifications();
  return (
    <PreferencesPage
      t={t}
      preferences={composedUserPreferences}
      loading={preferencesLoading}
      subscribedToPushNotifications={subscribedToPushNotifications}
    />
  );
};
