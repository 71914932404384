import React from 'react';
import { useTranslation } from 'react-i18next';
import { endpoints } from 'config';
import { secureFetch } from 'utils';
import { NoConnectionPage } from './NoConnectionPage';

export const NoConnectionPageContainer = () => {
  const { t } = useTranslation();

  const retryConnectionHandler = async event => {
    event.preventDefault();
    try {
      const response = await secureFetch(endpoints.ping);
      if (response) {
        window.location.reload();
      }
    } catch (error) {
      // Don't do anything here
    }
    return null;
  };

  return (
    <NoConnectionPage retryConnectionHandler={retryConnectionHandler} t={t} />
  );
};
