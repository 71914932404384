import React from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import { selectActiveCostAssignment } from 'utils';
import { LineItemDetailPage } from './LineItemDetailPage';

export const LineItemDetailPageContainer = ({ task, ...props }) => {
  const {
    match: {
      params: { subsection, subsectionId: lineItemId },
    },
  } = useReactRouter();
  const { t } = useTranslation();
  const data = (() => {
    if (lineItemId) {
      let matchingLineItem = null;
      task.lineItems.forEach(lineItem => {
        if (Number(lineItem.id) === Number(lineItemId)) {
          matchingLineItem = lineItem;
        }
      });
      return matchingLineItem;
    }
    return null;
  })();

  return (
    <LineItemDetailPage
      task={task}
      data={data}
      active={subsection === 'line-items'}
      t={t}
      costAssignmentData={selectActiveCostAssignment(data)}
      {...props}
    />
  );
};
