import { createGlobalStyle } from 'styled-components';

// Global styles but theme- and update-able!
const GlobalStyle = createGlobalStyle`
  * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: "relevant", Helvetica, sans-serif;
    color: ${({ theme }) => theme.colors.secondary};
    letter-spacing: -0.22px;
    color: ${({ theme }) => theme.colors.darkerGray};
    @media (prefers-color-scheme: dark) {
      color: ${({ theme }) => theme.colors.darkerWhite};
    }
  }

  html, body {
    min-height: 100% !important;
    height: 100%;
  }

  body {
    font-family: "relevant";
    position: relative;
    font-size: ${({ theme }) => `${theme.fontSizes[1]}px`};
    overflow-x: hidden;
    -webkit-print-color-adjust: exact !important;
    @media (prefers-color-scheme: dark) {
      background-color: ${({ theme }) => theme.colors.black};
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  button {
    cursor: pointer;
  }

  #root {
    height: 100%;
    position: relative;
  }

  li {
    list-style-type: none;
  }
  ol li {
    list-style-type: decimal;
  }
  ol {
    margin-left: 16px;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.tertiary};
  }

  h1, h2, h3, h4 {
    font-weight: bold;
  }

  button {
    user-select: none;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  input[type=search]::-ms-clear, input[type=search]::-ms-reveal {
    display: none; width : 0; height: 0;
  }

  input[type="search"]::-webkit-search-cancel-button {
    display: none;
  }
`;

export default GlobalStyle;
