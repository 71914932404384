import { endpoints } from 'config';
import { userUpdate } from 'store/user/user.duck';
import { secureFetch } from './secureFetch';

export const getUserInfo = async dispatch => {
  // Resolve the current user credentials
  let json = null;
  try {
    json = await secureFetch(endpoints.userInfo);
  } catch (e) {
    // Something went wrong while retrieving user info. This is not blocking however
  }

  if (json && dispatch) {
    dispatch(userUpdate({ currentUser: json }));
  }

  return json;
};

export const getUserNameFromEmail = email => {
  if (typeof email === 'string') {
    return email.includes('@') ? email.split('@')[0] : 'john.doe';
  }
  return 'john.doe';
};

export const getUserName = currentUser => {
  if (typeof currentUser === 'object' && currentUser !== null) {
    return getUserNameFromEmail(currentUser.email);
  }
  return getUserNameFromEmail(currentUser);
};

export const executeBetaFeature = (
  enableBetaFeatures,
  callbackExecutedForBetaFeatures = () => {},
  callbackFallback = () => {}
) => {
  if (enableBetaFeatures) {
    return callbackExecutedForBetaFeatures();
  }
  return callbackFallback();
};
