import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from './Section';

export const SectionContainer = ({
  openedOnLoad = false,
  collapseAble = false,
  ...props
}) => {
  const { t } = useTranslation();
  const [sectionToggled, setSectionToggled] = useState(openedOnLoad);
  const collapseHandler = event => {
    event.preventDefault();
    setSectionToggled(!sectionToggled);
  };
  return (
    <Section
      t={t}
      collapseHandler={collapseHandler}
      sectionToggled={sectionToggled}
      collapseAble={collapseAble}
      {...props}
    />
  );
};
