// based on https://www.w3schools.com/howto/howto_css_custom_checkbox.asp
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

const StyledSpanTextInput = styled.span`
  position: relative;
  top: 2px;
  margin-left: calc(18px + ${({ theme }) => theme.space[3]}px);
  color: ${({ theme }) => theme.colors.secondary};
  ${({ checked }) =>
    checked
      ? css`
          color: ${({ theme }) => theme.colors.primary};
        `
      : ''}
  ${({ focused }) => {
    if (focused) {
      return css`
        color: ${({ theme }) => theme.colors.tertiary};
      `;
    }
  }}
`;

const StyledInputInvisible = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const activeBoxStyle = css`
  border: 1px solid ${({ theme }) => theme.colors.tertiary};
  background-color: ${({ theme }) => theme.colors.lightblue[0]};
  ${({ checked }) => {
    if (checked) {
      return css`
        border: 1px solid ${({ theme }) => theme.colors.tertiary};
        background-color: ${({ theme }) => theme.colors.tertiary} !important;
      `;
    }
  }}
`;

const StyledBox = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid
    ${({ theme, initialBorderColor }) => theme.colors[initialBorderColor]};
  ${({ checked }) => {
    if (checked) {
      return css`
        border: 1px solid ${({ theme }) => theme.colors.primary};
        background-color: ${({ theme }) => theme.colors.primary} !important;
        &:after {
          content: '';
          position: absolute;
          left: 5px;
          top: 2px;
          width: 5px;
          height: 8px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      `;
    }
  }}
  ${({ focused }) => {
    if (focused) {
      return activeBoxStyle;
    }
  }}
`;

const StyledLabel = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  margin-bottom: 1px;
  -webkit-tap-highlight-color: transparent !important;
  &:hover {
    color: ${({ theme }) => theme.colors.tertiary};
    ${StyledBox} {
      ${activeBoxStyle}
    }
  }
`;

export const Checkbox = ({
  checked = false,
  label = 'I have seen all GOT episodes',
  className,
  dataCy,
  initialBorderColor = 'primary',
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  return (
    <StyledLabel data-cy={dataCy} className={className} checked={checked}>
      {label ? (
        <StyledSpanTextInput checked={checked} focused={focused}>
          {label}
        </StyledSpanTextInput>
      ) : null}
      <StyledBox
        initialBorderColor={initialBorderColor}
        checked={checked}
        focused={focused}
      />
      <StyledInputInvisible
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        type="checkbox"
        checked={checked}
        {...props}
      />
    </StyledLabel>
  );
};
