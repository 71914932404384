import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { routes } from 'config';
import { userInterfaceUpdate } from 'store/userInterface/userInterface.duck';
import { getShowHeaderPerPage } from 'store/userInterface/userInterface.selectors';

export const useResetScrollPosition = () => {
  const {
    location: { pathname },
  } = useReactRouter();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

const scrollWindowYPaths = {};
export const useSaveScrollPosition = () => {
  const {
    location: { pathname },
  } = useReactRouter();
  const scrollPosition = useRef(0);
  const handleScroll = useCallback(() => {
    scrollPosition.current = window.scrollY;
  }, []);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  useEffect(() => {
    const thisPathnameScrollWindowY = scrollWindowYPaths[pathname];
    if (thisPathnameScrollWindowY !== scrollPosition) {
      window.scrollTo(0, thisPathnameScrollWindowY);
    }
    return () => {
      if (scrollPosition) {
        scrollWindowYPaths[pathname] = scrollPosition.current;
      }
    };
  }, [pathname]);
};

const scrollPositionsPerPage = {};
export const useShowOrHideOnScroll = () => {
  const showHeaderPerPage = useSelector(getShowHeaderPerPage);
  const dispatch = useDispatch();
  const {
    location: { pathname },
    match: { path, url },
  } = useReactRouter();
  let matchingRouteName = null;
  Object.keys(routes).forEach(key => {
    const value = routes[key];
    if (value === path) matchingRouteName = key;
  });
  const showThisPageHeader = showHeaderPerPage[matchingRouteName];
  const treshHold = 150;
  const handleScroll = useCallback(() => {
    if (pathname === url) {
      scrollPositionsPerPage[matchingRouteName] = {
        old: scrollPositionsPerPage[matchingRouteName]
          ? scrollPositionsPerPage[matchingRouteName].new
          : 0,
        new: window.scrollY,
      };
      const thisPageScrollPosition = scrollPositionsPerPage[matchingRouteName];
      if (thisPageScrollPosition.old === thisPageScrollPosition.new) return;
      const shouldHideHeader =
        thisPageScrollPosition.old < thisPageScrollPosition.new &&
        thisPageScrollPosition.new > treshHold &&
        thisPageScrollPosition.old;
      if (shouldHideHeader && showThisPageHeader) {
        dispatch(
          userInterfaceUpdate({
            showHeaderPerPage: {
              ...showHeaderPerPage,
              [matchingRouteName]: false,
            },
          })
        );
      }
      if (!shouldHideHeader && !showThisPageHeader) {
        dispatch(
          userInterfaceUpdate({
            showHeaderPerPage: {
              ...showHeaderPerPage,
              [matchingRouteName]: true,
            },
          })
        );
      }
    }
  }, [
    dispatch,
    matchingRouteName,
    pathname,
    showHeaderPerPage,
    showThisPageHeader,
    url,
  ]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  return showThisPageHeader;
};
