import React from 'react';
import styled, { css } from 'styled-components';
import { Badge, LinkOrAnchor } from 'components';
import { onlyMobileStyle } from 'styled';

const StyledLi = styled.li`
  ${onlyMobileStyle}

  ${({ sidebar }) =>
    sidebar
      ? css`
          /* width: 100%; */
          &:first-of-type {
            margin-top: 0;
          }
          margin-top: ${({ theme }) => theme.space[4]}px;
          @media only screen and (max-width: ${({ theme }) =>
              theme.breakPoints[3]}) {
            margin-top: 12px;
          }
        `
      : css`
          @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
            width: 25%;
            margin-bottom: ${({ theme }) => theme.space[5]}px;
          }

          @media all and (min-width: ${({ theme }) =>
              theme.breakPoints[5]}) and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
            margin-bottom: ${({ theme }) => theme.space[9]}px;
          }
        `}
`;

const StyledSubwrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: ${({ theme }) => theme.breakPoints[3]}) {
    ${({ sidebar }) =>
      sidebar
        ? css`
            flex-direction: row;
          `
        : ''}
  }
`;

const StyledIconSuperWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 76px;
  height: 76px;

  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[5]}) {
    ${({ sidebar }) =>
      sidebar
        ? ''
        : css`
            width: 140px;
            height: 140px;
          `}
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakPoints[3]}) {
    ${({ sidebar }) =>
      sidebar
        ? css`
            width: 40px;
            height: 40px;
            margin-left: ${({ theme }) => theme.space[5]}px;
          `
        : ''}
  }

  border-radius: 50%;
  ${({ disabled }) =>
    disabled
      ? css`
          border: 1px solid rgba(255, 255, 255, 0.3);
        `
      : css`
          background-color: rgba(255, 255, 255, 0.2);
        `}
`;

const StyledIconWrapper = styled.div`
  position: absolute;
  height: 30px;
  width: 30px;
  .dynamic_icon {
    height: 100%;
    max-width: 100%;
    path,
    rect,
    circle {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[5]}) {
    ${({ sidebar }) =>
      sidebar
        ? ''
        : css`
            width: 50px;
            height: 50px;
          `}
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakPoints[3]}) {
    ${({ sidebar }) =>
      sidebar
        ? css`
            width: 20px;
            height: 20px;
          `
        : ''}
  }
  display: block;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: width 0.15s;
`;

const highlightLink = css`
  outline: none;
  ${StyledIconSuperWrapper} {
    /* border: 2px solid white; */
    background-color: white;
  }

  svg:not(.badgeLoader) {
    path,
    rect,
    circle {
      fill: ${({ theme }) => theme.colors.primary} !important;
    }
  }
`;

const StyledLink = styled(LinkOrAnchor)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};

  @media only screen and (max-width: ${({ theme }) => theme.breakPoints[3]}) {
    ${({ sidebar }) =>
      sidebar
        ? css`
            display: block;
          `
        : ''}
  }
  /* width: 100px; */
  ${({ disabled }) => {
    if (disabled) {
      return css`
        opacity: 0.3;
      `;
    }
  }}
  ${({ active }) =>
    active
      ? css`
          ${highlightLink}
        `
      : css``}
  &:hover,
  &:focus {
    ${highlightLink}
  }
`;

const StyledTitle = styled.h4`
  font-weight: normal;
  text-align: center;
  color: white;

  ${({ sidebar }) =>
    sidebar
      ? css`
          margin-top: ${({ theme }) => theme.space[1]}px;
          @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
            width: 80px;
          }
          @media only screen and (max-width: ${({ theme }) =>
              theme.breakPoints[3]}) {
            margin-top: 0px;
            margin-left: ${({ theme }) => theme.space[4]}px;
          }
        `
      : css`
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[5]}) {
            margin-top: ${({ theme }) => theme.space[5]}px;
          }
          margin-top: ${({ theme }) => theme.space[3]}px;
        `}
`;

const StyledBadge = styled(Badge)`
  right: -20px;
  top: -5px;

  ${({ sidebar }) =>
    sidebar
      ? css`
          @media only screen and (max-width: ${({ theme }) =>
              theme.breakPoints[3]}) {
            right: -25px;
            top: -12px;
          }
        `
      : css`
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[5]}) {
            right: -14px;
            top: -2px;
          }
        `}

  ${({ showInHamburgerMenu, sidebar }) => {
    if (showInHamburgerMenu && sidebar) {
      return css`
        display: none;
        @media only screen and (max-width: ${({ theme }) =>
            theme.breakPoints[3]}) {
          display: flex;
          left: 60px;
          top: -3px;
        }
      `;
    }
    if (!showInHamburgerMenu && sidebar) {
      return css`
        display: flex;
        @media only screen and (max-width: ${({ theme }) =>
            theme.breakPoints[3]}) {
          display: none;
        }
      `;
    }
    if (!showInHamburgerMenu) {
      return css`
        display: flex;
      `;
    }
    return css`
      display: none;
    `;
  }}
`;

const StyledRibbon = styled.div`
  position: absolute;
  left: 0;
  background-color: ${({ theme }) => theme.colors.blue[6]};
  color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  bottom: 4px;
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  padding: 1px 5px 0px 18px;
  ${({ sidebar }) =>
    sidebar
      ? css`
          @media only screen and (max-width: ${({ theme }) =>
              theme.breakPoints[3]}) {
            padding: 0px 5px 0px 5px;
          }
        `
      : css`
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakPoints[5]}) {
            bottom: ${({ theme }) => theme.space[3]}px;
            font-size: ${({ theme }) => theme.fontSizes[1]}px;
            padding: 3px 10px 2px 30px;
          }
        `}
`;

export const SectionLink = ({
  active,
  Icon,
  title = 'title',
  number = 0,
  loadingCount = false,
  disabled = false,
  to = '#',
  sidebar,
  onClick,
  onlyMobile,
  beta,
  t,
}) => {
  const commonBadgeProps = { loading: loadingCount, sidebar, number };
  return (
    <StyledLi onlyMobile={onlyMobile} sidebar={sidebar} disabled={disabled}>
      <StyledLink
        sidebar={sidebar}
        to={to}
        onClick={onClick}
        disabled={disabled}
        active={active}
        data-cy={disabled ? 'disabledLink' : 'nonDisabledLink'}
        data-tag="sectionTile"
      >
        {/* toString is necessary to make warning go away in console */}
        <StyledSubwrapper sidebar={sidebar}>
          <StyledIconSuperWrapper sidebar={sidebar} disabled={disabled}>
            <StyledIconWrapper sidebar={sidebar}>
              <Icon className="dynamic_icon" sidebar={sidebar} />
              <StyledBadge {...commonBadgeProps} showInHamburgerMenu={false} />
            </StyledIconWrapper>
            {beta ? (
              <StyledRibbon sidebar={sidebar}>{t('COMMON.BETA')}</StyledRibbon>
            ) : null}
          </StyledIconSuperWrapper>
          <StyledBadge {...commonBadgeProps} showInHamburgerMenu />
          <StyledTitle active={active} sidebar={sidebar}>
            {title}
          </StyledTitle>
        </StyledSubwrapper>
      </StyledLink>
    </StyledLi>
  );
};
