import React from 'react';

import { PreferencesTemplate } from 'components';

export const PreferencesPage = ({ t, ...props }) => (
  <PreferencesTemplate
    t={t}
    headerProps={{
      title: t('COMMON.PREFERENCES'),
      to: '/',
      search: false,
      onlyMobile: false,
      emptyDesktop: true,
      singlePage: true,
      detailDesktopBackgroundColor: true,
      goBackEnabled: true,
    }}
    {...props}
  />
);
