import React from 'react';
import styled, { css } from 'styled-components';
import {
  HomePage,
  MultipleTasksConfirmPage,
  MultipleTasksPage,
  NoConnectionPage,
  TaskDetailConfirmPage,
  TaskDetailPage,
  TaskListPage,
} from 'components';
import { routes } from 'config';

const StyledWrapper = styled.main`
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    display: flex;
  }
`;

const displayStickySafariFix = css`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    position: relative;
  }
`;

const StyledTaskListPage = styled(TaskListPage)`
  ${displayStickySafariFix};
  ${({ selectedTaskId }) =>
    selectedTaskId &&
    css`
      @media only screen and (max-width: ${({ theme }) =>
          theme.breakPoints[3]}) {
        display: none;
      }
    `}
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    width: 400px;
    height: 100vh;
    overflow-y: auto;
    border-right: 1px solid ${({ theme }) => theme.colors.lightGray};
    @media (prefers-color-scheme: dark) {
      border-right: 1px solid ${({ theme }) => theme.colors.lighterBlack};
    }
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[5]}) {
    width: 600px;
  }
  @media print {
    display: none;
  }
`;

const StyledDetailPageWrapper = styled.div`
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    height: 100vh;
    overflow: auto;
    width: calc(100% - (400px + 120px));
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[5]}) {
    width: calc(100% - (600px + 190px));
  }
`;

export const TaskListAndDetailWrapper = ({
  onlyShowContact,
  taskId,
  section,
  subsection,
  ...props
}) => (
  <StyledWrapper {...props}>
    {window.navigator.onLine ? (
      <>
        <HomePage sidebar={1} />
        <StyledTaskListPage
          id="infiniteScrollTargetDesktop"
          selectedTaskId={taskId}
        />
        {/* THE ID IS TO MAKE THE INFINITE SCROLL WORK */}
        <StyledDetailPageWrapper>
          {(() => {
            if (subsection === `${routes.multiple}-${routes.confirmReject}`) {
              return <MultipleTasksConfirmPage />;
            }
            if (subsection === routes.confirmReject) {
              return (
                <TaskDetailConfirmPage status="REJECTED" commentNoteRequired />
              );
            }
            if (subsection === routes.confirmApprove) {
              return (
                <TaskDetailConfirmPage
                  status="APPROVED"
                  commentNoteRequired={false}
                />
              );
            }
            if (taskId) {
              return <TaskDetailPage />;
            }
            return null;
          })()}
        </StyledDetailPageWrapper>
      </>
    ) : (
      <NoConnectionPage />
    )}
    <MultipleTasksPage />
  </StyledWrapper>
);
