import React from 'react';
import { useTranslation } from 'react-i18next';
import { Forenight } from 'icons';
import { TripDetailsTable } from './TripDetailsTable';

export const AccomodationDetailsTable = ({ rows }) => {
  const { t } = useTranslation();
  return (
    <TripDetailsTable
      dataCy="accomodationDetailsTable"
      headers={[
        {
          Icon: Forenight,
          label: t('TRIP_DETAILS.FORENIGHT'),
          viewBox: '0 0 26 27',
        },
      ]}
      rows={rows}
    />
  );
};
