import React from 'react';
import { MultipleTasksConfirmTemplate } from 'components';

export const MultipleTasksConfirmPage = ({ t, previousRoute, ...props }) => (
  <MultipleTasksConfirmTemplate
    headerProps={{
      title: t('MULTIPLE_TASKS_CONFIRM.TITLE'),
      to: previousRoute,
      search: false,
      onlyMobile: false,
      emptyDesktop: true,
      detailDesktopBackgroundColor: true,
    }}
    subtitle={t('MULTIPLE_TASKS_CONFIRM.SUBTITLE')}
    description={t('MULTIPLE_TASKS_CONFIRM.DESCRIPTION')}
    previousRoute={previousRoute}
    {...props}
  />
);
