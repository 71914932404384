import React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyValue } from './KeyValue';

export const KeyValueContainer = ({ showOnlyIfAvailable, ...props }) => {
  const { t } = useTranslation();
  const { valueText } = props;
  if (!valueText && showOnlyIfAvailable) return null;
  return <KeyValue t={t} {...props} />;
};
