import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from 'hooks/user';
import { getFirstAndLastName } from 'utils';
import { Metroline } from './Metroline';

export const MetrolineTravelAndExpensesContainer = ({
  task: {
    approvalSequence,
    tripDetails: {
      status,
      employee,
      requestDate,
      respondDate,
      rejectionReason,
    },
    approval,
    ...task
  },
  ...props
}) => {
  const requestor = (() => {
    if (!employee) return null;
    return {
      ...getFirstAndLastName(employee.name),
      ...employee,
    };
  })();
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const approver = (() => {
    if (approval?.approver?.name) {
      return {
        ...currentUser,
        ...approval.approver,
      };
    }
    return currentUser;
  })();
  const customApprovalSequence = [
    {
      status,
      date: respondDate,
      ...approval,
      approver,
    },
  ];
  return (
    <Metroline
      taskStatus={status}
      requestor={requestor}
      rejectionReason={rejectionReason}
      approvalSequence={customApprovalSequence}
      requestDate={requestDate}
      t={t}
      {...task}
      {...props}
    />
  );
};
