import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { resetButtonLayout } from 'styled';

const StyledForm = styled.form`
  height: 50px;
  width: calc(100% / 3);
  display: flex;
`;

const StyledSpan = styled.span`
  ${({ selected, theme }) =>
    selected
      ? css`
          color: ${theme.colors.tertiary};
          font-weight: bold;
          @media (prefers-color-scheme: dark) {
            color: ${theme.colors.white};
          }
        `
      : css`
          color: ${theme.colors.secondary};
          @media (prefers-color-scheme: dark) {
            color: ${theme.colors.darkerWhite};
          }
        `}
  font-size: 14px;
  text-transform: uppercase;
  user-select: none;
`;

const StyledButton = styled.button`
  ${resetButtonLayout}
  width: 100%;
  &:focus {
    outline: none;
    background-color: ${({ theme }) => theme.colors.focus};
    @media (prefers-color-scheme: dark) {
      background-color: ${({ theme }) => theme.colors.focusDark};
    }
  }
`;

export const Tab = forwardRef(
  ({ onSubmit, value, children, selected }, ref) => (
    <StyledForm onSubmit={event => onSubmit(event, value)}>
      <StyledButton type="submit" data-cy={`tab${value}`}>
        <StyledSpan ref={ref} selected={selected}>
          {children}
        </StyledSpan>
      </StyledButton>
    </StyledForm>
  )
);
