import React from 'react';
import styled from 'styled-components';
import { StyledSeperator } from 'styled';

const StyledWrapper = styled.div`
  margin: ${({ theme }) => theme.space[4]}px;
`;

const StyledH4 = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  margin-bottom: ${({ theme }) => theme.space[2]}px;
`;

const StyledParagraph = styled.p`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  max-width: 350px;
`;

export const TaskListLastItem = ({ searchIsActive, tasks, filter, t }) => {
  if (!tasks.allDataFetched || filter === 'PENDING') return null;

  return (
    <>
      <StyledWrapper>
        <StyledH4>
          {t(`TASK_LIST.LAST_ITEM.TITLE.${searchIsActive ? 'SEARCH' : filter}`)}
        </StyledH4>
        <StyledParagraph>
          {t('TASK_LIST.LAST_ITEM.DESCRIPTION')}
        </StyledParagraph>
      </StyledWrapper>
      <StyledSeperator marginRight />
    </>
  );
};
